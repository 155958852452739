import React, { useState, useEffect, useContext } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import "./calender.css";

//Mui Componants
import { Button, Stack } from "@mui/material";

//Mui Icons
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import ContentPasteIcon from "@mui/icons-material/ContentPaste";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

import { IconPlus } from "@tabler/icons";
import GlobalContext from "../../../../context/GlobalContext";
import LibraryModal from "../../../../components/LibraryModal";

const localizer = momentLocalizer(moment);
const DragAndDropCalendar = withDragAndDrop(Calendar);

const DragAndDropCalendarComponent = (props) => {
  const { setShowLibraryModal, setDaySelected, showLibraryModal } =
    useContext(GlobalContext);
  const {
    data,
    setmasterData,
    setFormData,
    seteventformdrawerState,
    setworkoutformdrawerState,
    setdraggedEvent,
    draggedEvent,
  } = props;

  const [events, setEvents] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedSlotInfo, setSelectedSlotInfo] = useState(null);
  const [copyEvent, setCopyEvent] = useState(null);
  const [hiddenDays, setHiddenDays] = useState([]);

  useEffect(() => {
    setEvents(data);
  }, [data]);

  const moveEvent = ({ event, start, end }) => {
    const idx = events.indexOf(event);
    const updatedEvent = { ...event, start, end };

    const nextEvents = [...events];
    nextEvents.splice(idx, 1, updatedEvent);
    setEvents(nextEvents);
    setmasterData(nextEvents);
    localStorage.setItem("allEvents", JSON.stringify(nextEvents));
  };

  const resizeEvent = ({ event, start, end }) => {
    const nextEvents = events.map((existingEvent) => {
      return existingEvent.id === event.id
        ? { ...existingEvent, start, end }
        : existingEvent;
    });
    setEvents(nextEvents);
    setmasterData(nextEvents);
    localStorage.setItem("allEvents", JSON.stringify(nextEvents));
  };

  const newEvent = (event, type) => {
    const idList = events.length + 1 || 1;
    const nextEvents = [...events];

    if (type === "single") {
      const hour = {
        id: idList,
        title: event.title,
        allDay: event.isAllDay,
        start: event.start,
        end: event.end,
        calender: event.calender,
      };
      nextEvents.push(hour);
    } else {
      event.forEach((item) => {
        const hour = {
          id: idList,
          title: item.title,
          allDay: item.isAllDay,
          start: item.start,
          end: item.end,
          calender: item.calender,
        };
        nextEvents.push(hour);
      });
    }

    setEvents(nextEvents);
    setmasterData(nextEvents);
    localStorage.setItem("allEvents", JSON.stringify(nextEvents));
  };

  const onDropFromOutside = ({ start, end, allDay }) => {
    //const { draggedEvent, counters } = this.state;
    const event = {
      title: draggedEvent.title, //formatName(draggedEvent.name, counters[draggedEvent.name])
      start,
      end,
      isAllDay: allDay,
      calender: draggedEvent.calender,
    };
    setdraggedEvent(null);
    newEvent(event, "single");
  };

  //when any event to edit
  const onEventSelect = (eventInfo) => {
    const { calender, end, start, id, title } = eventInfo;
    const eventObject = {
      calender: calender,
      end: end, //end.slice(0, 10),
      start: start, //start.slice(0, 10),
      id: id,
      title: title,
      isEditForm: true,
    };
    setFormData(eventObject);
    //seteventformdrawerState(true);
    setworkoutformdrawerState(true);
  };

  const handleSelectSlot = (slotInfo) => {
    const { start, end } = slotInfo;
    setSelectedSlotInfo(slotInfo);
    const selectedEvents = events.filter((event) => {
      return (
        moment(event.start).isSameOrBefore(start, "minute") &&
        moment(event.end).isSameOrAfter(end, "minute")
      );
    });

    setSelectedSlotInfo({ slotInfo: slotInfo, eventList: selectedEvents });
  };

  const deleteSlotEventList = () => {
    console.log("bsdnbansfvsavfbv", "sdksjdbashgdhsgah");
    const nextEvents = events.filter((event) => {
      return !selectedSlotInfo.eventList.includes(event);
    });
    setEvents(nextEvents);
    console.log("nextEvents",nextEvents);
    setmasterData(nextEvents);
    localStorage.setItem("allEvents", JSON.stringify(nextEvents));
  };

  const copySlotEventList = () => {
    setCopyEvent(selectedSlotInfo.eventList);
  };

  const pasteSlotEventList = () => {
    const slotInfo = selectedSlotInfo.slotInfo;
    const newArray = copyEvent.map((event) => ({
      title: event.title,
      allDay: event.allDay,
      start: new Date(slotInfo.start).toString(),
      end: new Date(slotInfo.end).toString(),
      calender: event.calender,
      id: event.id,
    }));

    newEvent(newArray, "array");
  };

  const toggleHideDay = (day) => {
    setHiddenDays((prevHiddenDays) => {
      const updatedHiddenDays = prevHiddenDays.includes(day)
        ? prevHiddenDays.filter((hiddenDay) => hiddenDay !== day)
        : [...prevHiddenDays, day];
      return updatedHiddenDays;
    });
  };

  //this function used for event color changing and hide/unhide events
  const eventStyleGetter = (event, start, end, isSelected) => {
    const eventDay = moment(start).format("YYYY-MM-DD");
    const isHidden = hiddenDays.includes(eventDay);
    let backgroundColor, color;

    switch (event.calender) {
      case "Personal":
        backgroundColor = "#FCE7E7"; //  #FFE57373
        color = "#EB5555";
        break;

      case "Business":
        backgroundColor = "#E7E5FC"; //  #FF7986CB
        color = "#7B68EE";
        break;

      //E7E5FC, DCF5E6, D6F6FB,  FFEEDF,  FBE2E2
      case "Family":
        backgroundColor = "#FFEEDF"; //  #FFFFCC80
        color = "#FF9F43";
        break;

      case "Holiday":
        backgroundColor = "#DCF5E6"; //  #FF81C784
        color = "#28C76E";
        break;

      case "ETC":
        backgroundColor = "#D6F6FB"; //  #FF81D4FA
        color = "#55DEEF";
        break;

      default:
        break;
    }

    return {
      style: {
        display: isHidden ? "none" : "block",
        backgroundColor,
        color,
        marginLeft: "10px",
        width: "90%",
      },
    };
  };

  const CalendarToolbar = ({ label, localizer, onNavigate, onView, views }) => (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        marginTop: "2%",
      }}
    >
      <div style={{ display: "flex", justifyContent: "left" }}>
        <button onClick={() => onNavigate("PREV")} className="text-gray-500">
          <ArrowBackIosIcon />
        </button>
        <button
          onClick={() => onNavigate("NEXT")}
          className="text-gray-500 ml-2"
        >
          <ArrowForwardIosIcon />
        </button>
        <p
          style={{ marginTop: "10px" }}
          onClick={() => onNavigate("TODAY")}
          className="ml-4 text-xl text-gray-500 font-bold"
        >
          {label}
        </p>
      </div>
      <div className="mb-4" style={{ backgroundColor: "rgb(37, 99, 235)" }}>
        <button
          onClick={() => onView("month")}
          style={{
            color: "white",
            borderRight: "1px solid ",
          }}
          className="p-2"
        >
          Month
        </button>
        <button
          // onClick={() => onView("week")}
          style={{
            color: "white",
            borderRight: "1px solid ",
           
          }}
          className="p-2"
        >
          Week
        </button>
        <button
          // onClick={() => onView("day")}
          style={{
            borderRight: "1px solid ",
            color: "white",
            
          }}
          className="p-2"
        >
          Day
        </button>
        <button
          // onClick={() => onView("agenda")}
          style={{
            color: "white",
            marginRight: "5px",
            
          }}
          className="p-2"
        >
          List
        </button>
      </div>
    </div>
  );

  const handleLibrary = () => {
    setDaySelected(1);
    setShowLibraryModal(true);
  };

  const DateHeader = ({ label, drilldownView, onDrillDown }) => {
    return (
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div className="p-1" onClick={handleLibrary}>
          <IconPlus color="darkgray" className="cursor-pointer" />
        </div>
        <header className="flex flex-col items-center text-slate-800">
          <p className="text-sm p-1 my-1 text-center text-slate-800 text-black">
            {label}
          </p>
        </header>
        <span>
          <button
            onClick={() => setIsOpen(true)}
            // style={{
            //   color: "black",
            //   background: "white",
            //   border: "0px",
            // }}
            className="text-gray-500 p-1"
          >
            <MoreHorizIcon /> {/*  ...{ label, drilldownView, onDrillDown } */}
          </button>
        </span>
      </div>
    );
  };

  return (
    <>
      {showLibraryModal ? (
        <div className="fixed z-50">
          <LibraryModal /> {/**userId={userId} */}
        </div>
      ) : (
        ""
      )}
      <div style={{ position: "relative" }}>
        {selectedSlotInfo !== null && (
          <div
            onClick={() => {
              setIsOpen(!isOpen);
            }}
            style={{
              visibility: isOpen ? "visible" : "hidden",
              zIndex: 999999,
              opacity: isOpen ? "1" : "0",
              position: "fixed",
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
              background: "rgba(0, 0, 0, 0.7)",
            }}
          >
            <Stack
              spacing={1}
              direction="column"
              style={{
                // margin: "70px",
                padding: "5px",
                background: "#fff",
                borderRadius: "5px",
                width: "10%",
                position: "relative",
                top: `${selectedSlotInfo.slotInfo.box.y}px`,
                left: `${selectedSlotInfo.slotInfo.box.x}px`,
              }}
            >
              <Button
                startIcon={<VisibilityOffIcon />}
                size="small"
                onClick={() =>
                  toggleHideDay(
                    moment(selectedSlotInfo.slotInfo.start).format("YYYY-MM-DD")
                  )
                }
              >
                Hide All
              </Button>
              <Button
                startIcon={<VisibilityIcon />}
                size="small"
                onClick={() =>
                  toggleHideDay(
                    moment(selectedSlotInfo.slotInfo.start).format("YYYY-MM-DD")
                  )
                }
              >
                Unhide All
              </Button>
              <Button
                startIcon={<ContentCopyIcon />}
                size="small"
                onClick={copySlotEventList}
              >
                Copy
              </Button>
              <Button
                startIcon={<ContentPasteIcon />}
                size="small"
                onClick={pasteSlotEventList}
              >
                Paste
              </Button>
              <Button
                startIcon={<DeleteForeverIcon />}
                size="small"
                onClick={deleteSlotEventList}
              >
                Delete......
              </Button>
            </Stack>
          </div>
        )}
      </div>
      <DragAndDropCalendar
        localizer={localizer}
        events={events}
        defaultView="month"
        onEventDrop={moveEvent}
        onEventResize={resizeEvent}
        resizable
        selectable
        style={{ height: "100vh" }}
        views={["month", "week", "day"]} //["month", "week", "day"]
        onSelectEvent={(eventInfo) => {
          onEventSelect(eventInfo);
        }}
        eventPropGetter={eventStyleGetter}
        components={{
          toolbar: CalendarToolbar,
          dateHeader: DateHeader,
        }}
        popupOffset={{ x: 0, y: -133 }}
        popup
        onDropFromOutside={onDropFromOutside}
        onSelectSlot={handleSelectSlot}
      />
    </>
  );
};

export default DragAndDropCalendarComponent;
