import React from 'react'

const GlobalContext = React.createContext({
  monthIndex: 0,
  setMonthIndex: (index) => {},
  smallCalendarMonth: 0,
  setSmallCalendarMonth: (index) => {},
  daySelected: null,
  setDaySelected: (day) => {},
  showEventModal: false,
  setShowEventModal: () => {},
  showLibraryModal: false,
  setShowLibraryModal: () => {},
  showBlockModal: false,
  setShowBlockModal: () => {},
  dispatchCalEvent: ({ type, payload }) => {},
  savedEvents: [],
  selectedEvent: null,
  setSelectedEvent: () => {},
  setLabels: () => {},
  labels: [],
  updateLabel: () => {},
  filteredEvents: [],
  user: '',
  setUser: () => {},
  isLoggedIn: false,
  setIsLoggedIn: () => {},
  isRemember: true,
  setIsRemember: () => {},
  workoutMode: false,
  setWorkoutMode: () => {}
})

export default GlobalContext
