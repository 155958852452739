// ** React Imports
import React, { useState, useEffect } from "react";

// ** MUI Components
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import CardContent from "@mui/material/CardContent";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import { styled, useTheme } from "@mui/material/styles";
import MuiCard from "@mui/material/Card";
import InputAdornment from "@mui/material/InputAdornment";
import MuiFormControlLabel from "@mui/material/FormControlLabel";
import LoadingButton from "@mui/lab/LoadingButton";

// ** Icons Imports
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { Resetpassword } from "../../API/api-endpoint";
import Background from "../../Images/Background.png";
import { FormHelperText, FormLabel, Grid } from "@mui/material";
import SlickCarousel from "../SlickCarousel";

// ** Styled Components
const Card = styled(MuiCard)(({ theme }) => ({
  [theme.breakpoints.up("sm")]: { width: "28rem" },
}));

const ResetPassword = () => {
  const [values, setValues] = useState({
    confirmpassword: "",
    password: "",
    showPassword: false,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [token, setToken] = useState(null);
  const [dLink, setDlink] = useState(null);

  console.log("isValid", dLink);
  const validatePassword = (password) => {
    const passwordRegex = /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[\W_]).{8,}$/;
    return passwordRegex.test(password);
  };
  const navigate = useNavigate();

  useEffect(() => {
    const currentURL = window.location.href;
    setDlink(currentURL.split("?")[2].split("=")[1])
    setToken(currentURL.split("?")[1])
  }, []);

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClickShowPassword = (keyvalue) => {
    setValues({ ...values, showPassword: !values.showPassword });
  };
  const handleClickShowConfirmPassword = (keyvalue) => {
    setValues({ ...values, showConfirmPassword: !values.showConfirmPassword });
  };
  const onSubmit = async () => {
    if (values.confirmpassword == "" || values.password == "") {
      Swal.fire({
        title: "Error",
        text: "Please Enter Passwords",
        icon: "error",
      });
    } else if (values.confirmpassword === values.password) {
      if (validatePassword(values.password)) {
        setIsLoading(true);
        console.log(typeof values.password)
        let result = await Resetpassword(values.password, token);
        console.log("result", result);
        if (result.status) {
          Swal.fire({
            title: "Success",
            text: "Password Reset successful",
            icon: "success",
          });
          setIsLoading(false);
          const openNewTab = (url) => {
            const newTab = window.open(dLink, '_blank');
            if (newTab) {
              newTab.opener = null; // Prevents the new tab from having access to the opener window
            }
          };
          if (isMobile) {
            openNewTab()
          } else {

            navigate("/");
          }
        } else {
          Swal.fire({
            title: "Error!!",
            text: result?.error ? "Link Expired" : result?.message,
            icon: "error",
          });
          setIsLoading(false);
        }
      }

    } else {
      Swal.fire({
        title: "Error!!",
        text: "Both Password is not matched",
        icon: "error",
      });
    }
  };
  const isPasswordMatch = values.confirmpassword && values.password !== values.confirmpassword;
  const passwordErrorText = isPasswordMatch ? "Passwords do not match" : "";
  console.log("validatePassword(values.password)", validatePassword(values.password));

  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    const checkDeviceType = () => {
      const userAgent = window.navigator.userAgent;
      setIsMobile(
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent)
      );
    };
    checkDeviceType();
    // Listen to window resize if needed
    const handleResize = () => {
      checkDeviceType();
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return (
    <Box
      className="content-center"
      style={{
        width: "100vw",
        padding: "40px",
        height: "100vh", // 100% of the viewport height
      }}
    >
      <Grid container>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={7} style={{
            // display: "flex",
            // justifyContent: "center",
            padding: "20px"
          }}>
            <div >
              <div>
                <Box style={{ marginTop: "80px", marginBottom: "40px" }}>
                  <Typography variant="h4" sx={{ fontWeight: "700", fontSize: "35px" }}>Reset <span style={{ color: "#FFA654" }}>Password</span></Typography>
                </Box>
                <form autoComplete="off" onSubmit={onSubmit} className="form1">
                  <Grid sx={{ display: 'flex', flexDirection: 'column' }} container spacing={2}>
                    <Grid item xs={12} sm={8}>
                      <FormControl fullWidth>
                      <FormLabel sx={{ fontSize: "14px", fontWeight: "600", color: "background: rgba(13, 20, 28, 1)", marginBottom: "10px" }} >Password</FormLabel>
                        <OutlinedInput
                          value={values.password}
                          id="auth-login-password"
                          onChange={handleChange("password")}
                          required
                           placeholder="Enter your password"
                          type={values.showPassword ? "text" : "password"}

                          endAdornment={
                            <InputAdornment style={{ marginRight: "15px" }} position="end">
                              <IconButton
                                edge="end"
                                onClick={() => handleClickShowPassword("showPassword")}
                                aria-label="toggle password visibility"
                              >
                                {values.showPassword ? (
                                  <VisibilityIcon />
                                ) : (
                                  <VisibilityOffIcon />
                                )}
                              </IconButton>
                            </InputAdornment>
                          }
                        />
                        <FormHelperText style={{ color: "#d32f2f" }}>
                          {values.password && !validatePassword(values.password) && "Password must be at least 8 characters long and contain a number and a special character"}
                        </FormHelperText>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                      <FormControl fullWidth>
                      <FormLabel sx={{ fontSize: "14px", fontWeight: "600", color: "background: rgba(13, 20, 28, 1)", marginBottom: "10px" }} >Confirm Password</FormLabel>
                        <OutlinedInput
                          value={values.confirmpassword}
                          id="auth-login-password"
                          onChange={handleChange("confirmpassword")}
                          required
                           placeholder="Confirm your password"
                          error={passwordErrorText}
                          helperTes
                          type={values.showConfirmPassword ? "text" : "password"}
                          endAdornment={
                            <InputAdornment style={{ marginRight: "15px" }} position="end">
                              <IconButton
                                edge="end"
                                onClick={() => handleClickShowConfirmPassword("showConfirmPassword")}
                                aria-label="toggle password visibility"
                              >
                                {values.showConfirmPassword ? (
                                  <VisibilityIcon />
                                ) : (
                                  <VisibilityOffIcon />
                                )}
                              </IconButton>
                            </InputAdornment>
                          }
                        />
                        <FormHelperText style={{ color: "#d32f2f" }}>
                          {passwordErrorText}
                        </FormHelperText>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <LoadingButton
                        fullWidth
                        size="large"
                        variant="contained"
                        sx={{
                          color: "white",
                          backgroundColor: "#FFA654",
                          '&:hover': {
                            backgroundColor: '#FFA654', // Change to your desired hover color
                          },
                        }}
                        onClick={() => onSubmit()}
                        loading={isLoading}
                        loadingPosition="start"
                      >
                        Reset Password
                      </LoadingButton>
                    </Grid></Grid>

                </form>
              </div>
            </div>
          </Grid>

          <Grid item xs={12} sm={5}  >
            <SlickCarousel />
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ResetPassword;
