import {
    FormLabel,
    Grid,
    MenuItem,
    OutlinedInput,
    TextField,
  } from "@mui/material";
  import { Button, Modal } from "antd";
  import React, { useEffect, useState } from "react";
  import {
    createOptiondata,
    getAllQuestionData,
    updateOptiondata,
  } from "../../API/api-endpoint";
  import { useFormik } from "formik";
  import Swal from "sweetalert2";
  import SlickCarousel from "../../pages/SlickCarousel";
  const CreateOption = ({
    fetchReport,
    setShowAssesmentModal,
    showAssesmentModal,
    editData,
    setEditData,
  }) => {
    const [questionList, setQuestionList] = useState([]);
    const formik = useFormik({
      initialValues: {
        weightage: "",
        question_id: "",
        option_text: "",
        lower_limit: null,
        upper_limit: null

      },
      validate: (values) => {
        const errors = {};
        if (!values.question_id) {
          errors.question_id = "Question is required";
        }
        if (!values.weightage) {
          errors.weightage = "Weightage is required";
        }
        if (!values.option_text) {
          errors.option_text = "Option is required";
        }
        // if (!values.lower_limit) {
        //   errors.lower_limit = "Lower limit is required";
        // }
        // if (!values.upper_limit) {
        //   errors.upper_limit = "Upper limit is required";
        // }
        return errors;
      },
      // validationSchema: {},
      onSubmit: (values, { resetForm }) => {
        handleSubmitAssesmentForm(values, resetForm);
      },
    });
    const QuestionData = async () => {
      const response = await getAllQuestionData();
      setQuestionList(response);
    };
    useEffect(() => {
      QuestionData();
    }, []);
  
    const handleSubmitAssesmentForm = async (data, resetForm) => {
      let response = "";
      if (editData?.id) {
        response = await updateOptiondata(data);
      } else {
        response = await createOptiondata(data);
      }
      if (response?.status) {
        Swal.fire({
          title: "Success",
          text: response.message,
          icon: "success",
        });
        setEditData({});
        setShowAssesmentModal(false);
        fetchReport();
        formik.resetForm();
        formik?.setValues({
          weightage: "",
          option_text: "",
          upper_limit: "",
          lower_limit: "",
        });
      } else {
        Swal.fire({
          title: "Error",
          text: response.message,
          icon: "error",
        });
      }
      console.log("response", response);
    };
    useEffect(() => {
      if (editData?.id) {
        const { srID, ...data } = editData;
        console.log("data", data);
  
        formik?.setValues(data);
        formik?.setFieldValue("comments", data?.comments ? data?.comments : "");
      } else {
        setEditData({});
      }
    }, [editData?.id]);
  
    return (
      <Modal
        width={1200}
        open={showAssesmentModal}
        onCancel={() => {
          setShowAssesmentModal(false);
          setEditData({});
          formik.resetForm();
          formik?.setValues({
            weightage: "",
            option_text: "",
            upper_limit: "",
            lower_limit: "",
          });
        }}
        footer={
          <div></div>
          //   loading={isLoading}
        }
      >
        <div className="headingCont">
          <span className="heading">{editData?.id ? "Edit " : "Create"}</span>{" "}
          <span className="orange heading">Option</span>
        </div>
  
        <div className="parentCont">
          <form className="form1" onSubmit={formik.handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={11}>
                <FormLabel>Question<span className="text-[red]">*</span></FormLabel>
  
                <TextField
                  fullWidth
                  size="small"
                  select
                  name="question_id"
                  SelectProps={{
                    MenuProps: {
                      PaperProps: {
                        style: {
                           scrollbarColor:"#E67E22 white",
                           scrollbarWidth:"thin"
                        },
                      },
                    },
                  }}
                  value={
                    formik?.values?.question_id ? formik?.values?.question_id : ""
                  }
                  onChange={formik.handleChange}
                  error={formik.touched.question_id && formik.errors.question_id}
                  helperText={
                    formik.touched.question_id && formik.errors.question_id
                  }
                  id="form-layouts-separator-select"
                  labelId="form-layouts-separator-select-label"
                  input={<OutlinedInput id="select-multiple-language" />}
                >
                  <MenuItem value={""} disabled>
                    Select Name
                  </MenuItem>
                  {questionList?.map((value, index) => {
                    return (
                      <MenuItem value={value?.id}>{value?.question}</MenuItem>
                    );
                  })}
                </TextField>
              </Grid>
              <Grid item xs={12} sm={11}>
                <FormLabel>Weightage<span className="text-[red]">*</span></FormLabel>
  
                <TextField
                  fullWidth
                  placeholder="weightage"
                  size="small"
                  type="text"
                  name="weightage"
                  value={formik?.values?.weightage}
                  onChange={formik.handleChange}
                  error={formik.touched.weightage && formik.errors.weightage}
                  helperText={formik.touched.weightage && formik.errors.weightage}
                />
              </Grid>
              <Grid item xs={12} sm={11}>
                <FormLabel>Option Text<span className="text-[red]">*</span></FormLabel>
  
                <TextField
                  fullWidth
                  placeholder="option text"
                  size="small"
                  type="text"
                  name="option_text"
                  value={formik?.values?.option_text}
                  onChange={formik.handleChange}
                  error={formik.touched.option_text && formik.errors.option_text}
                  helperText={
                    formik.touched.option_text && formik.errors.option_text
                  }
                />
              </Grid>
              <Grid item xs={12} sm={11}>
                <FormLabel>Lower Limit</FormLabel>
  
                <TextField
                  fullWidth
                  placeholder="lower limit"
                  size="small"
                  type="text"
                  name="lower_limit"
                  value={formik?.values?.lower_limit}
                  onChange={formik.handleChange}
                  error={formik.touched.lower_limit && formik.errors.lower_limit}
                  helperText={
                    formik.touched.lower_limit && formik.errors.lower_limit
                  }
                />
              </Grid>
              <Grid item xs={12} sm={11}>
                <FormLabel>Upper Limit</FormLabel>
  
                <TextField
                  fullWidth
                  placeholder="upper limit"
                  size="small"
                  type="text"
                  name="upper_limit"
                  value={formik?.values?.upper_limit}
                  onChange={formik.handleChange}
                  error={formik.touched.upper_limit && formik.errors.upper_limit}
                  helperText={
                    formik.touched.upper_limit && formik.errors.upper_limit
                  }
                />
              </Grid>
              <Grid item xs={12} sm={11}>
                <FormLabel>Is quantative</FormLabel>
  
                <TextField
                  fullWidth
                  size="small"
                  select
                  name="is_quantative"
                  value={
                    formik?.values?.is_quantative
                      ? formik?.values?.is_quantative
                      : ""
                  }
                  onChange={formik.handleChange}
                  error={
                    formik.touched.is_quantative && formik.errors.is_quantative
                  }
                  helperText={
                    formik.touched.is_quantative && formik.errors.is_quantative
                  }
                  id="form-layouts-separator-select"
                  labelId="form-layouts-separator-select-label"
                  input={<OutlinedInput id="select-multiple-language" />}
                >
                  <MenuItem value={""} disabled>
                    Select
                  </MenuItem>
  
                  <MenuItem value={"true"}>True</MenuItem>
                  <MenuItem value={"false"}>False</MenuItem>
                </TextField>
              </Grid>
  
              <Grid item xs={12} sm={6}>
              <Button
                className="btn"
                key="submit"
                type="primary"
                onClick={() => formik.handleSubmit()}
              >
                Submit
              </Button>
              </Grid>
            </Grid>
          </form>
  
          <div className="slick-container">
            <SlickCarousel />
          </div>
        </div>
      </Modal>
    );
  };
  export default CreateOption;
  