import React, { useEffect, useMemo, useState } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";
import { Box } from "@mui/system";
import { Button, CircularProgress,  Grid,  Pagination, TextField } from "@mui/material";
import { useNavigate } from "react-router";
import "../../components/AssignCoach/Assign.css";

import Header from "../../components/Header";
import { getAllrenderAssesment } from "../../API/api-endpoint";
import MenuItem from "@mui/material/MenuItem";

// let PageSize = 15;
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#1e40af",
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
        border: 0,
    },
}));
const RenderAssesmentData = () => {
    const [reportData, setReportData] = useState()
    const [isLoading, setIsLoading] = useState(true)
    const [searchTerm, setSearchTerm] = useState('');


    const fetchReport = async () => {
        const response = await getAllrenderAssesment()
        setIsLoading(false)

        setReportData(JSON.parse(response))
    }
    useEffect(() => {
        fetchReport()
    }, [])

    const [currentPage, setCurrentPage] = useState(1);
    const [selectedActivityFilter, setSelectedActivityFilter] = useState('All');

    const handleActivityFilterChange = (event) => {
        setSelectedActivityFilter(event.target.value);
        setCurrentPage(1); // Reset the current page when the filter changes
    };

    const filteredList = useMemo(() => {
        return reportData?.Segments?.filter((segment) => {
            if (selectedActivityFilter === 'All') {
                return segment.name.toLowerCase().includes(searchTerm.toLowerCase());
            } else {
                return (
                    segment.name.toLowerCase().includes(searchTerm.toLowerCase()) &&
                    segment.name.toLowerCase().trim() === selectedActivityFilter.toLowerCase()
                );
            }
        });
    }, [reportData, selectedActivityFilter, searchTerm]);
    let PageSize = searchTerm || selectedActivityFilter !== 'All' ? filteredList?.length : 15;
    const checkLastPage = useMemo(() => {
        let frstPgae = (currentPage - 1) * PageSize;
        let lastPage = frstPgae + PageSize;
        return filteredList?.slice(frstPgae, lastPage)?.map((row, index) => ({
            ...row,
            srID: index + 1 + (currentPage > 1 ? frstPgae : 0),
        }));
    }, [currentPage, reportData,filteredList]);
    const handlePageChange = (event, page) => {
        setCurrentPage(page);
    };
    console.log("currentPage", currentPage);
    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
        setCurrentPage(1); // Reset the current page when the search term changes
    };
    return (
        <div>
            <Header />
            <div className="grid grid-cols-1 xl:grid-cols-5 items-start gap-x-4"></div>
            <div style={{ marginTop: "100px", padding: "20px" }}>
                <TableContainer component={Paper}>
                    <div style={{ fontSize: "18px", background: "#FFEADC", width: "100%", padding: "10px" }}>
                        <Grid container spacing={0}>
                        <Grid item xs={12} sm={1.5} sx={{ marginTop: "30px" }}>
                                <a href="/assesmentname">
                                    <Button variant="contained">Get Assessment</Button>
                                </a>
                            </Grid>
                            <Grid item xs={12} sm={1.5} sx={{ marginTop: "30px" }}>
                                <a href="/segments">
                                    <Button variant="contained" >Get Segments</Button>
                                </a>
                            </Grid>
                            <Grid item xs={12} sm={1.8} sx={{ marginTop: "30px" }}>
                                <a href="/sub-segments">
                                    <Button variant="contained" >Get Sub Segments</Button>
                                </a>
                            </Grid>
                            <Grid item xs={12} sm={1.5} sx={{ marginTop: "30px" }}>
                                <a href="/questions">
                                    <Button variant="contained" >Get Questions</Button>
                                </a>
                            </Grid>
                            <Grid item xs={12} sm={1.5} sx={{ marginTop: "30px" }}>
                                <a href="/option">
                                    <Button variant="contained" >Get Option</Button>
                                </a>
                            </Grid>
                            <Grid item xs={12} sm={2} sx={{ textAlign: "end", marginTop: "30px" }}>
                                <TextField
                                    select
                                    size="small"
                                    label="Filter by Activity"
                                    value={selectedActivityFilter}
                                    onChange={handleActivityFilterChange}
                                    style={{ width: '150px' }} 
                                >
                                    <MenuItem value="All">All</MenuItem>
                                    <MenuItem value="Wellness">Wellness</MenuItem>
                                    <MenuItem value="Mindfulness">Mindfulness</MenuItem>
                                    <MenuItem value="Fitness">Fitness</MenuItem>
                                    <MenuItem value="Nutrition">Nutrition</MenuItem>
                                </TextField>
                            </Grid>
                            <Grid item xs={12} sm={2} sx={{ textAlign: "end",marginTop: "30px" }}>
                            <TextField type="text" size="small" value={searchTerm} onChange={handleSearchChange} placeholder="Search By Name.." />
                        </Grid>

                        </Grid>
                    </div>
                    <TableContainer style={{ maxHeight: 550,scrollbarWidth:"none" }}>
                    <Table stickyHeader sx={{ minWidth: 700,}} aria-label="customized table">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell align="left">Sr ID</StyledTableCell>
                                <StyledTableCell align="left">Name</StyledTableCell>
                                <StyledTableCell align="left">Percentage</StyledTableCell>


                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {isLoading ? (
                                <CircularProgress className="m-6" />) : (
                                <>
                                    {checkLastPage?.length > 0 ? (
                                        <>
                                            {checkLastPage?.map((row, index) => (
                                                <StyledTableRow key={index}>
                                                    <StyledTableCell align="left">
                                                        {row?.srID}
                                                    </StyledTableCell>
                                                    <StyledTableCell align="left">
                                                        {row?.name}
                                                    </StyledTableCell>
                                                    <StyledTableCell align="left">
                                                        {row?.segment_percentage}
                                                    </StyledTableCell>
                                                </StyledTableRow>
                                            ))}
                                        </>
                                    ) : (
                                        <div className="p-4">No data found</div>
                                    )}
                                </>
                            )}

                        </TableBody>
                    </Table>
                    </TableContainer>
                </TableContainer>
                &nbsp;
                <div className="flex justify-end">
                    <Pagination
                        count={Math.ceil(filteredList?.Segments?.length / PageSize)} // Calculate total number of pages
                        color="primary"
                        page={currentPage}
                        onChange={handlePageChange}
                    />
                </div>
            </div>
        </div>
    )
}
export default RenderAssesmentData
