import { FormLabel, Grid, MenuItem, OutlinedInput, TextField } from '@mui/material';
import { Button, Modal, TimePicker } from 'antd'
import React, { useEffect, useState } from 'react'
import { createprogramFeature,  getAllPrograms,  updateProgramFeature, uploadsaveFile } from '../../API/api-endpoint';
import { useFormik } from 'formik';
import Swal from 'sweetalert2';
import { IoCloseSharp } from "react-icons/io5";
import SlickCarousel from "../../pages/SlickCarousel";

const CreateProgramFeature = ({ fetchReport, setShowAssesmentModal, showAssesmentModal, editData, setEditData }) => {
   
    const [programList, setProgramList] = useState([]);
   
    const formik = useFormik({
        initialValues: {
            program_id: "", 
            image:"",
            text:"",
            is_under_coach_profile:false,
        }, validate: (values) => {
            const errors = {};
            if (!values.program_id) {
                errors.program_id = "Activity Name is required";
            }
            if (!values.text) {
                errors.text = "Synonyms is required";
            }
        
            return errors;
        },
        // validationSchema: {},
        onSubmit: (values, { resetForm }) => {
            handleSubmitAssesmentForm(values, resetForm)

        },
    });
    const getAllProgramsData = async () => {
        const response = await getAllPrograms()
        console.log("response", response);
        setProgramList(response?.rows)
    }
    useEffect(() => {
        getAllProgramsData()
    }, [])
    const handleSubmitAssesmentForm = async (data, resetForm) => {
        let response = ""
        if (editData?.id) {

            response = await updateProgramFeature(data)

        } else {
            response = await createprogramFeature(data)

        }
        if (response?.status) {
            Swal.fire({
                title: "Success",
                text: response.message,
                icon: "success",
            });
            setEditData({})
            setShowAssesmentModal(false)
            fetchReport()
            formik.resetForm()
            formik?.setValues({
                program_id: "", 
                image:"",
                text:""
            })
        } else {
            Swal.fire({
                title: "Error",
                text: response.message,
                icon: "error",
            });
        }
        console.log("response", response);
    }
    useEffect(() => {
        if (editData?.id) {
            const { srID,program, ...data } = editData
            formik?.setValues(data)
        }
    }, [editData?.id])

    const handleFileUpload = async (event) => {
        const file = event.target.files[0];
        if (file) {

            const formData = new FormData();
            formData.append('file', file);

            const responst = await uploadsaveFile(formData)
            if (responst?.status) {

                formik.setFieldValue("image", responst?.file)
            }
        }
    }
    return (
        <Modal
            width={1200}
            // title={editData?.id ? "Edit Program Feature" : "Create Program Feature"}
            // title={editData ? "Edit Challenge" : "Add Challenges"}
            open={showAssesmentModal}
            onCancel={() => {
                setShowAssesmentModal(false)
                formik.resetForm()
                setEditData({})
                formik?.setValues({
                    program_id: "", 
                    image:"",
                    text:""
                })
            }}
            footer={<div></div>}
        >
             <div className="headingCont">
        <span className="heading">{editData?.id ? "Edit " : "Create"}</span>{" "}
        <span className="orange heading">Program Feature</span>
      </div>

      <div className="parentCont">
            <form className="form1" >
                <Grid container spacing={2}>
                <Grid item xs={12} sm={11}>
                <FormLabel >Program Name<span className="text-[red]">*</span></FormLabel>
           
                <TextField
                    fullWidth
                    size="small"
                    select
                    name="program_id"
                    value={formik?.values?.program_id || "placeholder"}
                    onChange={formik.handleChange}
                    error={formik.touched.program_id && formik.errors.program_id}
                    helperText={
                        formik.touched.program_id && formik.errors.program_id
                    }

                    id="form-layouts-separator-select"
                    labelId="form-layouts-separator-select-label"
                    input={<OutlinedInput id="select-multiple-language" />}
                >
                    <MenuItem value={"placeholder"} disabled>
                        Select Program
                    </MenuItem>
                    {programList?.map((value, index) => {
                        return (
                            <MenuItem value={value?.program_id}>
                            {value?.program_name}
                            </MenuItem>
                        );
                    })}
                </TextField>
            </Grid>
                    <Grid item xs={12} sm={11} className='relative'>
                        <FormLabel >Upload Image:</FormLabel>
                    
                        <TextField
                            fullWidth
                            placeholder="Zone"
                            size="small"
                            type={formik?.values?.image ? "text" : "file"}
                            name="image"
                            disabled={formik?.values?.image}
                            value={formik?.values?.image}
                            onChange={handleFileUpload}
                            error={formik.touched.image && formik.errors.image}
                            helperText={
                                formik.touched.image && formik.errors.image
                            }
                            inputProps={{
                                accept: ".jpg, .png .jpeg", // Specify accepted file formats here
                            }}

                        />
                        {formik?.values?.image &&
                            <IoCloseSharp onClick={()=>{
                                formik.setFieldValue("image", "")
                            }}  color="darkgray" className="cursor-pointer absolute top-0 right-[-10px]" style={{fontSize:"24px"}} />
                        }
                    </Grid>
                    <Grid item xs={12} sm={11}>
                    <FormLabel >Feature Name<span className="text-[red]">*</span></FormLabel>
                
                    <TextField
                        fullWidth
                        placeholder="Feature Name"
                        size="small"
                        type="text"
                        name="text"
                        value={formik?.values?.text}
                        onChange={formik.handleChange}
                        error={formik.touched.text && formik.errors.text}
                        helperText={
                            formik.touched.text && formik.errors.text
                        }

                    />
                </Grid>
                <Grid item xs={12} sm={6}>
              <Button
                className="btn"
                key="submit"
                type="primary"
                onClick={() => formik.handleSubmit()}
              >
                Submit
              </Button>
            </Grid>
                
                </Grid>
            </form>
            <div className="slick-container">
          <SlickCarousel />
        </div>
      </div>
        </Modal>
    )
}
export default CreateProgramFeature
