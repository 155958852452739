import { Avatar, Box, Button, Checkbox, Paper, Tabs, Typography } from '@mui/material'
import React, { useEffect, useMemo, useState } from 'react'
import ArrowOutwardSharpIcon from '@mui/icons-material/ArrowOutwardSharp';
import "../../styles/chatlanding.css"
import "./../../styles/newchat/newChat.css"
import NewGroupNav from '../../components/navbar/NewGroupNav';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import EastOutlinedIcon from '@mui/icons-material/EastOutlined';
import { useDispatch, useSelector } from 'react-redux';
import { getAllUsersAction, storeUsersToCreateGroup } from '../../redux/action/usersAction';
import { useNavigate } from 'react-router-dom';
import Header from '../../../../components/Header';
import SearchIcon from '@mui/icons-material/Search';
import { URL } from '../../../../API/api-endpoint';


export default function CreateNewGroup({setOpenPageOne}) {
    const paper = {
        padding: "0rem",
        minHeight: "88vh",
        maxWidth: '33vw',
        maxHeight: "88vh",
        overflowY : "scroll",
        marginTop: "90px"
    };

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const { allUsers, storedUserToCreateGroup } = useSelector(state => state.users)
    const { currentUser } = useSelector(state => state.auth)
    const [filteredUsers, seTfilteredUsers] = useState([])
    const [searchTerm, setSearchTerm] = useState('');
    const roleID = localStorage.getItem("roleID")


    const [checked, setChecked] = useState(false);
    const [selectedProfiles, setSelectedProfile] = useState(storedUserToCreateGroup)

    const addToSelected = user => {
        const copiedSelected = [...selectedProfiles];

        const userIndex = copiedSelected.findIndex(obj => obj.uid === user.uid);
        if (userIndex !== -1) {
            copiedSelected.splice(userIndex, 1);
        } else {
            copiedSelected.push(user);
        }

        setSelectedProfile(copiedSelected);
    };

    const removeSelected = uid => {
        const arr = [...selectedProfiles]

        const index = arr.findIndex(obj => obj.uid === uid);
        if (index !== -1) {
            arr.splice(index, 1);
        }
        setSelectedProfile(arr);
        return;
    }

    const filterAndSortData = searchTerm => {
        const filteredData = allUsers.filter(obj =>
            obj.displayName.toLowerCase().includes(searchTerm.toLowerCase())
        );

        const sortedData = [...filteredData].sort((a, b) =>
            a.displayName.localeCompare(b.displayName)
        );

        return sortedData;
    };

    const filteredAndSortedData = useMemo(() => {
        const filteredData = allUsers?.filter(obj =>
            obj.displayName?.toLowerCase().includes(searchTerm?.toLowerCase())
        );

        const sortedData = [...filteredData].sort((a, b) =>
            a.displayName.localeCompare(b.displayName)
        );

        return sortedData;
    }, [allUsers, searchTerm]);

    console.log("allUsers", allUsers, filteredAndSortedData);

    const handleSearchChange = event => {
        setSearchTerm(event.target.value);
    };
    console.log(selectedProfiles)
    const handleNextStep = async () => {
        const copySelecteds = [...selectedProfiles]
        const isUidExists = selectedProfiles.some(item => item.uid === currentUser.uid)
        if (!isUidExists) copySelecteds.push(currentUser);
        await dispatch(storeUsersToCreateGroup(copySelecteds))
        setOpenPageOne("stepTwo")
    }
    console.log("setOpenPageOne",setOpenPageOne);

    useEffect(() => {
        dispatch(getAllUsersAction(roleID))
    }, [])

    return(
        <>
        <Header />
    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Paper className='chatPaper' sx={{ flexGrow: 1 }} elevation={10} style={paper}>
            <NewGroupNav setOpenPageOne={setOpenPageOne} />

            <Box sx={{ backgroundColor: "#F5F5F5", padding: "8px", display: "flex", justifyContent: 'space-between', alignItems: 'center',marginTop: "5px" }}>
                <Box sx={{ width: "100%", display: "flex", alignItems: 'center', padding: '6px', overflowX: "auto" }}>

                    {
                        selectedProfiles.length === 0 && <Box
                            style={{ position: "relative", marginRight: "1.5rem", overflowX: "hidden" }}>
                            <Avatar sx={{ height: 58, width: 58 }} alt="Remy Sharp" src="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png" />
                            <Typography className='selectects-avatar-name'>Buddy</Typography>
                        </Box>
                    }

                    <Box scroll style={{
                        display: 'flex',
                        // overflowX: 'scroll',
                        scrollbarWidth: 'thin',
                        scrollbarColor: 'transparent transparent',
                        '-ms-overflow-style': 'none', /* Internet Explorer and Edge */
                        'scrollbar-width': 'thin',
                        '&::-webkit-scrollbar': {
                            width: '6px', /* Adjust the width as needed */
                        },
                        '&::-webkit-scrollbar-track': {
                            backgroundColor: 'transparent', /* Change to desired background color */
                        },
                        '&::-webkit-scrollbar-thumb': {
                            backgroundColor: 'transparent', /* Change to desired thumb color */
                        },
                    }}>

                        {
                            selectedProfiles?.slice(0).reverse().map((user, index) => {
                                if (user.uid === currentUser.uid) return;
                                return <Box
                                    onClick={() => removeSelected(user.uid)}
                                    key={index} style={{ position: "relative", marginRight: "1.5rem", overflowX: "hidden" }}>
                                    <Avatar sx={{ height: 58, width: 58 }} alt={user?.displayName} src={`${URL}/static/public/userimages/${user?.photoURL}`} />
                                    <Typography className='selectects-avatar-name'>
                                        {user.displayName.length > 8 ? `${user.displayName.slice(0, 8)}...` : user.displayName}
                                    </Typography>
                                    <Box className="cross-button">
                                        <img height={7} width={7} src="/images/cross-icon.png" alt="remove" />
                                    </Box>
                                </Box>
                            })
                        }

                    </Box>
                </Box>
                <ArrowOutwardSharpIcon sx={{ paddingRight: "0.4rem" }} />
            </Box>

            <Box>
                <Typography className='select-buddies-text'>Select Buddies to add to your Group</Typography>

                <Box style={{ position: 'relative', textAlign: "center" }}>
                    <input onChange={handleSearchChange} placeholder='Search Buddies' type="text" className='search-buddy-input' />
                    <Box className="search-icon-parent" style={{ textAlign: "center",backgroundColor: "#E67E22",color:'white' }}>
                        {/* <img src="/images/search-icon.png" alt="search" /> */}
                        <SearchIcon />
                    </Box>
                </Box>
            </Box>
            {filteredAndSortedData?.length > 0 ? filteredAndSortedData?.map((item, index) => {
                if (item.uid === currentUser.uid) return;
                return <Box className="profile-row" onClick={() => {
                    addToSelected(item)
                    document.getElementById(`check-profile-${index}`).click()
                }} key={index}
                    sx={{ padding: "8px", paddingTop: "12px", display: "flex", justifyContent: 'space-between', alignItems: 'center' }}>
                    <Box sx={{ display: "flex", alignItems: 'center', padding: '6px' }}>
                        <Avatar  alt={item?.displayName} src={`${URL}/static/public/userimages/${item?.photoURL}`} />
                        <Box>
                            <Typography className='userName' fontWeight="fontWeightBold" sx={{ paddingLeft: '1.2rem', fontSize: '14px' }}>{item.displayName}</Typography>
                        </Box>
                    </Box>
                    <Checkbox
                        id={`check-profile-${index}`}
                        sx={{
                            color: "#F4F4F4",
                            '&.Mui-checked': {
                                color: '#E67E22',
                            }
                        }}
                        checked={selectedProfiles.some(obj => obj.uid === item.uid)}
                        icon={<CheckCircleOutlineIcon />}
                        checkedIcon={<CheckCircleIcon />}
                    />
                </Box>
            }) : ( <Box>
                <Typography className='userName' fontWeight="fontWeightBold" sx={{ paddingLeft: '3.0rem',paddingTop:"20px", fontSize: '14px' }}>No Data Found</Typography>
            </Box>)}
            <Box className="sticky-next-btn">
                <Button
                    disabled={!selectedProfiles.length > 0}
                    onClick={handleNextStep}
                    style={{ boxShadow: "-2px 4px 10px rgba(0, 0, 0, 0.25)", fontSize: 10, letterSpacing: "0.16px", display: "flex" }} className={selectedProfiles.length > 0 ? 'next-mui-btn-selected' : 'next-mui-btn'}>
                    Next
                    <EastOutlinedIcon sx={
                        selectedProfiles.length > 0
                            ? { fontSize: '14px', paddingLeft: '0.24rem', color: "white" }
                            : { fontSize: '14px', paddingLeft: '0.24rem', color: "#FFFFFF", }
                    } />
                </Button>
            </Box>
        </Paper >
    </Box >
    </>
)
}
