import { FormControlLabel, Grid, Radio, RadioGroup} from '@mui/material';
import { Button, Modal,  } from 'antd'
import React, { useEffect, useState } from 'react'
import { createAssesmentform, getAllReviewSectionByID } from '../../API/api-endpoint';
import { useFormik } from 'formik';
import Swal from 'sweetalert2';
import Rating from '../../components/Rating';
const Options = ({idd, editFormData, setEditFormData, setShowAssesmentModal, showAssesmentModal,fetchAssesmentData }) => {
    const [formReviewSection, setFormReviewSection] = useState([]);
    const handleOptionChange = (sectionName, sectionId, optionId, optionComment, index, weightage) => {
        const updatedSectionArray = [...formik.values.options];
        const existingSectionIndex = updatedSectionArray.findIndex(opt => opt.section_id === sectionId);
      
        if (existingSectionIndex !== -1) {
          updatedSectionArray[existingSectionIndex] = {
            section_id: sectionId,
            option_id: optionId,
            weightage: weightage,
            coachcomment: optionComment,
            coachSection: sectionName
          };
        } else {
          updatedSectionArray.push({
            section_id: sectionId,
            option_id: optionId,
            weightage: weightage,
            coachcomment: optionComment,
            coachSection: sectionName
          });
        }
      
        formik.setFieldValue("options", updatedSectionArray);
      
        setFormReviewSection(prevSections => {
          const existingSectionIndex = prevSections.findIndex(section => section.id === sectionId);
      
          if (existingSectionIndex !== -1) {
            const updatedSections = [...prevSections];
            updatedSections[existingSectionIndex].coachcomment = optionComment;
            updatedSections[existingSectionIndex].weightage = parseInt(weightage);
            return updatedSections;
          } else {
            return [...prevSections, { id: sectionId, coachcomment: optionComment, weightage: parseInt(weightage) }];
          }
        });
      };
  
    const formik = useFormik({
        initialValues: {
          options:[]
        }, 
        onSubmit: (values, { resetForm }) => {
            handleSubmitAssesmentForm(values, resetForm)

        },
    });
   
   

    const handleSubmitAssesmentForm = async (data) => {
        const reponse = await createAssesmentform(data)
        if (reponse?.status) {
            Swal.fire({
                title: "Success",
                text: reponse.message,
                icon: "success",
              });
              setShowAssesmentModal(false)
              fetchAssesmentData(idd)
        }else{
            Swal.fire({
                title: "Error",
                text: reponse.message,
                icon: "error",
              });
        }
    }
    const getAllFormReviewOptins = async (id) => {
        const response = await getAllReviewSectionByID(id);
        setFormReviewSection(response?.data);
      };
      useEffect(() => {
        const initializeForm = async () => {
          if (editFormData?.id) {
            const { activity, createdAt, weightage, updatedAt, review_rating, reviewrating_comments, userformreviewimages, totalscore, user, form_review_section_options_users, user_id, ...getAllGoalsData } = editFormData;
            let imagesshow = editFormData?.userformreviewimages[editFormData?.userformreviewimages?.length - 1]
            let data = { ...getAllGoalsData, options:editFormData?.options?.length>0 ?editFormData?.options:[], user_id_to_sent: editFormData?.user_id, images: imagesshow?.image ? [imagesshow?.image] : [] }
            formik.setValues(data)
          }
          if (editFormData?.id && editFormData?.options?.length > 0) {
            const response = await getAllReviewSectionByID(editFormData?.activity_id);
    editFormData?.options?.forEach(option => {
              const sectionIndex = response?.data.findIndex(section => section.id === option.section_id);
              if (sectionIndex !== -1) {
                const updatedSections = [...response?.data];
                updatedSections[sectionIndex].coachcomment = option?.coachcomment;
                updatedSections[sectionIndex].coachSection = option?.coachSection;
                updatedSections[sectionIndex].weightage = option.weightage;
                setFormReviewSection(updatedSections);
              }else{
                setFormReviewSection(response?.data);

              }
            });
          } else {
            await getAllFormReviewOptins(editFormData?.activity_id);
          }
        };
    
        initializeForm();
      }, [editFormData?.id]);
     
    return (
        <Modal
        width={1200}
            
            // title={editData ? "Edit Challenge" : "Add Challenges"}
            open={showAssesmentModal}
            onCancel={() =>{ setShowAssesmentModal(false)
              setEditFormData({})
              formik?.setValues({
               options:[]
            })
            }}
            footer={
                <div></div>
                //   loading={isLoading}
              }
        >
       

      <div className="parentCont">
      
            <form className="form1">
                <Grid container spacing={2}>
                   
                <Grid item xs={12} sm={11.5} sx={{color:"transparent"}}> 
                    Check
                  </Grid>
                    
                    <Grid item xs={12} sm={11.5}>
                    <div style={{border:"1px solid gray"}}>
            <div className="flex">
              <div style={{ "width": "30%", padding: "2px", "border": "1px solid gray", fontWeight: "700",padding:"0px 0px 0px 10px" }}>
                Section
              </div >
              <div style={{ "width": "30%", padding: "2px", "border": "1px solid gray", fontWeight: "700",padding:"0px 0px 0px 10px" }}>
                Rating
              </div>
              <div style={{ "width": "30%", padding: "2px", "border": "1px solid gray", fontWeight: "700",padding:"0px 0px 0px 10px" }}>
                Review Comment
              </div>
              <div style={{ "width": "100%", padding: "2px", "border": "1px solid gray", "borderLeft": "none", fontWeight: "700",padding:"0px 0px 0px 10px" }}>
                Options
              </div>
            </div>
            <div className='assement-scroll'>

            {formReviewSection?.map((section, index) => {
              return (
                <div className="flex">
                  <div style={{ "width": "30%", padding: "2px", "borderTop": "none", "border": "1px solid gray" ,padding:"10px 0px 0px 10px",display:"flex",alignItems:"center"}}>
                    {section?.section}
                  </div >
                  <div style={{ "width": "30%", padding: "2px", "borderTop": "none", "border": "1px solid gray" ,padding:"10px 0px 0px 10px",display:"flex",alignItems:"center"}}>
                  <Rating rating={section?.weightage}  size={"25px"}/>
                  </div >
                  <div style={{ "width": "30%", padding: "2px", "borderTop": "none", "border": "1px solid gray" ,padding:"10px 0px 0px 10px",display:"flex",alignItems:"center"}}>
                    {section?.coachcomment}
                  </div >
                  <div style={{ "width": "100%", padding: "2px", "borderTop": "none", "border": "1px solid gray", "borderLeft": "none" }}>
                    {section?.form_review_section_options?.map((options,optionIndex)=>{
                      return(
                        <div style={{display:"flex",alignItems:"center",padding:"0px 0px 0px 10px"}}>
                          <div>
                          <RadioGroup
                                                                        aria-labelledby="demo-radio-buttons-group-label"
                                                                        name={`radio-buttons-group-${section.id}`}
                                                                        value={formik.values.options.find(opt => opt.section_id === section.id)?.option_id || ""}
                                                                        onChange={(e) => handleOptionChange(section?.section, section.id, options.id, options.option, optionIndex, options.weightage)}
                                                                    >
                          <FormControlLabel
                            value={options?.id}
                            control={<Radio />}
                          />
                        </RadioGroup>
                          </div>
                          <div style={{marginLeft:"0%",padding:"10px", borderBottom:"1px ridge #ede5e5"}}> {options?.option}</div>
                         </div>
                      )
                    })}
                  </div>
                </div>
              )
            })}
            </div>
          </div>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                  </Grid>
                    <Grid item xs={12} sm={5.5} style={{textAlign:"right"}}>
                    <Button
                      className="btn" 
                      key="submit"
                      type="primary"
                      onClick={() => formik.handleSubmit()}
                    >
                      Submit
                    </Button>
                  </Grid>
                  
                </Grid>
            </form>
      </div>
        </Modal>
    )
}
export default Options
