import React from "react";
import ReactDOM from "react-dom/client";
import Example from "./example";
// import { DndProvider } from "react-dnd";

import "./CustomCalender.css";

function CustomCalender() {
  return (
    <Example />
    // <DndProvider backend={HTML5Backend}>
    //   <Example />
    // </DndProvider>
  );
}

export default CustomCalender;
