import { Avatar, Box, Paper, Typography } from '@mui/material'
import React from 'react'
import ArrowOutwardSharpIcon from '@mui/icons-material/ArrowOutwardSharp';
import "../../styles/chatlanding.css"
import GroupAddOutlinedIcon from '@mui/icons-material/GroupAddOutlined';
import NewChatNav from '../../components/navbar/NewChatNav';
import "./../../styles/newchat/newChat.css"

export default function NewChat() {
    const paper = {
        padding: "0rem",
        minHeight: "100vh",
        maxWidth: '27rem'
    };

    const data = [
        {
            name: 'Poornima Raj',
            desc: '7724 Followers | 4.65',
            time: '10:30 AM',
            showMediaIcon: true,
            showBellIcon: true,
            msgCount: 4
        },
        {
            name: 'Poornima Raj',
            desc: '7724 Followers | 4.65',
            time: '08:00 AM',
            showMediaIcon: false,
            showBellIcon: false,
            msgCount: 8
        },
        {
            name: 'Poornima Raj',
            desc: '7724 Followers | 4.65',
            time: 'yeasterday',
            showMediaIcon: false,
            showBellIcon: false,
            msgCount: 0
        },
        {
            name: 'Poornima Raj',
            desc: '7724 Followers | 4.65',
            time: '10:30 AM',
            showMediaIcon: false,
            showBellIcon: false,
            msgCount: 2
        },
        {
            name: 'Poornima Raj',
            desc: '7724 Followers | 4.65',
            time: '10:30 AM',
            showMediaIcon: false,
            showBellIcon: false,
            msgCount: 4
        },
        {
            name: 'Poornima Raj',
            desc: '7724 Followers | 4.65',
            time: 'Apr 1',
            showMediaIcon: false,
            showBellIcon: true,
            msgCount: 0
        },
        {
            name: 'Poornima Raj',
            desc: '7724 Followers | 4.65',
            time: 'March 31',
            showMediaIcon: false,
            showBellIcon: false,
            msgCount: 4
        },
        {
            name: 'Poornima Raj',
            desc: '7724 Followers | 4.65',
            time: 'March 31',
            showMediaIcon: false,
            showBellIcon: false,
            msgCount: 4
        },
        {
            name: 'Poornima Raj',
            desc: '7724 Followers | 4.65',
            time: '10:30 AM',
            showMediaIcon: false,
            showBellIcon: false,
            msgCount: 4
        },
        {
            name: 'Poornima Raj',
            desc: '7724 Followers | 4.65',
            time: '10:30 AM',
            showMediaIcon: false,
            showBellIcon: false,
            msgCount: 4
        },
        {
            name: 'Poornima Raj',
            desc: '7724 Followers | 4.65',
            time: '10:30 AM',
            showMediaIcon: false,
            showBellIcon: false,
            msgCount: 4
        },
    ]

    return <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Paper sx={{ flexGrow: 1 }} elevation={10} style={paper}>
            <NewChatNav
                title={"Merchant Runner"}
                backButtonPath={"/merchant/merchant-app"}
            />
            {/* position: 'fixed', width: "100%", maxWidth: '26rem', zIndex: 10 */}
            <Box sx={{ backgroundColor: "#FDFBE7", padding: "8px", display: "flex", justifyContent: 'space-between', alignItems: 'center' }}>
                <Box sx={{ display: "flex", alignItems: 'center', padding: '6px' }}>
                    <Avatar sx={{ backgroundColor: '#FDE723' }}>
                        <GroupAddOutlinedIcon sx={{ color: "black" }} />
                    </Avatar>
                    <Typography sx={{ paddingLeft: '1.2rem', fontWeight: 'bold', fontSize: "14px" }}>Create New Group</Typography>
                </Box>
                <ArrowOutwardSharpIcon sx={{ paddingRight: "0.4rem" }} />
            </Box>

            <Box>
                <Typography className='select-buddies-text'>Select Buddies to add to your Group</Typography>

                <Box style={{ position: 'relative', textAlign: "center" }}>
                    <input placeholder='Search Buddies' type="text" className='search-buddy-input' />
                    <Box className="search-icon-parent">
                        <img src="/images/search-icon.png" alt="search" />
                    </Box>
                </Box>
            </Box>

            {
                data?.map((item, index) => <Box key={index} sx={{ padding: "8px", paddingTop: "12px", display: "flex", justifyContent: 'space-between', alignItems: 'center' }}>
                    <Box sx={{ display: "flex", alignItems: 'center', padding: '6px' }}>
                        <Avatar alt="Remy Sharp" src="https://i.insider.com/5aeb7d1919ee864c008b489d?width=1136&format=jpeg" />
                        <Box>
                            <Typography className='userName' fontWeight="fontWeightBold" sx={{ paddingLeft: '1.2rem', fontSize: '14px' }}>{item.name}</Typography>
                            <Typography sx={{ paddingTop: "2px", paddingLeft: '1.2rem', fontSize: '12px', display: "flex", alignItems: "center" }}>
                                {item.desc}
                                <img src="/images/star-icon.png" alt="star" />
                            </Typography>
                        </Box>
                    </Box>
                </Box>)
            }

        </Paper >
    </Box >
}
