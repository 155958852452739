import React, { useState } from 'react'
import Header from '../../components/Header'
import { getRacessReadinessReult } from '../../API/api-endpoint'
import { useEffect } from 'react'
import { LinearProgress, linearProgressClasses } from '@mui/material';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import "./racces.css";


const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height:"3vw",
    // height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 5,
        backgroundColor: theme.palette.mode === 'light' ? '#13b613' : '#308fe8',
    },
}));
const RaccesScore = () => {
    const [wellNessScore, setwellNessScore] = useState()
    console.log("wellNessScore", wellNessScore);

    useEffect(() => {
        getWeelnessResult()
    }, [])
    const getWeelnessResult = async () => {
        const response = await getRacessReadinessReult()
        setwellNessScore(response)
    }
    return (
        <div>
            <Header />
            <div style={{ marginTop: '90px', padding: '20px' }}>
                <Card className='card-contener' >
                    <CardContent>
                        <div className='flex flex-col justify-center text-center'>
                            <h2>
                                <strong><a href='/racessScore'>Segment Scores</a></strong>&nbsp;
                            </h2>
                            <div className='score-item'>
                                <strong>
                                    Total Score:{wellNessScore?.totalscored}
                                </strong>
                            </div>
                            <div className='score-item'>
                                <strong>
                                    Points Range:{wellNessScore?.totalpoints}
                                </strong>
                            </div>
                            <div className='score-item'>
                                <strong>
                                    Sprint Description:
                                </strong>
                                {wellNessScore?.scores_json?.readinessscores?.sprint_description}
                            </div>
                            <div className='score-item'>
                                <strong>
                                    Olympic Description:
                                </strong>
                                {wellNessScore?.scores_json?.readinessscores?.olympic_description}
                            </div>
                            <div className='score-item'>
                                <strong>
                                    70.3 Description:
                                </strong>
                                {wellNessScore?.scores_json?.readinessscores?.seventy_point_three_description}

                            </div>
                            <div className='score-item'>
                                <strong>
                                    Ironman Description:
                                </strong>
                                {wellNessScore?.scores_json?.readinessscores?.ironman_description}
                            </div>



                            <div id="segment-scores">
                                {wellNessScore?.scores_json?.scoresds?.map((item) => {
                                    return (
                                        <div className="segment">
                                            <div className="segment-name">{item?.segment_name}</div>
                                            <div className="bar-container">
              <BorderLinearProgress variant="determinate" value={item?.segment_score} />
                                            </div>
                                            <div className="percentage">{item?.segment_score} Points</div>
                                            {item?.subsegment_scores?.map((ele) => {
                                                return (
                                                    <div className="subsegment">
                                                        <div className="subsegment-name">{ele?.subsegment_name}</div>
                                                        <div className="bar-container">
                                                        <BorderLinearProgress variant="determinate" value={ele?.subsegment_score} />
                                                        </div>
                                                        <div className="percentage">{ele?.subsegment_score} Points</div>
                                                    </div>
                                                )
                                            })}


                                        </div>
                                    )
                                })}

                            </div>
                        </div>
                    </CardContent></Card>


            </div>
        </div>
    )
}

export default RaccesScore
