import { getDatabase, onValue, ref } from "firebase/database";
import { GET_CHATS_MESSAGES_FAIL, GET_CHATS_MESSAGES_INIT, GET_CHATS_MESSAGES_SUCCESS } from "../constant/chatsConstants";

export const getChatMessagesAction = (combineId) => async (dispatch) => {
    try {
        dispatch({ type: GET_CHATS_MESSAGES_INIT });

        const db = getDatabase();
        const starCountRef = ref(db, `/chats/${combineId}/messages`);

        await onValue(starCountRef, (snapshot) => {
            const data = snapshot.val();
            if (data) {
                const messages = Object.values(data).filter(
                    (message) => !message.isDeleted || message.isDeleted === false
                );
                const sortedMessages = messages.sort(
                    (a, b) => a.timeStamp - b.timeStamp
                );
                dispatch({
                    type: GET_CHATS_MESSAGES_SUCCESS,
                    payload: sortedMessages,
                });
            } else {
                dispatch({ type: GET_CHATS_MESSAGES_SUCCESS, payload: [] });
            }
        });
    } catch (error) {
        dispatch({ type: GET_CHATS_MESSAGES_FAIL, payload: error });
    }
};


// export const getChatMessagesAction = (combineId) => async dispatch => {
//     try {

//         dispatch({ type: GET_CHATS_MESSAGES_INIT })

//         const db = getDatabase();
//         const starCountRef = ref(db, `/chats/${combineId}/messages`);

//         await onValue(starCountRef, (snapshot) => {
//             const data = snapshot.val();
//             data
//                 ? dispatch({ type: GET_CHATS_MESSAGES_SUCCESS, payload: Object.values(data).sort((a, b) => a.timeStamp - b.timeStamp) })
//                 : dispatch({ type: GET_CHATS_MESSAGES_SUCCESS, payload: [] })
//         });

//     } catch (error) {

//         dispatch({ type: GET_CHATS_MESSAGES_FAIL, payload: error })

//     }
// }