import React, { useEffect, useState } from "react";
import { Box, Button, Dialog, Grid } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import DialogTitle from "@mui/material/DialogTitle";
import { createCommentOnworkout, getAllcomments } from "../../../API/api-endpoint";
import SendIcon from '@mui/icons-material/Send';
const Comment = ({ comment, onReply }) => {
  const [isReplying, setIsReplying] = useState(false);
  const [replyText, setReplyText] = useState("");

  const handleReply = () => {
    onReply(comment.id, replyText);
    setReplyText("");
    setIsReplying(false);
  };

  return (
    <div>
    <span>Sent By <b>{comment?.author?.firstname} {comment?.author?.lastname}</b></span>
      <div style={{ display: "flex" , margin:"5px 0px 5px 10px"}}>
        <input style={{
          border: "1px solid #b9b0b0",
    borderRadius: "8px",
    padding:"6px"
        }}  value={comment?.comment} disabled />
        &nbsp;
        <Button variant="contained" onClick={() => setIsReplying(!isReplying)}>
          Reply
        </Button>
        &nbsp;
      </div>
      &nbsp;
      {isReplying && (
        <div style={{ margin:"10px 0px 10px 0px"}}>
          <input style={{
            border: "1px solid #b9b0b0",
    borderRadius: "8px",
    padding:"6px"
          }} 
            type="text"
            placeholder="Type your reply"
            value={replyText}
            onChange={(e) => setReplyText(e.target.value)}
          />{" "}
          &nbsp;
        <SendIcon style={{color:"#2563EB", fontSize:"30px"}} onClick={handleReply}/>
        </div>
      )}
      {comment.children && comment.children.length > 0 && (
        <div style={{ marginLeft: "20px" }}>
          {comment.children.map((child) => (
            <Comment key={child.id} comment={child} onReply={onReply} />
          ))}
        </div>
      )}
    </div>
  );
};

const CommentBox = ({ onClose, open ,selectedValue}) => {
  let userId = localStorage.getItem('userId');
  
  useEffect(() => {
    if (selectedValue) {
      getComments()
    }
  }, [selectedValue])
  const getComments =async()=>{
    let passdata = {
      id:selectedValue,
      process:"Planned"
    }
    const result = await getAllcomments(passdata)
    setCommentData(result);
  }
  const [newCommentText, setNewCommentText] = useState("");
  const [commentData, setCommentData] = useState([]);
  const [currentValue, setCuurectValue] = useState();
console.log("commentData",commentData);

  const handleReply = async(parentCommentId, text) => {
    console.log("parentCommentId", parentCommentId,text);
    let findCurrentValue =[]

    const addReplyToComment = async(comments, parentCommentId, reply) => {
      return comments.map(async(comment) => {
        if (comment.id === parentCommentId) {
          comment.children = comment.children || [];
          const newReply = {
            id: selectedValue,
            comment: reply,
            parent_comment_id: parentCommentId,
            process: "Planned",
            author_id: userId,
            children: [],
          };

          const response = await createCommentOnworkout(newReply)
          let passdata = {
            id:selectedValue,
            process:"Planned"
          }
          const result = await getAllcomments(passdata)
          setCommentData(result);
          comment.children.push(newReply);
        } else if (comment.children && comment.children.length > 0) {
          addReplyToComment(comment.children, parentCommentId, reply);
        }
        return comment;
      });
    };

    const updatedComments = addReplyToComment(
      commentData,
      parentCommentId,
      text
    );
    
    // setCommentData(updatedComments);
  };

  const handleNewComment = async() => {
    if (newCommentText) {
      try {
       
       let comments=   {
            id:selectedValue,
            comment: newCommentText,
            parent_comment_id: null,
            process:"Planned",
            author_id:userId,
  
            children: [],
          }
        const response = await createCommentOnworkout(comments)
        console.log("response",response);
          let passdata = {
            id:selectedValue,
            process:"Planned"
          }
          const result = await getAllcomments(passdata)
          console.log("result",result);
        setCommentData(result);
        setNewCommentText("");

        console.log("response",response);
      } catch (error) {
        console.log("error");
      }
     
    }
  };

  return (
    
    <div className="w-100 h-60 overflow-scroll border-solid border-[1px] border-[#ede4e4]"  
    >
      <div className="flex justify-between border-b-solid border-b-[1px] border-b-[#ede4e4] w-100 ">
        <DialogTitle className="">Comments</DialogTitle>
      </div>
      <div className="p-4">
      {commentData?.map((comment) => (
        <Comment key={comment.id} comment={comment} onReply={handleReply} />
      ))}
      <div >
        <input style={{
          border: "1px solid #b9b0b0",
    borderRadius: "8px",
    padding:"6px"
        }} 
          type="text"
          placeholder="Type your comment"
          value={newCommentText}
          onChange={(e) => setNewCommentText(e.target.value)}
        />&nbsp;
        <SendIcon style={{color:"#2563EB", fontSize:"30px"}} onClick={handleNewComment}/>
        
      </div>
      </div>
    </div>
  );
};

export default CommentBox;
