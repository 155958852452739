import { LoadingButton } from '@mui/lab'
import React, { useEffect, useState } from 'react'
import { getStravaURL } from '../API/api-endpoint';
import { Box } from '@mui/material';
import Background from "../../src/Images/Background.png";

const Strava = () => {
  const [stravaURL, setstravaURL] = useState()
  const userId = localStorage.getItem('userId')


  useEffect(() => {
    if (userId) {
      
      getURLByLibraryId();
    }
  }, [userId]);

  const getURLByLibraryId = async () => {
    if (userId !== null) {
      let result = await getStravaURL(userId);
      setstravaURL(result)

    }
  };
  return (
    <Box
    className="content-center"
    style={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100vh", // 100% of the viewport height
      background: `url(${Background}) center no-repeat`, // Replace 'your-background-image.jpg' with the path to your background image
      backgroundSize: "100% 100vh",
    }}
  >
    <LoadingButton
    
    size="large"
    variant="contained"
    sx={{
      marginBottom: 2,
      // color: "white",
      // backgroundColor: "rgb(145, 85, 253)",
      color: "white",
      backgroundColor: "#2563EB",
    }}
    
    // onClick={() =>
    //   handleStravaLogin() }
    loadingPosition="start"
  >
    <a href={stravaURL?.url}>
   Connect with Strava
   </a>
  </LoadingButton>
 
    </Box>
  )
}

export default Strava
