import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import "./workout.css";
import { Button, Select, MenuItem } from "@mui/material";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Rating from "@mui/material/Rating";

import { customIcons } from "../../utils/editorTollbarOptions";

const StyledRating = styled(Rating)(({ theme }) => ({
  "& .MuiRating-iconEmpty .MuiSvgIcon-root": {
    color: theme.palette.action.disabled,
  },
}));

function IconContainer(props) {
  const { value, ...other } = props;
  return <span {...other}>{customIcons[value].icon}</span>;
}

IconContainer.propTypes = {
  value: PropTypes.number.isRequired,
};

const initialValues = {
  description: "",
  plannedDistance: "",
  plannedDistanceMeasure: "",
  plannedDuration: "",
  actualDistance: "",
  actualDistanceMeasure: "",
  actualDuration: "",
  zone: "",
  plannedHeartRate: "",
  plannedHeartRateMeasure: "",
  actualHeartRate: "",
  actualHeartRateMeasure: "",
  plannedPace: "",
  actualPace: "",
  plannedRPE: "",
  actualRPE: "",
  plannedCadence: "",
  plannedCadenceMeasure: "",
  actualCadenceMeasure: "",
  actualCadence: "",
  postWorkoutFeel: "",
  isGroupWorkout: "",
  plannedPower: "",
  actualPower: "",
};

const initialCommentsValues = {
  comment: "",
  niceToHave: "",
  important: "",
  veryImportant: "",
};
const Workouts = () => {
  const [activity, setActivity] = useState()
  console.log(activity);
  const handleActivityChange = (e) => {
    setActivity(e.target.value)
  }

  const [subActivity, setSubActivity] = useState()
  console.log(subActivity);
  const handleSubActivityChange = (e) => {
    setSubActivity(e.target.value)
  }

  const [workOutFormValues, setWorkOutFormValues] = useState(initialValues);
  // console.log(workOutFormValues, "workOutFormValues");
  const [workOutCommentValues, setWorkOutCommentValues] = useState(
    initialCommentsValues
  );
  // console.log("workOutCommentValues",workOutCommentValues);

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    // Handle checkboxes separately
    if (type === "checkbox") {
      setWorkOutFormValues({
        ...workOutFormValues,
        [name]: checked,
      });
    } else {
      setWorkOutFormValues({
        ...workOutFormValues,
        [name]: value,
      });
    }
  };

  const handleCommentsChange = (e) => {
    const { name, value } = e.target;
    setWorkOutCommentValues({
      ...workOutCommentValues,
      [name]: value,
    });
  };

  const handleCommentSubmit = async () => {
    console.log(workOutCommentValues);
    // try {
    //   const response = await axios.post(
    //     "https://api.example.com/endpoint",
    //     { data: values },
    //     {
    //       headers: {
    //         "Content-Type": "application/json",
    //          Authorization: "Bearer your_access_token_here",
    //       },
    //     }
    //   );
    //   console.log(response.data);
    // } catch (err) {
    //   console.log(err.message);
    // }
  };
  return (
    <div className="workout-form-container">
      <div className="workout-form-main">
        <p className="workout-form-title">Workouts</p>
        <div className="workout-form-body">
          <div className="form-first-row">
            <Grid container>
              <Grid md={6} sm={6} lg={6} xs={12}>
                <div className="workout-form-group">
                  <label>Activity Name</label>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    className="select"
                    name="activity"
                    value={activity}
                    onChange={(e) => handleActivityChange(e)}
                    variant="standard"
                  >
                    <MenuItem value={"Running"}>Running</MenuItem>
                    <MenuItem value={"swimming"}>swimming</MenuItem>
                    <MenuItem value={"walking"}>walking</MenuItem>
                  </Select>
                </div>
              </Grid>
              <Grid md={6} sm={6} lg={6} xs={12}>
                <div className="workout-form-group">
                  <label>Sub-Activity</label>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    className="select"
                    name="subActivity"
                    value={subActivity}
                    onChange={(e) => handleSubActivityChange(e)}
                    variant="standard"
                  >
                    <MenuItem value={"Running"}>Running</MenuItem>
                    <MenuItem value={"swimming"}>swimming</MenuItem>
                    <MenuItem value={"walking"}>walking</MenuItem>
                  </Select>
                </div>
              </Grid>
            </Grid>
          </div>
          <div className="form-second-row">
            <Grid container>
              <Grid md={12} sm={12} lg={12} xs={12}>
                <div className="workout-form-group">
                  <label>Discription</label>
                  <textarea
                    type="text"
                    placeholder="Easy Run.."
                    name="description"
                    value={workOutFormValues.description}
                    onChange={handleInputChange}
                  />
                </div>
              </Grid>
            </Grid>
          </div>
          <div className="form-third-row">
            <Grid container sx={{ display: "flex" }}>
              <Grid md={7} sm={12} lg={7} xs={12}>
                <div className="third-row-leftside">
                  <p className="main-title">Fetch from strava</p>
                  <div className="leftside-main">
                    <div className="left-first-row">
                      <div className="planned-form">
                        <div className="title">
                          <p>Planned</p>
                        </div>
                        <div className="planned-form-group">
                          <label className="small-device-label">
                            Planned Distance
                          </label>
                          <label className="full-device-label">Distance</label>
                          <div className="two-inputs">
                            <input
                              type="text"
                              placeholder="0"
                              name="plannedDistance"
                              value={workOutFormValues.plannedDistance}
                              onChange={handleInputChange}
                            />
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              className="select"
                              name="plannedDistanceMeasure"
                              value={workOutFormValues.plannedDistanceMeasure}
                              onChange={handleInputChange}
                              defaultValue="KM"
                              label="select"
                              variant="standard"
                            >
                              <MenuItem value={"KM"}>KM</MenuItem>
                              <MenuItem value={"meter"}>Meter</MenuItem>
                              <MenuItem value={"yard"}>yard</MenuItem>
                            </Select>
                          </div>
                        </div>
                        <div className="planned-form-group">
                          <label className="small-device-label">
                            Planned Duration
                          </label>
                          <label className="full-device-label">Duration</label>
                          <input
                            style={{ width: "100%" }}
                            type="text"
                            placeholder="00 : 00 : 00"
                            name="plannedDuration"
                            value={workOutFormValues.plannedDuration}
                            onChange={handleInputChange}
                          />
                        </div>
                      </div>
                      <div className="actuals-form">
                        <div className="title">
                          <p>Actuals</p>
                        </div>
                        <div className="actuals-form-group">
                          <label className="small-device-label">
                            Actuals Distance
                          </label>
                          <div className="two-inputs">
                            <input
                              type="text"
                              placeholder="0"
                              name="actualDistance"
                              value={workOutFormValues.actualDistance}
                              onChange={handleInputChange}
                            />
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              className="select"
                              name="actualDistanceMeasure"
                              value={workOutFormValues.actualDistanceMeasure}
                              onChange={handleInputChange}
                              label="select"
                              variant="standard"
                              placeholder="select"
                            >
                              <MenuItem value={"KM"}>KM</MenuItem>
                              <MenuItem value={"meter"}>Meter</MenuItem>
                              <MenuItem value={"yard"}>yard</MenuItem>
                            </Select>
                          </div>
                        </div>
                        <div className="actuals-form-group">
                          <label className="small-device-label">
                            Actuals Duration
                          </label>
                          <input
                            style={{ width: "100%" }}
                            type="text"
                            placeholder="01 : 00 : 00"
                            name="actualDuration"
                            value={workOutFormValues.actualDuration}
                            onChange={handleInputChange}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="left-second-row">
                      <div className="zone-row">
                        <label>Zone</label>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          className="select"
                          name="zone"
                          value={workOutFormValues.zone}
                          onChange={handleInputChange}
                          label="select"
                          variant="standard"
                          placeholder="select"
                        >
                          <MenuItem value={"east"}>East</MenuItem>
                          <MenuItem value={"west"}>West</MenuItem>
                          <MenuItem value={"north"}>North</MenuItem>
                        </Select>
                      </div>
                    </div>
                    <div className="left-third-row">
                      <div className="third-row-form-group">
                        <div className="label-main">
                          <label className="full-device-label">
                            Heart Rate
                          </label>
                        </div>
                        <div className="planned-inputs">
                          <label className="small-device-label">
                            Planned Heart Rate
                          </label>
                          <div className="third-row-two-inputs">
                            <input
                              type="text"
                              placeholder="123"
                              name="plannedHeartRate"
                              value={workOutFormValues.plannedHeartRate}
                              onChange={handleInputChange}
                            />
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              className="select"
                              name="plannedHeartRateMeasure"
                              value={workOutFormValues.plannedHeartRateMeasure}
                              onChange={handleInputChange}
                              label="select"
                              variant="standard"
                              placeholder="select"
                            >
                              <MenuItem value={"60 Bpm"}>60 Bpm</MenuItem>
                              <MenuItem value={"80 Bpm"}>80 Bpm</MenuItem>
                              <MenuItem value={"100 Bpm"}>100 Bpm</MenuItem>
                            </Select>
                          </div>
                        </div>
                        <div className="actuals-inputs">
                          <label className="small-device-label">
                            Actuals Heart Rate
                          </label>
                          <div className="third-row-two-inputs">
                            <input
                              type="text"
                              placeholder="123"
                              name="actualHeartRate"
                              value={workOutFormValues.actualHeartRate}
                              onChange={handleInputChange}
                            />
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              className="select"
                              name="actualHeartRateMeasure"
                              value={workOutFormValues.actualHeartRateMeasure}
                              onChange={handleInputChange}
                              label="select"
                              variant="standard"
                              placeholder="select"
                            >
                              <MenuItem value={"60 Bpm"}>60 Bpm</MenuItem>
                              <MenuItem value={"80 Bpm"}>80 Bpm</MenuItem>
                              <MenuItem value={"100 Bpm"}>100 Bpm</MenuItem>
                            </Select>
                          </div>
                        </div>
                      </div>
                      <div className="third-row-form-group">
                        <div className="label-main">
                          <label className="full-device-label">Pace</label>
                        </div>
                        <div className="planned-inputs">
                          <label className="small-device-label">
                            Planned Pace
                          </label>
                          <div className="third-row-two-inputs one-input">
                            {/* <input type="text" placeholder="123" /> */}
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              className="select"
                              name="plannedPace"
                              value={workOutFormValues.plannedPace}
                              onChange={handleInputChange}
                              label="select"
                              variant="standard"
                              placeholder="select"
                            >
                              <MenuItem value={10}>Ten</MenuItem>
                              <MenuItem value={20}>Twenty</MenuItem>
                              <MenuItem value={30}>Thirty</MenuItem>
                            </Select>
                          </div>
                        </div>
                        <div className="actuals-inputs">
                          <label className="small-device-label">
                            Actuals Pace
                          </label>
                          <div className="third-row-two-inputs one-input">
                            {/* <input type="text" placeholder="123" /> */}
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              className="select"
                              name="actualPace"
                              value={workOutFormValues.actualPace}
                              onChange={handleInputChange}
                              label="select"
                              variant="standard"
                              placeholder="select"
                            >
                              <MenuItem value={10}>Ten</MenuItem>
                              <MenuItem value={20}>Twenty</MenuItem>
                              <MenuItem value={30}>Thirty</MenuItem>
                            </Select>
                          </div>
                        </div>
                      </div>
                      <div className="third-row-form-group">
                        <div className="label-main">
                          <label className="full-device-label">
                            Rate of Perceived Exertion (RPE)
                          </label>
                        </div>
                        <div className="planned-inputs">
                          <label className="small-device-label">
                            Planned RPE
                          </label>
                          <div className="third-row-two-inputs one-input">
                            {/* <input type="text" placeholder="123" /> */}
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              className="select"
                              name="plannedRPE"
                              value={workOutFormValues.plannedRPE}
                              onChange={handleInputChange}
                              label="select"
                              variant="standard"
                              placeholder="select"
                            >
                              <MenuItem value={10}>Ten</MenuItem>
                              <MenuItem value={20}>Twenty</MenuItem>
                              <MenuItem value={30}>Thirty</MenuItem>
                            </Select>
                          </div>
                        </div>
                        <div className="actuals-inputs">
                          <label className="small-device-label">
                            Actuals RPE
                          </label>
                          <div className="third-row-two-inputs one-input">
                            {/* <input type="text" placeholder="123" /> */}
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              className="select"
                              name="actualRPE"
                              value={workOutFormValues.actualRPE}
                              onChange={handleInputChange}
                              label="select"
                              variant="standard"
                              placeholder="select"
                            >
                              <MenuItem value={10}>Ten</MenuItem>
                              <MenuItem value={20}>Twenty</MenuItem>
                              <MenuItem value={30}>Thirty</MenuItem>
                            </Select>
                          </div>
                        </div>
                      </div>
                      <div className="third-row-form-group">
                        <div className="label-main">
                          <label className="full-device-label">Cadence</label>
                        </div>
                        <div className="planned-inputs">
                          <label className="small-device-label">
                            Planned Cadence
                          </label>
                          <div className="third-row-two-inputs">
                            <input
                              type="text"
                              placeholder="123"
                              name="plannedCadence"
                              value={workOutFormValues.plannedCadence}
                              onChange={handleInputChange}
                            />
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              className="select"
                              name="plannedCadenceMeasure"
                              value={workOutFormValues.plannedCadenceMeasure}
                              onChange={handleInputChange}
                              label="select"
                              variant="standard"
                              placeholder="select"
                            >
                              <MenuItem value={"60 Spm"}>60 Spm</MenuItem>
                              <MenuItem value={"80 Spm"}>80 Spm</MenuItem>
                              <MenuItem value={"100 Spm"}>100 Spm</MenuItem>
                            </Select>
                          </div>
                        </div>
                        <div className="actuals-inputs">
                          <label className="small-device-label">
                            Actuals Cadence
                          </label>
                          <div className="third-row-two-inputs">
                            <input
                              type="text"
                              placeholder="123"
                              name="actualCadence"
                              value={workOutFormValues.actualCadence}
                              onChange={handleInputChange}
                            />
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              className="select"
                              name="actualCadenceMeasure"
                              value={workOutFormValues.actualCadenceMeasure}
                              onChange={handleInputChange}
                              label="select"
                              variant="standard"
                              placeholder="select"
                            >
                              <MenuItem value={"60 Spm"}>60 Spm</MenuItem>
                              <MenuItem value={"80 Spm"}>80 Spm</MenuItem>
                              <MenuItem value={"100 Spm"}>100 Spm</MenuItem>
                            </Select>
                          </div>
                        </div>
                      </div>
                      <div className="third-row-form-group">
                        <div className="label-main">
                          <label className="full-device-label">Power</label>
                        </div>
                        <div className="planned-inputs">
                          <label className="small-device-label">
                            Planned Power
                          </label>
                          <div className="third-row-two-inputs one-input">
                            {/* <input type="text" placeholder="123" /> */}
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              className="select"
                              name="plannedRPE"
                              value={workOutFormValues.plannedPower}
                              onChange={handleInputChange}
                              label="select"
                              variant="standard"
                              placeholder="select"
                            >
                              <MenuItem value={10}>Ten</MenuItem>
                              <MenuItem value={20}>Twenty</MenuItem>
                              <MenuItem value={30}>Thirty</MenuItem>
                            </Select>
                          </div>
                        </div>
                        <div className="actuals-inputs">
                          <label className="small-device-label">
                            Actuals Power
                          </label>
                          <div className="third-row-two-inputs one-input">
                            {/* <input type="text" placeholder="123" /> */}
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              className="select"
                              name="actualRPE"
                              value={workOutFormValues.actualPower}
                              onChange={handleInputChange}
                              label="select"
                              variant="standard"
                              placeholder="select"
                            >
                              <MenuItem value={10}>Ten</MenuItem>
                              <MenuItem value={20}>Twenty</MenuItem>
                              <MenuItem value={30}>Thirty</MenuItem>
                            </Select>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="rating-section">
                      <p className="rating-title">
                        How did you Post workout feel afterthe activity ?
                      </p>
                      <StyledRating
                        name="highlight-selected-only"
                        defaultValue={5}
                        value={workOutFormValues.postWorkoutFeel}
                        onChange={(event, newValue) => {
                          setWorkOutFormValues({
                            ...workOutFormValues,
                            postWorkoutFeel: newValue,
                          });
                        }}
                        IconContainerComponent={IconContainer}
                        getLabelText={(value) => customIcons[value].label}
                        highlightSelectedOnly
                      />
                    </div>
                    <div className="submit-section">
                      <div className="checkbox-section">
                        <input
                          type="checkbox"
                          name="isGroupWorkout"
                          checked={workOutFormValues.isGroupWorkout}
                          onChange={handleInputChange}
                        />
                        <label>Group workout</label>
                      </div>
                      <div className="submit">
                        <Button variant="contained">Submit</Button>
                      </div>
                    </div>
                  </div>
                </div>
              </Grid>
              <Grid md={5} sm={12} lg={5} xs={12}>
                <div className="third-row-rightside">
                  <div className="workout-form-group">
                    <label>Commnets</label>
                    <textarea
                      className="rightside-comment-textarea"
                      style={{ height: "200px" }}
                      type="text"
                      placeholder="Write Here.."
                      name="comment"
                      value={workOutCommentValues.comment}
                      onChange={handleCommentsChange}
                    />
                  </div>
                  <div className="workout-form-group">
                    <label>How important is this to you ?</label>
                    <div className="important-section">
                      <textarea
                        style={{ height: "150px" }}
                        type="text"
                        placeholder="Nice to Have"
                        name="niceToHave"
                        value={workOutCommentValues.niceToHave}
                        onChange={handleCommentsChange}
                      />
                      <textarea
                        style={{ height: "150px" }}
                        type="text"
                        placeholder="Important"
                        name="important"
                        value={workOutCommentValues.important}
                        onChange={handleCommentsChange}
                      />
                      <textarea
                        style={{ height: "150px" }}
                        type="text"
                        placeholder="Very Important"
                        name="veryImportant"
                        value={workOutCommentValues.veryImportant}
                        onChange={handleCommentsChange}
                      />
                    </div>
                  </div>
                  <div className="workout-form-gruop">
                    <Button
                      sx={{
                        width: "97%",
                        margin: "5px 10px",
                        background: "rgb(255, 204, 122)",
                        borderRadius: "20px",
                      }}
                      variant="contained"
                      color="primary"
                      onClick={handleCommentSubmit}
                    >
                      Subimt
                    </Button>
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Workouts;
