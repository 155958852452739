import React from 'react'
import { Input, Select } from '@mantine/core'
import Header from '../components/Header'

const TraineeManagementPage = () => {
  return (
    <>
      <Header />
      {/* Topbar */}
      <div className='p-6'>
        <div className='flex items-start gap-x-2'>
          <h1 className='font-medium text-xl'>Trainee Management Report</h1>
        </div>
        <br />
        <div className='flex flex-col md:flex-row gap-4 md:gap-8 items-start md:items-end mb-6'>
          <div>
            <Input.Wrapper id='input-demo' label='Search'>
              <Input id='input-demo' />
            </Input.Wrapper>
          </div>
          <Select
            label='Select a coach'
            data={[
              { value: 'Usha Hegde', label: 'Usha Hegde' },
              { value: 'Ignit Anand', label: 'Ignit Anand' },
              { value: 'Neil Dsilva', label: 'Neil Dsilva' },
              { value: 'Ranajit Roy', label: 'Ranajit Roy' }
            ]}
          />
          <Select
            label='Select an organization'
            data={[
              { value: 'YRA', label: 'YRA' },
              { value: 'YCA', label: 'YCA' },
              { value: 'YTA', label: 'YTA' },
              { value: 'YFA', label: 'YFA' }
            ]}
          />
          <Select
            label='Select view'
            data={[
              { value: 'Coach view', label: 'Coach view' },
              { value: 'Athlete view', label: 'Athlete view' }
            ]}
          />
          <button className='py-1.5 px-5 bg-blue-500 text-slate-50 rounded text-base'>
            Show reports
          </button>
        </div>
        {/* Table */}
        <div className='w-full mb-6'>
          <div className='my-6'>
            <div className='flex flex-col'>
              <div className='overflow-x-auto shadow-md'>
                <div className='inline-block min-w-full align-middle'>
                  <div className='overflow-hidden '>
                    <table className='min-w-full divide-y divide-gray-200 table-fixed dark:divide-gray-700'>
                      <thead className='bg-gray-100 '>
                        <tr>
                          <th
                            scope='col'
                            className='py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase dark:text-gray-400'
                          >
                            Coach
                          </th>
                          <th
                            scope='col'
                            className='py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase dark:text-gray-400'
                          >
                            Athlete
                          </th>
                          <th
                            scope='col'
                            className='py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase dark:text-gray-400'
                          >
                            Completed weekly training feedback to Trainee via
                            Call OR Message OR in Person
                          </th>
                          <th
                            scope='col'
                            className='py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase dark:text-gray-400'
                          >
                            Races Updated in System
                          </th>
                          <th
                            scope='col'
                            className='py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase dark:text-gray-400'
                          >
                            Run Training Zones aligned to current fitness levels
                          </th>
                          <th
                            scope='col'
                            className='py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase dark:text-gray-400'
                          >
                            Goals - Next set and ongoing updated in system
                          </th>
                          <th
                            scope='col'
                            className='py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase dark:text-gray-400'
                          >
                            Schedule call with Athlete
                          </th>
                          <th
                            scope='col'
                            className='py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase dark:text-gray-400'
                          >
                            Run Form Review - Online/Video or Physical Presence
                          </th>
                        </tr>
                      </thead>
                      <tbody className='bg-white divide-y divide-gray-200 '></tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default TraineeManagementPage
