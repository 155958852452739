import "./App.css";
import ScrollToTop from "./components/ScrollToTop";
import React, { useEffect, useState } from "react";
import { FitProRoutes } from "./Routes/routes";
import { DndProvider } from "react-dnd";
import HTML5Backend from "react-dnd-html5-backend";
import { UnderMentenance } from "./API/api-endpoint";
import MaintenancePage from "./pages/MaintenancePage";

function App() {
const [checkMaintenance,setcheckMaintenance]=useState()

  const getmentanenceMode =async()=>{
    const response =await UnderMentenance()
    if (response?.status) {
      setcheckMaintenance(response?.applicationmode)
    }
  }
  useEffect(()=>{
    getmentanenceMode()
  },[])
  console.log("checkMaintenance",checkMaintenance);
  
  return (
    <>
    {checkMaintenance === "maintenance"?(
      <MaintenancePage/>
    ):(
      <>
      <ScrollToTop />
      <DndProvider backend={HTML5Backend}>
        <FitProRoutes />
      </DndProvider>
      </>
    )}
    
    </>
  );
}

export default App;
