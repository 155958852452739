import {  FormControlLabel, FormHelperText,  Radio, RadioGroup, TextField } from '@mui/material'
import { useFormik } from 'formik';
import React, { useState } from 'react'
import { createCalculator } from '../../API/api-endpoint';
import { LoadingButton } from '@mui/lab';
import { handleInputChangeMinutesSeconds, handleTimeChange } from '../../utils/Resubale';
const distanceSwimming = [
    {
        name: "Yards",
        value: "yards"
    },
    {
        name: "Meters",
        value: "meters"
    }
]

const Triathlon = ({ fetchGoals, distanceUnit }) => {
    const [triathlonValue, setTriathlonValue] = useState();
    const formik = useFormik({
        initialValues: {
            sporttype: "multi", type: "triathalon", pacerun: "", runningtime: "", unitruntri: "", speedcycle: "",
            cyclingtime: "", unitbiketri: "", paceswim: "", swimmingtime: "", unitswimtri: "", goal: ""
        },
        validate: (values) => {
            const errors = {};

            if (!values.goal) {
                errors.goal = "Goal is required";
            }

            return errors;
        },
        onSubmit: (values, { resetForm }) => {
            handleOnSubmitForm(values, resetForm)

        },
    });
    const handleOnSubmitForm = async () => {
        if (formik.isValid) {
            const response = await createCalculator(formik?.values)
            if (response?.status) {
                let data = response.data.flat()
                let filterData = (data?.filter((ele) => ele !== null))
                let firstItem = Array.isArray(filterData[0]) ? filterData[0] : filterData;
        firstItem = firstItem[0];
      console.log([firstItem]);
      
      setTriathlonValue([firstItem]);
            }
        }
    }
    return (
        <div className='h-[80vh] overflow-scroll'>
            <form onSubmit={formik.handleSubmit}>
                <div
                    style={{
                        marginTop:"20px",
                        borderRadius: "10px",
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        gap: "10px",
                    }}
                    className="flow-running-form"
                >
                <div className="headingCont">
                <span className="heading">Triathlon</span>{" "}
                <span className="orange heading">Calculator</span>
              </div>

                    <label style={{ width: "10vw" }}>Goals:</label>
                    <select
                        className="running-step-input"
                        name="goal"
                        id="country"
                        value={formik?.values?.goal}
                        onChange={formik.handleChange}

                    ><option value="" disabled >
                            Select Goals
                        </option>
                        {fetchGoals?.map((ele, index) => (
                            <option key={index} value={ele?.goal_name}>
                                {ele?.goal_name}
                            </option>
                        ))}
                    </select>
                    <FormHelperText style={{ color: "#d32f2f" }}>
                        {formik.touched.goal && formik.errors.goal}
                    </FormHelperText>
                    <div style={{ "border": "1px solid rgb(128 128 128 / 35%)", "padding": "14px", "borderRadius": "8px" }}>
                        <label style={{ width: "10vw" }}>Swimming Pace:</label>
                        <TextField
                            fullWidth
                            name="paceswim"
                            value={formik?.values?.paceswim}
                            onBlur={formik.handleBlur}
                            onChange={(e)=>{
                                const formattedTime = handleInputChangeMinutesSeconds(e);
                                formik.setFieldValue("paceswim", formattedTime);
                                formik.setFieldValue("swimmingtime", "");

                            
                            }}
                            error={formik.touched.paceswim && formik.errors.paceswim}
                            helperText={
                                formik.touched.paceswim && formik.errors.paceswim
                            }
                            placeholder="Swimming Pace (mm:ss)"
                        />
                        <div className='mt-4'>
                            <label style={{ width: "10vw" }}>Swimming Completion Time:</label>


                            <TextField
                                fullWidth
                                name="swimmingtime"
                                type='text'
                                value={formik?.values?.swimmingtime}
                                onBlur={formik.handleBlur}
                                onChange={(e)=>{
                                    const formattedTime = handleTimeChange(e);
                                    formik.setFieldValue("swimmingtime", formattedTime);
                                formik.setFieldValue("paceswim", "");

                                
                                }}

                                error={formik.touched.swimmingtime && formik.errors.swimmingtime}
                                helperText={
                                    formik.touched.swimmingtime && formik.errors.swimmingtime
                                }
                                placeholder="Enter Time Trial (hh:mm:ss)"
                            />
                        </div>
                        <div className='mt-4'>

                            <label style={{ width: "10vw" }}>Units of measurement:</label>
                            <RadioGroup
                                aria-labelledby="demo-radio-buttons-group-label"
                                defaultValue=""
                                name="unitswimtri"
                                value={formik?.values?.unitswimtri}
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}

                                error={formik.touched.unitswimtri && formik.errors.unitswimtri}
                                helperText={
                                    formik.touched.unitswimtri && formik.errors.unitswimtri
                                }
                            >
                                {distanceSwimming?.map((unit) => {
                                    return (
                                        <FormControlLabel value={unit?.value} control={<Radio />} label={`100 ${unit?.name}`} />
                                    )
                                })}
                            </RadioGroup>
                            <FormHelperText style={{ color: "#d32f2f" }}>
                                {formik.touched.unitswimtri && formik.errors.unitswimtri}
                            </FormHelperText>
                        </div>
                    </div>
                    <div style={{ "border": "1px solid rgb(128 128 128 / 35%)", "padding": "14px", "borderRadius": "8px" }}>
                        <label style={{ width: "10vw" }}>Cycling Speed:</label>
                        <TextField
                            fullWidth
                            name="speedcycle"
                            value={formik?.values?.speedcycle}
                            onBlur={formik.handleBlur}
                            onChange={(e)=>{
                                const formattedTime = handleInputChangeMinutesSeconds(e);
                                formik.setFieldValue("speedcycle", formattedTime);
                                formik.setFieldValue("cyclingtime", "");

                            
                            }}
                            error={formik.touched.speedcycle && formik.errors.speedcycle}
                            helperText={
                                formik.touched.speedcycle && formik.errors.speedcycle
                            }
                            placeholder="Cycling Speed"
                        />
                        <div className='mt-4'>
                            <label style={{ width: "10vw" }}>Cycling Completion Time:</label>


                            <TextField
                                fullWidth
                                name="cyclingtime"
                                value={formik?.values?.cyclingtime}
                                onBlur={formik.handleBlur}
                                onChange={(e)=>{
                                    const formattedTime = handleTimeChange(e);
                                    formik.setFieldValue("cyclingtime", formattedTime);
                                formik.setFieldValue("speedcycle", "");

                                
                                }}
                                error={formik.touched.cyclingtime && formik.errors.cyclingtime}
                                helperText={
                                    formik.touched.cyclingtime && formik.errors.cyclingtime
                                }
                                placeholder="Enter Time Trial (hh:mm:ss)"
                            />
                        </div>
                        <div className='mt-4'>

                            <label style={{ width: "10vw" }}>Units of measurement:</label>
                            <RadioGroup
                                aria-labelledby="demo-radio-buttons-group-label"
                                defaultValue="female"
                                name="unitbiketri"
                                value={formik?.values?.unitbiketri}
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}

                                error={formik.touched.unitbiketri && formik.errors.unitbiketri}
                                helperText={
                                    formik.touched.unitbiketri && formik.errors.unitbiketri
                                }
                            >
                                {distanceUnit?.map((unit) => {
                                    return (
                                        <FormControlLabel value={unit?.value} control={<Radio />} label={unit?.name} />
                                    )
                                })}
                            </RadioGroup>
                            <FormHelperText style={{ color: "#d32f2f" }}>
                                {formik.touched.unitbiketri && formik.errors.unitbiketri}
                            </FormHelperText>
                        </div>
                    </div>
                    <div style={{ "border": "1px solid rgb(128 128 128 / 35%)", "padding": "14px", "borderRadius": "8px" }}>
                        <label style={{ width: "10vw" }}>Running Pace:</label>
                        <TextField
                            fullWidth
                            value={formik?.values?.pacerun}
                            // onChange={formik.handleChange}
                            placeholder="Running Pace (mm:ss)"
                            onBlur={formik.handleBlur}
                            type='text'
                            onChange={(e)=>{
                                const formattedTime = handleInputChangeMinutesSeconds(e);
                                formik.setFieldValue("pacerun", formattedTime);
                                formik.setFieldValue("runningtime", "");

                            
                            }}
                            
                            
                            error={formik.touched.pacerun && formik.errors.pacerun}
                            helperText={
                                formik.touched.pacerun && formik.errors.pacerun
                            }
                        />
                        <div className='mt-4'>
                            <label style={{ width: "10vw" }}>Running Completion Time:</label>


                            <TextField
                                fullWidth
                                name="runningtime"
                                value={formik?.values?.runningtime}
                                onBlur={formik.handleBlur}
                                onChange={(e)=>{
                                    const formattedTime = handleTimeChange(e);
                                    formik.setFieldValue("runningtime", formattedTime);
                                formik.setFieldValue("pacerun", "");

                                
                                }}
                                error={formik.touched.runningtime && formik.errors.runningtime}
                                helperText={
                                    formik.touched.runningtime && formik.errors.runningtime
                                }
                                placeholder="Enter Time Trial (hh:mm:ss)"
                            />
                        </div>
                        <div className='mt-4'>

                            <label style={{ width: "10vw" }}>Units of measurement:</label>
                            <RadioGroup
                                aria-labelledby="demo-radio-buttons-group-label"
                                defaultValue="female"
                                name="unitruntri"
                                value={formik?.values?.unitruntri}
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}

                                error={formik.touched.unitruntri && formik.errors.unitruntri}
                                helperText={
                                    formik.touched.unitruntri && formik.errors.unitruntri
                                }

                            >
                                {distanceUnit?.map((unit) => {
                                    return (
                                        <FormControlLabel value={unit?.value} control={<Radio />} label={unit?.name} />
                                    )
                                })}
                            </RadioGroup>
                            <FormHelperText style={{ color: "#d32f2f" }}>
                                {formik.touched.unitruntri && formik.errors.unitruntri}
                            </FormHelperText>
                        </div>
                    </div>
                    
                </div>
                &nbsp;
                <LoadingButton
                    size="small"
                    variant="contained"
                    className="form-btn"
                    sx={{
                        marginTop: 2,
                    }}
                    type="submit"
                    disabled={!formik.isValid}
                    loadingPosition="start"
                >
                    Calculate
                </LoadingButton>
            </form>
            &nbsp;

            {triathlonValue?.length > 0 &&
                <div>
                    <div className='mt-4 text-xl'><strong>Run</strong></div>
                    <div className='flex'>
                        <div style={{ border: "1px solid #d1caca", borderBottom: "none", borderRight: "none" }} className='p-[6px] box-border rounded-tl-sm w-[50%]  min-h-[5vh]'>
                            <b>Goal Name</b>
                        </div>
                        {triathlonValue[0]?.givenpaceperminuterun != "NaN:NaN" && (
                            <div style={{ border: "1px solid #d1caca", borderBottom: "none" }} className='p-[6px] box-border rounded-tr-sm w-[50%]  min-h-[5vh]'>
                                <b> Pace per Minute</b>
                            </div>
                        )}
                        {triathlonValue[0]?.runningtimebasedongivenpace &&

                            <div style={{ border: "1px solid #d1caca", borderBottom: "none" }} className='p-[6px] box-border rounded-tr-sm w-[50%]  min-h-[5vh]'>
                              <b> Time</b>
                            </div>
                          }


                        <div style={{ border: "1px solid #d1caca", borderBottom: "none" }} className='p-[6px] box-border rounded-tr-sm w-[50%]  min-h-[5vh]'>
                            <b> Running Distance</b>
                        </div>
                    </div>
                    {triathlonValue?.map((ele) => {
                        return (
                            <div className='flex'>
                                <div style={{ border: "1px solid #d1caca", borderRight: "none" }} className='p-[6px] box-border rounded-bl-sm w-[50%]  min-h-[5vh]'>
                                    {`${ele?.goal_name}`}
                                </div>
                                {ele?.givenpaceperminuterun != "NaN:NaN" && (
                                    <div style={{ border: "1px solid #d1caca" }} className='p-[6px] box-border rounded-br-sm w-[50%]  min-h-[5vh]'>
                                        {`${ele?.givenpaceperminuterun}  ${ele?.paceperminuterununit}`}
                                    </div>
                                )}
                                {ele?.runningtimebasedongivenpace &&
                                    <div style={{ border: "1px solid #d1caca" }} className='p-[6px] box-border rounded-tr-sm w-[50%]  min-h-[5vh]'>
                                      <b> {ele?.runningtimebasedongivenpace}</b>
                                    </div>
                                  }
                                <div style={{ border: "1px solid #d1caca" }} className='p-[6px] box-border rounded-br-sm w-[50%]  min-h-[5vh]'>
                                    {`${ele?.running_quota_f} ${ele?.runningunit}`}
                                </div>
                            </div>
                        )
                    })}

                    <div className='mt-4 text-xl'><strong>Cycle</strong></div>
                    <div className='flex'>
                        <div style={{ border: "1px solid #d1caca", borderBottom: "none", borderRight: "none" }} className='p-[6px] box-border rounded-tl-sm w-[50%]  min-h-[5vh]'>
                            <b>Goal Name</b>
                        </div>
                        {triathlonValue[0]?.givenspeedperhourcycle != "NaN" && (

                            <div style={{ border: "1px solid #d1caca", borderBottom: "none" }} className='p-[6px] box-border rounded-tr-sm w-[50%]  min-h-[5vh]'>
                                <b>Speed per Hour</b>
                            </div>
                        )}
                        {triathlonValue[0]?.cyclingtimebasedongivenspeed &&

                            <div style={{ border: "1px solid #d1caca", borderBottom: "none" }} className='p-[6px] box-border rounded-tr-sm w-[50%]  min-h-[5vh]'>
                              <b> Time</b>
                            </div>
                          }
                        <div style={{ border: "1px solid #d1caca", borderBottom: "none" }} className='p-[6px] box-border rounded-tr-sm w-[50%]  min-h-[5vh]'>
                            <b> Cycling Distance</b>
                        </div>
                    </div>
                    {triathlonValue?.map((ele) => {
                        return (
                            <div className='flex'>
                                <div style={{ border: "1px solid #d1caca", borderRight: "none" }} className='p-[6px] box-border rounded-bl-sm w-[50%]  min-h-[5vh]'>
                                    {`${ele?.goal_name}`}
                                </div>
                                {ele?.givenspeedperhourcycle != "NaN" && (

                                    <div style={{ border: "1px solid #d1caca" }} className='p-[6px] box-border rounded-br-sm w-[50%]  min-h-[5vh]'>
                                        {`${ele?.givenspeedperhourcycle}  ${ele?.speedperhourcycleunit}`}
                                    </div>
                                )}
                                {ele?.cyclingtimebasedongivenspeed &&
                                    <div style={{ border: "1px solid #d1caca" }} className='p-[6px] box-border rounded-tr-sm w-[50%]  min-h-[5vh]'>
                                      <b> {ele?.cyclingtimebasedongivenspeed}</b>
                                    </div>
                                  }
                                <div style={{ border: "1px solid #d1caca" }} className='p-[6px] box-border rounded-br-sm w-[50%]  min-h-[5vh]'>
                                    {`${ele?.cycling_quota} ${ele?.cyclingunit}`}
                                </div>
                            </div>
                        )
                    })}

                    <div className='mt-4 text-xl'><strong>Swim</strong></div>
                    <div className='flex'>
                        <div style={{ border: "1px solid #d1caca", borderBottom: "none", borderRight: "none" }} className='p-[6px] box-border rounded-tl-sm w-[50%]  min-h-[5vh]'>
                            <b>Goal Name</b>
                        </div>
                        {triathlonValue[0]?.givenpaceperminuteswim != "NaN:NaN" && (
                            <div style={{ border: "1px solid #d1caca", borderBottom: "none" }} className='p-[6px] box-border rounded-tr-sm w-[50%]  min-h-[5vh]'>
                                <b> Pace per Minute</b>
                            </div>
                        )}
                        {triathlonValue[0]?.swimmingtimebasedongivenpace &&

                            <div style={{ border: "1px solid #d1caca", borderBottom: "none" }} className='p-[6px] box-border rounded-tr-sm w-[50%]  min-h-[5vh]'>
                              <b> Time</b>
                            </div>
                          }
                        <div style={{ border: "1px solid #d1caca", borderBottom: "none" }} className='p-[6px] box-border rounded-tr-sm w-[50%]  min-h-[5vh]'>
                            <b> Swimming Distance</b>
                        </div>
                    </div>
                    {triathlonValue?.map((ele) => {
                        return (
                            <div className='flex'>
                                <div style={{ border: "1px solid #d1caca", borderRight: "none" }} className='p-[6px] box-border rounded-bl-sm w-[50%]  min-h-[5vh]'>
                                    {`${ele?.goal_name}`}
                                </div>
                                {ele?.givenpaceperminuteswim != "NaN:NaN" && (

                                    <div style={{ border: "1px solid #d1caca" }} className='p-[6px] box-border rounded-br-sm w-[50%]  min-h-[5vh]'>
                                        {`${ele?.givenpaceperminuteswim}  ${ele?.paceperminuteswimunit}`}
                                    </div>
                                )}
                                {ele?.swimmingtimebasedongivenpace &&
                                    <div style={{ border: "1px solid #d1caca" }} className='p-[6px] box-border rounded-tr-sm w-[50%]  min-h-[5vh]'>
                                      <b> {ele?.swimmingtimebasedongivenpace}</b>
                                    </div>
                                  }
                                <div style={{ border: "1px solid #d1caca" }} className='p-[6px] box-border rounded-br-sm w-[50%]  min-h-[5vh]'>
                                    {`${ ele?.swimming_quota} ${ele?.swimminguni}`}
                                </div>
                            </div>
                        )
                    })}
                </div>
            }



        </div>
    )
}

export default Triathlon
