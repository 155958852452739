import {
  FormLabel,
  Grid,
  MenuItem,
  OutlinedInput,
  TextField,
} from "@mui/material";
import { Button, Modal, TimePicker } from "antd";
import React, { useEffect, useState } from "react";
import {
  createSystemConfigrationdata,
  getAllActivityData,
  updateSystemConfigrationdata,
  uploadsaveFile,
} from "../../API/api-endpoint";
import { useFormik } from "formik";
import Swal from "sweetalert2";
import SlickCarousel from "../../pages/SlickCarousel";
import { capitalizeFirstLetter } from "../../utils/Resubale";
const CreateSystemConfigration = ({
  fetchReport,
  setShowAssesmentModal,
  showAssesmentModal,
  editData,
  setEditData,
}) => {
  const formik = useFormik({
    initialValues: {
      type: "",
      value: "",
      user_id: 2,
    },
    validate: (values) => {
      const errors = {};
      if (!values.type) {
        errors.type = "Type is required";
      }
      if (!values.value) {
        errors.value = "Value is required";
      }
      return errors;
    },
    // validationSchema: {},
    onSubmit: (values, { resetForm }) => {
      handleSubmitAssesmentForm(values, resetForm);
    },
  });
  console.log("formik", formik?.values, formik?.errors);

  const handleSubmitAssesmentForm = async (data, resetForm) => {
    let response = "";
    if (editData?.id) {
      response = await updateSystemConfigrationdata(data);
    } else {
      response = await createSystemConfigrationdata(data);
    }
    if (response?.status) {
      Swal.fire({
        title: "Success",
        text:capitalizeFirstLetter(response.message),
        icon: "success",
      });
      setEditData({});
      setShowAssesmentModal(false);
      fetchReport();
      formik.resetForm();
      formik?.setValues({
        type: "",
        value: "",
        user_id: 2,
      });
    } else {
      Swal.fire({
        title: "Error",
        text: response.message,
        icon: "error",
      });
    }
    console.log("response", response);
  };
  useEffect(() => {
    if (editData?.id) {
      const { srID, createdAt, updatedAt, ...data } = editData;
      formik?.setValues(data);
    }
  }, [editData?.id]);

  return (
    <Modal
      width={1200}
      open={showAssesmentModal}
      onCancel={() => {
        setShowAssesmentModal(false);
        formik.resetForm();
        setEditData({});
        formik?.setValues({
          type: "",
          value: "",
          user_id: 2,
        });
      }}
      footer={
        <div></div>
        //   loading={isLoading}
      }
    >
      <div className="headingCont">
        <span className="heading">{editData?.id ? "Edit " : "Create"}</span>{" "}
        <span className="orange heading">System Configuration</span>
      </div>
      {/* <h1>{editData ? editData.challengeId : values.challengeId}</h1> */}
      <div className="parentCont">
        <form className="form1" onSubmit={formik.handleSubmit}>
          <Grid container spacing={2}>
          {!editData?.id &&
            <Grid item xs={12} sm={11}>
            <FormLabel>Type<span className="text-[red]">*</span></FormLabel>

            <TextField
              fullWidth
              placeholder="Type"
              size="small"
              type="text"
              name="type"
              value={formik?.values?.type}
              disabled={editData?.id}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.type && formik.errors.type}
              helperText={formik.touched.type && formik.errors.type}
            />
          </Grid>
          }
           
            <Grid item xs={12} sm={11}>
              <FormLabel>Value<span className="text-[red]">*</span></FormLabel>

              <TextField
                fullWidth
                placeholder="Value"
                size="small"
                type="text"
                name="value"
                value={formik?.values?.value}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.value && formik.errors.value}
                helperText={formik.touched.value && formik.errors.value}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Button
                className="btn"
                key="submit"
                type="primary"
                onClick={() => formik.handleSubmit()}
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </form>
        <div className="slick-container">
          <SlickCarousel />
        </div>
      </div>
    </Modal>
  );
};

export default CreateSystemConfigration;
