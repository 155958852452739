import React, { useState, useEffect,useRef } from "react";
import {
  IconEdit,
  IconLiveView,
  IconMessage,
  IconTrash,
  IconCopy,

  IconX,
} from "@tabler/icons";
import TagInput from "../../../../components/Taginput";
import { Modal, Table, Tag } from "antd";
import AddWorkout from "./AddWorkout";
import { Button, FormLabel, TextField } from "@mui/material";
import {
  deleteWorkoutfromLibraryByWorkoutId,
  getAllWorkoutsFromLibraryByLibraryId,
  getAllPhasesFitnessLevelGoalList,
  getAllActivity,
  getAllSubActivity,
  getAllTags,
  getAllfitnesLevel,
  getAllWorkoutsByLiabrary,
  copyPasteWorkoutLibrari,
  copyPasteWorkoutLibrariWorkout,
} from "../../../../API/api-endpoint";
import DeleteModal from "../../../DeleteModal";
import Swal from "sweetalert2";
import "./AddWorkout.css"
import CustomModal from "../../../../components/CopyModalForm";

const ManageWorkouts = (props) => {
  const {
    setIsOpenWorkOut,
    isOpenWorkOut,
    selectedLibraryItem,
    setIsLibraryChange,
    isLibraryChange,
    fetchAllActivities,
    editFormData,
    setEditFormData,
    workoutsData
  } = props;
  console.log("selectedLibraaryItem", isOpenWorkOut,workoutsData);
  const tableStyle = {
    // border: 1px solid gray,
    // border-radius: 3px;
    // border: '1px solid #000', // Add border to the table
  };
  const tableRef = useRef(null);
  const [scrollPosition, setScrollPosition] = useState(0);
  const headerStyle = {
    background: '#f0f0f0', // Change header background color
  };
  const [workouts, setWorkouts] = useState([]);
  const [copyWorkouts, setCopyWorkouts] = useState({valid:false,id:"",title:""});

  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteModalId, setDeleteModalId] = useState();
  console.log("copyWorkouts", copyWorkouts);

  const [OptionListFormAddWorkoutForm, setOptionListFormAddWorkoutForm] =
    useState();
  
  console.log("workouts", workouts, editFormData);
  useEffect(() => {
    fetchAllWorkoutsFromLibrary();
    fetchAllDetails();
  }, [isLibraryChange, selectedLibraryItem.id]);
  useEffect(() => {
    if (isOpenWorkOut === "showTable" && tableRef.current) {
      tableRef.current.scrollTop = scrollPosition;
    }
  }, [isOpenWorkOut]);

  const handleEditClick = (index) => {
    const tableElement = tableRef.current;
    if (tableElement) {
      setScrollPosition(tableElement.scrollTop);
    }
    
  };



  const fetchAllWorkoutsFromLibrary = async () => {
    console.log("selectedLibraryItem", selectedLibraryItem);
    let library_id = selectedLibraryItem["workout-library-id"];
    let result = await getAllWorkoutsByLiabrary(selectedLibraryItem?.id);
    console.log("result", result[0]?.workout_library_workouts);
    setWorkouts(result[0]?.workout_library_workouts);
    setIsLibraryChange(false);
  };

  const fetchAllDetails = async () => {
    const fethAllTagslevels = await getAllfitnesLevel(selectedLibraryItem.id)
    let PhasesFitnessLevelGoal = await getAllPhasesFitnessLevelGoalList();
    let activity = await getAllActivity();
    let subActivity = await getAllSubActivity();
    let Tags = await getAllTags();

    setOptionListFormAddWorkoutForm({
      PhasesFitnessLevelGoal: fethAllTagslevels?.phasenames,
      activity: fethAllTagslevels?.phasenames,
      subActivity: subActivity,
      Tags: fethAllTagslevels?.levels,
    });
  };

  const columns = [
    {
      title: "Id",
      dataIndex: "id",
      key: "id",
      render: (id) => id,
    },
    {
      title: 'Name',
      dataIndex: ['workout_master', 'workout_planned_title'],
      key: 'workout_planned_title',
      render: (id) => id,

    },
    {
      title: 'Activity',
      dataIndex: ['workout_master', 'activity', 'activity_name'],
      key: 'activity_name',
      render: (activity) => activity,
    },
    {
      title: 'Sub-Activity',
      dataIndex: ['workout_master', 'subworkout', 'subworkout'],
      key: 'subworkout',
      render: (subActivity) => subActivity,
    },
  {
    title: "Distance",
    dataIndex: ["workout_master", "workout_planned_distance"],
    key: "workout_planned_distance",
    render: (distance, record) => (
      <>
        {distance} {record.workout_master.unit}
      </>
    ),
  },
    {
      title: "Duration",
      dataIndex: ['workout_master', 'workout_planned_duration'],
      key: 'workout_planned_duration',
      render: (duration) => duration,
    },
    {
      title: "Activity Description",
      dataIndex: ['workout_master', 'workout_description'],
      key: 'workout_description',
      render: (description) =>
        <label dangerouslySetInnerHTML={{
          __html: description,
        }}></label>,
    },

    {
      title: "Actions",
      dataIndex: "id",
      key: "id",
      render: (id, workout_id) => (
        <div className="flex ">
          <span className="pr-2 cursor-pointer">
            <IconEdit
              color="#E67E22"
              onClick={() => {
                setIsOpenWorkOut("showForm");
                setEditFormData({ workout_id: id, isEditForm: true });
                handleEditClick(id)
              }}
            />
          </span>
          <span className="px-2 cursor-pointer">
            <IconTrash
              color="red"
              onClick={() => handleOpen(workout_id)}
            />
          </span>
          <span className="px-2 cursor-pointer">
            <IconCopy
              color="#E67E22"
              onClick={() => handleOpenCopyWorkouts(workout_id)}
            />
          </span>
        </div>
      ),
    },
  ];
  const containsImage = (description) => {
    const imgRegex = /<img[^>]+>/i;
    return imgRegex.test(description);
  };
  const handleOpenCopyWorkouts=(id)=>{
    setCopyWorkouts({valid:true, id:id.id})
  }
  const handleCutCopyLibraryID = async () => {
    if (copyWorkouts?.title) {
      let result = await copyPasteWorkoutLibrariWorkout(copyWorkouts);
      console.log("result", result);
      if (result.status) {
        Swal.fire({
          title: "Success",
          text: result.message,
          icon: "success",
        });
        setCopyWorkouts({valid:false,title:"",id:""})
        setIsLibraryChange(true); //update the libray state then fetch all workout again
        setTimeout(() => {
          setIsLibraryChange(false);
        }, 100);
        // setSelectedLibraryItem({});
      }
    }else{
      Swal.fire({
        title: "Alert",
        text: "Please add title first",
        icon: "info",
      });
    }
   
  };
  const handleOpen = async (workout_id) => {
    setDeleteModal(true)
    setDeleteModalId(workout_id)
    // let result = await deleteWorkoutfromLibraryByWorkoutId(workout_id);
    // console.log(result);
    // if (result.message == "Workout deleted successfully") {
    //   console.log("98");
    //   setIsLibraryChange(true); //update the libray state then fetch all workout again
    // }
    setTimeout(() => {
      setIsLibraryChange(false);
    }, 100);
  };
  const deleteWorkoutFromLibrary = async () => {
    let result = await deleteWorkoutfromLibraryByWorkoutId(deleteModalId?.workout_id);
    console.log(result);
    if (result.message == "workout deleted successfully") {
      console.log("98");
      setDeleteModal(false)
      setIsLibraryChange(true); //update the libray state then fetch all workout again
    }
    setTimeout(() => {
      setIsLibraryChange(false);
    }, 100);
  };

  return (
    <div
      style={{
        marginTop: "50px",
      }}
    >
      <div className="workout-screen-section">
        <div className="text-center mt-12">
          <h1 className="font-bold text-xl text-slate-600 mt-7">
            Library Name : {selectedLibraryItem["workout_library_name"]}
          </h1>
        </div>
        {isOpenWorkOut === "showTable" && (
          <div className="workout-table-section py-4 ">
            <div className="workout-button-section mb-2">
              <Button
                variant="contained"
                style={{backgroundColor:"#E67E22"}}
                onClick={() => {
                  setEditFormData({ workout_id: null, isEditForm: false });
                  setIsOpenWorkOut("showForm");
                }}
              >
                Add Workout
              </Button>
            </div>
           <div style={{height:"68vh", overflowY:"scroll",scrollbarWidth:"thin",scrollbarColor: "#E67E22 white"}} ref={tableRef}>
           
            <Table columns={columns}
           // scroll={{ y: 400 }} 
           sticky
             style={tableStyle} // Apply table border
             className="custom-table" // Optional class for additional styling
             bordered // Show borders around columns
             headerStyle={headerStyle} // Apply header background color
             dataSource={workouts} pagination={true} /> 
           </div>
          </div>
        )}
        {deleteModal &&
          <DeleteModal deleteModal={deleteModal} workoutName={"workout"} setDeleteModal={setDeleteModal} deleteWorkoutFromLibrary={deleteWorkoutFromLibrary} />}
        {isOpenWorkOut === "showForm" && (
          <AddWorkout
            fetchAllActivities={fetchAllActivities}
            setIsOpenWorkOut={setIsOpenWorkOut}
            libraryName={selectedLibraryItem["workout-library-name"]}
            libraryID={selectedLibraryItem["id"]}
            setIsLibraryChange={setIsLibraryChange}
            workoutsData={workouts?workouts:workoutsData}
            editFormData={editFormData}
            OptionListFormAddWorkoutForm={OptionListFormAddWorkoutForm}
          />
        )}
      </div>
      <CustomModal
      title="Change title to copy workout"
      visible={copyWorkouts.valid}
      onOk={handleCutCopyLibraryID}
      onCancel={() => setCopyWorkouts({ valid: false, id: '', title: '' })}
      initialTitle={copyWorkouts.title}
      onChangeTitle={(title) =>
        setCopyWorkouts({ ...copyWorkouts, title })
      }
    />
    </div>
  );
};

export default ManageWorkouts;
