// import firebase from "firebase/app";
// import "firebase/auth";
// import "firebase/firestore";
// import "firebase/storage";
// import "firebase/functions";
// import axios from "axios";
import { initializeApp } from "firebase/app";
import { GoogleLogin, GoogleSignup } from "./api-endpoint";
import {
  getAuth,
  RecaptchaVerifier,
  PhoneAuthProvider,
  signInWithPopup,
  GoogleAuthProvider,
} from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import axios from "axios";
import { getAnalytics } from "firebase/analytics";
import { getStorage } from "firebase/storage";
import {
  get,
  push,
  getDatabase,
  set,
  update,
  ref,
  child,
  runTransaction,
  serverTimestamp,
} from "firebase/database";
import { storeUserCreds } from "../pages/Chat/redux/action/authAction";
// const firebaseConfig = {
//   apiKey: "AIzaSyAtuR3l6wl5ugE102LlxTBIsAk_1fYT3fE",
//   authDomain: "someprog-ed162.firebaseapp.com",
//   projectId: "someprog-ed162",
//   storageBucket: "someprog-ed162.appspot.com",
//   messagingSenderId: "783201874525",
//   appId: "1:783201874525:web:2df8469d5a24a47b9753b4"
// };
// const firebaseConfig = {
//   apiKey: "AIzaSyAtuR3l6wl5ugE102LlxTBIsAk_1fYT3fE",
//   authDomain: "yo-fit-288a9.firebaseapp.com",
//   projectId: "yo-fit-288a9",
//   storageBucket: "yo-fit-288a9.appspot.com",
//   messagingSenderId: "142461606348",
//   appId: "1:142461606348:web:c4018ec1ba0b15e15ca569",
//   measurementId: "G-Q8X9WRGL12",
// };

// firebase.initializeApp(firebaseConfig);
//
const firebaseConfig = {
  apiKey: "AIzaSyAcdgXFsrYIf6BCUgntZPEfoTnXX9dJqEo",
  authDomain: "yo-fit-v1.firebaseapp.com",
  databaseURL: "https://yo-fit-v1-default-rtdb.firebaseio.com",
  projectId: "yo-fit-v1",
  storageBucket: "yo-fit-v1.appspot.com",
  messagingSenderId: "780771101036",
  appId: "1:780771101036:web:63a8605a3119f12c5e4b49",
  measurementId: "G-J0JS1VDXP5"
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export const auth = getAuth(app);
export const firestore = getFirestore(app);
const provider = new GoogleAuthProvider();
provider.setCustomParameters({ prompt: "select_account" });
const db = getDatabase(app);
const storage = getStorage();

export {
  provider,
  db,
  storage,
  ref,
  get,
  push,
  set,
  update,
  child,
  runTransaction,
  serverTimestamp,
};

export const SignupWithGoogle = async (dispatch) => {
  try {
    // Sign in with Google Popup
    const userCredential = await signInWithPopup(auth, provider);
    const user = userCredential.user;
    const { uid, displayName, email, photoURL } = user;
    WriteUserData(uid, displayName, email, photoURL, dispatch);

    // Get the user's ID token
    const idToken = await user.getIdToken();

    // Set the ID token in localStorage
    localStorage.setItem("token", idToken);


    // Call the Firebase Function to perform signup
    const response = await GoogleSignup({
      uid: user.uid,
      fullName: user.displayName,
      email: user.email,
    });

    localStorage.setItem("fullname", user.displayName);
    localStorage.setItem("email", user.email);

    console.log(response);
    if (
      response.status 
    ) {
      localStorage.setItem("token", `Bearer ${response.token}`);
      localStorage.setItem("userId", response.userId);
    localStorage.setItem("roleID", response?.user?.role_id)

      return { success: true ,message:response?.message};
    } else {
      return { success: false, message:response?.message};
    }
  } catch (error) {
    console.error("Error signing in with Google:", error);

    let errorMessage = error.message;

    if (error.code === "auth/cancelled-popup-request") {
      // Handle the case where the user closed the Google popup
      // errorMessage = "Google sign-in was cancelled.";
    }

    return { success: false };
  }
};
export const SigninWithGoogle = async (dispatch) => {
  try {
    // Sign in with Google Popup
    const userCredential = await signInWithPopup(auth, provider);
    const user = userCredential.user;
    console.log("user",user);
    const { uid, displayName, email, photoURL } = user;
    WriteUserData(uid, displayName, email, photoURL, dispatch);

    // Get the user's ID token
    const idToken = await user.getIdToken();

    // Set the ID token in localStorage
    localStorage.setItem("token", idToken);

    // Call the Firebase Function to perform signup
    const response = await GoogleLogin({
      uid: user.uid,
      fullName: user.displayName,
      email: user.email,
    });

    localStorage.setItem("fullname", user.displayName);
    localStorage.setItem("email", user.email);

    console.log(response.data);
    if (
      response.status
    ) {
      localStorage.setItem("token", `Bearer ${response.token}`);
      return { success: true, isNewUser: false,user:response?.user };
    } else {
      return { success: false, isNewUser: true,message:response.message,user:response?.user,token:response?.token,subs:response?.subs };
    }
  } catch (error) {
    console.error("Error signing in with Google:", error);

    let errorMessage = error.message;

    if (error.code === "auth/cancelled-popup-request") {
      // Handle the case where the user closed the Google popup
      // errorMessage = "Google sign-in was cancelled.";
    }

    return { success: false };
  }
};

export async function WriteUserData(
  uid,
  displayName,
  email,
  photoURL,
  dispatch
) {
  console.log("displayName",uid,displayName,email);
  const db = getDatabase();
  const userRef = ref(db, "users/" + uid);
  try {
    const snapshot = await get(userRef);
    if (snapshot.exists()) {
      // User already signed up
      // Handle the case or dispatch an action if needed
      dispatch(storeUserCreds(uid, displayName, email, photoURL));
    } else {
      await set(userRef, {
        uid,
        displayName,
        email,
        photoURL,
        onlineStatus: {
          isOnline: false,
        },
      });
      // User signed up successfully
      dispatch(storeUserCreds(uid, displayName, email, photoURL));
    }
  } catch (error) {
    console.error("Error:", error);
    // Handle the error, e.g., show an error message
  }
}

export const sendOTPToUser = async (phone, token) => {
  try {
    // Replace with your Firebase API key
    const apiKey = "AIzaSyAcdgXFsrYIf6BCUgntZPEfoTnXX9dJqEo"; // Replace with your actual API key

    // URL for sending the verification code
    const url = `https://www.googleapis.com/identitytoolkit/v3/relyingparty/sendVerificationCode?key=${apiKey}`;

    // Data to send in the request
    const requestData = {
      phoneNumber: phone, // Replace with the phone number to send the code to
      recaptchaToken: token, // Additional parameters as needed for your use case
    };

    // Make the POST request using async/await
    const response = await axios.post(url, requestData);

    // Handle the response
    console.log("Verification code sent successfully:", response.data);
    return { success: true, data: response.data.sessionInfo };
  } catch (error) {
    // Handle errors
    console.error("Error sending verification code:", error);
    let errorMessage = error.message;

    if (error.response) {
      // If the response contains an error message, use it
      errorMessage = error.response.data.error.message;
    }

    return { success: false, message: errorMessage };
  }
};

export const verifyOTPAndMerge = async (sessionInfo, otp) => {
  try {
    // Replace with your Firebase API key
    const apiKey = "AIzaSyAcdgXFsrYIf6BCUgntZPEfoTnXX9dJqEo"; // Replace with your actual API key

    // URL for verifying the OTP
    const url = `https://www.googleapis.com/identitytoolkit/v3/relyingparty/verifyPhoneNumber?key=${apiKey}`;

    // Data to send in the request
    const requestData = {
      sessionInfo: sessionInfo,
      code: otp, // Replace with the OTP code
    };

    // Make the POST request using async/await
    const response = await axios.post(url, requestData);

    // Handle the response
    console.log("OTP verified successfully:", response.data);
    return { success: true, data: response.data, message: "" };
  } catch (error) {
    // Handle errors
    console.error("Error verifying OTP:", error);
    let errorMessage = error.message;

    if (error.response) {
      // If the response contains an error message, use it
      errorMessage = error.response.data.error.message;
    }

    return { success: false, message: errorMessage };
  }
};
