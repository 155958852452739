import React, { useEffect, useMemo, useState } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";
import { Button, CircularProgress,  Grid, Pagination, TextField } from "@mui/material";
import "../../components/AssignCoach/Assign.css";
import { ExclamationCircleFilled } from '@ant-design/icons';
import Header from "../../components/Header";
import { URL, deleteProgramFeature, getAllProgramFeature, } from "../../API/api-endpoint";
import { IconEdit, IconTrash } from "@tabler/icons";
import { Modal } from "antd";
import MenuItem from "@mui/material/MenuItem";
import CreateProgramFeature from "../../components/FeedData/CreateProgramFeature";

// let PageSize = 15;
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#1e40af",
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
        border: 0,
    },
}));
const ProgramFeature = () => {
    const { confirm } = Modal;
    const [reportData, setReportData] = useState()
    const [editData, setEditData] = useState()
    const [isLoading, setIsLoading] = useState(true)
    const [searchTerm, setSearchTerm] = useState('');
    const [showAssesmentModal, setShowAssesmentModal] = useState(false);


    const fetchReport = async () => {
        const response = await getAllProgramFeature()
        setIsLoading(false)
        setReportData(response?.data)
    }
    useEffect(() => {
        fetchReport()
    }, [])
    const [currentPage, setCurrentPage] = useState(1);
    const [selectedActivityFilter, setSelectedActivityFilter] = useState('All');

    const handleActivityFilterChange = (event) => {
        setSelectedActivityFilter(event.target.value);
        setCurrentPage(1); // Reset the current page when the filter changes
    };

    const filteredList = useMemo(() => {
        return reportData?.filter((row) => {
            if (selectedActivityFilter === 'All') {
                return row?.text?.toLowerCase().includes(searchTerm.toLowerCase());
            } else {
                return (
                    row?.text?.toLowerCase().includes(searchTerm.toLowerCase()) &&
                    row?.program?.program_name?.toLowerCase().trim() === selectedActivityFilter.toLowerCase()
                );
            }
        });
    }, [reportData, selectedActivityFilter, searchTerm]);
    let PageSize = searchTerm || selectedActivityFilter !== 'All' ? filteredList?.length : 15;
    const checkLastPage = useMemo(() => {
        let frstPgae = (currentPage - 1) * PageSize;
        let lastPage = frstPgae + PageSize;
        return filteredList?.slice(frstPgae, lastPage)?.map((row, index) => ({
            ...row,
            srID: index + 1 + (currentPage > 1 ? frstPgae : 0),
        }));
    }, [currentPage, reportData, filteredList]);
    const handlePageChange = (event, page) => {
        setCurrentPage(page);
    };

    const DeleteZones = async (id) => {

        const response = await deleteProgramFeature(id)
        setCurrentPage(1)
        fetchReport()
        console.log("res", response);
    }
    const showDeleteConfirm = (id) => {
        confirm({
            title: 'Are you sure delete this program feature?',
            icon: <ExclamationCircleFilled />,
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk() {
                DeleteZones(id)
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    };
    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
        setCurrentPage(1); // Reset the current page when the search term changes
    };
    return (
        <div>
            <Header />
            <div className="grid grid-cols-1 xl:grid-cols-5 items-start gap-x-4"></div>
            <div style={{ marginTop: "100px", padding: "20px" }}>
                <TableContainer component={Paper}>
                    <div style={{ fontSize: "18px", background: "#FFEADC", width: "100%", padding: "10px" }}>
                        <Grid container spacing={2}>
                        <Grid item xs={12} sm={4} sx={{ marginTop: "30px" }}>
                                <Button variant="contained" onClick={() => {
                                    setShowAssesmentModal(true)
                                    setEditData({})
                                }}>Create Program Feature</Button>
                            </Grid>
                           
                            <Grid item xs={12} sm={6} sx={{ textAlign: "end", marginTop: "30px" }}>
                                <TextField
                                    select
                                    size="small"
                                    style={{ width: '150px' }} 
                                    label="Filter by Program Name"
                                    value={selectedActivityFilter}
                                    onChange={handleActivityFilterChange}
                                >
                                    <MenuItem value="All">All</MenuItem>
                                    <MenuItem value="YRA">YRA</MenuItem>
                                    <MenuItem value="YCA">YCA</MenuItem>
                                    <MenuItem value="YSA">YSA</MenuItem>
                                    <MenuItem value="YFA">YFA</MenuItem>
                                    <MenuItem value="YTA">YTA</MenuItem>
                                </TextField>
                            </Grid>
                            <Grid item xs={12} sm={2} sx={{ textAlign: "end", marginTop: "30px" }}>
                                <TextField type="text" size="small" value={searchTerm} onChange={handleSearchChange} placeholder="Search By Feature Name.." />
                            </Grid>
                        </Grid>
                    </div>
                    <TableContainer style={{ maxHeight: 550,scrollbarWidth:"none" }}>
                    <Table stickyHeader sx={{ minWidth: 700}} aria-label="customized table">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell align="left">Sr ID</StyledTableCell>
                                <StyledTableCell align="left">Feature Name</StyledTableCell>
                                <StyledTableCell align="left">Program Name</StyledTableCell>
                                <StyledTableCell align="left">Image</StyledTableCell>
                                <StyledTableCell align="left">Action</StyledTableCell>


                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {isLoading ? (
                                <CircularProgress className="m-6" />) : (
                                <>
                                    {checkLastPage?.length > 0 ? (
                                        <>
                                            {checkLastPage?.map((row, index) => (
                                                <StyledTableRow key={index}>
                                                    <StyledTableCell align="left">
                                                        {row?.srID}
                                                    </StyledTableCell>
                                                    <StyledTableCell align="left">
                                                        {row?.text}

                                                    </StyledTableCell>
                                                    <StyledTableCell align="left">
                                                    {row?.program?.program_name}
                                                </StyledTableCell>
                                                    <StyledTableCell align="left">
                                                        <img
                                                            src={row?.image ? `${URL}/static/public/userimages/${row?.image}` : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTbQIfgvrUcDEY90ZrIVSMPYX4hvjFe963pHSxaVK3fuw&s"}
                                                            alt="..."
                                                            className="w-10 h-10 rounded-full border-2"
                                                        />
                                                    </StyledTableCell>

                                                    <StyledTableCell align="left">
                                                        <div className="flex ">
                                                            <span className="px-2 cursor-pointer">
                                                                <IconEdit
                                                                    color="blue"
                                                                    onClick={() => {
                                                                        setShowAssesmentModal(true);
                                                                        setEditData(row);
                                                                    }}
                                                                />
                                                            </span>
                                                            <span className="px-2 cursor-pointer">
                                                                <IconTrash
                                                                    color="red"
                                                                    onClick={() => showDeleteConfirm(row?.id)}
                                                                />
                                                            </span>
                                                        </div>
                                                    </StyledTableCell>
                                                </StyledTableRow>
                                            ))}
                                        </>
                                    ) : (
                                        <div className="p-4">No data found</div>
                                    )}
                                </>
                            )}
                        </TableBody>
                    </Table>
                    </TableContainer>
                </TableContainer>
                &nbsp;
                <div className="flex justify-end">
                    <Pagination
                        count={Math.ceil(filteredList?.length / PageSize)} // Calculate total number of pages
                        color="primary"
                        page={currentPage}
                        onChange={handlePageChange}
                    />
                </div>
            </div>
            {
                <CreateProgramFeature setEditData={setEditData} editData={editData} fetchReport={fetchReport} showAssesmentModal={showAssesmentModal} setShowAssesmentModal={setShowAssesmentModal} />}
        </div>
    )
}
export default ProgramFeature
