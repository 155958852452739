import dayjs from "dayjs";
import React, { useEffect, useReducer, useState } from "react";
import GlobalContext from "./GlobalContext";
import { fakeChallenges } from "../utils/LeaderBoardUsers";

function savedEventsReducer(state, { type, payload }) {
  switch (type) {
    case "push":
      return [...state, payload];
    case "update":
      return state.map((evt) => (evt.id === payload.id ? payload : evt));
    case "delete":
      return state.filter((evt) => evt.id !== payload.id);
    default:
      throw new Error();
  }
}

function initEvents() {
  const storageEvents = localStorage.getItem("activities");
  const parsedEvents = storageEvents ? JSON.parse(storageEvents) : [];
  return parsedEvents;
}

const ContextWrapper = (props) => {
  // States
  const [monthIndex, setMonthIndex] = useState(dayjs().month());
  const [smallCalendarMonth, setSmallCalendarMonth] = useState(null);
  const [daySelected, setDaySelected] = useState(dayjs());
  const [showEventModal, setShowEventModal] = useState(false);
  const [showLibraryModal, setShowLibraryModal] = useState(false);
  const [showBlockModal, setShowBlockModal] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [isRemember, setIsRemember] = useState(true);
  const [user, setUser] = useState("");
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [workoutMode, setWorkoutMode] = useState(false);
  const [challengesData, setChallengesData] = useState(fakeChallenges);

  // Hooks
  const [savedEvents, dispatchCalEvent] = useReducer(
    savedEventsReducer,
    [],
    initEvents
  );

  // useEffect(() => {
  //   localStorage.setItem('activities', JSON.stringify(savedEvents))
  // }, [savedEvents])

  useEffect(() => {
    if (smallCalendarMonth !== null) {
      setMonthIndex(smallCalendarMonth);
    }
  }, [smallCalendarMonth]);

  return (
    <GlobalContext.Provider
      value={{
        monthIndex,
        setMonthIndex,
        smallCalendarMonth,
        setSmallCalendarMonth,
        daySelected,
        setDaySelected,
        showEventModal,
        setShowEventModal,
        dispatchCalEvent,
        savedEvents,
        selectedEvent,
        setSelectedEvent,
        user,
        setUser,
        isLoggedIn,
        setIsLoggedIn,
        isRemember,
        setIsRemember,
        workoutMode,
        setWorkoutMode,
        showLibraryModal,
        setShowLibraryModal,
        showBlockModal,
        setShowBlockModal,
        challengesData,
        setChallengesData,
      }}
    >
      {props.children}
    </GlobalContext.Provider>
  );
};

export default ContextWrapper;
