import { getDatabase, onValue, ref } from "firebase/database";
import {
  GET_ALL_USERS_CHATS_FAIL,
  GET_ALL_USERS_CHATS_INIT,
  GET_ALL_USERS_CHATS_SUCCESS,
} from "../constant/userChats";

export const getCurrentUserChatsAction = (currentUserId) => async (dispatch) => {
  try {
    dispatch({ type: GET_ALL_USERS_CHATS_INIT });

    if (!currentUserId) {
      dispatch({ type: GET_ALL_USERS_CHATS_SUCCESS, payload: [] });
      return;
    }

    const db = getDatabase();
    const starCountRef = ref(db, `/userChats/${currentUserId}`);

    await onValue(starCountRef, (snapshot) => {
      const data = snapshot.val();
      const chats = data ? Object.values(data) : [];
      
      dispatch({
        type: GET_ALL_USERS_CHATS_SUCCESS,
        payload: chats,
      });
    });
  } catch (error) {
    dispatch({ type: GET_ALL_USERS_CHATS_FAIL, payload: error });
  }
};
