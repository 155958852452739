import { Avatar, Box, Button, Checkbox, Paper, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import "../../styles/chatlanding.css"
import "./../../styles/newchat/newChat.css"

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import EastOutlinedIcon from '@mui/icons-material/EastOutlined';
import { useDispatch, useSelector } from 'react-redux';
import { getAllUsersAction } from '../../redux/action/usersAction';
import { useLocation, useNavigate } from 'react-router-dom';
import NavForwardMsg from '../../components/navbar/NavForwardMsg';
import { db, get, push, ref, serverTimestamp, set, update } from '../../../../API/firebase.config';

export default function SelectMsgForwardUsers() {
    const paper = {
        padding: "0rem",
        minHeight: "100vh",
        maxWidth: '27rem'
    };
    const dispatch = useDispatch()
    const { state: { msgToForward } } = useLocation()
    const navigate = useNavigate()

    const { allUsers } = useSelector(state => state.users)
    const { currentUser } = useSelector(state => state.auth)
    const [filteredUsers, seTfilteredUsers] = useState([])

    const [selectedProfiles, setSelectedProfile] = useState([])

    const addToSelected = user => {
        const copiedSelected = [...selectedProfiles];

        if (copiedSelected.some(obj => obj.uid === user.uid)) {
            const filteredSelected = copiedSelected.filter(obj => obj.uid !== user.uid);
            setSelectedProfile(filteredSelected);
        } else {
            copiedSelected.push(user);
            setSelectedProfile(copiedSelected);
        }
    };
    console.log(selectedProfiles)

    const handleForwardMessage = async () => {
        console.log(msgToForward)
        const shareWithIDs = selectedProfiles.map(obj => obj.uid);

        try {
            for (const uid of shareWithIDs) {
                const combinedId =
                    currentUser.uid > uid
                        ? currentUser.uid + uid
                        : uid + currentUser.uid;
                const chatRef = ref(db, `chats/${combinedId}/messages`);
                const checkChatRef = ref(db, 'chats/' + combinedId);
                const snapshot = await get(checkChatRef);

                //

                if (!snapshot.exists()) {
                    // Create a chat in the "chats" collection
                    set(chatRef, {
                        isMsgReqAccepted: false
                    })
                        .then(() => {
                            return;
                        })
                        .catch((error) => {
                            console.error('Failed to write chat data:', error);
                        });

                    // Create user chats
                    const currentUserChatsRef = ref(db, `userChats/${currentUser.uid}`);
                    const userChatsRef = ref(db, `userChats/${uid}`);

                    await update(currentUserChatsRef, {
                        [`${combinedId}/userInfo`]: {
                            uid: uid
                        },
                        [`${combinedId}/date`]: serverTimestamp(),
                        [`${combinedId}/isMsgReqAccepted`]: true,
                        [`${combinedId}/lastMessage`]: '',
                        [`${combinedId}/chatType`]: 'personal',
                    });

                    await update(userChatsRef, {
                        [`${combinedId}/userInfo`]: {
                            uid: currentUser.uid,
                        },
                        [`${combinedId}/date`]: serverTimestamp(),
                        [`${combinedId}/isMsgReqAccepted`]: false,
                        [`${combinedId}/lastMessage`]: '',
                        [`${combinedId}/chatType`]: 'personal',
                    });

                    navigate('/individual-chat')
                }


                const updatedMessage = {
                    isStarred: false,
                    isForwarded: true,
                    isDeleted: false,
                    msgOwner: msgToForward?.msgOwner || msgToForward.senderId,
                    senderId: currentUser.uid,
                    type: msgToForward.type,
                    isSeen: false,
                    timeStamp: serverTimestamp(),
                    message: msgToForward.message,
                    docType: msgToForward.docType || null,
                    fileName: msgToForward.fileName || null,
                };

                await push(chatRef, updatedMessage);
            }
            console.log("post shared!")
            navigate('/')
            return;
        } catch (error) {
            console.error('Failed to send message:', error);
        }

    };

    useEffect(() => {
        dispatch(getAllUsersAction())
    }, [])

    return <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Paper sx={{ flexGrow: 1 }} elevation={10} style={paper}>
            <NavForwardMsg
                backButtonPath={"/"}
            />

            {
                filteredUsers.legnth > 0
                    ? filteredUsers
                    : allUsers
                        ?.map((item, index) => item.uid !== currentUser.uid && <Box className="profile-row"
                            onClick={() => {
                                addToSelected(item)
                                document.getElementById(`check-profile-${index}`).click()
                            }}
                            key={index}
                            sx={{ padding: "8px", paddingTop: "12px", display: "flex", justifyContent: 'space-between', alignItems: 'center' }}>
                            <Box sx={{ display: "flex", alignItems: 'center', padding: '6px' }}>
                                <Avatar alt="Remy Sharp" src={item.photoURL} />
                                <Box>
                                    <Typography className='userName' fontWeight="fontWeightBold" sx={{ paddingLeft: '1.2rem', fontSize: '14px' }}>{item.displayName}</Typography>
                                    <Typography sx={{ paddingTop: "2px", paddingLeft: '1.2rem', fontSize: '12px', display: "flex", alignItems: "center" }}>
                                        {'7724 Followers | 4.65'}
                                        <img src="/images/star-icon.png" alt="star" />
                                    </Typography>
                                </Box>
                            </Box>
                            <Checkbox
                                id={`check-profile-${index}`}
                                sx={{
                                    color: "#F4F4F4",
                                    '&.Mui-checked': {
                                        color: '#056B6B',
                                    }
                                }}
                                checked={selectedProfiles.some(obj => obj.uid === item.uid)}
                                icon={<CheckCircleOutlineIcon />}
                                checkedIcon={<CheckCircleIcon />}
                            />
                        </Box>)
            }

            <Box className="sticky-next-btn">
                <Button
                    disabled={!selectedProfiles.length > 0}
                    onClick={handleForwardMessage}
                    style={{ boxShadow: "-2px 4px 10px rgba(0, 0, 0, 0.25)" }} className={selectedProfiles.length > 0 ? 'next-mui-btn-selected' : 'next-mui-btn'}>
                    Forward
                    <EastOutlinedIcon sx={
                        selectedProfiles.length > 0
                            ? { fontSize: '14px', paddingLeft: '0.24rem', color: "black", marginTop: "-1px" }
                            : { fontSize: '14px', paddingLeft: '0.24rem', color: "#FFFFFF", marginTop: "-1px" }
                    } />
                </Button>
            </Box>
        </Paper >
    </Box >
}
