import React, {  useState } from "react";
import { Modal, Button } from "antd";
import {FormControl, FormLabel,FormHelperText, Grid, TextField } from "@mui/material";
import Swal from "sweetalert2";
import { useFormik } from "formik";
import { createTRackChallenge } from "../../API/api-endpoint";
import SlickCarousel from "../SlickCarousel";
import { capitalizeFirstLetter } from "../../utils/Resubale";
import ReactSelect from "react-select";


const CreateChallengeTrack = ({  isAssignUserModalOpen, isLoading, allChalengesData,  setIsAssignUserModalOpen }) => {

  const [addAllTrack, setAddAllTrack] = useState([])
  const formik = useFormik({
    initialValues: { trackName: "" },
    onSubmit: (values, { resetForm }) => {
      handleOnSubmitForm(values, resetForm)
    },
  });
  const handleOnSubmitForm = async () => {
    if (formik.values?.challenges && formik.values?.trackName) {
      let data = allChalengesData?.allchallenges?.filter((ele)=>ele.id ==formik.values?.challenges)
    let objectDat ={...formik.values, challnegName:data[0]?.challengeName}
      setAddAllTrack([...addAllTrack, objectDat])
    } else {
      Swal.fire({
        title: "Error",
        text: "Please fill all details",
        icon: "error",
      });
    }
  }
  const handleOnSavetForm = async () => {
    let allChallenges = [].concat(...addAllTrack.map(item => item.challenges));
    if (allChallenges?.length > 0) {
      let data = {
        track_name: formik.values?.trackName,
        challenges: allChallenges
      }
      const response = await createTRackChallenge(data)
      if (response.status) {
        setIsAssignUserModalOpen(false)
        Swal.fire({
          title: "Success",
          text: capitalizeFirstLetter(response?.message),
          icon: "success",
        });
      }
    }

    
  }
  const options = allChalengesData?.allchallenges?.map((value) => ({
      label: value['challengeName'],
      value: value['id'],
    })) || [];
  return (
    <Modal
      width={1200}
      // title={"Challenge Tracker"}
      // title={editData ? "Edit Challenge" : "Add Challenges"}
      open={isAssignUserModalOpen}
      onCancel={() => setIsAssignUserModalOpen(false)}
      footer={
        <div>
         
        </div>

      }
    >
      <div className="headingCont">
          <span className="heading">Challenge</span>{" "}
          <span className="orange heading">Tracker</span>
        </div>
    <div className="parentCont">
      <form  className="form1">
        <Grid container spacing={2}>

          <Grid item xs={12} sm={11} >
          <FormLabel >Track Name<span className="text-[red]">*</span></FormLabel>
            <TextField
              fullWidth
              placeholder="Track Name"
              size="small"
              type="text"
              name="trackName"
              value={formik?.values?.trackName}
              onChange={(e) => {
                const { value } = e.target;
                const capitalizedValue = value.charAt(0).toUpperCase() + value.slice(1);
                formik.setFieldValue('trackName', capitalizedValue);
              }}
            />
          </Grid>

          <Grid item xs={12} sm={11} >
          <FormLabel >Select Challenge<span className="text-[red]">*</span></FormLabel>
          <FormControl
                   fullWidth
                   error={formik.touched.challenges && Boolean(formik.errors.challenges)}
                 >
                <ReactSelect
                 styles={{
                   control: (provided, state) => ({
                       ...provided,
                       borderColor: formik.touched.challenges && formik.errors.challenges ? 'red' : provided.borderColor,
                       '&:hover': {
                         borderColor: formik.touched.challenges && formik.errors.challenges ? 'red' : provided['&:hover'].borderColor,
                       },
                       // boxShadow: formik.touched.challenges && formik.errors.challenges ? '0 0 0 1px red' : provided.boxShadow,
                     }),
                   menu: (provided, state) => ({
                     ...provided,
                     scrollbarColor: '#E67E22 white',
                     scrollbarWidth: 'thin',
                     '&::-webkit-scrollbar': {
                       width: '12px',
                     },
                     '&::-webkit-scrollbar-track': {
                       background: 'white',
                     },
                     '&::-webkit-scrollbar-thumb': {
                       backgroundColor: '#E67E22',
                       borderRadius: '20px',
                       border: '3px solid transparent',
                       backgroundClip: 'content-box',
                     },
                   }),
                 }}
                 name="challenges"
                 classNamePrefix="custom-select"
               //   isMulti
               // value={formik?.values?.challenges}
                 options={options}
                 value={options.find(option => option?.value === formik?.values?.challenges) || []}
                 onChange={(selectedOption) => {
                   formik.setFieldValue('challenges', selectedOption ? selectedOption.value : '');
                 }}
           
                 placeholder="Select Training Block"
                 isClearable
               />
               {formik.touched.challenges && formik.errors.challenges ? (
                     <FormHelperText>{formik.errors.challenges}</FormHelperText>
                   ) : null}
                   </FormControl>
          </Grid>
          {addAllTrack?.length > 0 &&
            <Grid item xs={12} sm={4} >

            <Button className="btn"  key="submit" type="primary" loading={isLoading} onClick={() => handleOnSavetForm()}>Save Tracks</Button>
            </Grid>
          }
          <Grid item xs={12} sm={4} >
          <div >
            <Button className="btn" key="submit" type="primary" loading={isLoading} onClick={() => handleOnSubmitForm()}>Add Track</Button>
          </div>
          </Grid>
        </Grid>

        &nbsp;&nbsp;&nbsp;
        {addAllTrack?.length > 0
          &&

          <div>
            <div ><strong>Challenge Track:</strong></div>
            <div className="flex">
              <div style={{ "width": "20%", padding: "2px", "border": "1px solid gray", fontWeight: "700" }}>
                #
              </div >
              <div style={{ "width": "68%", padding: "2px", "border": "1px solid gray", "borderLeft": "none", fontWeight: "700" }}>
                Challenge Name
              </div>
            </div>
            {addAllTrack?.map((ele, index) => {
              return (
                <div className="flex">
                  <div style={{ "width": "20%", padding: "2px", "borderTop": "none", "border": "1px solid gray" }}>
                    {index + 1}
                  </div >
                  <div style={{ "width": "68%", padding: "2px", "borderTop": "none", "border": "1px solid gray", "borderLeft": "none" }}>
                    {ele.challnegName}
                  </div>
                </div>
              )
            })}
          </div>}
      </form>
      <div className="slick-container">
      <SlickCarousel />
    </div>
  </div>
    </Modal>
  )
}

export default CreateChallengeTrack

