export const INITIAL_EVENTS = [
  {
    id: Math.floor(Math.random() * 900000) + 100000,
    title: "All-day event",
    classNames: "running",
    start: "2023-08-07",
    end: "2023-08-07",
    description: "Lecture",
  },
  {
    id: Math.floor(Math.random() * 900000) + 100000,
    title: "Doctor's appointment",
    classNames: "running",
    start: "2023-08-16",
    end: "2023-08-16",
    description: "Go for hospital",
  },
  // {
  //   id: Math.floor(Math.random() * 900000) + 100000,
  //   title: "Sales meeting",
  //   classNames: "business",
  //   start: "2023-08-11",
  //   end: "2023-08-11",
  //   description: "",
  // },
  // {
  //   id: Math.floor(Math.random() * 900000) + 100000,
  //   title: "Dinner Date",
  //   classNames: "family",
  //   start: "2023-08-09",
  //   end: "2023-08-09",
  //   description: "dinner time with family",
  // },
  // {
  //   id: Math.floor(Math.random() * 900000) + 100000,
  //   title: "Sister's Birthday",
  //   classNames: "family",
  //   start: "2023-08-31",
  //   end: "2023-08-31",
  //   description: "Party",
  // },
  // {
  //   id: Math.floor(Math.random() * 900000) + 100000,
  //   title: "Product Sprint planning",
  //   classNames: "business",
  //   start: "2023-08-28",
  //   end: "2023-08-28",
  //   description: "Lecture",
  // },
  {
    id: Math.floor(Math.random() * 900000) + 100000,
    title: "Maldivas Tour",
    classNames: "swimming",
    start: "2023-08-15",
    end: "2023-08-25",
    description: "Long weekend ",
  },
  // {
  //   id: Math.floor(Math.random() * 900000) + 100000,
  //   title: "independence Day",
  //   classNames: "holiday",
  //   start: "2023-08-15",
  //   end: "2023-08-16",
  //   description: "Long weekend ",
  // },
  // {
  //   id: Math.floor(Math.random() * 900000) + 100000,
  //   title: "Public Holiday",
  //   classNames: "holiday",
  //   start: "2023-08-03",
  //   end: "2023-08-03",
  //   description: "Enjoye",
  // },
  // {
  //   id: Math.floor(Math.random() * 900000) + 100000,
  //   title: "Client Meeting",
  //   classNames: "business",
  //   start: "2023-08-16",
  //   end: "2023-08-18",
  //   description: "Lecture",
  // },
  // {
  //   id: Math.floor(Math.random() * 900000) + 100000,
  //   title: "Green Day",
  //   classNames: "holiday",
  //   start: "2023-08-16",
  //   end: "2023-08-16",
  //   description: "Lecture",
  // },
  // {
  //   id: Math.floor(Math.random() * 900000) + 100000,
  //   title: "Fun activity",
  //   start: "2023-08-26",
  //   end: "2023-08-26",
  //   classNames: "holiday",
  //   description: "Have Some Fun",
  // },
];

export const LableOptions = [
  {
    LableValue: "running",
    LableIcon: (
      <div className="lable-option-container">
        <span className="lable-span lable-running"></span>
        <p className="lable-option-text"> running</p>
      </div>
    ),
  },
  // {
  //   LableValue: "business",
  //   LableIcon: (
  //     <div className="lable-option-container">
  //       <span className="lable-span lable-business"></span>
  //       <p className="lable-option-text"> Business</p>
  //     </div>
  //   ),
  // },
  // {
  //   LableValue: "family",
  //   LableIcon: (
  //     <div className="lable-option-container">
  //       <span className="lable-span lable-family"></span>
  //       <p className="lable-option-text"> Family</p>
  //     </div>
  //   ),
  // },
  // {
  //   LableValue: "holiday",
  //   LableIcon: (
  //     <div className="lable-option-container">
  //       <span className="lable-span lable-holiday"></span>
  //       <p className="lable-option-text"> Holiday</p>
  //     </div>
  //   ),
  // },
  {
    LableValue: "swimming",
    LableIcon: (
      <div className="lable-option-container">
        <span className="lable-span lable-swimming"></span>
        <p className="lable-option-text"> swimming</p>
      </div>
    ),
  },
];

export const filterOptions = [
  { value: "running", color: "error", lable:"running" },
  // { value: "business", color: "secondary", lable:"Business" },
  // { value: "family", color: "warning", lable:"Family" },
  // { value: "holiday", color: "success", lable:"Holiday" },
  { value: "swimming", color: "primary", lable:"swimming" },
];

export const filterDateIntoString = (strDate) => {
  // const inputDateString =
  //   "Thu Aug 03 2023 05:30:00 GMT+0530 (India Standard Time)";
  const inputDate = new Date(strDate);
  const year = inputDate.getFullYear();
  const month = String(inputDate.getMonth() + 1).padStart(2, "0");
  const day = String(inputDate.getDate()).padStart(2, "0");
  const formattedDate = `${year}-${month}-${day}`;
  return formattedDate;
};
