import React, { useState, useEffect } from "react";
import SearchIcon from "@mui/icons-material/Search";
import { styled, alpha } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import Card from "@mui/material/Card";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Collapse, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { IconAntennaBars1 } from "@tabler/icons";
import { Dropdown, Space } from "antd";
import {
    copyPasteWorkoutLibrari,
    copyPasteWorkoutLibrary,
    deleteWorkoutLibrary,
    getAllWorkoutLibrary,
    getAllWorkoutLibrary1,
    getAllWorkoutsByLiabrary,
    getAllWorkoutsFromLibraryByLibraryId,
} from "../../../API/api-endpoint";
import Swal from "sweetalert2";
import "../componants/ManageWorkouts/AddWorkout.css"
import { Switch } from "@mantine/core";
import { URL } from "../../../API/api-endpoint";
import CircularProgress from '@mui/material/CircularProgress';


const Search = styled("div")(({ theme }) => ({
    position: "relative",
    border: "1px solid black",
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    "&:hover": {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
        marginLeft: theme.spacing(1),
        // width: "auto",
    },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: "inherit",
    "& .MuiInputBase-input": {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create("width"),
        width: "100%",
        [theme.breakpoints.up("md")]: {
            // width: "20ch",
        },
    },
}));

const workOutGroup = [
    {
        name: "Workout Library 4",
        workouts: [],
    },
    {
        name: "Workout Library 1",
        workouts: [
            { name: "Jumping", calender: "Business" },
            { name: "Cycle", calender: "Personal" },
        ],
    },
    {
        name: "Workout Library 2",
        workouts: [
            { name: "Cycling", calender: "Personal" },
            { name: "Running", calender: "Holiday" },
            { name: "Swimming", calender: "ETC" },
            { name: "Walking", calender: "Personal" },
            { name: "Jumping", calender: "Business" },
            { name: "Cycle", calender: "Personal" },
        ],
    },
    {
        name: "Workout Library 3",
        workouts: [
            { name: "Cycling", calender: "Personal" },
            { name: "Running", calender: "Holiday" },
        ],
    },
    {
        name: "Workout Library 5",
        workouts: [
            { name: "Walking", calender: "Personal" },
            { name: "Jumping", calender: "Business" },
            { name: "Cycle", calender: "Personal" },
        ],
    },
];

const SearchBar = (props) => {
    const {
        setIsOpenWorkOut,
        setdraggedEvent,
        setIsOpenHandleWorkOut,
        setSelectedLibraryItem,
        setWorkoutLibraryDrawerState,
        workoutLibraryDrawerState,
        selectedLibraryItem,
        setIsLibraryChange,
        workoutLibrary,
        draggableEl,
        handleDragStart,
        handleChangeSidebar,
        togglesidebar,
        // setCreateModal,
        setIsProfileOpen,
        setIsEnrolledChallengesOpen,
        control
    } = props;
    console.log("selectedLibraryItem", selectedLibraryItem);
    // take a value from the user and save it to a state
    const [searchTerm, setSearchTerm] = useState("");
    const [isOpenCollapse, setIsOpenCollapse] = useState(null);
    const [createModal, setCreateModal] = useState(false);
  
    const [LibraryId, setLibraryId] = useState();
    const [workouts, setWorkouts] = useState(undefined);

    console.log("copyCutLibraryId", workouts);
    // at every occurance of the change event, sets the current value of the form to the state
    const handleChange = (e) => {
        setSearchTerm(e.target.value);
    };


 
  
    const [badge, setBadge] = useState("");
    const [IsLoader, setIsLoader] = useState(true);
    const fetchAllWorkoutsFromLibrary = async (id) => {
        console.log("hdsgfhsdgfh", id);
        setIsLoader(true)
        let result = await getAllWorkoutsByLiabrary(id);
        setIsLoader(false)
        setWorkouts(result[0]?.workout_library_workouts);
        setBadge(result[0]?.tagnames[0]?.badge)
    };
    const handleOpen = (clickedIndex, type, library_id) => {
        setCreateModal(false)
      
          setWorkouts([])
          console.log("type", type, library_id);
          if (type == "down") {
          setCreateModal(true)
      
              fetchAllWorkoutsFromLibrary(library_id)
          }
          setCreateModal(true)
      
          if (isOpenCollapse === clickedIndex) {
              setIsOpenCollapse(null);
          } else {
              setIsOpenCollapse(clickedIndex);
          }
      };

    const filterData = workoutLibrary?.filter((workout) =>
        workout?.workout_library_name?.toLowerCase().includes(searchTerm?.toLowerCase())
    );
    console.log("togglesidebar value:", togglesidebar);
    return (
        <div >
            {" "}
            <div className='my-4' >
            <ToggleButtonGroup size="small" {...control} aria-label="Small sizes">
                <ToggleButton
                  style={{
                    backgroundColor: togglesidebar === 'workout' ? '#E67E22' : 'inherit',
                    color: togglesidebar === 'workout' ? 'white' : 'inherit',
                  }}
                  value="workout" key="workout">
                  Workouts
                </ToggleButton>,
                <ToggleButton
                  style={{
                    backgroundColor: togglesidebar === 'athlete' ? '#E67E22' : 'inherit',
                    color: togglesidebar === 'athlete' ? 'white' : 'inherit',
                  }}
                  value="athlete" key="athlete">
                  Athletes
                </ToggleButton>
              </ToggleButtonGroup>
               
            </div>
            <Search style={{backgroundColor:"white",borderColor:"white"}}>
                <SearchIconWrapper>
                    <SearchIcon style={{color:"#E67E22"}}/>
                </SearchIconWrapper>
                <StyledInputBase
                    placeholder="Search…"
                    inputProps={{ "aria-label": "search" }}
                    value={searchTerm}
                    onChange={handleChange}
                />
            </Search> &nbsp;

            <div id="events" style={{height:"80vh", overflow:"scroll"}}>
                {filterData?.map((item, index) => (
                    <div className="p-1 ml-1 mt-1"  >
                        <div style={{ display: "flex", justifyContent: "space-between" ,fontSize:"14px"}}>
                            <div className="library-scrollbar">
                                <h3
                                    onClick={() => {
                                        setSelectedLibraryItem({ ...item, isEditForm: true });
                                    }}
                                    style={{fontSize:"14px"}}
                                >
                                    {item?.workout_library_name}
                                </h3>
                            </div>
                            <span>
                                {isOpenCollapse === index ? (
                                    <KeyboardArrowUpIcon 
                                    style={{color:"#E67E22"}}
                                        onClick={() => handleOpen(index, "up")}
                                    />
                                ) : (
                                    <KeyboardArrowDownIcon 
                                    style={{color:"#E67E22"}}
                                        onClick={() =>
                                            handleOpen(index, "down", item?.id)
                                        }
                                    />
                                )}
                            </span>

                        </div>
                        <hr />
                        {IsLoader && isOpenCollapse === index ? (<div><CircularProgress /></div>) : (
<>
                        {workouts !== undefined &&
                            workouts?.map((workout, workoutindex) => {
                                return (
                                    <Collapse in={isOpenCollapse === index} key={workoutindex}>
                                        <div
                                            id={`eventworkout-${workoutindex}`} // Update this line to use a unique identifier for each workout
                                            className="library-scrollbar eventworkout fc-event fc-h-event mb-1 fc-daygrid-event fc-daygrid-block-event p-2 cursor-all-scroll"
                                            title={workout?.workout_master?.workout_planned_title}
                                            data-id={workout?.id}
                                            workoutData={JSON.stringify(workout)}
                                            draggable={true}
                                            style={{marginRight:"6px",backgroundColor:"white",borderColor:"white",fontSize:"13px",display:"flex",alignItems:"center"}}
                                            onDragStart={(e) => handleDragStart(e, workout)}
                                        >
                                             <span style={{minWidth:"33px"}}>  
                                              <img
                                                src={`${URL}/static/public/assets/${badge}`}
                                                alt="..."
                                                className="w-6 h-6  border-2 rounded-full cursor-pointer mr-2"
                                                // style={{borderColor: eventInfo?.event?.borderColor}}
                                                style={{position:"relative",top:"4px"}}
                                              />
                                             </span>
                                            <span style={{top:"5px",color:"black"}} className="fc-event-main">{workout?.workout_master?.workout_planned_title}</span>
                                        </div>
                                    </Collapse>
                                );
                            })}
                            </>
                        )}
                    </div>
                ))}{" "}
            </div>
        </div>
    );
};
export default SearchBar
