import React, { useState, useEffect } from "react";
import SearchIcon from "@mui/icons-material/Search";
import { styled, alpha } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import Card from "@mui/material/Card";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Button from "@mui/material/Button";
import { Collapse } from "@mui/material";
import { IconAntennaBars1 } from "@tabler/icons";
import { Dropdown, Space } from "antd";
import {
  copyPasteWorkoutLibrary,
  deleteWorkoutLibrary,
  getAllWorkoutLibrary,
  getAllWorkoutLibrary1,
  getAllWorkoutsByLiabrary,
  getAllWorkoutsFromLibraryByLibraryId,
} from "../../../API/api-endpoint";
import { useDrag } from "react-dnd";
import { Flex } from "@mantine/core";
import { URL } from "../../../API/api-endpoint";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  border: "1px solid black",
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));

const DraggableItem = ({ workout, isOpenCollapse, workoutindex, index,badge }) => {
  let newObject = {
    ...workout,
    id: workout?.workout_id,
    type: "BLOCK",
    component: {
      type: workout?.workout_master?.workout_planned_title,
      content: workout?.workout_master?.workout_planned_title,
    },
    isHidden: false,
  };

  const [, ref] = useDrag({
    item: newObject,
  });
console.log("ddddd",workout);
  return (
    <Collapse in={isOpenCollapse === index} key={workoutindex}>
      <div
        draggable="true"
        key={"grf"}
        ref={ref}
        style={{backgroundColor:"white",borderColor:"white",fontSize:"13px",width:"90%",display:"flex",alignItems:"center",whiteSpace:"nowrap",overflowX:"auto",scrollbarWidth:"none",paddingRight:"10px"}}
        className="bg-violet-200/80 mt-1 p-2 mb-2 ml-2 pl-2 pr-2 text-gray-600"
      >
        <span style={{minWidth:"33px"}}>  
          <img
            src={`${URL}/static/public/assets/${badge}`}
            alt="..."
            className="w-6 h-6  border-2 rounded-full cursor-pointer mr-2"
            // style={{borderColor: eventInfo?.event?.borderColor}}
            // style={{position:"relative",top:"4px"}}
          />
         </span>
        <span style={{overflow:"scroll",scrollbarWidth:"thin",position:"relative", top:"6px"}}>{workout?.workout_master?.workout_planned_title}</span>
      </div>
    </Collapse>
  );
};

const Searchbar = (props) => {
  const {
    setIsOpenHandleWorkOut,
    setSelectedLibraryItem,
    setWorkoutLibraryDrawerState,
    selectedLibraryItem,
    setIsLibraryChange,
    setTogglesidebar,
  } = props;

  // take a value from the user and save it to a state
  const [searchTerm, setSearchTerm] = useState("");
  const [workoutLibrary, setWorkoutLibrary] = useState([]);
  const [isOpenCollapse, setIsOpenCollapse] = useState(null);
 
  const [LibraryId, setLibraryId] = useState(null);
  const [workouts, setWorkouts] = useState(undefined);
  const [copyCutLibraryId, setCutCopyLibraryID] = useState(undefined);

  // at every occurance of the change event, sets the current value of the form to the state
  const handleChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleCutCopyLibraryID = () => {
    setCutCopyLibraryID(LibraryId);
  };

  const handlePasteLibrary = async (type) => {
    let result = await copyPasteWorkoutLibrary({
      libraryId: copyCutLibraryId,
      destinationId: LibraryId,
      operationType: type,
    });
    if (result.message == "Library item pasted successfully") {
      setSelectedLibraryItem({});
    }
  };

  const handleManageWorkouts = () => {
    setIsLibraryChange(true);
    setIsOpenHandleWorkOut(true);
  };

  const handleDrawerChange = () => {
    setWorkoutLibraryDrawerState(true);
  };

  const items = [
    {
      label: (
        <div onClick={() => handleCutCopyLibraryID("copy")}>
          Copy Workout Library
        </div>
      ),
      key: "0",
    },
    {
      label: (
        <div onClick={() => handleCutCopyLibraryID("cut")}>
          Cut Workout Library
        </div>
      ),
      key: "1",
    },
    {
      label: <div onClick={handlePasteLibrary}>Paste Workout Library</div>,
      key: "3",
    },
    {
      label: <div onClick={handleDrawerChange}>Edit Workout Library</div>,
      key: "4",
    },
    {
      label: <div onClick={() => deleteLibrary()}>Delete Workout Library</div>,
      key: "5",
    },
    {
      label: (
        <div onClick={() => handleManageWorkouts()}>Manage workout(s)</div>
      ),
      key: "6",
    },
  ];

  const getLibraryList = async () => {
    let result = await getAllWorkoutLibrary1();
    setWorkoutLibrary(result?.library);
  };

  const deleteLibrary = async () => {
    let result = await deleteWorkoutLibrary(
      selectedLibraryItem["workout-library-id"]
    );
    setSelectedLibraryItem({});
  };

  useEffect(() => {
    getLibraryList();
  }, [selectedLibraryItem]);

  
  const [badge, setBadge] = useState("");
  const fetchAllWorkoutsFromLibrary = async (id) => {
    let result = await getAllWorkoutsByLiabrary(id);
    setWorkouts(result[0]?.workout_library_workouts);
    setBadge(result[0]?.tagnames[0]?.badge)
  };

  const filterData = workoutLibrary?.filter((workout) =>
  workout?.workout_library_name?.toLowerCase().includes(searchTerm?.toLowerCase())
);
console.log("filterData",filterData);
  const handleOpen = (clickedIndex, type, library_id) => {
    if (type == "down") {
      setLibraryId(library_id);
      fetchAllWorkoutsFromLibrary(library_id)

    }
    if (isOpenCollapse === clickedIndex) {
      setIsOpenCollapse(null);
    } else {
      setIsOpenCollapse(clickedIndex);
    }
  };

  return (
    <>
      {" "}
      <div >
      <Button
        size="small"
        style={{ margin: "10px", backgroundColor: "rgb(230, 126, 34)" }}
        variant="contained"
        onClick={() => setTogglesidebar(false)}
      >
        Open Training Plan
      </Button>
      </div>
      <Search style={{backgroundColor:"white",borderColor:"white"}}>
        <SearchIconWrapper>
          <SearchIcon style={{color:"#E67E22"}}/>
        </SearchIconWrapper>
        <StyledInputBase
          placeholder="Search…"
          inputProps={{ "aria-label": "search" }}
          value={searchTerm}
          onChange={handleChange}
        />
      </Search>
      <div>
        {filterData?.map((item, index) => (
          <div className="p-1 ml-3 mt-1">
            <div className="mb-2" style={{ display: "flex", justifyContent: "space-between" }}>
              <div>
                <h3
                style={{fontSize:"14px"}}
                  onClick={() => {
                    setSelectedLibraryItem({ ...item, isEditForm: true });
                  }}
                >
                  {item?.workout_library_name}
                </h3>
                <h4 className="text-sm text-slate-400 font-medium fw-bolder">
                  {/* Total Workouts ({item.workouts.length}) */}
                </h4>
              </div>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
              <span>
                {isOpenCollapse === index ? (
                  <KeyboardArrowUpIcon
                  style={{color:"#E67E22"}}
                    onClick={() => handleOpen(index, "up")}
                  />
                ) : (
                  <KeyboardArrowDownIcon
                  style={{color:"#E67E22"}}
                    onClick={() =>
                      handleOpen(index, "down", item?.id)
                    }
                  />
                )}
              </span>
             {/*
              <span className="p-1">
                <Dropdown
                  menu={{
                    items,
                  }}
                  trigger={["click"]}
                  onOpenChange={() => {
                    setSelectedLibraryItem({ ...item, isEditForm: true });
                  }}
                >
                  <div>
                    <Space>
                      <IconAntennaBars1
                        style={{ transform: "rotate(90deg)",color:"#E67E22" }}
                      />
                    </Space>
                  </div>
                </Dropdown>
              </span>
             */}
              </div>
            </div>
            {/* <hr /> */}
            {workouts !== undefined &&
              workouts.map((workout, workoutindex) => {
                return (
                  <DraggableItem
                    index={index}
                    workout={workout}
                    workouts={workoutindex}
                    isOpenCollapse={isOpenCollapse}
                    badge={badge}
                  />
                );
              })}
              <hr />
            {/* {isChildSearch.bool === false ? (
              item.workouts.map((workout, workoutindex) => {
                return (
                  <Collapse in={isOpenCollapse === index} key={workoutindex}>
                    <div
                      draggable="true"
                      key={"grf"}
                      onDragStart={() =>
                        handleDragStart({
                          title: workout.name,
                          calender: workout.calender,
                        })
                      }
                      className="bg-[#e67e224d] p-2 m-2 pl-2 text-gray-600"
                    >
                      <span>{workout.name}</span>
                    </div>
                  </Collapse>
                );
              })
            ) : (
              <Collapse in={isOpenCollapse === index}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                    border: "1px solid black",
                    margin: "5px",
                  }}
                  draggable="true"
                  key={"grf"}
                  onDragStart={() =>
                    handleDragStart({
                      title: isChildSearch.childData.name,
                      calender: isChildSearch.childData.calender,
                    })
                  }
                >
                  <span>{isChildSearch.childData.name}</span>
                </div>
              </Collapse>
            )} */}
          </div>
        ))}{" "}
      </div>
    </>
  );
};

export default Searchbar;
