import { Box, Typography } from "@mui/material";
import "./../styles/popover.css"
import { get, getDatabase, ref, runTransaction, serverTimestamp, set, update } from "firebase/database";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useRef } from "react";
import { db } from "../../../API/firebase.config";
import { useDispatch } from "react-redux";
import { storeOpenedUserInfoAction } from "../redux/action/usersAction";
import { Modal } from "antd";
import { ExclamationCircleFilled } from '@ant-design/icons';

const Popover = ({ typeOfFile,setTypeOfFile,isVisible, setPopoverVisible, msg, combinedId, setIsReplyMsg, isGroup, currentUserID }) => {
    const { confirm } = Modal;
    const popoverStyle = {
        display: isVisible ? 'block' : 'none',
        position: 'fixed', // Use 'fixed' instead of 'absolute'
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)', // Center the popover
        backgroundColor: 'white',
        padding: '10px',
        zIndex: 5,
        width: '200px',
        // height: '336px',
        borderRadius: '10px',
        boxShadow: '0px 4px 25px rgba(0, 0, 0, 0.2)',
        mixBlendMode: 'normal',
    };
    const navigate = useNavigate()
    const isVisibleRef = useRef(isVisible);
    const dispatch = useDispatch()

    const handleStarMessage = () => {
        setPopoverVisible(false)

        const db = getDatabase();
        const chatRef = ref(db, `chats/${combinedId}/messages`);

        // Fetch the current value of messages
        runTransaction(chatRef, (messages) => {
            if (messages) {
                // Convert messages object to an array
                const messageArray = Object.values(messages);

                for (let i = 0; i < messageArray.length; i++) {
                    if (messageArray[i].timeStamp === msg.timeStamp) {
                        messageArray[i].isStarred = !messageArray[i].isStarred;
                        break;
                    }
                }

                // Convert the modified array back to an object
                const updatedMessages = messageArray.reduce((acc, curr) => {
                    acc[curr.timeStamp] = curr;
                    return acc;
                }, {});

                return updatedMessages;
            }

            return messages;
        }).then(() => {
            console.log('Msg starred!.');
        }).catch((error) => {
            console.log('Msg star failed: ', error);
        });
    }

    const getUserDataById = async (userId) => {
        try {
            const userRef = ref(db, `users/${userId}`);
            const snapshot = await get(userRef);

            if (snapshot.exists()) {
                const userData = snapshot.val()
                return userData;
            } else {
                throw new Error("User data not found.");
            }
        } catch (error) {
            console.error("Error getting user data:", error);
            throw error;
        }
    }

    const handleSelect = async (msgSenderId) => {
        setPopoverVisible(false)
        const usersData = await getUserDataById(msgSenderId)

        dispatch(storeOpenedUserInfoAction(usersData));

        const combinedId =
            currentUserID > msgSenderId
                ? currentUserID + msgSenderId
                : msgSenderId + currentUserID;

        try {
            const chatRef = ref(db, 'chats/' + combinedId);
            const snapshot = await get(chatRef);

            if (!snapshot.exists()) {
                set(chatRef, {
                    isMsgReqAccepted: false
                })
                    .then(() => {
                        return;
                    })
                    .catch((error) => {
                        console.error('Failed to write chat data:', error);
                    });

                // Create user chats
                const currentUserChatsRef = ref(db, `userChats/${currentUserID}`);
                const userChatsRef = ref(db, `userChats/${msgSenderId}`);

                await update(currentUserChatsRef, {
                    [`${combinedId}/userInfo`]: {
                        uid: msgSenderId
                    },
                    [`${combinedId}/date`]: serverTimestamp(),
                    [`${combinedId}/isMsgReqAccepted`]: true,
                    [`${combinedId}/lastMessage`]: '',
                    [`${combinedId}/chatType`]: 'personal',
                });

                await update(userChatsRef, {
                    [`${combinedId}/userInfo`]: {
                        uid: currentUserID
                    },
                    [`${combinedId}/date`]: serverTimestamp(),
                    [`${combinedId}/isMsgReqAccepted`]: false,
                    [`${combinedId}/lastMessage`]: '',
                    [`${combinedId}/chatType`]: 'personal',
                });

                navigate('/individual-chat')
            }
            navigate('/individual-chat')
        } catch (err) {
            console.log(err)
        }
    };

    useEffect(() => {
        isVisibleRef.current = isVisible;
    }, [isVisible]);
    const handleClickOutside = (event) => {
        if (isVisibleRef.current && !event.target.closest(".popover-container")) {
            setPopoverVisible(false);
        }
    };

    const handleDeleteMessage = () => {
        setPopoverVisible(false)

        const db = getDatabase();
        const chatRef = ref(db, `chats/${combinedId}/messages`);

        // Fetch the current value of messages
        runTransaction(chatRef, (messages) => {
            if (messages) {
                // Convert messages object to an array
                const messageArray = Object.values(messages);

                for (let i = 0; i < messageArray.length; i++) {
                    if (messageArray[i].timeStamp === msg.timeStamp) {
                        messageArray[i].isDeleted = true;
                        break;
                    }
                }

                // Convert the modified array back to an object
                const updatedMessages = messageArray.reduce((acc, curr) => {
                    acc[curr.timeStamp] = curr;
                    return acc;
                }, {});

                return updatedMessages;
            }
            setTypeOfFile("Message")

            return messages;
        }).then(() => {
            console.log('Msg deleted!.');
        }).catch((error) => {
            console.log('Msg delete failed: ', error);
        });
    }
    const showConfirm = () => {
        confirm({
          title: `Do you Want to delete this ${typeOfFile}?`,
          icon: <ExclamationCircleFilled />,
          onOk() {
            handleDeleteMessage();
          },
          onCancel() {
            console.log('Cancel');
          },
        });
      };

    const handleCopyMessage = () => {
        setPopoverVisible(false);

        if (navigator.clipboard && typeof navigator.clipboard.writeText === 'function') {
            navigator.clipboard.writeText(msg.message)
                .then(() => {
                    console.log('Message Copied!');
                })
                .catch((error) => {
                    console.error('Failed to copy text: ', error);
                });
        } else {
            const tempTextArea = document.createElement('textarea');
            tempTextArea.value = msg.message;
            tempTextArea.style.position = 'fixed';
            tempTextArea.style.opacity = 0;

            document.body.appendChild(tempTextArea);
            tempTextArea.select();

            try {
                const successful = document.execCommand('copy');
                if (successful) {
                    console.log('Message Copied!');
                } else {
                    console.error('Copy command was unsuccessful');
                }
            } catch (error) {
                console.error('Failed to copy text: ', error);
            }

            document.body.removeChild(tempTextArea);
        }
    };

    const handleForwardMessage = () => {
        setPopoverVisible(false)
        navigate('/individual-chat/forward-message', { state: { msgToForward: msg } })
    }

    const handleReplyMsg = () => {
        setIsReplyMsg(true)
        Cookies.set('replyMsgInfo', JSON.stringify(msg))
        setPopoverVisible(false)
    }

    useEffect(() => {
        let timerId = null;

        if (isVisible) {
            timerId = setTimeout(() => {
                document.addEventListener("click", handleClickOutside);
            }, 800); // 2000 milliseconds = 2 seconds
        }

        return () => {
            clearTimeout(timerId);
            document.removeEventListener("click", handleClickOutside);
        };
    }, [isVisible]);

    const cont = <Box onClick={() => setPopoverVisible(false)}>
        {/*
    <Box onClick={handleStarMessage} className="chat-press-unit-box">
            <Typography className="chat-press-unit-heading">
                {
                    msg.isStarred ? 'Remove Star' : 'Star'
                }
            </Typography>
            <img height={
                msg.isStarred
                    ? 19
                    : 22
            } src={
                msg.isStarred
                    ? "/images/msgIcons/starred.png"
                    : "/images/msgIcons/star-icon.png"
            } alt="star-icon" />
        </Box>
        <Box
            // onClick={handleReplyMsg}
            className="chat-press-unit-box">
            <Typography className="chat-press-unit-heading">Reply</Typography>
            <img src="/images/msgIcons/reply-icon.png" alt="" />
        </Box>
        <Box onClick={handleCopyMessage} className="chat-press-unit-box">
            <Typography className="chat-press-unit-heading">Copy</Typography>
            <img src="/images/msgIcons/copy-icon.png" alt="" />
        </Box>
        <Box onClick={handleForwardMessage} className="chat-press-unit-box">
            <Typography className="chat-press-unit-heading">Forward</Typography>
            <img src="/images/msgIcons/forward-icon.png" alt="" />
        </Box>
        {isGroup && <Box onClick={() => handleSelect(msg.senderId)} className="chat-press-unit-box">
            <Typography className="chat-press-unit-heading">Reply Privately</Typography>
            <img src="/images/msgIcons/private-reply-icon.png" alt="" />
        </Box>}
        <Box onClick={() => setPopoverVisible(false)} className="chat-press-unit-box">
            <Typography className="chat-press-unit-heading">Report</Typography>
            <img src="/images/msgIcons/report-icon.png" alt="" />
        </Box>
    */}
        {
            (msg.senderId === currentUserID) &&
            <Box onClick={showConfirm} className="chat-press-unit-box" style={{ borderBottom: 'none' ,cursor:"pointer"}}>
                <Typography className="chat-press-unit-heading" sx={{ color: '#EF053D !important' }}>Delete</Typography>
                <img src="/images/msgIcons/delete-icon.png" alt="" />
            </Box>
        }
    </Box>

    return (
        <Box sx={
            isGroup
                ? (msg.senderId === currentUserID) ? popoverStyle : { ...popoverStyle }
                : {
                    ...popoverStyle,
                }}
            onClick={() => setPopoverVisible(false)}
        >
            {cont}
        </Box >
    );
};

export default Popover;