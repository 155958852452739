import React, { useState } from "react";
import { Grid, TextField, MenuItem, Button } from "@mui/material";
import { useEffect } from "react";

function WeekInput({ weekNumber, formValue, handleChange }) {
  let rowSum = 0;

  const renderDayInputs = () => {
    const dayInputs = [];
    let rowSum = 0; // Initialize row sum for this row

    for (let day = 1; day <= 8; day++) {
      const fieldName = `week${weekNumber}_day${day}`;
      const value = parseFloat(formValue[fieldName]) || 0;
      rowSum += value; // Update row sum for this row

      let isDisabled = rowSum === 100 && value === 0;

      dayInputs.push(
        <Grid item xs={3} sm={1} key={fieldName}>
          <TextField
            type="number"
            name={fieldName}
            value={formValue?.[fieldName]}
            onChange={(e) => handleChange(e, 0, 100, weekNumber)}
            disabled={isDisabled}
            inputProps={{
              min: 0, // Set the minimum value here
              max: 100, // Maximum value is always 100
            }}
          />
        </Grid>
      );
    }
    return dayInputs;
  };

  return (
    <Grid container columnGap={1}>
      <Grid item xs={3} sm={2}>
        Week {weekNumber}
      </Grid>
      {renderDayInputs()}
      <Grid item xs={3} sm={1}>
        {rowSum}%
      </Grid>
    </Grid>
  );
}

const FormComponents = () => {
  const [formValue, setFormValue] = useState({});
  const [sumOfWeek, setSumOfWeek] = useState({});

  useEffect(() => {
    calculateSum();
  }, [formValue]);

  const calculateSum = () => {
    const newSumOfWeek = {};

    for (let week = 1; week <= 26; week++) {
      let sum = 0;
      for (let day = 1; day <= 8; day++) {
        const fieldName = `week${week}_day${day}`;
        const value = parseFloat(formValue[fieldName]) || 0;
        sum += value;
        // console.log("sum", sum);
        localStorage.setItem("sumofRow", sum);
      }
      newSumOfWeek[`week${week}_sum`] = sum;
    }
    setSumOfWeek(newSumOfWeek);
  };

  const handleChange = (e, minValue, maxValue, weekNumber) => {
    const { name, value } = e.target;
    let numericValue = parseFloat(value);

    // Calculate the current row's sum without the current field
    let currentRowSum = 0;
    for (let day = 1; day <= 8; day++) {
      const fieldName = `week${weekNumber}_day${day}`;
      if (fieldName !== name) {
        currentRowSum += parseFloat(formValue[fieldName]) || 0;
      }
    }

    // Check if the entered value is within the specified range (minValue and maxValue)
    if (
      !isNaN(numericValue) &&
      numericValue >= minValue &&
      numericValue <= maxValue
    ) {
      // Calculate the total row sum including the new value
      let totalRowSum = currentRowSum + numericValue;

      // Check if the total row sum exceeds the maximum allowed value (e.g., 100)
      if (totalRowSum <= maxValue) {
        setFormValue((prevValue) => ({
          ...prevValue,
          [name]: numericValue.toString(),
        }));
      } else {
        // If the total row sum exceeds the limit, set it to an empty string
        setFormValue((prevValue) => ({
          ...prevValue,
          [name]: "",
        }));
      }
    } else {
      setFormValue((prevValue) => ({
        ...prevValue,
        [name]: "",
      }));
    }
  };

  const handleSubmit = () => {
    // Use Object.keys to iterate over the properties of the object
    const keys = Object.keys(sumOfWeek);

    // Use Array.some to check if any element's value is equal to 100
    const isAnyEqualTo100 = keys.some((key) => sumOfWeek[key] > 100);

    if (isAnyEqualTo100) {
      alert("At least one rowSum's value is grater then 100.");
    }
  };

  return (
    <div className="workout-form-section py-5 px-10">
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: "2%",
          marginBottom: "1%",
        }}
      >
        <span className="text-center" style={{ fontSize: "30px" }}>
          Add Training Volume
        </span>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: "2%",
          marginBottom: "5%",
        }}
      >
        <span style={{ fontSize: "20px" }}>Training Volume Goal</span>
        <span style={{ marginLeft: "5%" }}>
          <TextField
            id="outlined-select-currency"
            select
            label="Training Volume Goal"
            value="Half Marathon"
          >
            <MenuItem value={"Half Marathon"}>Half Marathon</MenuItem>
          </TextField>
        </span>
      </div>
      <Grid container rowGap={2}>
        <Grid container columnGap={1}>
          <Grid item xs={3} sm={2}>
            Week Number{" "}
          </Grid>
          <Grid item xs={3} sm={1}>
            Run Volume
          </Grid>
          <Grid item xs={3} sm={1}>
            <TextField
              id="outlined-select-currency"
              select
              label="Select"
              defaultValue="TV1"
            >
              <MenuItem value={"TV1"}>TV1</MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={3} sm={1}>
            <TextField
              id="outlined-select-currency"
              select
              label="Select"
              defaultValue="TV2"
            >
              <MenuItem value={"TV2"}>TV2</MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={3} sm={1}>
            <TextField
              id="outlined-select-currency"
              select
              label="Select"
              defaultValue="TV3"
            >
              <MenuItem value={"TV3"}>TV3</MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={3} sm={1}>
            <TextField
              id="outlined-select-currency"
              select
              label="Select"
              defaultValue="TV4"
            >
              <MenuItem value={"TV4"}>TV4</MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={3} sm={1}>
            <TextField
              id="outlined-select-currency"
              select
              label="Select"
              defaultValue="TV5"
            >
              <MenuItem value={"TV5"}>TV5</MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={3} sm={1}>
            <TextField
              id="outlined-select-currency"
              select
              label="Select"
              defaultValue="TV6"
            >
              <MenuItem value={"TV6"}>TV6</MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={3} sm={1}>
            <TextField
              id="outlined-select-currency"
              select
              label="Select"
              defaultValue="TV7"
            >
              <MenuItem value={"TV7"}>TV7</MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={3} sm={1}>
            Blank
          </Grid>
        </Grid>
        {/* Week 1 to Week 26 */}
        {[...Array(26).keys()].map((weekNumber) => (
          <WeekInput
            key={weekNumber}
            weekNumber={weekNumber + 1}
            formValue={formValue}
            handleChange={handleChange}
          />
        ))}
      </Grid>
      <div
        style={{ display: "flex", justifyContent: "center" }}
        className="mt-12"
        onClick={handleSubmit}
      >
        <Button variant="contained">Add Training Volume</Button>
      </div>
    </div>
  );
};

export default FormComponents;
