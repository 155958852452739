import {
  Chip,
  FormControl,
  FormLabel,
  Grid,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
} from "@mui/material";
import { Button, Modal } from "antd";
import React, { useEffect, useState } from "react";
import {
  CreatePrograms,
  CreateZonesClasification,
  createGoalNameYTA,
  createGoalsdata,
  createYTAVolumedata,
  getAllActivityData,
  getAllGoalNameForYTA,
  getAllPhaseNameData,
  getAllPrograms,
  getAllYTAData,
  getAlllevels,
  updateGoalNameYTA,
  updateGoalsdata,
  updatePrograms,
  updateYTAVolumedata,
  updateZonesClasification,
  weeklyFeedDataPattern,
  weeklyFeedDataProgram,
} from "../../API/api-endpoint";
import { useFormik } from "formik";
import Swal from "sweetalert2";
import SlickCarousel from "../../pages/SlickCarousel";
const scoreData = [1, 2, 3, 4, 5];
const CreateYTAVloume = ({
  fetchReport,
  setShowAssesmentModal,
  showAssesmentModal,
  editData,
  setEditData,
}) => {
  const [programList, setProgramList] = useState([]);
  const [ytaGoalList, setYtaGoalList] = useState([]);

  console.log("editData", editData);
  const formik = useFormik({
    initialValues: {
      activities: "",
      goalname_id: "",
      week: "",
      running: "",
      cycling: "",
      swimming: "",
    },
    validate: (values) => {
      const errors = {};

      // Validation for activities
      if (values.activities === "") {
        errors.activities = "Activity name is required";
      }

      // Validation for goalname_id
      if (values.goalname_id === "") {
        errors.goalname_id = "Goal name is required";
      }

      // Validation for week
      if (values.week === "") {
        errors.week = "Week is required";
      }

      // Validation for running
      if (values.running === "") {
        errors.running = "Running is required";
      }

      // Validation for cycling
      if (values.cycling === "") {
        errors.cycling = "Cycling is required";
      }

      // Validation for swimming
      if (values.swimming === "") {
        errors.swimming = "Swimming is required";
      }

      return errors;
    },
    onSubmit: (values, { resetForm }) => {
      handleSubmitAssesmentForm(values, resetForm);
    },
  });
  console.log("formik", formik?.values);
  const getAllProgramsData = async () => {
    const response = await getAllActivityData();
    console.log("response", response);
    setProgramList(response?.rows);
  };
  const getPhaseName = async () => {
    const response = await getAllYTAData();
    console.log("response", response);
    setYtaGoalList(response);
  };
  useEffect(() => {
    getAllProgramsData();
    getPhaseName();
  }, []);

  const handleSubmitAssesmentForm = async (data, resetForm) => {
    const selectedGoal = ytaGoalList.find((goal) => goal.id === data.goalname_id);

// Update the form data to include goaltable instead of goalname_id
const updatedData = {
  ...data,
  goalname_id: selectedGoal?.goaltable,  // Replace goalname_id with goaltable
};

    let response = "";
    if (editData?.id) {
      response = await updateYTAVolumedata(updatedData);
    } else {
      response = await createYTAVolumedata(updatedData);
      console.log("response", response);
    }
    if (response?.status) {
      Swal.fire({
        title: "Success",
        text: response.message,
        icon: "success",
      });
      setShowAssesmentModal(false);
      setEditData({});
      fetchReport();
      resetForm();

      formik?.setValues({
        activities: "",
        goalname_id: "",
        week: "",
        running: "",
        cycling: "",
        swimming: "",
      });
    } else {
      Swal.fire({
        title: "Error",
        text: response.message,
        icon: "error",
      });
    }
    console.log("response", response);
  };
  useEffect(() => {
    if (editData?.id) {
      const { srID, activity, phasename, ...data } = editData;
      console.log("data", data);
      formik?.setValues(data);
      formik.setFieldValue("phase", phasename?.phase);
      formik.setFieldValue("activities", data?.activities);
      formik.setFieldValue("goalname_id", data?.ytagoal?.id);
    }
  }, [editData?.id]);
  return (
    <Modal
      width={1200}
      open={showAssesmentModal}
      onCancel={() => {
        setShowAssesmentModal(false);
        formik.resetForm();
        setEditData({});
        formik?.setValues({
          activities: "",
          goalname_id: "",
          week: "",
          running: "",
          cycling: "",
          swimming: "",
        });
        // formik?.setValues({activities:"",program_name:"",activity_ids:""})
      }}
      footer={
        <div></div>
        //   loading={isLoading}
      }
    >
      <div className="headingCont">
        <span className="heading">{editData?.id ? "Edit " : "Create"}</span>{" "}
        <span className="orange heading">YTA Goal Volume</span>
      </div>
      {/* <h1>{editData ? editData.challengeId : values.challengeId}</h1> */}
      <div className="parentCont">
        <form className="form1" onSubmit={formik.handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={11}>
              <FormLabel>Activity Name<span className="text-[red]">*</span></FormLabel>

              <TextField
                fullWidth
                size="small"
                select
                name="activities"
                SelectProps={{
                  MenuProps: {
                    PaperProps: {
                      style: {
                         scrollbarColor:"#E67E22 white",
                         scrollbarWidth:"thin"
                      },
                    },
                  },
                }}
                value={
                  formik?.values?.activities ? formik?.values?.activities : "placeholder"
                }
                onChange={formik.handleChange}
                error={formik.touched.activities && formik.errors.activities}
                helperText={
                  formik.touched.activities && formik.errors.activities
                }
                id="form-layouts-separator-select"
                labelId="form-layouts-separator-select-label"
                input={<OutlinedInput id="select-multiple-language" />}
              >
                <MenuItem value={"placeholder"} disabled>
                  Select Activity
                </MenuItem>
                {programList?.map((value, index) => {
                  return (
                    <MenuItem value={value?.id}>
                      {value?.activity_name}
                    </MenuItem>
                  );
                })}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={11}>
              <FormLabel>Goal Name<span className="text-[red]">*</span></FormLabel>

              <TextField
                fullWidth
                size="small"
                select
                name="goalname_id"
                SelectProps={{
                  MenuProps: {
                    PaperProps: {
                      style: {
                         scrollbarColor:"#E67E22 white",
                         scrollbarWidth:"thin"
                      },
                    },
                  },
                }}
                value={
                  formik?.values?.goalname_id ? formik?.values?.goalname_id : "placeholder"
                }
                onChange={formik.handleChange}
                error={formik.touched.goalname_id && formik.errors.goalname_id}
                helperText={
                  formik.touched.goalname_id && formik.errors.goalname_id
                }
                id="form-layouts-separator-select"
                labelId="form-layouts-separator-select-label"
                input={<OutlinedInput id="select-multiple-language" />}
              >
                <MenuItem value={"placeholder"} disabled>
                  Select Name
                </MenuItem>
                {ytaGoalList?.map((value, index) => {
                  return (
                    <MenuItem value={value?.id}>{value?.goalname}</MenuItem>
                  );
                })}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={11}>
              <FormLabel>Week<span className="text-[red]">*</span></FormLabel>

              <TextField
                fullWidth
                placeholder="Week"
                size="small"
                type="number"
                name="week"
                value={formik?.values?.week}
                onChange={formik.handleChange}
                error={formik.touched.week && formik.errors.week}
                helperText={formik.touched.week && formik.errors.week}
              />
            </Grid>
            <Grid item xs={12} sm={11}>
              <FormLabel>Running<span className="text-[red]">*</span></FormLabel>

              <TextField
                fullWidth
                placeholder="Running"
                size="small"
                type="text"
                name="running"
                value={formik?.values?.running}
                onChange={formik.handleChange}
                error={formik.touched.running && formik.errors.running}
                helperText={formik.touched.running && formik.errors.running}
              />
            </Grid>
            <Grid item xs={12} sm={11}>
              <FormLabel>Cycling<span className="text-[red]">*</span></FormLabel>

              <TextField
                fullWidth
                placeholder="cycling"
                size="small"
                type="text"
                name="cycling"
                value={formik?.values?.cycling}
                onChange={formik.handleChange}
                error={formik.touched.cycling && formik.errors.cycling}
                helperText={formik.touched.cycling && formik.errors.cycling}
              />
            </Grid>
            <Grid item xs={12} sm={11}>
              <FormLabel>Swimming<span className="text-[red]">*</span></FormLabel>

              <TextField
                fullWidth
                placeholder="Swimming"
                size="small"
                type="text"
                name="swimming"
                value={formik?.values?.swimming}
                onChange={formik.handleChange}
                error={formik.touched.swimming && formik.errors.swimming}
                helperText={formik.touched.swimming && formik.errors.swimming}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Button
                className="btn"
                key="submit"
                type="primary"
                onClick={() => formik.handleSubmit()}
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </form>
        <div className="slick-container">
          <SlickCarousel />
        </div>
      </div>
    </Modal>
  );
};

export default CreateYTAVloume;
