import React, { useEffect, useState } from 'react';
import Header from '../../components/Header';
import {  LinearProgress } from '@mui/material';
import { fetchSavedAssesmnetGetData, URL } from '../../API/api-endpoint';
import { Button } from 'antd';
import Rating from '../../components/Rating';

const AthletesavedReview = () => {
    const [savedData, setSavedData] = useState([]);
    const [index, setIndex] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    console.log("savedData",savedData);
    const currentData = savedData[index] || {};
    const { user, review, activity, totalscore, userformreviewimages, id,options,videourl } = currentData;

    const fetchAssesmentData = async () => {
        setIsLoading(true);
        const response = await fetchSavedAssesmnetGetData();
        setIsLoading(false);
        setSavedData(response?.newModified || []);
    };
    console.log("user", user, savedData, id);

    useEffect(() => {
        fetchAssesmentData();
    }, []);

    const loopIndex = (i) => {
        if (i < 0) {
            return savedData.length - 1;
        } else if (i >= savedData.length) {
            return 0;
        }
        return i;
    };

    const prevBtn = () => {
        setIndex((i) => loopIndex(i - 1));
    };

    const nextBtn = () => {
        setIndex((i) => loopIndex(i + 1));
    };
    const getCoachCommentColor = (rating) => {
        let color;
        if (rating >= 1 && rating < 2) {
          color = 'red';
        } else if (rating >= 2 && rating < 4) {
          color = 'orange';
        } else if (rating >= 4 && rating <= 5) {
          color = 'green';
        } else {
          color = 'black'; // default color if rating is out of range
        }
        return color;
      };
   
    return (
        <div >
            <Header />
            <div className="grid grid-cols-1 xl:grid-cols-5 items-start gap-x-4" style={{ marginTop: "100px" }}></div>
            <div >
                <div className='flex justify-between' style={{ width: "80%", margin: "30px auto",padding:"0px",}}>
                    <h2 className='text-3xl' style={{ marginLeft: "1%", color: "#E67E22" }}>
                        <strong>Form Review</strong>
                    </h2>
                    <Button type="primary" >
                <a href='/form-review' >
                Back
                </a>
                </Button> 
                </div>
                {isLoading ? (
                    <LinearProgress />
                ) : (
                    <div style={{ width: "80%", margin: "30px auto",padding:"55px",boxShadow:"rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset" }}>
                        <div><b style={{ fontSize: "20px", padding: "10px 0px 0px 0px" }}>{activity?.activity_name} Feedback</b></div>
                        <div style={{ width: "40%", margin: "0 auto"}}>
                        <div style={{ display: "flex", justifyContent: "space-evenly", width: "100%" }}>
                            <Button type="primary" disabled={index==0} onClick={prevBtn}>Previous</Button> 
                            <Button type="primary" disabled={index ==savedData?.length-1  || !savedData?.length>0} onClick={nextBtn}>Next</Button>
                        </div>
                        </div>

                        <div style={{ margin: "1rem 0px 0px 0px" }}>
                            <div className="flex">
                                <div style={{ "width": "35%",height:"40px",  "border": "1px solid gray", fontWeight: "700", padding: "10px" }}>
                                    Name
                                </div >
                                <div style={{ "width": "65%",height:"40px",  "border": "1px solid gray", "borderLeft": "none", fontWeight: "700", padding: "10px", }}>
                                    {user?.firstname} {user?.lastname}
                                </div>
                            </div>
                            <div className="flex">
                                <div style={{ "width": "35%",height:"40px",  "border": "1px solid gray", fontWeight: "700", padding: "10px" }}>
                                    Contact Details
                                </div >
                                <div style={{ "width": "65%",height:"40px",  "border": "1px solid gray", "borderLeft": "none", fontWeight: "700", padding: "10px" }}>
                                    {user?.email}
                                </div>
                                {/* <div style={{ "width": "100%", padding: "2px", "border": "1px solid gray", "borderLeft": "none", fontWeight: "700",padding:"0px 0px 0px 10px" }}>
                {user?.email}
              </div> */}
                            </div>

                        </div>

                        <div style={{ margin: "1rem 0px 0px 0px" }}>
                            <div className="flex">
                                <div style={{ "width": "35%", "border": "1px solid gray", fontWeight: "700", padding: "10px" }}>
                                    Review
                                </div >
                                <div style={{ "width": "65%", "border": "1px solid gray", "borderLeft": "none", fontWeight: "700", padding: "10px" }}>
                                    {review}
                                </div>
                            </div>
                            <div className="flex">
                                <div style={{ "width": "35%", height: "40px", "border": "1px solid gray", fontWeight: "700", padding: "10px" }}>
                                Overall rating
                                </div >
                                <div style={{ "width": "65%", height: "40px", "border": "1px solid gray", "borderLeft": "none", fontWeight: "700", padding: "10px" }}>
                                <Rating rating={parseInt(totalscore/options?.length <= 0.5 ? 1 : totalscore/options?.length)} size={"20px"} />  
                                </div>

                            </div>
                           


                        </div>
                        {options?.length>0 &&
                        <div style={{ margin: "1rem 0px 0px 0px" }}>
                            <div className="flex">
                                <div style={{ "width": "35%", height: "40px", "border": "1px solid gray", fontWeight: "700", padding: "10px" }}>
                                    Stroke Review
                                </div >
                                <div style={{ "width": "65%", height: "40px", "border": "1px solid gray", "borderLeft": "none", fontWeight: "700", padding: "10px" }}>
                                    Stroke Review
                                    Rating                                </div>
                                <div style={{ "width": "65%", height: "40px", "border": "1px solid gray", "borderLeft": "none", fontWeight: "700", padding: "10px" }}>
                                    Stroke Review Comments   </div>

                            </div>
                            <div className='assement-scroll'>
                            {options?.map((section)=>{
                                const color = getCoachCommentColor(section?.weightage);
                                return(
                                    <div className="flex" >
                                        <div style={{ "width": "35%",  "border": "1px solid gray", fontWeight: "700", padding: "10px" }}>
                                        {section?.coachSection}
                                </div >
                                <div style={{ "width": "65%",  "border": "1px solid gray", "borderLeft": "none", fontWeight: "700", padding: "10px" }}>
                                <Rating rating={section?.weightage} size={"20px"} />
                                </div>
                                <div style={{ "width": "65%",  "border": "1px solid gray", "borderLeft": "none", fontWeight: "700", padding: "10px",color:`${color}` }}>
                                {section?.coachcomment}
 
                                     </div>
                                    </div>
                                )
                            })}
                            </div>

                        </div>
                        }
                        <div style={{ margin: "1rem 0px 0px 0px" }}>
                        <div className="flex">
                                <div style={{ "width": "35%", padding: "2px", "border": "1px solid gray", fontWeight: "700", padding: "10px" }}>
                                    Video URL
                                </div >
                                <div style={{ "width": "65%", padding: "2px", "border": "1px solid gray", "borderLeft": "none", fontWeight: "700", padding: "10px" }}>
                                <a href={videourl} target="_blank" style={{textDecoration:"underline",color:"blue",wordBreak:"break-all"}}>{videourl}</a>
                               
                               
                                </div>
                            </div>
                            <div className="flex">
                                <div style={{ "width": "35%", padding: "2px", "border": "1px solid gray", fontWeight: "700", padding: "10px" }}>
                                    Images
                                </div >
                                <div style={{ "width": "65%", padding: "2px", "border": "1px solid gray", "borderLeft": "none", fontWeight: "700", padding: "10px" }}>
                                    {userformreviewimages?.map((img) => {
                                        return (
                                            <div>
                                                <img
                                                    src={`${URL}/static/public/userimages/${img?.image}`}

                                                />
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>


                        </div>

                      
                    </div>
                )}
            </div>
           
        </div>
    );
}

export default AthletesavedReview

