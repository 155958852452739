import { Box, Paper, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import "./../../../styles/individualChat.css";
import NavGroupChat from "../../../components/navbar/NavGroupChat";
import { useDispatch, useSelector } from "react-redux";
import "./../../../styles/footerFunctions.css";
import {
  get,
  getDatabase,
  push,
  ref as rtdbRef,
  serverTimestamp,
} from "firebase/database";
import { getGroupChatMessagesAction } from "../../../redux/action/groupAction";
import {
  getStorage,
  ref as storageRef,
  uploadBytes,
  getDownloadURL,
} from "firebase/storage";
import GroupMsg from "./GroupMsg";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import EmojiPicker from "emoji-picker-react";
import { db } from "../../../../../API/firebase.config";
import { useRef } from "react";
import InsertEmoticonIcon from "@mui/icons-material/InsertEmoticon";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import AddBoxIcon from "@mui/icons-material/AddBox";
import SendIcon from "@mui/icons-material/Send";
import Header from "../../../../../components/Header";
import Swal from "sweetalert2";

export default function GroupChatCreator() {
  const paper = {
    padding: "0rem",
    minHeight: "80vh",
    minHeight: "80vh",
    maxWidth: "47rem",
    // paddingBottom: "5rem",
    
    // backgroundImage: 'url("https://cdn.wallpapersafari.com/80/83/K7l2qB.jpg")',
  };

  const { openedGroupInfo, openedGroupMessages } = useSelector(
    (state) => state.group
  );
  const { currentUser } = useSelector((state) => state.auth);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [previewData, setPreviewData] = useState(null);
  const [isMobile, setIsMobile] = useState(false);
  const [showEmojis, setShowEmojis] = useState(false);
  const [showToolBox, setShowToolBox] = useState(false);
  const [getMessages, setGetMessages] = useState(false);
  const [typedMessage, setTypedMessage] = useState("");
  const [isReplyMsg, setIsReplyMsg] = useState(false);
  const [membersPallate, setMembersPallate] = useState("");
  const [isSearchActive, setIsSearchActive] = useState(false);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [isLoading, setIsLoading] = useState(false)

  let timer;

  const showToolBoxRef = useRef(showToolBox);
  const showEmojisRef = useRef(showEmojis);

  const handleMouseUp = () => {
    clearTimeout(timer);
    // setPopoverVisible(false);
    document.removeEventListener("mouseup", handleMouseUp);
  };

  const handleSendMessage = async (
    msgType,
    msg,
    docType,
    fileName,
    thumbnailURL
  ) => {
    setShowEmojis(false);
    setTypedMessage("");
    try {
      const { groupMembers } = openedGroupInfo;
      const groupRef = rtdbRef(db, `chats/${openedGroupInfo.groupId}/messages`);

      // Retrieve existing messages
      const groupSnapshot = await get(groupRef);
      const groupData = groupSnapshot.val();
      const existingMessages = groupData?.messages || [];

      //make new object for readBy
      const newObj = {};

      for (const member of groupMembers) {
        newObj[member] = currentUser.uid === member ? true : false;
      }

      console.log(newObj);

      // Add the new message to the existing messages

      const updatedMessages = {
        isDeleted: false,
        isStarred: false,
        senderId: currentUser.uid,
        senderName: currentUser.displayName,
        senderPhotoURL: currentUser.profileImageUrl,
        type: msgType,
        seenBy: newObj,
        timeStamp: serverTimestamp(),
        message: msg,
        docType: docType ? docType : null,
        fileName: fileName ? fileName : null,
        thumbnailURL: thumbnailURL ? thumbnailURL : null,
      };

      // Update the "messages" array in the database
      await push(groupRef, updatedMessages);

      window.scrollTo({
        top: document.documentElement.scrollHeight,
        behavior: "smooth",
      });

      console.log("MESSAGE WRITTEN!");
      return;
    } catch (error) {
      console.error("Failed to send message:", error);
    }
  };


  const handleEmojiClick = (emojiObject) => {
    // Assuming emojiObject is an object with a property 'emoji'
    setTypedMessage((prevTypedMessage) => {
      // Append the clicked emoji to the existing message
      return prevTypedMessage + emojiObject.emoji;
    });
  };
  function filterMessagesByDateRange(messages) {
    const today = new Date().toDateString();
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    const yesterdayDateString = yesterday.toDateString();

    const groupedMessages = {};
    for (const message of messages) {
      const date = new Date(message.timeStamp);
      if (isNaN(date.getTime())) {
        continue; // Skip this message if the timeStamp is not a valid date
      }
      const dateString = date.toDateString();

      if (!groupedMessages[dateString]) {
        groupedMessages[dateString] = [];
      }

      groupedMessages[dateString].push(message);
    }

    const formattedArray = [];
    for (const date in groupedMessages) {
      const filteredDate =
        date === today
          ? "Today"
          : date === yesterdayDateString
          ? "Yesterday"
          : date;
      const messages = groupedMessages[date];

      formattedArray.push({ filteredDate, messages });
    }

    return formattedArray;
  }

  const handleDocumentsUpload = async (event) => {
    setShowToolBox(false);
    if (event.target.files[0]) {
      const allowedFileTypes = [
        ".csv",
        ".doc",
        ".docx",
        ".pdf",
        ".ppt",
        ".txt",
      ];
      const selectedFileExtension = event.target.files[0].name.substring(
        event.target.files[0].name.lastIndexOf(".")
      );
      if (allowedFileTypes.includes(selectedFileExtension)) {
        try {
          const downloadURL = await uploadDocumentAndGetURL(
            event.target.files[0]
          );
          console.log("Download URL:", downloadURL);
        } catch (error) {
          console.error("Error uploading document:", error);
        }
      } else {
        console.log("Selected file is not from the allowed list.");
      }
    }
  };

  const uploadDocumentAndGetURL = async (file) => {
    const storage = getStorage();
    const storageReference = storageRef(storage, "documents/" + file.name);

    const database = getDatabase();
    const databaseReference = rtdbRef(database, "documents");

    try {
      // Upload the image to Firebase Storage
      await uploadBytes(storageReference, file);

      // Get the download URL of the uploaded image
      const downloadURL = await getDownloadURL(storageReference);

      // Save the download URL in Firestore
      // (your Firestore code here)

      await handleSendMessage(
        "document",
        downloadURL,
        file.name.split(".").pop(),
        file.name
      );

      console.log("Document uploaded and URL saved successfully.");

      return downloadURL;
    } catch (error) {
      console.error("Error uploading document:", error);
      throw error;
    }
  };

  const handleUploadVdoThumbnailAndGetLink = async (thumbnailData) => {
    const storage = getStorage();
    const storageReference = storageRef(
      storage,
      "thumbnail/" + thumbnailData.name
    );
    try {
      await uploadBytes(storageReference, thumbnailData);
      const downloadURL = await getDownloadURL(storageReference);
      console.log("VDO thumbnailURL : ", downloadURL);
      return downloadURL;
    } catch (error) {
      console.error("Error uploading media:", error);
      throw error;
    }
  };

  const uploadImageAndGetLink = async (file, isVideo, thumbnailData) => {
    const storage = getStorage();
    const storageReference = isVideo
      ? storageRef(storage, "videos/" + file.name)
      : storageRef(storage, "images/" + file.name);

    try {
      console.log(thumbnailData);
      const thumbnailURL = thumbnailData
        ? await handleUploadVdoThumbnailAndGetLink(thumbnailData)
        : null;

      console.log(thumbnailURL);
      // Upload the image to Firebase Storage
      await uploadBytes(storageReference, file);

      // Get the download URL of the uploaded image
      const downloadURL = await getDownloadURL(storageReference);

      // Save the download URL in Firestore
      // (your Firestore code here)

      await handleSendMessage(
        isVideo ? "video" : "photo",
        downloadURL,
        null,
        null,
        thumbnailURL
      );

      console.log("Image uploaded and URL saved successfully.");
      setIsLoading(false)

      return downloadURL;
    } catch (error) {
      setIsLoading(false)
      Swal.fire({
        title: "Error",
        text: error,
        icon: "error",
      });
      console.error("Error uploading image:", error);
      throw error;
    }
  };

  const handleImageUpload = async (event) => {
    setShowToolBox(false);
    if (event.target.files[0]) {
      const acceptedTypes = [
        'image/jpeg',
        'image/jpg',
        'image/png',
        'image/gif',
        'image/svg+xml',
        'video/mp4',
        'video/webm',
        'video/mkv',
        'video/ogg',
      ];
  
      const fileType = event.target.files[0].type;
  
      if (acceptedTypes.includes(fileType)) {
        const isVideo = fileType.startsWith('video/');
  
        try {
          if (isVideo) {
            setIsLoading(true);
            console.log("Uploading video...");
            const video = document.createElement('video');
            video.preload = 'metadata';
            video.src = URL.createObjectURL(event.target.files[0]);
  
            console.log('Video duration:', video.duration, video.src);
            await new Promise((resolve) => {
              video.onloadedmetadata = resolve;
            });
  
            if (!isFinite(video.duration) || video.duration <= 0) {
              throw new Error('Invalid video duration.');
            }
  
            // Seek to the middle of the video
            const seekTime = video.duration / 2;
            video.currentTime = seekTime;
  
            await new Promise((resolve) => {
              video.onseeked = resolve;
            });
  
            const canvas = document.createElement('canvas');
            canvas.width = video.videoWidth;
            canvas.height = video.videoHeight;
            const ctx = canvas.getContext('2d');
  
            ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
  
            const thumbnailBlob = await new Promise((resolve) =>
              canvas.toBlob(resolve, 'image/jpeg', 0.8)
            );
  
            const thumbnailFile = new File([thumbnailBlob], 'thumbnail.jpg', {
              type: 'image/jpeg',
            });
  
            // Pass the thumbnailFile to the next arrow function or perform any desired operations
            console.log('Thumbnail File:', thumbnailFile);
  
            const downloadURL = await uploadImageAndGetLink(
              event.target.files[0],
              isVideo,
              thumbnailFile
            );
            console.log('Download URL:', downloadURL);
  
            // Show success message
            console.log("Video uploaded successfully.");
            Swal.fire({
              title: "Success",
              text: "Video uploaded successfully.",
              icon: "success",
            });
          } else {
            // Handle image upload
            const downloadURL = await uploadImageAndGetLink(event.target.files[0]);
            console.log('Download URL:', downloadURL);
  
            // Show success message
            console.log("Image uploaded successfully.");
            Swal.fire({
              title: "Success",
              text: "Image uploaded successfully.",
              icon: "success",
            });
          }
        } catch (error) {
          console.error('Error uploading image:', error);
          setIsLoading(false);
          // Show error message
          console.log("Error uploading image:", error);
          Swal.fire({
            title: "Error",
            text: error.message || error,
            icon: "error",
          });
        }
      } else {
        console.log('File is not from the given options.');
        Swal.fire({
          title: "Error",
          text: "File Type Doesn't match",
          icon: "error",
        });
      }
    }
  };

  const handleTypeChange = (event) => {
    setTypedMessage(event.target.value);
  };

  const handleGetTwoMembers = async () => {
    const { groupMembers } = openedGroupInfo;
    let str = "";
    const db = getDatabase();

    try {
      if (Array.isArray(groupMembers)) {
        const promises = groupMembers.map(async (item) => {
          console.log(item);
          const userRef = rtdbRef(db, `users/${item}`);
          const snapshot = await get(userRef);

          if (snapshot.exists()) {
            return snapshot.val().displayName;
          } else {
            throw new Error("User data not found.");
          }
        });

        const userDataArray = await Promise.all(promises);
        str = userDataArray.join(", ");
        console.log(str);
        setMembersPallate(str);
      } else {
        console.error("groupMembers is not an array.");
        // Optionally handle the case when groupMembers is not an array
      }
    } catch (error) {
      console.error("Error getting user data:", error);
      throw error;
    }
  };

  useEffect(() => {
    showToolBoxRef.current = showToolBox;
    showEmojisRef.current = showEmojis;
  }, [showToolBox || showEmojis]);

  const handleClickOutside = (event) => {
    if (showToolBoxRef.current || showEmojisRef.current) {
      showToolBox && setShowToolBox(false);
      showEmojis && setShowEmojis(false);
    }
  };

  useEffect(() => {
    let timerId = null;

    if (showToolBox || showEmojis) {
      timerId = setTimeout(() => {
        document.addEventListener("click", handleClickOutside);
      }, 200); // 2000 milliseconds = 2 seconds
    }

    return () => {
      clearTimeout(timerId);
      document.removeEventListener("click", handleClickOutside);
    };
  }, [showToolBox, showEmojis]);

  useEffect(() => {
    handleGetTwoMembers();
    dispatch(getGroupChatMessagesAction(openedGroupInfo.groupId));
    return () => {
      dispatch(getGroupChatMessagesAction(openedGroupInfo.groupId));
    };
  }, [getMessages, openedGroupInfo?.groupMembers]);

  useEffect(() => {
    window.scrollTo({
      top: document.documentElement.scrollHeight + 10,
      scrollBehavior: "smooth",
    });
  }, [openedGroupMessages]);

  const chatContainerRef = useRef(null);

    useEffect(() => {
        // Scroll to the bottom of the chat container when chatMessages state changes
        if (chatContainerRef.current) {
            chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
        }
    }, [openedGroupMessages]);


  return (
    <div>
    <Header/>
    <Box 
    sx={{ display: "flex",marginTop:"90px", justifyContent: "center", alignItems: "center" }}
  >
   
      <Paper
        sx={{ flexGrow: 1}}
        elevation={10}
        style={paper}
      >
        <NavGroupChat
          membersPallate={membersPallate}
          backButtonPath={"/chat"}
          profilePicture={openedGroupInfo.groupProfileURL}
          groupName={openedGroupInfo.groupName}
          isSearchActive={isSearchActive}
          setIsSearchActive={setIsSearchActive}
          searchKeyword={searchKeyword}
          setSearchKeyword={setSearchKeyword}
        />
        <div
            style={{ height: "69vh", overflowY: "scroll" }}
            ref={chatContainerRef}
            className="chatPaper1"
          >
        {openedGroupInfo.createdById === currentUser.uid ? (
          <Box className="date-box-parent">
            <Box
              className="date-box"
              style={{ backgroundColor: "#FFFFFF", marginTop: "-0.8rem" }}
            >
              <Typography className="creator-badge-text">
                You’ve created this group
              </Typography>
            </Box>
          </Box>
        ) : (
          <Box className="date-box-parent">
            <Box
              className="date-box"
              style={{ backgroundColor: "#FFFFFF", marginTop: "-0.8rem" }}
            >
              <Typography className="creator-badge-text">
                {openedGroupInfo.createdByName} created this group & added you
              </Typography>
            </Box>
          </Box>
        )}
        {filterMessagesByDateRange(openedGroupMessages)?.map((item, index) => (
          <React.Fragment key={index}>
            <Box className="date-box-parent" mt={-1} mb={2}>
              <Box className="date-box">
                <Typography className="ind-chat-date">
                  {item.filteredDate}
                </Typography>
              </Box>
            </Box>

            {item.messages?.map(
              (msg) =>
                currentUser.uid in msg.seenBy && (
                  <GroupMsg
                  isLoading={isLoading}
                    key={msg.timestamp}
                    currentUser={currentUser}
                    groupId={openedGroupInfo?.groupId}
                    setIsReplyMsg={setIsReplyMsg}
                    msg={msg}
                    searchKeyword={searchKeyword}
                    isSearchActive={isSearchActive}
                  />
                )
            )}
          </React.Fragment>
        ))}
       </div>
        {showToolBox && (
          <Box className="plus-bar-content">
           {/*
          
           <Box className="plus-bar-icon-div">
              <img height={32} src="/images/profile-icon.png" alt="emoji" />
              <Typography className="plus-bar-icon-label">Profile</Typography>
            </Box>
            <Box className="plus-bar-icon-div">
              <img height={32} src="/images/user-icon.png" alt="emoji" />
              <Typography className="plus-bar-icon-label">Contact</Typography>
            </Box>
             <Box className="line-plus-icon-menu"></Box>

            <Box className="plus-bar-icon-div">
              <img
                // onClick={navigateToGoogleMaps}
                height={32}
                src="/images/location-icon.png"
                alt="emoji"
              />
              <Typography
                // onClick={navigateToGoogleMaps}
                className="plus-bar-icon-label"
              >
                Location
              </Typography>
            </Box>
          */}

           
            <Box className="plus-bar-icon-div">
              <img
                onClick={() =>
                  document
                    .getElementById("selectDocumentsInputForGroup")
                    .click()
                }
                height={32}
                src="/images/document-icon.png"
                alt="emoji"
                style={{cursor: "pointer"}}
              />
              <Typography
                onClick={() =>
                  document
                    .getElementById("selectDocumentsInputForGroup")
                    .click()
                }
                className="plus-bar-icon-label"
                style={{cursor: "pointer"}}
              >
                Document
              </Typography>
            </Box>

            <Box className="line-plus-icon-menu"></Box>
{/*
            <Box className="plus-bar-icon-div">
              <img
                onClick={() => navigate("/group-chat/camera")}
                height={32}
                src="/images/camera-icon.png"
                alt="emoji"
              />
             
             <Typography
                onClick={() => navigate("/group-chat/camera")}
                className="plus-bar-icon-label"
              >
                Camera
              </Typography>
              </Box>
            */}
            <Box className="plus-bar-icon-div">
              <img
                onClick={() =>
                  document.getElementById("selectImgInputForGroup").click()
                }
                height={32}
                src="/images/gallery-icon.png"
                alt="emoji"
                style={{cursor: "pointer"}}
              />
              <Typography
                onClick={() =>
                  document.getElementById("selectImgInputForGroup").click()
                }
                className="plus-bar-icon-label"
                style={{cursor: "pointer"}}
              >
                Gallery
              </Typography>
            </Box>
          </Box>
        )}
        <input
          id="selectDocumentsInputForGroup"
          hidden
          type="file"
          accept=".csc, .doc, .docx, .pdf, .ppt, .txt"
          onChange={handleDocumentsUpload}
        />
        <input
          id="selectImgInputForGroup"
          hidden
          type="file"
          accept=".jpeg, .jpg, .png, .gif, .svg, video/*"
          onChange={handleImageUpload}
        />
        {openedGroupInfo?.groupMembers?.includes(currentUser?.uid) && (
          <>
            <Box className="sticky-footer-parent">
              {showEmojis && (
                <Box onClick={(e) => e.stopPropagation()}>
                  <EmojiPicker
                    onEmojiClick={handleEmojiClick}
                    width={isMobile ? "100vw" : "26.9rem"}
                    height={"25rem"}
                    style={{cursor: "pointer"}}
                  />
                </Box>
              )}

              <Box
                className="sticky-footer"
                style={
                  previewData || showEmojis || isReplyMsg
                    ? { boxShadow: "none" }
                    : {}
                }
              >
                <Box className="input-box">
                  <InsertEmoticonIcon style={{cursor: "pointer"}} onClick={() => setShowEmojis(true)} />
                  <input
                    onKeyPress={(e) =>
                      e.key === "Enter" &&
                      typedMessage.length > 0 &&
                      handleSendMessage("text", typedMessage)
                    }
                    value={typedMessage}
                    onChange={handleTypeChange}
                    type="text"
                    placeholder="Type here..."
                    className="type-msg"
                  ></input>
                  {/* <img
                    onClick={() => setShowEmojis(true)}
                    className="emoji-icon"
                    height={17}
                    src="/images/emoji-icon.png"
                    alt="send"
                  /> 
                                  <CameraAltIcon
                    onClick={() => navigate("/group-chat/camera")}
                  />
                */}

                  {/* <img
                    onClick={() => navigate("/group-chat/camera")}
                    className="camera-icon"
                    height={17}
                    src="/images/camera.png"
                    alt="send"
                  />*/}
                  &nbsp;<AddBoxIcon style={{cursor: "pointer"}} onClick={() => setShowToolBox((pre) => !pre)} />
                  {/* <img
                    onClick={() => setShowToolBox((pre) => !pre)}
                    className="plus-icon"
                    height={15}
                    src="/images/plus-more-icon.png"
                    alt="send"
                  /> */}
                </Box>

                <Box
                style={{cursor: "pointer"}}
                  onClick={() =>
                    typedMessage.length > 0 &&
                    handleSendMessage("text", typedMessage)
                  }
                  //className="send-msg-btn"
                >
                  <SendIcon />
                  {/* <img height={17} src="/images/send-msg.png" alt="send" /> */}
                </Box>
              </Box>
            </Box>
          </>
        )}
      </Paper>
    </Box>
    </div>
  );
}
