import React, { useEffect } from 'react';
import Header from '../../components/Header';
import { Button, FormControlLabel, Radio, RadioGroup, TextField } from '@mui/material';
import { useState } from 'react';
import { createWellnessData, getWellnessQuetions } from '../../API/api-endpoint';
import Swal from 'sweetalert2';

const Wellnes = () => {
  const [discretion, setDiscretion] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);

  const [questions, setQuestions] = useState(null);
console.log("isSubmit",isSubmit);
  useEffect(() => {
    fetchAllWellness();
  }, []);

  const fetchAllWellness = async () => {
    const response = await getWellnessQuetions();
    setQuestions(JSON.parse(response));
  };

  const handleRadioChange = (segmentName, subSegmentName, questionId, selectedOption,type,question) => {
    setIsSubmit(true)
    setDiscretion((prevDiscretion) => {
      const newDiscretion = { ...prevDiscretion };
  
      if (!newDiscretion[segmentName]) {
        newDiscretion[segmentName] = {};
      }
  
      if (!newDiscretion[segmentName][subSegmentName]) {
        newDiscretion[segmentName][subSegmentName] = {};
      }
  console.log("selectedOption",type,selectedOption,typeof selectedOption, );
      if (type) {
        // For text input
        newDiscretion[segmentName][subSegmentName][`question_${questionId}`] = {
          selectedOptions: selectedOption,
          option: JSON.parse(question),
          question: questions.Segments
          .find((segment) => segment.name === segmentName)
          .SubSegments.find((subSegment) => subSegment.name === subSegmentName)
          .Questions.find((question) => question.id === questionId),

          
          // Add other properties relevant to text inputs if needed
        };
      } else {
        // For radio button inputs
        const selectedOptionText = JSON.parse(selectedOption).option_text;
        newDiscretion[segmentName][subSegmentName][`question_${questionId}`] = {
          selectedOptions: selectedOptionText,
          option: JSON.parse(selectedOption),
          question: questions.Segments
            .find((segment) => segment.name === segmentName)
            .SubSegments.find((subSegment) => subSegment.name === subSegmentName)
            .Questions.find((question) => question.id === questionId),
        };
      }
  
      return newDiscretion;
    });
  };
  
  

  const submitData = async () => {
    const data = {
      assessment_data: discretion,
      assessmentid: questions.id,
      assessment_name: questions.name,
    };
    console.log("data",data,discretion);
    if (isSubmit) {
      const response = await createWellnessData(data);
      console.log("response", response);
      if (response.status) {
        
      Swal.fire({
        title: "Success",
        text: response.message,
        icon: "success",
      });
      setDiscretion({})
      setIsSubmit(false)
    }
    }else{
      Swal.fire({
        title: "Warn",
        text: "Please select atleast one option",
        icon: "info",
      });
    }
   

  };

  return (
    <div>
      <Header />
      <div style={{ marginTop: '90px', padding: '20px', fontSize:"18px" }}>
      
      <h2 style={{borderBottom:"1px solid gray"}}>
          <strong style={{color:"#E67E22"}}><a href='/wellnessScore'>See Previous Assessment Result</a></strong>
        </h2> &nbsp;
        <h2>
          <strong>Assessment Form</strong>
        </h2>
        <div>Assessment Name: <TextField size="small" disabled type="text" id="fname" value={questions?.name}/></div>
        <div>
          <strong>Segments</strong>
        </div>
        <ol>
          {questions?.Segments.map((segment,index) => (
            <div key={segment.id}>
              <div className='ml-2'>
              {index+1}. 
                <strong>{segment.name}</strong>
              </div>
              {segment.SubSegments.map((subSegment,index) => (
                <div key={subSegment.id} >
                   <ul>
                   <li className='ml-[4%]'>
                   <strong >{subSegment.name}</strong>
                   </li>
                   </ul>

                  {subSegment.Questions.map((question,index) => (
                    <div key={question.id}>
                      <div className='ml-[6%]'>{index+1}. {question.question}</div>
                      {question?.user_response_type ==="input"?
                      <TextField
                      style={{margin:"1% 0% 0% 7%"}}
                      placeholder="Enter Bmi"
                      size="small"
                      type="text"
                      onChange={(e) =>
                        handleRadioChange(
                          segment.name, // Segment Name
                          subSegment.name, // Sub Segment Name
                          question.id, // Question ID
                          e.target.value, // Selected Option
                          true,
                          JSON.stringify(question?.Options)
                          
                        )
                      }
                    />
                    :(
                      <RadioGroup
                      aria-labelledby={`question-${question.id}`}
                      name={`radio-buttons-group-${question.id}`}
                      value={discretion[segment.name]?.[subSegment.name]?.[`question_${question.id}`]?.option?.option_text || ''}
                      onChange={(e) =>
                        handleRadioChange(
                          segment.name, // Segment Name
                          subSegment.name, // Sub Segment Name
                          question.id, // Question ID
                          e.target.value // Selected Option
                        )
                      }
                    >
                      {question.Options.map((option,index) => (
                        <div className='ml-[8%]'>
                        <FormControlLabel
                        key={option.id}
                        value={JSON.stringify(option)}
                        control={<Radio />}
                        label={option.option_text}
                        checked={
                          discretion[segment.name]?.[subSegment.name]?.[`question_${question.id}`]?.selectedOptions === option.option_text
                        }
                        />
                        </div>
                      ))}
                    </RadioGroup>
                    )}
                      
                    </div>
                  ))}
                </div>
              ))}
            </div>
          ))}
        </ol>
      </div>
      <Button style={{margin:"20px"}} variant='contained' onClick={submitData}>Submit</Button>
      &nbsp;
    </div>
  );
};

export default Wellnes;
