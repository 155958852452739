import React from "react";
import "./tranningprofile.css";
import { Button, Grid, MenuItem, Select, TextField } from "@mui/material";

const TranningProfile = () => {
  return (
    <div className="tranning-profile-main">
      <div className="tranning-profile-section">
        <div className="title">Tranning Profile</div>
        <div className="tranning-profile-container">
          <div className="profile-form-group">
            <label>Choose the method of tranning plan update</label>
            <div className="form-radio-group">
              <div className="radio-group">
                <input
                  type="radio"
                  id="Automatic"
                  name="plan"
                  value="Automatic"
                />
                <label for="Automatic">Automatic</label>
              </div>
              <div className="radio-group">
                <input type="radio" id="Manual" name="plan" value="Manual" />
                <label for="Manual">Manual</label>
              </div>
            </div>
          </div>
          <div className="profile-form-group">
            <label>
              Refine tranning plan based on actual trainne performance of last
              week
            </label>
            <div className="form-radio-group">
              <div className="radio-group">
                <input type="radio" id="Yes" name="plan_renew" value="Yes" />
                <label for="Yes">Yes</label>
              </div>
              <div className="radio-group">
                <input type="radio" id="No" name="plan_renew" value="No" />
                <label for="No">No</label>
              </div>
            </div>
          </div>
          <div className="profile-form-group">
            <label>
              Please select number of day in the week you would like to workout
            </label>
            <div className="no-days-section">
              <div className="day">1</div>
              <div className="day">2</div>
              <div className="day">3</div>
              <div className="day">4</div>
            </div>
          </div>
          <div className="profile-table-section">
            <div>
              select the most appropriate weekly schedual that suits you
            </div>
            <Grid container>
              <Grid xs={12} md={4}>
                <div className="tranning-table-section">
                  <div className="table-header">
                    <div className="day-cell">DAY</div>
                    <div className="workout-cell">WORKOUT</div>
                  </div>
                  <div className="table-row">
                    <div className="day-cell">Mon</div>
                    <div className="workout-cell">Rest Day</div>
                  </div>
                  <div className="table-row border-row">
                    <div className="day-cell active-cell">Tue</div>
                    <div className="workout-cell active-cell">
                      Short / Meduim Run
                    </div>
                  </div>
                  <div className="table-row">
                    <div className="day-cell">Wed</div>
                    <div className="workout-cell">Rest Day</div>
                  </div>
                  <div className="table-row">
                    <div className="day-cell active-cell">Thu</div>
                    <div className="workout-cell active-cell">
                      Short / Meduim Run
                    </div>
                  </div>
                  <div className="table-row border-row">
                    <div className="day-cell">Fri</div>
                    <div className="workout-cell">Rest Day</div>
                  </div>
                  <div className="table-row border-row">
                    <div className="day-cell active-cell">Sat</div>
                    <div className="workout-cell active-cell">Short Run</div>
                  </div>
                  <div className="table-row border-row">
                    <div className="day-cell active-cell">Sun</div>
                    <div className="workout-cell active-cell">Long Run</div>
                  </div>
                  <div className="profile-table-btn-section">
                    <Button variant="contained" color="primary">
                      Selected
                    </Button>
                  </div>
                </div>
              </Grid>
              <Grid xs={12} md={4}>
                <div className="tranning-table-section">
                  <div className="table-header">
                    <div className="day-cell">DAY</div>
                    <div className="workout-cell">WORKOUT</div>
                  </div>
                  <div className="table-row">
                    <div className="day-cell active-cell">Mon</div>
                    <div className="workout-cell active-cell">Rest Day</div>
                  </div>
                  <div className="table-row border-row">
                    <div className="day-cell">Tue</div>
                    <div className="workout-cell">Short / Meduim Run</div>
                  </div>
                  <div className="table-row">
                    <div className="day-cell active-cell">Wed</div>
                    <div className="workout-cell active-cell">Rest Day</div>
                  </div>
                  <div className="table-row">
                    <div className="day-cell">Thu</div>
                    <div className="workout-cell">Short / Meduim Run</div>
                  </div>
                  <div className="table-row border-row">
                    <div className="day-cell active-cell">Fri</div>
                    <div className="workout-cell active-cell">Rest Day</div>
                  </div>
                  <div className="table-row border-row">
                    <div className="day-cell active-cell">Sat</div>
                    <div className="workout-cell active-cell">Short Run</div>
                  </div>
                  <div className="table-row border-row">
                    <div className="day-cell">Sun</div>
                    <div className="workout-cell">Long Run</div>
                  </div>
                  <div className="profile-table-btn-section">
                    <Button variant="outlined" color="primary">
                      Select
                    </Button>
                  </div>
                </div>
              </Grid>
              <Grid xs={12} md={4}>
                <div className="tranning-table-section">
                  <div className="table-header">
                    <div className="day-cell">DAY</div>
                    <div className="workout-cell">WORKOUT</div>
                  </div>
                  <div className="table-row">
                    <div className="day-cell">Mon</div>
                    <div className="workout-cell">Rest Day</div>
                  </div>
                  <div className="table-row border-row">
                    <div className="day-cell active-cell">Tue</div>
                    <div className="workout-cell active-cell">
                      Short / Meduim Run
                    </div>
                  </div>
                  <div className="table-row">
                    <div className="day-cell">Wed</div>
                    <div className="workout-cell">Rest Day</div>
                  </div>
                  <div className="table-row">
                    <div className="day-cell active-cell">Thu</div>
                    <div className="workout-cell active-cell">
                      Short / Meduim Run
                    </div>
                  </div>
                  <div className="table-row border-row">
                    <div className="day-cell active-cell">Fri</div>
                    <div className="workout-cell active-cell">Rest Day</div>
                  </div>
                  <div className="table-row border-row">
                    <div className="day-cell ">Sat</div>
                    <div className="workout-cell ">Short Run</div>
                  </div>
                  <div className="table-row border-row">
                    <div className="day-cell active-cell">Sun</div>
                    <div className="workout-cell active-cell">Long Run</div>
                  </div>
                  <div className="profile-table-btn-section">
                    <Button variant="outlined" color="primary">
                      Select
                    </Button>
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
          <div className="profile-table-section">
            <div style={{ color: "red" }}>
              The changes needs to be done on or before Thursday EOD so the
              changes can reflect in the following week.
            </div>
          </div>
          <div className="profile-table-section">
            <div className="profile-input-form-group">
              <lable>Week Number</lable>
              <TextField
                id="standard-basic"
                label="Number"
                variant="standard"
              />
            </div>
            <div className="profile-input-form-group">
              <lable>Long Run</lable>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={"Range"}
                label="Range"
                variant="standard"
              >
                <MenuItem value={10}>10 KM</MenuItem>
                <MenuItem value={20}>20 KM</MenuItem>
                <MenuItem value={30}>30 KM</MenuItem>
              </Select>
            </div>
            <div className="profile-input-form-group">
              <lable>Select a Goal</lable>
              <TextField
                id="standard-basic"
                label="Number"
                variant="standard"
              />
            </div>
            <div className="profile-input-form-group">
              <lable>Goal End Date</lable>
              <input className="date-input" type="date" />
            </div>
          </div>
          <div className="profile-table-section">
            <div style={{ color: "red" }}>
              The above can be changed at any point to match youe personal
              schedual and changes would be done once a form your end.
            </div>
          </div>
          <div className="profile-table-section">
            <div style={{ color: "red" }}>
              any chnages/reqested submitted untill Thursday EOD are processed
              for that week and the following week's schedual ( following monday
              onwards.) is sutaibly aligned to match your request.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TranningProfile;
