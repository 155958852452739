import { Avatar, Box, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import { db, get, ref } from '../../../../API/firebase.config';
import { useState } from 'react';
import './../../styles/group/groupMembers.css'
import { URL } from '../../../../API/api-endpoint';

export default function GroupMembers({ item, index }) {


    const [userInfo, setUserInfo] = useState({})

    const getUserDataById = async (userId) => {
        try {
            const userRef = ref(db, `users/${userId}`);
            const snapshot = await get(userRef);

            if (snapshot.exists()) {
                setUserInfo(snapshot.val())
                return;
            } else {
                throw new Error("User data not found.");
            }
        } catch (error) {
            console.error("Error getting user data:", error);
            throw error;
        }
    }

    useEffect(() => {
        getUserDataById(item)
    }, [])
    useEffect(() => {
        console.log(userInfo,"userinfor");
        
    }, [userInfo])

    return <>
        <Box
            className="profile-row" key={index}
            sx={{ padding: "8px", paddingTop: "12px", display: "flex", justifyContent: 'space-between', alignItems: 'center' }}>
            <Box sx={{ display: "flex", alignItems: 'center', padding: '6px' }}>
            <Avatar alt={userInfo.displayName}
                     src={`${URL}/static/public/userimages/${userInfo?.photoURL}`} />
                <Box>
                    <Typography className='userName' fontWeight="fontWeightBold" sx={{ paddingLeft: '1.2rem', fontSize: '14px' }}>{userInfo?.displayName}</Typography>
                    <Typography sx={{ paddingTop: "2px", paddingLeft: '1.2rem', fontSize: '12px', display: "flex", alignItems: "center" }}>
                        {/*
                    
                    {'7724 Followers | 4.65'}
                    <img src="/images/star-icon.png" alt="star" />
                    */}
                    </Typography>
                </Box>
            </Box>
        </Box >
    </>
}