import * as React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import ReactPlayer from 'react-player';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    boxShadow: '0 0 0 2000px black', // Updated shadow
    border: 'none',
    outline: 'none', // Added outline property
    p: 0,
};

export default function VideoModal({ isOpen, onClose, videoUrl }) {
    const playerRef = React.useRef(null);

    React.useEffect(() => {
        if (playerRef.current) {
            playerRef.current.play();
        }
    }, []);

    return (
        <div>
            <Modal
                open={isOpen}
                onClose={onClose}
                aria-labelledby="fullscreen-modal-title"
                aria-describedby="fullscreen-modal-description"
            >
                <Box sx={style}>
                    <ReactPlayer
                        ref={playerRef}
                        width={'100%'}
                        height={'100%'}
                        url={videoUrl}
                        controls={{ download: false }} // Updated controls prop
                        playing={true}
                        style={{ backgroundColor: 'transparent', border: 'none' }}
                    />
                </Box>
            </Modal>
        </div>
    );
}
