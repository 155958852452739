import { LOGIN_USER_FAIL, LOGIN_USER_INIT, LOGIN_USER_SUCCESS } from "../constant/authConstants"

export const authReducer = (state = { currentUser: {} }, { type, payload }) => {
    switch (type) {

        case LOGIN_USER_INIT: return { loading: true }

        case LOGIN_USER_SUCCESS: return { loading: false, currentUser: payload }

        case LOGIN_USER_FAIL: return { loading: false, error: payload }

        default: return state
    }
}
