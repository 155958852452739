import { FormLabel, Grid, MenuItem, OutlinedInput, TextField } from '@mui/material';
import { Button, Modal, TimePicker } from 'antd'
import React, { useEffect, useState } from 'react'
import { createPowerdata,  getAllActivityData, getAllQuestionData, getAlllevels,  getZonesDataById,  updatePowerdata } from '../../API/api-endpoint';
import { useFormik } from 'formik';
import Swal from 'sweetalert2';
import SlickCarousel from '../../pages/SlickCarousel';
const CreateZonesPower = ({ fetchReport, setShowAssesmentModal, showAssesmentModal, editData, setEditData }) => {
    const [zonePowerList, setZonePowerData] = useState([]);
    const [programList, setProgramList] = useState([]);
    const [allLevelList, setAlllevelList] = useState([]);
  
    console.log("editData", programList);
    const formik = useFormik({
      initialValues: {
        activity_id: "",
        name: "",
        zone: "",
        offset_start: "",
        offset_end: "",
      },
      validate: (values) => {
        const errors = {};
        if (!values.activity_id) {
          errors.activity_id = "Activity is required";
        }
        if (!values.name) {
          errors.name = "Name is required";
        }
        if (!values.zone) {
          errors.zone = "Zone name is required";
        }
        if (!values.offset_start) {
          errors.offset_start = "Offset Start is required";
        }
        if (!values.offset_end) {
          errors.offset_end = "Offset End is required";
        }
        return errors;
      },
      // validationSchema: {},
      onSubmit: (values, { resetForm }) => {
        handleSubmitAssesmentForm(values, resetForm);
      },
    });
    console.log("formik", formik?.values, formik?.errors);
    const QuestionData = async (id) => {
        const response = await getZonesDataById(id)
        console.log("response", response);
        setZonePowerData(response.uniquesnamesonly)
    }
   
    useEffect(() => {
        if (formik?.values?.activity_id) {
            QuestionData(formik?.values?.activity_id)
        }
        getAllProgramsData()
        getAllLevelsData()
    }, [formik?.values?.activity_id])
    const getAllProgramsData = async () => {
      const response = await getAllActivityData();
      console.log("response", response);
      setProgramList(response?.rows);
    };
    const getAllLevelsData = async () => {
      const response = await getAlllevels();
      console.log("response", response);
      setAlllevelList(response?.rows);
    };
  
    const handleSubmitAssesmentForm = async (data, resetForm) => {
      let response = "";
      if (editData?.id) {
        response = await updatePowerdata(data);
      } else {
        response = await createPowerdata(data);
      }
      if (response?.status) {
        Swal.fire({
          title: "Success",
          text: response.message,
          icon: "success",
        });
        setEditData({});
        setShowAssesmentModal(false);
        fetchReport();
        formik.resetForm();
        formik?.setValues({
          activity_id: "",
          name: "",
          zone: "",
          offset_start: "",
          offset_end: "",
        });
      } else {
        Swal.fire({
          title: "Error",
          text: response.message,
          icon: "error",
        });
      }
      console.log("response", response);
    };
    useEffect(() => {
      if (editData?.id) {
        const { srID, ...data } = editData;
        formik?.setValues(data);
      }
    }, [editData?.id]);
  
    return (
      <Modal
        width={1200}
        open={showAssesmentModal}
        onCancel={() => {
          setShowAssesmentModal(false);
          formik.resetForm();
          setEditData({});
          formik?.setValues({
            activity_id: "",
            name: "",
            zone: "",
            offset_start: "",
            offset_end: "",
          });
        }}
        footer={
          <div></div>
          //   loading={isLoading}
        }
      >
        <div className="headingCont">
          <span className="heading">{editData?.id ? "Edit " : "Create"}</span>{" "}
          <span className="orange heading">Zone Power</span>
        </div>
        {/* <h1>{editData ? editData.challengeId : values.challengeId}</h1> */}
        <div className="parentCont">
          <form className="form1" onSubmit={formik.handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={11}>
                <FormLabel>Activity Name<span className="text-[red]">*</span></FormLabel>
  
                <TextField
                  fullWidth
                  size="small"
                  select
                  name="activity_id"
                  SelectProps={{
                    MenuProps: {
                      PaperProps: {
                        style: {
                           scrollbarColor:"#E67E22 white",
                           scrollbarWidth:"thin"
                        },
                      },
                    },
                  }}
                  value={formik?.values?.activity_id}
                  onChange={formik.handleChange}
                  error={formik.touched.activity_id && formik.errors.activity_id}
                  helperText={
                    formik.touched.activity_id && formik.errors.activity_id
                  }
                  id="form-layouts-separator-select"
                  labelId="form-layouts-separator-select-label"
                  input={<OutlinedInput id="select-multiple-language" />}
                >
                  <MenuItem value={""} disabled>
                    Select Activity
                  </MenuItem>
                  {programList?.map((value, index) => {
                    return (
                        <MenuItem value={value?.id}>
                            {value?.activity_name}
                        </MenuItem>
                    );
                  })}
                </TextField>
              </Grid>
              <Grid item xs={12} sm={11}>
                <FormLabel>Name<span className="text-[red]">*</span></FormLabel>
  
                <TextField
                  fullWidth
                  placeholder="Name"
                  size="small"
                  type="text"
                  name="name"
                  value={formik?.values?.name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.name && formik.errors.name}
                  helperText={formik.touched.name && formik.errors.name}
                />
              </Grid>
              <Grid item xs={12} sm={11}>
                <FormLabel>Zone<span className="text-[red]">*</span></FormLabel>
  
                <TextField
                  fullWidth
                  size="small"
                  select
                  SelectProps={{
                    MenuProps: {
                      PaperProps: {
                        style: {
                           scrollbarColor:"#E67E22 white",
                           scrollbarWidth:"thin"
                        },
                      },
                    },
                  }}
                  name="zone"
                  value={formik?.values?.zone}
                  onChange={formik.handleChange}
                  error={formik.touched.zone && formik.errors.zone}
                  helperText={formik.touched.zone && formik.errors.zone}
                  id="form-layouts-separator-select"
                  labelId="form-layouts-separator-select-label"
                  input={<OutlinedInput id="select-multiple-language" />}
                >
                  <MenuItem value={""} disabled>
                    Select Zone
                  </MenuItem>
                  {zonePowerList?.map((value, index) => {
                    return <MenuItem value={value}>{value}</MenuItem>;
                  })}
                </TextField>
              </Grid>
              <Grid item xs={12} sm={11}>
                <FormLabel>Offset Start<span className="text-[red]">*</span></FormLabel>
  
                <TextField
                  fullWidth
                  placeholder="Offset Start"
                  size="small"
                  type="number"
                  name="offset_start"
                  value={formik?.values?.offset_start}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.offset_start && formik.errors.offset_start
                  }
                  helperText={
                    formik.touched.offset_start && formik.errors.offset_start
                  }
                />
              </Grid>
              <Grid item xs={12} sm={11}>
                <FormLabel>Offset End<span className="text-[red]">*</span></FormLabel>
  
                <TextField
                  fullWidth
                  placeholder="Offset End"
                  size="small"
                  type="number"
                  name="offset_end"
                  value={formik?.values?.offset_end}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.offset_end && formik.errors.offset_end}
                  helperText={
                    formik.touched.offset_end && formik.errors.offset_end
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Button
                  className="btn"
                  key="submit"
                  type="primary"
                  onClick={() => formik.handleSubmit()}
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          </form>
          <div className="slick-container">
            <SlickCarousel />
          </div>
        </div>
      </Modal>
    );
  };
  export default CreateZonesPower;
  