import React, { useContext, useEffect, useRef, useState } from 'react'
import Header from '../components/Header'
import {  IconTrash } from '@tabler/icons'
import Sidebar from '../components/Sidebar'
import GlobalContext from '../context/GlobalContext'
import { useNavigate } from 'react-router-dom'
import { getAllWorkoutLibrary1,  getAllworkoutsDetails,  updatePrfile } from '../API/api-endpoint'
import WorkoutCalender from './Workouts/componants/workoutCalender'
import AtheleteHeader from '../components/AtheleteHeader'
import Profile from '../components/Athelete/Profile'
import { useFormik } from 'formik'
import {  profileinitialValue } from '../components/formik/FormikValidationSceema'
import Swal from 'sweetalert2'
import { Grid } from '@mui/material'
import { showInfo } from '../components/Messages'
import AssignedUserChallneges from './Challenges/AssignedUserChallneges'
import SearchBar from '../pages/Workouts/coach-yoska/SearchBar'
import AtheleZone from './Athlete/AtheleZone'
import {
  getAssignedCoach,
  getPrograms,
  getUsersProfile,
} from "../API/api-endpoint";

import { update, getDatabase, ref } from "firebase/database";
import { validateDOB, validateMobileNumber } from "../utils/Resubale";
// import { ref } from 'firebase/storage'

const RunningProgram = () => {
  const [calendarModal, setCalendarModal] = useState(false);
  const [athleteTab, setAthleteTab] = useState(true);
  const [isProfileOpen, setIsProfileOpen] = useState(false);
  const [isEnrolledChallengesOpen, setIsEnrolledChallengesOpen] = useState({
    isOpen: false,
    assignCoachId: "",
  });
  const [isZoneOpen, setIsZoneOpen] = useState({
    isOpen: false,
    assignCoachId: "",
  });
  const [hasChanged, setHasChanged] = useState(false);
  const [CopyWorkoutData, setCopyWorkoutData] = React.useState({
    year: "",
    month: "",
    copyweekindex: "",
    pasteweekindex: "",
  });

  const [isLoading, setIsLoading] = useState(false)
  const [isEditProfile, setIsEditProfile] = useState(false)
  const [usersDetails, setUserDetails] = useState()
  const [togglesidebar, setTogglesidebar] = useState("athlete")
console.log("calendarModal",calendarModal);




  const [workoutformdrawerState, setworkoutformdrawerState] = useState(false);
  const [workoutID, setWorkoutID] = useState();

  const [AssignedCoach, setAssignedCoach] = useState();
  const [assignedCocahId, setAssignedCoachId] = useState();
  const [allEvents, setAllevents] = useState(false);
  const [dateForworkout, setDate] = useState(false);
  const [getDaysoptionList, setfetchDaysoptionList] = useState([]);
  const [fetchActivePrograms, setFetchActivePrograms] = useState();
  const [formGoalValue, setFormGoalValue] = useState({
    goal: "",
    daysinweek: "",
    daysoption: "",
    activities: "",
  });

  const [athletes, setAthletes] = useState([]);
  // const [userId, setUserId] = useState(null)

  const navigate = useNavigate()

  

  const { user } = useContext(GlobalContext)

  const actions = [
    // {
    //   id: 1,
    //   title: 'Quick view',
    //   icon: <IconLiveView size={18} />
    // },
    // {
    //   id: 2,
    //   title: 'Chat',
    //   icon: <IconMessage size={18} />
    // },
    {
      id: 3,
      title: "Details",
      icon: <IconTrash size={18} />,
    },
  ];

  useEffect(() => {
    if (fetchActivePrograms?.program_id) {
      fetchCoachList(fetchActivePrograms?.program_id);
    }
    const apiUrl = `https://app.yoska.in/kona-coach/api/organizations/50/athletes`;
    const bearerToken = "qmmvofrilpsj59rctkn0ivseuil1jji5";

    fetch(apiUrl, {
      headers: {
        Authorization: `Bearer ${bearerToken}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setAthletes(data);
        // Do something with the data
      })
      .catch((error) => {
        console.error(error);
        // Handle the error
      });
  }, [user, fetchActivePrograms?.program_id]);

  const fetchCoachList = async (id) => {
    setAssignedCoach([]);
    const response = await getAssignedCoach(id);
    if (response?.allassinged?.length > 0) {
      setAssignedCoach(response?.allassinged);
    } else {
      showInfo("Athletes have not been assigned yet");
    }
    console.log("response?.allassinged", response);
  };

  useEffect(() => {
    const user = localStorage.getItem("username");
    setTimeout(() => {
      user === "toby@nextleveltricoaching.com.au" &&
        navigate("/nextlevel-tri-program");
    }, 1000);
  }, [navigate]);
  const getAllWorkouts = async () => {
    let result = await getAllworkoutsDetails(assignedCocahId);
    // Function to format the date
    const formatDate = (dateString) => {
      const date = new Date(dateString);
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based
      const day = String(date.getDate()).padStart(2, "0");
      return `${year}-${month}-${day}`;
    };

    // Map through the events to update the `start` property
    const updatedEvents = result.map((event) => ({
      ...event,
      start: formatDate(event.datetf),
    }));

    console.log(updatedEvents);
    setAllevents(updatedEvents);
  };
  useEffect(() => {
    // Initial call when the component mounts
    if (assignedCocahId) {
      getAllWorkouts();
    }
  }, [assignedCocahId]);
  const formik = useFormik({
    initialValues: profileinitialValue,
    validate: (values) => {
      let mobileValidationResult = "";
      let econtactValidationResult = "";
      // Validate the mobile number using the validateMobileNumber function
      const dobValidationResult = validateDOB(values.dateofbirth);
      if (values?.mobile) {
        mobileValidationResult = validateMobileNumber(
          values?.mobile?.toString()
        );
      }
      if (values?.econtact) {
        econtactValidationResult = validateMobileNumber(
          values?.econtact?.toString()
        );
      }
      const firstNameValidationResult = validateName(
        values?.firstname,
        "First name"
      );
      const lastNameValidationResult = validateName(
        values?.lastname,
        "Last name"
      );
      // Update the formik errors based on the mobile and DOB validation
      return {
        dateofbirth: dobValidationResult,
        mobile: mobileValidationResult.error,
        econtact: econtactValidationResult.error,
        firstname: firstNameValidationResult,
        lastname: lastNameValidationResult,
      };
    },
  });
  const validateName = (name, label) => {
    if (!name) {
      return `${label} is required`;
    }
    if (name.length < 1) {
      return `${label} must be at least 1 characters long`;
    }
    return "";
  };
  const handleOnSubmitForm = async (file) => {
    const showSuccess = (message) => {
      Swal.fire({
        title: "Success",
        text: message,
        icon: "success",
      });
    };

    const showErrorAndRedirect = (message) => {
      Swal.fire({
        title: "Error",
        text: message,
        icon: "error",
      });
    };
    try {
      if (
        !formik.errors?.dateofbirth &&
        !formik.errors?.mobile?.incorrectLength &&
        !formik.errors?.mobile?.incorrectFormat &&
        !formik.errors?.econtact?.incorrectLength &&
        !formik.errors?.econtact?.incorrectFormat &&
        !formik.errors?.firstname &&
        !formik.errors?.lastname
      ) {
        setIsLoading(true);
        let coachProgramID = localStorage.getItem("programID");

        console.log("formik?.value", formik?.values?.user_id);
        const actProgram = await getPrograms(formik?.values?.user_id);
        let filter = actProgram?.filter((data) => data.active == 1);
        let coachID = filter[0]?.program_id
          ? filter[0]?.program_id
          : coachProgramID;
        console.log("actProgram", coachID, coachProgramID);
        if (coachID) {
          const formData = new FormData();
          Object.keys(formik?.values).forEach((key) => {
            formData.append(key, formik?.values[key]);
          });
          // formData.append('user_id', assignedCocahId);
          if (formik?.values.dateofbirth) {
            formData.append("dob", formik?.values.dateofbirth);
          }
          formData.append("program_id", coachID);
          if (formik?.values?.firstname && formik?.values?.email) {
            const fullName = `${formik?.values?.firstname} ${formik?.values?.lastname}`;
            updateUserDetails(
              formik?.values?.google_id,
              fullName,
              formik?.values?.email
            );
          }
          if (file) {
            setHasChanged(true);
            formData.append("profile_image", file);
          }
          const Result = await updatePrfile(formData);
          if (Result.status) {
            showSuccess(Result.message);
            setIsEditProfile(false);

            setIsLoading(false);
            let coachProgramID = localStorage.getItem("programID");
            const actProgram = await getPrograms(assignedCocahId);
            let filter = actProgram?.filter((data) => data.active == 1);
            let coachID = filter[0]?.program_id
              ? filter[0]?.program_id
              : coachProgramID;
            console.log("actProgram", coachID, coachProgramID);
            if (coachID) {
              const response = await getUsersProfile(coachID, assignedCocahId);
              let data = {
                ...response?.existingUserContacts,
                ...response?.personalization_profile,
                ...response?.user,
              };
              formik.setValues(data);
              setUserDetails(response);
            }
          } else {
            showErrorAndRedirect(Result?.message);
          }
        }
      } else {
        const errorFields = [];

        if (formik.errors?.firstname) {
          errorFields.push("First Name");
        }
        if (formik.errors?.lastname) {
          errorFields.push("Last Name");
        }
        if (formik.errors?.dateofbirth) {
          errorFields.push("Date of Birth");
        }
        if (
          formik.errors?.mobile?.incorrectLength ||
          formik.errors?.mobile?.incorrectFormat
        ) {
          errorFields.push("Mobile Number");
        }
        if (
          formik.errors?.econtact?.incorrectLength ||
          formik.errors?.econtact?.incorrectFormat
        ) {
          errorFields.push("Emergency Contact");
        }

        if (errorFields.length > 0) {
          const errorMessage = `Please fill all fields correctly: ${errorFields.join(
            ", "
          )}`;
          showErrorAndRedirect(errorMessage);
        }
      }
    } catch (error) {
      setIsLoading(false);
    }
  };
  //  to upload file in base64

  const updateUserPhotoURL = async (userUid, newPhotoURL) => {
    try {
      const db = getDatabase();
      const userRef = ref(db, `/users/${userUid}`);
      await update(userRef, { photoURL: newPhotoURL });
      console.log("nigixPhotoURL updated successfully", newPhotoURL, userUid);
    } catch (error) {
      console.error("nigixError updating photoURL:", error);
    }
  };

  const updateUserDetails = async (userUid, newDisplayName, newEmail) => {
    try {
      const db = getDatabase();

      const userRef = ref(db, `/users/${userUid}`);
      await update(userRef, {
        displayName: newDisplayName,
        email: newEmail,
      });

      console.log(
        "nigixDisplayName and Email updated successfully",
        userUid,
        newDisplayName,
        newEmail
      );
    } catch (error) {
      console.error("nigixError updating DisplayName and Email:", error);
    }
  };

  useEffect(() => {
    if (formik?.values?.profile && hasChanged) {
      // const googleId = localStorage.getItem("google_id")
      updateUserPhotoURL(formik?.values?.google_id, formik?.values?.profile);
      setHasChanged(true);
    }
  }, [formik?.values?.profile, hasChanged]);

  // for workouts sidebar >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
  const [workoutLibrary, setWorkoutLibrary] = useState([]);
  const [draggedEvent, setdraggedEvent] = useState(null);
  const [fetchGoals, setFechGoals] = useState([]);
  console.log("fetchGoals", fetchGoals);
  const getLibraryList = async () => {
    let result = await getAllWorkoutLibrary1();
    console.log("result.data", result?.library);
    setWorkoutLibrary(result?.library);
  };
  useEffect(() => {
    getLibraryList();
  }, []);

  console.log("togglesidebar", togglesidebar);
  const handleChangeSidebar = (event, newAlignment) => {
    if (assignedCocahId) {
      setTogglesidebar(newAlignment);
    } else {
      Swal.fire({
        title: "Error",
        text: "Please First Select any Athletes",
        icon: "error",
      });
    }
  };
  const handleToggleChangeSidebar = (event, newAlignment) => {
    if (!assignedCocahId) {
      Swal.fire({
        title: "Error",
        text: "Please First Select any Athletes",
        icon: "error",
      });
    } else {
      setTogglesidebar(newAlignment);
      // setCalendarModal(false);
      setIsProfileOpen(false);
      setIsEnrolledChallengesOpen({ isOpen: false, assignCoachId: "" });
    }
  };

  const control = {
    value: togglesidebar,
    onChange: handleChangeSidebar,
    exclusive: true,
  };
  const workoutcontrol = {
    value: togglesidebar,
    onChange: handleToggleChangeSidebar,
    exclusive: true,
  };
  const draggableEl = useRef(null);

  const handleDragStart = (e, data) => {
    console.log("data", data);
    e.dataTransfer.setData("text/plain", JSON.stringify(data));
    setdraggedEvent(data);
  };
  console.log("draggedEvent", draggedEvent);
  const containerEl = document?.querySelector("#events");
  console.log("containerEl", containerEl);
  return (
    <>
      <Header setFetchActivePrograms={setFetchActivePrograms} />
      <div className="grid grid-cols-1 xl:grid-cols-5 items-start gap-x-4"></div>
      <div style={{ fontSize: "18px", width: "100%", marginTop: "80px" }}>
        <Grid container spacing={0} sx={{height:"90vh"}}>
          <Grid item md={2} xs={0} sm={3} style={{ background: "#FFEADC", padding: "10px", overflowY: "scroll" }}>
            {togglesidebar == "workout" ? (
              <SearchBar
                control={workoutcontrol}

                togglesidebar={togglesidebar}
                handleDragStart={(e) => handleDragStart(e, draggableEl)}
                draggableEl={draggableEl}
                workoutLibrary={workoutLibrary}
                handleChangeSidebar={handleToggleChangeSidebar}
                setIsProfileOpen={setIsProfileOpen}
                setIsEnrolledChallengesOpen={setIsEnrolledChallengesOpen}


              />
            ) : (
              <Sidebar
                setIsZoneOpen={setIsZoneOpen}
                setfetchDaysoptionList={setfetchDaysoptionList}
                togglesidebar={togglesidebar}
                formGoalValue={formGoalValue}
                control={control}
                draggedEvent={draggedEvent}
                handleChangeSidebar={handleChangeSidebar}
                formik={formik}
                setTogglesidebar={setTogglesidebar}
                actions={actions}
                athletes={athletes}
                setUserDetails={setUserDetails}
                assignedCocahId={assignedCocahId}
                setCalendarModal={setCalendarModal}
                AssignedCoach={AssignedCoach}
                setIsEnrolledChallengesOpen={setIsEnrolledChallengesOpen}
                setAssignedCoachId={setAssignedCoachId}
                setIsProfileOpen={setIsProfileOpen}
                setIsEditProfile={setIsEditProfile}
                setFormGoalValue={setFormGoalValue}
              />
            )}

          </Grid>
          <Grid item md={10} xs={12} sm={9}  className='athleteTab'>
            {assignedCocahId &&
              <AtheleteHeader setFechGoals={setFechGoals} setIsZoneOpen={setIsZoneOpen} formGoalValue={formGoalValue} setfetchDaysoptionList={setfetchDaysoptionList} setFormGoalValue={setFormGoalValue} setIsEnrolledChallengesOpen={setIsEnrolledChallengesOpen} setUserDetails={setUserDetails} formik={formik} assignedCocahId={assignedCocahId} setCalendarModal={setCalendarModal} setIsProfileOpen={setIsProfileOpen} setIsEditProfile={setIsEditProfile} />
            }
            {/* Create modal */}
            {isProfileOpen && (
              <div
                style={{
                  height: "125vh",
                  overflowY: "scroll",
                  scrollbarWidth: "none",
                }}
              >
                <Profile
                  fetchGoals={fetchGoals}
                  setIsZoneOpen={setIsZoneOpen}
                  setfetchDaysoptionList={setfetchDaysoptionList}
                  getDaysoptionList={getDaysoptionList}
                  setFormGoalValue={setFormGoalValue}
                  formGoalValue={formGoalValue}
                  setUserDetails={setUserDetails}
                  getUsersProfile={getUsersProfile}
                  handleOnSubmitForm={handleOnSubmitForm}
                  usersDetails={usersDetails}
                  setIsEditProfile={setIsEditProfile}
                  isEditProfile={isEditProfile}
                  assignedCocahId={assignedCocahId}
                  formik={formik}
                />
              </div>
            )}
            {calendarModal && (
              <WorkoutCalender
                setCopyWorkoutData={setCopyWorkoutData}
                CopyWorkoutData={CopyWorkoutData}
                setCalendarModal={setCalendarModal}
                setIsZoneOpen={setIsZoneOpen}
                formGoalValue={formGoalValue}
                draggedEvent={draggedEvent}
                setDate={setDate}
                dateForworkout={dateForworkout}
                setIsProfileOpen={setIsProfileOpen}
                setWorkoutID={setWorkoutID}
                setAllevents={setAllevents}
                workoutID={workoutID}
                getAllWorkouts={getAllWorkouts}
                assignedCocahId={assignedCocahId}
                setworkoutformdrawerState={setworkoutformdrawerState}
                workoutformdrawerState={workoutformdrawerState}
                calendarModal={calendarModal}
                eventsData={allEvents}
                draggableEl={draggableEl}
                athleteTab={athleteTab}
              />
            )}
            {isEnrolledChallengesOpen?.isOpen && (
              <div
                style={{
                  height: "125vh",
                  overflowY: "scroll",
                  scrollbarWidth: "none",
                  borderLeft: "2px solid white",
                }}
              >
                <AssignedUserChallneges
                  isEnrolledChallengesOpen={isEnrolledChallengesOpen}
                />
              </div>
            )}

            {isZoneOpen?.isOpen && <AtheleZone isZoneOpen={isZoneOpen} />}
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default RunningProgram;
