import {
    Chip,
    FormControl,
    FormLabel,
    Grid,
    MenuItem,
    OutlinedInput,
    Select,
    TextField,
  } from "@mui/material";
  import { Button, Modal } from "antd";
  import React, { useEffect, useState } from "react";
  import {
    CreateLevels,
    CreatePrograms,
    CreateZonesClasification,
    createAssesmentdata,
    createSubSugmentdata,
    createSugmentdata,
    createsuSegmentReadnessdata,
    getAllAssesmentData,
    getAllPrograms,
    getAllSegmentReadnessData,
    getAllSugmentData,
    getAlllevels,
    updateAssesmentdata,
    updateLevel,
    updatePrograms,
    updateSubSugmentdata,
    updateSugmentdata,
    updateZonesClasification,
    updatesuSegmentReadnessdata,
    weeklyFeedDataPattern,
    weeklyFeedDataProgram,
  } from "../../API/api-endpoint";
  import { useFormik } from "formik";
  import Swal from "sweetalert2";
  import SlickCarousel from "../../pages/SlickCarousel";
  const scoreData = [1, 2, 3, 4, 5];
  const CreateSubSegemntReadness = ({
    fetchReport,
    setShowAssesmentModal,
    showAssesmentModal,
    editData,
    setEditData,
  }) => {
    const [sugmentList, setSugmentList] = useState([]);
    console.log("editData", editData);
    const formik = useFormik({
      initialValues: {
        name: "",
        segment_id: "",
        measuring_factor: "",
      },
      validate: (values) => {
        const errors = {};
        if (!values.segment_id) {
          errors.segment_id = "Segment name is required";
        }
        if (!values.name) {
          errors.name = "Name is required";
        }
        if (!values.measuring_factor) {
          errors.measuring_factor = "Measuring factor is required";
        }
        return errors;
      },
      // validationSchema: {},
      onSubmit: (values, { resetForm }) => {
        handleSubmitAssesmentForm(values, resetForm);
      },
    });
    console.log("formik", formik?.values);
    const sugmentData = async () => {
      const response = await getAllSegmentReadnessData();
      console.log("response", response);
      setSugmentList(response);
    };
    useEffect(() => {
      sugmentData();
    }, []);
  
    const handleSubmitAssesmentForm = async (data, resetForm) => {
      let response = "";
      if (editData?.id) {
        response = await updatesuSegmentReadnessdata(data);
      } else {
        response = await createsuSegmentReadnessdata(data);
      }
      if (response?.status) {
        Swal.fire({
          title: "Success",
          text: response.message,
          icon: "success",
        });
        setShowAssesmentModal(false);
        fetchReport();
        resetForm();
        setEditData({});
        formik?.setValues({ name: "", measuring_factor: "", metric: "" });
      } else {
        Swal.fire({
          title: "Error",
          text: response.message,
          icon: "error",
        });
      }
      console.log("response", response);
    };
    useEffect(() => {
      if (editData?.id) {
        const { srID, ...data } = editData;
        console.log("data", data);
        formik?.setValues(data);
        formik?.setFieldValue("metric", data?.metric ? data?.metric : "");
      } else {
      }
    }, [editData?.id]);
  
    return (
      <Modal
        width={1200}
        open={showAssesmentModal}
        onCancel={() => {
          setShowAssesmentModal(false);
          setEditData({});
          formik.resetForm();
          formik?.setValues({ name: "", measuring_factor: "", metric: "" });
        }}
        footer={
          <div></div>
          //   loading={isLoading}
        }
      >
        <div className="headingCont">
          <span className="heading">{editData?.id ? "Edit " : "Create"}</span>{" "}
          <span className="orange heading">Sub-Segment Readiness</span>
        </div>
        {/* <h1>{editData ? editData.challengeId : values.challengeId}</h1> */}
        <div className="parentCont">
          <form className="form1" onSubmit={formik.handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={11}>
                <FormLabel>Sub Segment Name<span className="text-[red]">*</span></FormLabel>
  
                <TextField
                  fullWidth
                  size="small"
                  select
                  name="segment_id"
                  value={
                    formik?.values?.segment_id ? formik?.values?.segment_id : ""
                  }
                  onChange={formik.handleChange}
                  error={formik.touched.segment_id && formik.errors.segment_id}
                  helperText={
                    formik.touched.segment_id && formik.errors.segment_id
                  }
                  id="form-layouts-separator-select"
                  labelId="form-layouts-separator-select-label"
                  input={<OutlinedInput id="select-multiple-language" />}
                >
                  <MenuItem value={""} disabled>
                    Select Name
                  </MenuItem>
                  {sugmentList?.map((value, index) => {
                    return <MenuItem value={value?.id}>{value?.name}</MenuItem>;
                  })}
                </TextField>
              </Grid>
              <Grid item xs={12} sm={11}>
                <FormLabel>Name<span className="text-[red]">*</span></FormLabel>
  
                <TextField
                  fullWidth
                  placeholder="Name"
                  size="small"
                  type="text"
                  name="name"
                  value={formik?.values?.name}
                  onChange={formik.handleChange}
                  error={formik.touched.name && formik.errors.name}
                  helperText={formik.touched.name && formik.errors.name}
                />
              </Grid>
              <Grid item xs={12} sm={11}>
                <FormLabel>Measuring Factor<span className="text-[red]">*</span></FormLabel>
  
                <TextField
                  fullWidth
                  placeholder="Measuring factor"
                  size="small"
                  type="text"
                  name="measuring_factor"
                  value={formik?.values?.measuring_factor}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.measuring_factor &&
                    formik.errors.measuring_factor
                  }
                  helperText={
                    formik.touched.measuring_factor &&
                    formik.errors.measuring_factor
                  }
                />
              </Grid>
              <Grid item xs={12} sm={11}>
                <FormLabel>Metric</FormLabel>
  
                <TextField
                  fullWidth
                  placeholder="Metric"
                  size="small"
                  type="text"
                  name="metric"
                  value={formik?.values?.metric}
                  onChange={formik.handleChange}
                  error={formik.touched.metric && formik.errors.metric}
                  helperText={formik.touched.metric && formik.errors.metric}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Button
                  className="btn"
                  key="submit"
                  type="primary"
                  onClick={() => formik.handleSubmit()}
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          </form>
          <div className="slick-container">
            <SlickCarousel />
          </div>
        </div>
      </Modal>
    );
  };
  export default CreateSubSegemntReadness;
  