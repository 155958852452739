import {
  Button,
  FormControl,
  InputLabel,
  Select,
  TextField,
  Input,
  Chip,
  MenuItem,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import AddIcon from "@mui/icons-material/Add";
import { useSelector, useDispatch } from "react-redux";
import {
  getTagsFromDb,
  handleEditTrainingBlockDialogState,
} from "../../store/slices/MultiTrainingBlocksSlice";
import {
  createTrainingBlockLibrary,
  updateTrainingBlockLibrary,
} from "../../API/api-endpoint";
import Swal from "sweetalert2";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const EditTrainingBlockLibrary = (props) => {
  const { setisLibraryUpdated } = props;
  const dispatch = useDispatch();
  const [values, setValues] = useState({
    library_name: "",
    tags: [],
  });

  let isOpen = useSelector(
    (state) =>
      state.MultiTrainingBlocksSlice.isEditTrainingBlockLibraryDialogisOpen
  );
  let Library = useSelector(
    (state) => state.MultiTrainingBlocksSlice.currentSelectedTrainingLibrary
  );
  let tagList = useSelector((state) => state.MultiTrainingBlocksSlice.tags);
  console.log(Library);

  useEffect(() => {
    if (Library !== null) {
      let Tags = Library["tags"].split(",");
      setValues({
        library_name: Library["training-block-library-name"],
        tags: Tags,
      });
    }
  }, [Library]);

  useEffect(() => {
    dispatch(getTagsFromDb());
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    const trimmedValue = value.trimStart(); // Trim white space only at the beginning
  
    setValues({
      ...values,
      [name]: trimmedValue,
    });
  };

  const handleClose = () => {
    dispatch(
      handleEditTrainingBlockDialogState({ bool: false, library: null })
    );
  };

  const handleSubmit = async () => {
    console.log(values); //updateTrainingBlockLibrary
    if (values?.library_name && values?.tags?.length>0) {
      let result = await updateTrainingBlockLibrary(
        values,
        Library["training-block-library-id"]
      );
      if (result.message == "Training block library updated successfully") {
        handleClose();
        setisLibraryUpdated(true);
        Swal.fire({
          title: "Success",
          text: result.message,
          icon: "success",
        });
      }
      setTimeout(() => {
        setisLibraryUpdated(false);
      }, 300);
    }else{
      alert("Please fill all details")
    }
    
  };
  return (
    <div>
      <Dialog
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Edit Training Blocks Library"}
        </DialogTitle>
        <DialogContent>
          <TextField
            style={{ marginTop: "30px" }}
            className="activity-input"
            id="outlined-basic"
            label="Library Name"
            variant="outlined"
            name="library_name"
            value={values.library_name}
            onChange={handleInputChange}
          />
          <FormControl style={{ width: 400, marginTop: "20px" }}>
            <InputLabel id="demo-mutiple-checkbox-label">Tags</InputLabel>
            <Select
              labelId="demo-mutiple-checkbox-label"
              id="demo-mutiple-checkbox"
              multiple
              name="tags"
              value={values?.tags || []}
              onChange={(e)=>{
                setValues({
                  ...values,
                  tags: e.target.value,
                });
              }}
              input={<Input />}
              renderValue={(selected) => (
                <div>
                  {selected?.map((value) => (
                    <Chip
                      key={value}
                      label={value}
                      // className={classes.chip}
                    />
                  ))}
                </div>
              )}
              MenuProps={MenuProps}
              //style={{ minWidth: "400px" }}
            >
              {tagList?.map((tag) => (
                <MenuItem
                  key={tag["tag-cloud-name"]}
                  value={tag["tag-cloud-name"]}
                  // style={getStyles(name, personName, theme)}
                >
                  {tag["tag-cloud-name"]}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            onClick={handleSubmit}
            variant="contained"
            style={{ backgroundColor: "#2563EB", color: "white" }}
          >
            Edit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default EditTrainingBlockLibrary;
