import React from "react";
import { Bar, Line, Pie } from "react-chartjs-2";

function BarChart(props) {
  return (
    <div className={props?.showWidth ?`w-[${props?.showWidth}]`:"w-[40vw]"}>
    <Bar
    data={props.chartData}
    options={{
      title: {
        display: props.displayTitle,
        text: "Largest Cities in Massachusetts",
        fontSize: 25,
      },
      legend: {
        display: props.displayLegend,
        position: props.legendPosition,
        labels: {
          fontColor: "#000",
        },
      },
    }}
  />
    </div>
  );
}

BarChart.defaultProps = {
  displayTitle: true,
  displayLegend: false,
  legendPosition: "bottom",
};


export default BarChart
