import {
  Button,
  FormControl,
  InputLabel,
  Select,
  TextField,
  Input,
  Chip,
  MenuItem,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useSelector, useDispatch } from "react-redux";
import {
  getAllTraningBlocksbyLibraryId,
  getTagsFromDb,
  handleCreateTraningBlockDialogState,
} from "../../store/slices/MultiTrainingBlocksSlice";
import { createTrainingBlock } from "../../API/api-endpoint";
import { showError, showSuccess } from "../../components/Messages";

const initialValues = {
  block_name: "",
  block_description: "",
  tags: [],
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const AddTrainingBlock = () => {
  const dispatch = useDispatch();
  const [values, setValues] = useState(initialValues);

  let tagList = useSelector((state) => state.MultiTrainingBlocksSlice.tags);
  let isOpen = useSelector(
    (state) => state.MultiTrainingBlocksSlice.isCreateTrainingBlockDialogisOpen
  );
  let LibraryID = useSelector(
    (state) => state.MultiTrainingBlocksSlice.currentSelectedTrainingLibraryID
  );

  useEffect(() => {
    dispatch(getTagsFromDb());
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    const trimmedValue = value.trimStart();
    setValues({
      ...values,
      [name]: trimmedValue,
    });
  };

  const handleClose = () => {
    dispatch(
      handleCreateTraningBlockDialogState({ bool: false, libraryID: null })
    );
  };
  const handleSubmit = async () => {
    if (values?.block_name && values?.block_description && values?.tags?.length>0) {
      let result = await createTrainingBlock(values, LibraryID);
      if (result.message == "Training block created successfully") {
      showSuccess(result.message)
      dispatch(
        getAllTraningBlocksbyLibraryId(LibraryID)
      );
        handleClose();
        setValues(initialValues);
      }else{
        showError(result.message)
      }
    }else{
      alert("Please fill all details")
    }
   
  };
  return (
    <div>
      <Dialog
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Create Training Blocks"}
        </DialogTitle>
        <DialogContent>
          <TextField
            style={{ marginTop: "30px" }}
            className="activity-input"
            id="block_name"
            label="Block Name"
            variant="outlined"
            name="block_name"
            value={values.block_name}
            onChange={handleInputChange}
            fullWidth
          />

          <TextField
            id="block_description"
            name="block_description"
            label="Description"
            className="description-Input"
            multiline
            rows={4}
            variant="outlined"
            fullWidth
            value={values.block_description}
            onChange={handleInputChange}
            style={{ marginTop: "20px" }}
          />
          <FormControl style={{ width: 400 }}>
            <InputLabel
              id="demo-mutiple-checkbox-label"
              style={{ marginTop: "20px" }}
            >
              Tags
            </InputLabel>
            <Select
              fullWidth
              labelId="demo-mutiple-checkbox-label"
              id="demo-mutiple-checkbox"
              multiple
              name="tags"
              defaultValue={values?.tags || []}
              onChange={(e)=>{
                setValues({
                  ...values,
                  tags: e.target.value,
                });
              }}
              input={<Input />}
              style={{ marginTop: "30px" }}
              renderValue={(selected) => (
                <div>
                  {selected?.map((value) => (
                    <Chip
                      key={value}
                      label={value}
                      // className={classes.chip}
                    />
                  ))}
                </div>
              )}
              MenuProps={MenuProps}
              //style={{ minWidth: "400px" }}
            >
              {tagList?.map((tag) => (
                <MenuItem
                  key={tag["tag-cloud-name"]}
                  value={tag["tag-cloud-name"]}
                  // style={getStyles(name, personName, theme)}
                >
                  {tag["tag-cloud-name"]}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            onClick={handleSubmit}
            variant="contained"
            style={{ backgroundColor: "#2563EB", color: "white" }}
          >
            Create
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default AddTrainingBlock;
