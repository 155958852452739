import {FormLabel, Grid, MenuItem, OutlinedInput, TextField } from '@mui/material';
import { Button, Modal } from 'antd'
import React, { useEffect, useState } from 'react'
import {createOptionReadnessdata, createOptiondata, getAllQuestionData, getAllsubSegmentReadnessData, updateOptionReadnessdata, updateOptiondata } from '../../API/api-endpoint';
import { useFormik } from 'formik';
import Swal from 'sweetalert2';
import SlickCarousel from "../../pages/SlickCarousel";
const CraeteOptionReadness = ({ fetchReport, setShowAssesmentModal, showAssesmentModal, editData, setEditData }) => {
    const [questionList, setQuestionList] = useState([]);
    console.log("editData", questionList);
    const formik = useFormik({
        initialValues: {
            points: "",
            subsegment_id: "",
            option_text: ""
        }, validate: (values) => {
            const errors = {};
            if (!values.subsegment_id) {
                errors.subsegment_id = "Sub segment is required";
            }
            if (!values.points) {
                errors.points = "Points is required";
            }
            if (!values.option_text) {
                errors.option_text = "Text is required";
            }
            return errors;
        },
        // validationSchema: {},
        onSubmit: (values, { resetForm }) => {
            handleSubmitAssesmentForm(values, resetForm)

        },
    });
    console.log("formik", formik?.values);
    const QuestionData = async () => {
        const response = await getAllsubSegmentReadnessData()
        console.log("response", response);
        setQuestionList(response)
    }
    useEffect(() => {
        QuestionData()
    }, [])

    const handleSubmitAssesmentForm = async (data, resetForm) => {
        let response = ""
        if (editData?.id) {

            response = await updateOptionReadnessdata(data)

        } else {
            response = await createOptionReadnessdata(data)

        }
        if (response?.status) {
            Swal.fire({
                title: "Success",
                text: response.message,
                icon: "success",
            });
            setShowAssesmentModal(false)
            fetchReport()
            formik.resetForm()
            setEditData({})
                formik?.setValues({ points: "", option_text:""})
        } else {
            Swal.fire({
                title: "Error",
                text: response.message,
                icon: "error",
            });
        }
        console.log("response", response);
    }
    useEffect(() => {
        if (editData?.id) {
            const { srID, ...data } = editData
            console.log("data", data);

            formik?.setValues(data)
            formik?.setFieldValue("comments", data?.comments ? data?.comments : "")

        } else {
            setEditData({})
        }
    }, [editData?.id])


    return (
        <Modal
      width={1200}
      open={showAssesmentModal}
      onCancel={() => {
        setShowAssesmentModal(false);
        setEditData({});
        formik.resetForm();
        formik?.setValues({ points: "", option_text: "" });
      }}
      footer={
        <div></div>
        //   loading={isLoading}
      }
    >
      <div className="headingCont">
        <span className="heading">{editData?.id ? "Edit " : "Create"}</span>{" "}
        <span className="orange heading">Options</span>
      </div>

      <div className="parentCont">
        <form className="form1" onSubmit={formik.handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={11}>
              <FormLabel>Sub Segment Name<span className="text-[red]">*</span></FormLabel>

              <TextField
                fullWidth
                size="small"
                select
                SelectProps={{
                  MenuProps: {
                    PaperProps: {
                      style: {
                         scrollbarColor:"#E67E22 white",
                         scrollbarWidth:"thin"
                      },
                    },
                  },
                }}
                name="subsegment_id"
                value={
                  formik?.values?.subsegment_id
                    ? formik?.values?.subsegment_id
                    : ""
                }
                onChange={formik.handleChange}
                error={
                  formik.touched.subsegment_id && formik.errors.subsegment_id
                }
                helperText={
                  formik.touched.subsegment_id && formik.errors.subsegment_id
                }
                id="form-layouts-separator-select"
                labelId="form-layouts-separator-select-label"
                input={<OutlinedInput id="select-multiple-language" />}
              >
                <MenuItem value={""} disabled>
                  Select Name
                </MenuItem>
                {questionList?.map((value, index) => {
                  return <MenuItem value={value?.id}>{value?.name}</MenuItem>;
                })}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={11}>
              <FormLabel>Points<span className="text-[red]">*</span></FormLabel>

              <TextField
                fullWidth
                placeholder="points"
                size="small"
                type="text"
                name="points"
                value={formik?.values?.points}
                onChange={formik.handleChange}
                error={formik.touched.points && formik.errors.points}
                helperText={formik.touched.points && formik.errors.points}
              />
            </Grid>
            <Grid item xs={12} sm={11}>
              <FormLabel>Option Text<span className="text-[red]">*</span></FormLabel>

              <TextField
                fullWidth
                placeholder="option text"
                size="small"
                type="text"
                name="option_text"
                value={formik?.values?.option_text}
                onChange={formik.handleChange}
                error={formik.touched.option_text && formik.errors.option_text}
                helperText={
                  formik.touched.option_text && formik.errors.option_text
                }
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Button
                className="btn"
                key="submit"
                type="primary"
                onClick={() => formik.handleSubmit()}
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </form>

        <div className="slick-container">
          <SlickCarousel />
        </div>
      </div>
    </Modal>
  );
};
export default CraeteOptionReadness;
