import {
    Chip,
    FormControl,
    FormLabel,
    Grid,
    MenuItem,
    OutlinedInput,
    Select,
    TextField,
  } from "@mui/material";
  import { Button, Modal } from "antd";
  import React, { useEffect, useState } from "react";
  import {
    CreateLevels,
    CreatePrograms,
    CreateZonesClasification,
    createActivity,
    createRaceCalculation,
    getAllActivityData,
    getAllPrograms,
    getAlllevels,
    updateActivity,
    updateLevel,
    updatePrograms,
    updateRaceCalculation,
    updateZonesClasification,
    weeklyFeedDataPattern,
    weeklyFeedDataProgram,
  } from "../../API/api-endpoint";
  import { useFormik } from "formik";
  import Swal from "sweetalert2";
  import SlickCarousel from "../../pages/SlickCarousel";
  const scoreData = [1, 2, 3, 4, 5];
  const CreateRaceCalculation = ({
    fetchReport,
    setShowAssesmentModal,
    showAssesmentModal,
    editData,
    setEditData,
  }) => {
    const [programList, setProgramList] = useState([]);
    const formik = useFormik({
      initialValues: {
        goal_name: "",
        quota: "",
        type: "",
      },
      validate: (values) => {
        const errors = {};
        if (!values.goal_name) {
          errors.goal_name = "Name is required";
        }
        if (!values.quota) {
          errors.quota = "Distance is required";
        }
        if (!values.type) {
          errors.type = "Activity type is required";
        }
        return errors;
      },
      // validationSchema: {},
      onSubmit: (values, { resetForm }) => {
        handleSubmitAssesmentForm(values, resetForm);
      },
    });
    console.log("formik", formik?.values, formik?.errors, formik?.touched);
  
    const handleSubmitAssesmentForm = async (data, resetForm) => {
      let response = "";
      if (editData?.id) {
        response = await updateRaceCalculation(data);
      } else {
        response = await createRaceCalculation(data);
      }
      if (response?.status) {
        Swal.fire({
          title: "Success",
          text: response.message,
          icon: "success",
        });
        setEditData({});
        setShowAssesmentModal(false);
        fetchReport();
        resetForm();
        formik?.setValues({ goal_name: "", quota: "" });
      } else {
        Swal.fire({
          title: "Error",
          text: response.message,
          icon: "error",
        });
      }
      console.log("response", response);
    };
    useEffect(() => {
      if (editData?.id) {
        const { srID, ...data } = editData;
        console.log("data", data);
        formik?.setValues(data);
      } else {
        setEditData({});
      }
    }, [editData?.id]);
    const getAllProgramsData = async () => {
      const response = await getAllActivityData();
      console.log("response", response);
      setProgramList(response?.rows);
    };
    useEffect(() => {
      getAllProgramsData();
    }, []);
    return (
      <Modal
        width={1200}
        open={showAssesmentModal}
        onCancel={() => {
          setShowAssesmentModal(false);
          setEditData({});
          formik.resetForm();
          formik?.setValues({ goal_name: "", quota: "" });
        }}
        footer={
          <div></div>
          //   loading={isLoading}
        }
      >
        <div className="headingCont">
          <span className="heading">{editData?.id ? "Edit " : "Create"}</span>{" "}
          <span className="orange heading">Race Calculation</span>
        </div>
        {/* <h1>{editData ? editData.challengeId : values.challengeId}</h1> */}
        <div className="parentCont">
          <form className="form1" onSubmit={formik.handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={11}>
                <FormLabel>Goal Name<span className="text-[red]">*</span></FormLabel>
  
                <TextField
                  fullWidth
                  placeholder="Goal name:"
                  size="small"
                  type="text"
                  name="goal_name"
                  value={formik?.values?.goal_name}
                  onChange={formik.handleChange}
                  error={formik.touched.goal_name && formik.errors.goal_name}
                  helperText={formik.touched.goal_name && formik.errors.goal_name}
                />
              </Grid>
              <Grid item xs={12} sm={11}>
                <FormLabel>Distance<span className="text-[red]">*</span></FormLabel>
  
                <TextField
                  fullWidth
                  placeholder="Distance"
                  size="small"
                  type="number"
                  name="quota"
                  value={formik?.values?.quota}
                  onChange={formik.handleChange}
                  error={formik.touched.quota && formik.errors.quota}
                  helperText={formik.touched.quota && formik.errors.quota}
                />
              </Grid>
              <Grid item xs={12} sm={11}>
                <FormLabel>Activity Type<span className="text-[red]">*</span></FormLabel>
  
                <TextField
                  fullWidth
                  size="small"
                  select
                  name="type"
                  value={formik?.values?.type ? formik?.values?.type : ""}
                  onChange={formik.handleChange}
                  error={formik.touched.type && formik.errors.type}
                  helperText={formik.touched.type && formik.errors.type}
                  id="form-layouts-separator-select"
                  labelId="form-layouts-separator-select-label"
                  input={<OutlinedInput id="select-multiple-language" />}
                >
                  <MenuItem value={""} disabled>
                    Select Activity
                  </MenuItem>
                  <MenuItem value={"running"}>Running</MenuItem>
                  <MenuItem value={"cycling"}>Cycling</MenuItem>
                  <MenuItem value={"swimming"}>Swimminng</MenuItem>
                  {/*
                          
                           {programList?.map((value, index) => {
                                  return (
                                      <MenuItem value={value?.id}>
                                          {value?.activity_name}
                                      </MenuItem>
                                  );
                              })}
                          */}
                </TextField>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Button
                  className="btn"
                  key="submit"
                  type="primary"
                  onClick={() => formik.handleSubmit()}
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          </form>
          <div className="slick-container">
            <SlickCarousel />
          </div>
        </div>
      </Modal>
    );
  };
  export default CreateRaceCalculation;
  