import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Untitled from '../Images/Running.png';
import Untitled2 from '../Images/Cycling.png';
import Untitled3 from '../Images/Swimming.png';

const SlickCarousel = ({hideunhide}) => {
  const settings = {
    
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    dots: true,
  };

  return (
    <Slider {...settings} className={` slickCar ${hideunhide}`} >
      <div style={{outline: 'none'}}>
        <img style={{outline: 'none'}} src={Untitled} className="p-3 d-block img" alt="Slide 1" />
        <h1 className='text-center mb-3 mr-2 ml-2'>Running Enhances cardiovascular health, burns calories, and boosts mental well-being through the release of endorphins.</h1>
      </div>
      <div style={{outline: 'none'}}>
        <img style={{outline: 'none'}} src={Untitled2} className="p-3 d-block img" alt="Slide 2" />
        <h1 className='text-center mb-3 mr-2 ml-2'>Cycling improves strength, balance and coordination. It may also help to prevent falls and fractures.</h1>
      </div>
      <div style={{outline: 'none'}}>
        <img style={{outline: 'none'}} src={Untitled3} className="p-3 d-block img" alt="Slide 3" />
        <h1 className='text-center mb-3 mr-2 ml-2'>Builds endurance, muscle strength and cardiovascular fitness. Tones muscles and builds strength. provides an all of your muscles are used during swimming.</h1>
      </div>
    </Slider>
  );
};

export default SlickCarousel;
