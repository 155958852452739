import { Accordion } from "@mantine/core";
import {
  IconAntennaBars1,
  IconFilter,
  IconPlus,
  IconSearch,
  IconX,
} from "@tabler/icons";
import { Dropdown, Space } from "antd";
import React, { useEffect, useState } from "react";

const MasterSidebar = ({
  actions,
  setIsOpenWoerkOut,
  handleModalReveal,
  setSelectedLibraryItem,
  setIsOpenHandleWoerkOut,
}) => {
  const handleManageWorkouts = () => {
    setIsOpenHandleWoerkOut(true);
    setIsOpenWoerkOut("showTable")
  };

  const items = [
    {
      label: <a href="#test">Copy workout(s)</a>,
      key: "0",
    },
    {
      label: "Cut workout(s)",
      key: "1",
    },
    {
      label: "Paste workout(s)",
      key: "3",
    },
    {
      label: "Edit workout(s)",
      key: "4",
    },
    {
      label: "Delete workout(s)",
      key: "5",
    },
    {
      label: (
        <div onClick={() => handleManageWorkouts()}>Manage workout(s)</div>
      ),
      key: "5",
    },
  ];

  const [library, setLibrary] = useState([]);
  console.log(library);
  useEffect(() => {
    // let apiUrl = `https://app.yoska.in/kona-coach/api/coaches/340/workoutLibrary/?fullDetails=true`;
    // const bearerToken = "qmmvofrilpsj59rctkn0ivseuil1jji5";

    // fetch(apiUrl, {
    //   method: "GET",
    //   headers: {
    //     Authorization: `Bearer ${bearerToken}`,
    //   },
    // })
    //   .then((response) => response.json())
    //   .then((data) => {
    //     data && console.log("Workout library", data);
    //     setLibrary(data);
    //   })
    //   .catch((error) => {
    //     console.error(error);
    //   });
    setLibrary([
      {
        id: 53,
        description: "Add a Training Volume",
        isActive: true,
        shares: [
          {
            id: 92,
            sharedDate: "2022-02-04T18:59:12Z",
            sharedWith: {
              id: 6738,
            },
            sharedByCoachId: 340,
          },
        ],
        workouts: [],
      },
    ]);
  }, []);

  const handleDragStart = (e, plan) => {
    // console.log('Drag started....')
    e.dataTransfer.setData("workoutId", plan.id);
    e.dataTransfer.setData("workoutName", plan.name);
    e.dataTransfer.setData("workoutDesc", plan.description);
    e.dataTransfer.setData("workoutDistance", plan.distance);
    e.dataTransfer.setData("workoutDuration", plan.duration);
  };

  return (
    <>
      <div className="flex items-center justify-between">
        <div className="flex items-center justify-between space-x-20">
          <div className="flex space-x-5">
            <div>
              <IconPlus
                size={24}
                onClick={handleModalReveal}
                color="dodgerblue"
                className="cursor-pointer"
              />
            </div>
            <div>
              <IconSearch size={24} color="dodgerblue" />
            </div>
            <div>
              <IconFilter size={24} color="dodgerblue" />
            </div>
          </div>
          <div>
            <IconX
              size={24}
              className="cursor-pointer"
              color="dodgerblue"
              onClick={() => setIsOpenHandleWoerkOut(false)}
            />
          </div>
        </div>
      </div>
      <div className="flex flex-col items-start h-[70vh] mt-4 overflow-y-scroll">
        <Accordion defaultValue="customization">
          {library.length > 0 ? (
            library.map((item) => (
              <Accordion.Item
                value={item.description}
                key={item.id}
                onClick={(e) => {
                  e.preventDefault();
                  handleManageWorkouts(); // Save the selected library item's data
                }}
              >
                <div className="flex items-center">
                  <Accordion.Control>
                    {item.description}
                    <br />
                    <span className="text-sm text-slate-400 font-medium">
                      Total Workouts ({item.workouts.length})
                    </span>
                  </Accordion.Control>
                  {/* <Dropdown
                    menu={{
                      items,
                    }}
                    trigger={["click"]}
                  >
                    <div>
                      <Space>
                        <IconAntennaBars1
                          style={{ transform: "rotate(90deg)" }}
                        />
                      </Space>
                    </div>
                  </Dropdown> */}
                </div>
                {/* <Accordion.Panel>
                  {item.workouts.map((workout) => (
                    <div
                      key={workout.id}
                      draggable
                      onDragStart={(e) => handleDragStart(e, workout)}
                      className="flex flex-col gap-y-1 items-start mb-2 cursor-pointer bg-[rgb(230 126 34 / 20%)] p-2"
                    >
                      <p className="leading-tight">{workout.name}</p>
                      <p className="text-sm ">
                        {workout.distance} | {workout.duration}
                      </p>
                    </div>
                  ))}
                </Accordion.Panel> */}
              </Accordion.Item>
            ))
          ) : (
            <div>Loading.....</div>
          )}
        </Accordion>
      </div>
    </>
  );
};

export default MasterSidebar;
