import { Box, Paper, Typography } from "@mui/material";
import React from "react";
import "../../styles/chatlanding.css";
import "./../../styles/newchat/newChat.css";
import { useSelector } from "react-redux";
import NavGroupInfo from "../../components/navbar/NavGroupInfo";
import GroupMembers from "../../components/group/GroupMembers";
import { db, get, ref, runTransaction } from "../../../../API/firebase.config";
import Swal from "sweetalert2";

export default function GroupInfo() {
  const paper = {
    padding: "0rem",
    minHeight: "87vh",
    maxHeight: "87vh",
    maxWidth: "27rem",
    marginTop:"90px",
    overflowY : "scroll"
  };

  const { currentUser } = useSelector((state) => state.auth);
  const { openedGroupInfo } = useSelector((state) => state.group);
  console.log("openedGroupInfo", openedGroupInfo);

  const handleExitGroup = async (groupId, userId, isAdmin) => {
    const groupMembersRef = ref(db, `groups/${groupId}/groupMembers`);

    try {
      const groupMembersSnapshot = await get(groupMembersRef);
      const groupMembers = groupMembersSnapshot.val();

      if (Array.isArray(groupMembers) && groupMembers.includes(userId)) {
        const updatedGroupMembers = groupMembers.filter(
          (member) => member !== userId
        );

        await runTransaction(groupMembersRef, () => updatedGroupMembers);
        Swal.fire({
          title: "Success",
          text: !isAdmin ? "Group Exited" : "User removed from group",
          icon: "success",
        });
        console.log("User removed from the group successfully");
      } else {
        Swal.fire({
          title: "Error",
          text: !isAdmin
            ? "You are no longer participant!"
            : "This user is no longer participant",
          icon: "error",
        });
        console.log("User is not a member of the group.");
      }
    } catch (error) {
      console.error("Error removing user from group:", error);
      throw error;
    }
  };
  return (
    <Box
      sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
    >
      <Paper sx={{ flexGrow: 1 }} elevation={10} style={paper} className="chatPaper">
        <NavGroupInfo
          backButtonPath={"/group-chat/creator"}
          groupName={openedGroupInfo.groupName}
        />

        <Box
          sx={{
            // backgroundColor: "#FDFBE7",
            padding: "8px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            style={{ borderRadius: "100%",width:"250px",height:"250px" }}
            src={openedGroupInfo?.groupProfileURL ? openedGroupInfo?.groupProfileURL : "https://i.ibb.co/5xCF7vx/u-https-spng-pngfind-com-pngs-s-610-6104451-image-placeholder-png-user-profile-placeholder-image-png.jpg"}
            alt=""
          />
        </Box>
        <h5
          style={{ textAlign: "center", fontSize: "16px", marginTop: "11px" }}
        >
          {openedGroupInfo.groupName}
        </h5>

        <Box>
          <Typography  className="select-buddies-text">
            Total Group Members : {openedGroupInfo?.groupMembers?.length}
          </Typography>
        </Box>

        <Box>
          <Typography mt={-1} className="select-buddies-text">
            Group Created at : {openedGroupInfo?.createdAt ? 
        `${new Date(openedGroupInfo?.createdAt).toLocaleDateString('en-GB', {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
        })} ${new Date(openedGroupInfo?.createdAt).toLocaleTimeString('en-US', {
          hour: '2-digit',
          minute: '2-digit',
        })}` 
        : ''}
          </Typography>
        </Box>

        {openedGroupInfo?.groupMembers?.map((item, index) => (
          <GroupMembers
            isAdmin={openedGroupInfo.createdById === currentUser.uid}
            key={index}
            item={item}
            handleExitGroup={handleExitGroup}
            openedGroupId={openedGroupInfo.groupId}
            currentUser={currentUser}
            index={index}
          />
        ))}
      </Paper>
    </Box>
  );
}
