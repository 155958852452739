import React, { useContext, useEffect, useState } from "react";
import { Form, Input, DatePicker, Modal, Table, Tag,Button } from "antd";
import {  Checkbox, FormControlLabel, FormLabel, Grid, MenuItem, OutlinedInput, Radio, RadioGroup, Select, TextField } from "@mui/material";
import Swal from "sweetalert2";
import { useFormik } from "formik";


const DeleteModal = ({workoutName,deleteModal,setDeleteModal,isLoading,allChalengesData,modalName,setIsAssignUserModalOpen,deleteWorkoutFromLibrary}) => {
   
 
  return (
    <Modal
            width={600}
            // title={editData ? "Edit Challenge" : "Add Challenges"}
            open={deleteModal}
            centered
            onCancel={()=>setDeleteModal(false)}
            footer={
            <>
            <Button key="submit" type="primary" loading={isLoading} onClick={()=>setDeleteModal(false)}>Cancel</Button>
            <Button key="submit" type="primary" loading={isLoading} onClick={()=>deleteWorkoutFromLibrary()}>Yes</Button>
            </>
            
          }
          >
          <div className="text-xl h-[10vh]"><strong>Are you sure want to delete this {workoutName} ???</strong></div>
            {/* <h1>{editData ? editData.challengeId : values.challengeId}</h1> */}
            
          </Modal>
  )
}

export default DeleteModal


