import {
    FormLabel,
    Grid,
    MenuItem,
    OutlinedInput,
    TextField,
  } from "@mui/material";
  import { Button, Modal, TimePicker } from "antd";
  import React, { useEffect, useState } from "react";
  import {
    createsubspackagedata,
    getAllPrograms,
    getAlllevels,
    getPowerData,
    updatesubspackagedata,
  } from "../../API/api-endpoint";
  import { useFormik } from "formik";
  import Swal from "sweetalert2";
  import { capitalizeFirstLetter, handleTimeChangeSeconds } from "../../utils/Resubale";
  import SlickCarousel from "../../pages/SlickCarousel";
  const CreateSubscription = ({
    fetchReport,
    setShowAssesmentModal,
    showAssesmentModal,
    editData,
    setEditData,
  }) => {
    const [zonePowerList, setZonePowerData] = useState([]);
    const [programList, setProgramList] = useState([]);
    const [allLevelList, setAlllevelList] = useState([]);
  
    console.log("editData", programList);
    const formik = useFormik({
      initialValues: {
        program_id: "",
        name: "",
        duration: "",
        price: "",
        status: true,
      },
      validate: (values) => {
        const errors = {};
        if (!values.program_id) {
          errors.program_id = "Program name is required";
        }
        if (!values.name) {
          errors.name = "Name is required";
        }
        if (!values.duration) {
          errors.duration = "Duration is required";
        }
        if (!values.price) {
          errors.price = "Price is required";
        }
  
        return errors;
      },
      // validationSchema: {},
      onSubmit: (values, { resetForm }) => {
        handleSubmitAssesmentForm(values, resetForm);
      },
    });
    console.log("formik", formik?.values, formik?.errors);
    const QuestionData = async () => {
      const response = await getPowerData();
      console.log("response", response);
      setZonePowerData(response);
    };
    useEffect(() => {
      QuestionData();
      getAllProgramsData();
      getAllLevelsData();
    }, []);
    const getAllProgramsData = async () => {
      const response = await getAllPrograms();
      console.log("response", response);
      setProgramList(response?.rows);
    };
    const getAllLevelsData = async () => {
      const response = await getAlllevels();
      console.log("response", response);
      setAlllevelList(response?.rows);
    };
  
    const handleSubmitAssesmentForm = async (data, resetForm) => {
      let response = "";
      if (editData?.id) {
        response = await updatesubspackagedata(data);
      } else {
        response = await createsubspackagedata(data);
      }
      if (response?.status) {
        Swal.fire({
          title: "Success",
          text: capitalizeFirstLetter(response.message),
          icon: "success",
        });
        setEditData({});
        setShowAssesmentModal(false);
        fetchReport();
        formik.resetForm();
        formik?.setValues({
          program_id: "",
          name: "",
          duration: "",
          price: "",
          status: true,
        });
      } else {
        Swal.fire({
          title: "Error",
          text: response.message,
          icon: "error",
        });
      }
      console.log("response", response);
    };
    useEffect(() => {
      if (editData?.id) {
        const { srID, ...data } = editData;
        formik?.setValues(data);
      }
    }, [editData?.id]);
  
    return (
      <Modal
        width={1200}
        open={showAssesmentModal}
        onCancel={() => {
          setShowAssesmentModal(false);
          formik.resetForm();
          setEditData({});
          formik?.setValues({
            program_id: "",
            name: "",
            duration: "",
            price: "",
            status: true,
          });
        }}
        footer={
          <div></div>
          //   loading={isLoading}
        }
      >
        <div className="headingCont">
          <span className="heading">{editData?.id ? "Edit " : "Create"}</span>{" "}
          <span className="orange heading">Subscription</span>
        </div>
        {/* <h1>{editData ? editData.challengeId : values.challengeId}</h1> */}
        <div className="parentCont">
          <form className="form1" onSubmit={formik.handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={11}>
                <FormLabel>Program Name<span className="text-[red]">*</span></FormLabel>
  
                <TextField
                  fullWidth
                  size="small"
                  select
                  name="program_id"
                  value={formik?.values?.program_id || "placeholder"}
                  onChange={formik.handleChange}
                  error={formik.touched.program_id && formik.errors.program_id}
                  helperText={
                    formik.touched.program_id && formik.errors.program_id
                  }
                  id="form-layouts-separator-select"
                  labelId="form-layouts-separator-select-label"
                  input={<OutlinedInput id="select-multiple-language" />}
                >
                  <MenuItem value={"placeholder"} disabled>
                    Select Program
                  </MenuItem>
                  {programList?.map((value, index) => {
                    return (
                      <MenuItem value={value?.program_id}>
                        {value?.program_name}
                      </MenuItem>
                    );
                  })}
                </TextField>
              </Grid>
              <Grid item xs={12} sm={11}>
                <FormLabel>Name<span className="text-[red]">*</span></FormLabel>
  
                <TextField
                  fullWidth
                  placeholder="Name"
                  size="small"
                  type="text"
                  name="name"
                  value={formik?.values?.name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.name && formik.errors.name}
                  helperText={formik.touched.name && formik.errors.name}
                />
              </Grid>
              <Grid item xs={12} sm={11}>
                <FormLabel>Duration<span className="text-[red]">*</span></FormLabel>
  
                <TextField
                  fullWidth
                  placeholder="Duration (mm)"
                  size="small"
                  type="number"
                  name="duration"
                  value={formik?.values?.duration}
                  onChange={(e) => {
                    const formattedTime = handleTimeChangeSeconds(e);
                    formik.setFieldValue("duration", formattedTime);
                  }}
                  onBlur={formik.handleBlur}
                  error={formik.touched.duration && formik.errors.duration}
                  helperText={formik.touched.duration && formik.errors.duration}
                />
              </Grid>
              <Grid item xs={12} sm={11}>
                <FormLabel>Price<span className="text-[red]">*</span></FormLabel>
  
                <TextField
                  fullWidth
                  placeholder="Price"
                  size="small"
                  type="number"
                  name="price"
                  value={formik?.values?.price}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.price && formik.errors.price}
                  helperText={formik.touched.price && formik.errors.price}
                />
              </Grid>
              <Grid item xs={12} sm={11}>
                <FormLabel>Status</FormLabel>
  
                <TextField
                  fullWidth
                  size="small"
                  select
                  name="status"
                  value={formik?.values?.status}
                  onChange={formik.handleChange}
                  error={formik.touched.status && formik.errors.status}
                  helperText={formik.touched.status && formik.errors.status}
                  id="form-layouts-separator-select"
                  labelId="form-layouts-separator-select-label"
                  input={<OutlinedInput id="select-multiple-language" />}
                >
                  <MenuItem value={true}>Yes</MenuItem>
                  <MenuItem value={"false"}>No</MenuItem>
                </TextField>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Button
                  className="btn"
                  key="submit"
                  type="primary"
                  onClick={() => formik.handleSubmit()}
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          </form>
          <div className="slick-container">
            <SlickCarousel />
          </div>
        </div>
      </Modal>
    );
  };
  export default CreateSubscription;
  