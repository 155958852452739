import { Box, Toolbar, Typography } from '@mui/material'
import React from 'react'
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import '../../styles/navbar.css'
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { clearUsersToCreateGroup } from '../../redux/action/usersAction';

export default function NavForwardMsg({ backButtonPath }) {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const handleGoTo = async () => {
        await dispatch(clearUsersToCreateGroup())
        navigate(backButtonPath)
    }

    return <>
        <Toolbar className="appBar">
            <Box sx={{ display: 'flex' }}>
                <KeyboardBackspaceIcon onClick={handleGoTo} />
                <Typography className='chatTitle' style={{ paddingLeft: "1rem", fontSize: "16px" }}>Select users to forward message</Typography>
            </Box>
            <Box>
                <img style={{ paddingRight: '0.5rem' }} height={20} src="/images/info-icon.png" alt="info" />
            </Box>
        </Toolbar >
    </>
}