import shortid from "shortid";

export const SIDEBAR_ITEM = "BLOCK";
export const ROW = "row";
export const COLUMN = "column";
export const COMPONENT = "component";

export const SIDEBAR_ITEMS = [
  {
    id: shortid.generate(),
    type: SIDEBAR_ITEM,
    component: {
      type: "Cycling",
      content: "Cycling",
    },
    isHidden: false,
  },
  {
    id: shortid.generate(),
    type: SIDEBAR_ITEM,
    component: {
      type: "Running",
      content: "Running",
    },
    isHidden: false,
  },
  {
    id: shortid.generate(),
    type: SIDEBAR_ITEM,
    component: {
      type: "Walking",
      content: "Walking",
    },
    isHidden: false,
  },
  {
    id: shortid.generate(),
    type: SIDEBAR_ITEM,
    component: {
      type: "Jumping",
      content: "Jumping",
    },
    isHidden: false,
  },
  {
    id: shortid.generate(),
    type: SIDEBAR_ITEM,
    component: {
      type: "Swimming",
      content: "Swimming",
    },
    isHidden: false,
  },
];

export const workOutGroup = [
  {
    name: "Training Block 1",
    workouts: [
      {
        id: shortid.generate(),
        type: "sidebarItem",
        isHidden: false,
        component: {
          type: "Cycling",
          content: "Cycling",
        },
      },
      {
        id: shortid.generate(),
        type: "sidebarItem",
        isHidden: false,
        component: {
          type: "Jumping",
          content: "Jumping",
        },
      },
    ],
  },
  {
    name: "Training Block 2",
    workouts: [
      {
        id: shortid.generate(),
        type: "sidebarItem",
        isHidden: false,
        component: {
          type: "Cycling",
          content: "Cycling",
        },
      },
      {
        id: shortid.generate(),
        type: "sidebarItem",
        isHidden: false,
        component: {
          type: "Running",
          content: "Running",
        },
      },
      {
        id: shortid.generate(),
        type: "sidebarItem",
        isHidden: false,
        component: {
          type: "Swimming",
          content: "Swimming",
        },
      },
      {
        id: shortid.generate(),
        type: "sidebarItem",
        isHidden: false,
        component: {
          type: "Swimming",
          content: "Swimming",
        },
      },
      {
        id: shortid.generate(),
        type: "sidebarItem",
        isHidden: false,
        component: {
          type: "Jumping",
          content: "Jumping",
        },
      },
      {
        id: shortid.generate(),
        type: "sidebarItem",
        isHidden: false,
        component: {
          type: "Cycle",
          content: "Cycle",
        },
      },
    ],
  },
  {
    name: "Training Block 3",
    workouts: [
      {
        id: shortid.generate(),
        type: "sidebarItem",
        isHidden: false,
        component: {
          type: "Cycling",
          content: "Cycling",
        },
      },
      {
        id: shortid.generate(),
        type: "sidebarItem",
        isHidden: false,
        component: {
          type: "Running",
          content: "Running",
        },
      },
    ],
  },
];
