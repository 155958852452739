// ** React Imports
import React, { useState } from "react";

// ** MUI Components
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import OutlinedInput from "@mui/material/OutlinedInput";
import LoadingButton from "@mui/lab/LoadingButton";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { AdminLogin,  generateUid,  updateProgram } from "../../API/api-endpoint";
import { useDispatch } from "react-redux";
import { FormLabel, Grid, MenuItem } from "@mui/material";
import { Modal } from "antd";
import { showError } from "../../components/Messages";
import { useMessageContext } from "../../context/MessageContext"
import SlickCarousel from "../SlickCarousel";






const AdminLoginPage = () => {
  const [values, setValues] = useState({
    email: "",
    password: "",
    showPassword: false,
  });

  const [isLoading, setIsLoading] = useState(false);
  const [programId, setProgramId] = useState();
  const { updateTotalUnseenMessageCount } = useMessageContext();
  console.log("programId", programId);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  console.log("values", values);
  const onSubmit = async () => {
    if (values.email) {
      setIsLoading(true);
      const result = await AdminLogin({
        email: values.email,
      }, dispatch);

      console.log("result", result, result?.message == "Invalid email or password");
      if (result.message == "Login successful") {
        if (result?.user?.profile != null) {
          localStorage.setItem("profileImage", result?.user?.profile);
        }
        if (!result?.user?.google_id) {
          const genrate = await generateUid(result?.user.firstname, result?.user.lastname, result.user.email, dispatch)
          if (genrate.status) {
            if (result?.user?.role_id == 3) {
              // await dispatch(getAllUsersAction(roleID));
              showSuccess("Login successful", "/coach-yoska");

            } else {
              // await dispatch(getAllUsersAction(roleID));
              const today = new Date();
              const inputEndDate = new Date(result?.subs[0]?.end_date);
              if (inputEndDate < today) {
                // await dispatch(getAllUsersAction(roleID));
                setTimeout(() => {
                  Swal.fire({
                    title: "Info!!",
                    text: 'This account is in restricted mode, You cannot use automation',
                    icon: "info",
                  });
                  navigate('/workout');
                }, 1000);
              }else{
                showSuccess("Login successful", "/workout");

              }
            }
          }
        } else {
          if (result?.user?.role_id == 3) {
            // await dispatch(getAllUsersAction(roleID));
            showSuccess("Login successful", "/coach-yoska");
          } else {
            const today = new Date();
            const inputEndDate = new Date(result?.subs[0]?.end_date);
            if (inputEndDate < today) {
              // await dispatch(getAllUsersAction(roleID));
              setTimeout(() => {
                Swal.fire({
                  title: "Info!!",
                  text: 'This account is in restricted mode, You cannot use automation',
                  icon: "info",
                });
                navigate('/workout');
              }, 1000);
            }else{
              showSuccess("Login successful", "/workout");

            }
          }
        }
        console.log("result", result);

      }
      else if (!result?.status) {
        setIsLoading(false)
        Swal.fire({
          title: "Error",
          text: "Invalid email !",
          icon: "error",
        });
      }
      else {
        if (result.message == "Payment is not done") {
          setIsLoading(false);

          showError(`User verification is not completed `);
        }
      }


    }
    else {
      Swal.fire({
        title: "Error",
        text: "Please enter email",
        icon: "error",
      });
    }

  };


  const showSuccess = async (message, path) => {
    Swal.fire({
      title: "Success",
      text: message,
      icon: "success",
    });
    setIsLoading(false);
    // await dispatch(getCurrentUserChatsAction(currentUser?.uid));
    // await dispatch(getAllUsersAction(roleID));
    updateTotalUnseenMessageCount(0);
    navigate(path);
  };

  // useEffect(() => {
  //   const fetchData = async () => {
  //     await dispatch(getAllUsersAction(roleID));
  //     await dispatch(getCurrentUserChatsAction(currentUser?.uid));
  //   };

  //   fetchData();
  // }, [currentUser?.uid, roleID, dispatch]);

  const updateProfile = async (id) => {
    console.log("programId", programId);
    // if (programId) {

    const response = await updateProgram(programId)
    if (response?.status) {
      showSuccess("Login successful", "/workout");

      Swal.fire({
        title: "Success",
        text: response.message,
        icon: "success",
      });
    }
    // } else{
    //   Swal.fire({
    //     title: "Error",
    //     text: "Please Select any program",
    //     icon: "error",
    //   });
    // }
  }

  return (
    <Box
      className="content-center"
      style={{
        width: "100vw",
        padding: "40px",
        height: "100vh", // 100% of the viewport height
      }}
    >
      <Grid container>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={7} style={{
            display: "flex",
            // justifyContent: "center",
            alignItems: "center",
            padding: "20px"
          }}>
            <div style={{ width: "100%" }}>
              <div>
                <div className="headingCont">
                  <Typography variant="h4" className="heading">Admin<span style={{ color: "orange" }}> Login</span></Typography>{" "}
                </div>
                <form autoComplete="off" onSubmit={onSubmit}>
                  <Grid container spacing={2}>

                    <Grid item xs={12} sm={12}>
                      <FormLabel sx={{ fontSize: "14px", fontWeight: "600", color: "background: rgba(13, 20, 28, 1)", marginBottom: "10px" }} >Email</FormLabel>
                      <TextField
                        autoFocus
                        fullWidth
                        id="email"
                        placeholder="Email"
                        onChange={handleChange("email")}
                        type="email"
                        required
                      />
                    </Grid>
                  </Grid>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      flexWrap: "wrap",
                      justifyContent: "space-between",
                      fontWeight: '600',
                      fontSize: '14px',
                    }}
                  >
                    {/*
            <FormControlLabel control={<Checkbox />} label="Remember Me" />
          */}
                  </Box>
                  <Grid container spacing={2} sx={{ marginTop: "10px" }}>
                    <Grid item xs={12} sm={6}>
                      <LoadingButton
                        fullWidth
                        size="small"
                        variant="contained"
                        sx={{
                          fontSize: "15px",
                          marginBottom: 2,
                          // color: "white",
                          // backgroundColor: "rgb(145, 85, 253)",
                          color: "white",
                          backgroundColor: "#FFA654",
                          '&:hover': {
                            backgroundColor: '#FFA654', // Change to your desired hover color
                          },
                        }}
                        onClick={() => onSubmit()}
                        loading={isLoading}
                        loadingPosition="start"
                      >
                        Login
                      </LoadingButton>
                    </Grid>

                  </Grid>
                  {/*
                
                 <Typography  sx={{
                  fontWeight: '500',
                  fontSize: '14px',
                }} variant="h6" component="div">Don't have an account yet?<span>
                <a onClick={() => navigate("/sign-up")} className="cursor-pointer text-[#E67E22]"> Sign up</a>
              </span></Typography>
                */}
                </form>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} sm={5}  >
            <SlickCarousel />
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AdminLoginPage;

