import { FormLabel, Grid, MenuItem, OutlinedInput, TextField } from '@mui/material';
import { Button, Modal, TimePicker } from 'antd'
import React, { useEffect, useState } from 'react'
import { createBound, createOptiondata, getAllActivityData, getAllPrograms, getAllQuestionData, getAlllevels, updateOptiondata, updateBounds } from '../../API/api-endpoint';
import { useFormik } from 'formik';
import Swal from 'sweetalert2';
import { handleTimeChange } from '../../utils/Resubale';
import SlickCarousel from "../../pages/SlickCarousel";
const CreateBounds = ({ fetchReport, setShowAssesmentModal, showAssesmentModal, editData, setEditData }) => {
    const [questionList, setQuestionList] = useState([]);
    const [programList, setProgramList] = useState([]);
    const [allLevelList, setAlllevelList] = useState([]);

    console.log("editData", editData);
    const formik = useFormik({
        initialValues: {
            activity_id: "",
            distance: "",
            level_id: "",
            from_range: "",
            end_range: ""
        }, validate: (values) => {
            const errors = {};
            if (!values.activity_id) {
                errors.activity_id = "Activity is required";
            }
            if (values.higherbound==="" ) {
                errors.higherbound = "Higher bound is required";
            }else if (values.higherbound <= 0) {
                errors.higherbound = "Higher bound must be greater than or equal to 0";
            }
           
            if (!values.lowerbound) {
                errors.lowerbound = "Lower bound is required";
            } else if (values.higherbound <values.lowerbound) {
                errors.lowerbound = "Lower bound must be less than higer bound";
            }
            
            return errors;
        },
        // validationSchema: {},
        onSubmit: (values, { resetForm }) => {
            handleSubmitAssesmentForm(values, resetForm)

        },
    });
    console.log("formik", formik?.values);
    const QuestionData = async () => {
        const response = await getAllQuestionData()
        console.log("response", response);
        setQuestionList(response)
    }
    useEffect(() => {
        QuestionData()
        getAllProgramsData()
        getAllLevelsData()
    }, [])
    const getAllProgramsData = async () => {
        const response = await getAllActivityData()
       
        setProgramList(response?.rows)
    }
    const getAllLevelsData = async () => {
        const response = await getAlllevels()
        console.log("response", response);
        setAlllevelList(response?.rows)
    }

    const handleSubmitAssesmentForm = async (data, resetForm) => {
        let response = ""
        if (editData?.id) {

            response = await updateBounds(data)

        } else {
            response = await createBound(data)

        }
        if (response?.status) {
            Swal.fire({
                title: "Success",
                text: response.message,
                icon: "success",
            });
            setEditData({})
            setShowAssesmentModal(false)
            fetchReport()
            formik.resetForm()
            formik?.setValues({
                activity_id: "",
                lowerbound: "",
                higherbound: "",
                
            })
        } else {
            Swal.fire({
                title: "Error",
                text: response.message,
                icon: "error",
            });
        }
        console.log("response", response);
    }
    useEffect(() => {
        if (editData?.id) {
            const { srID, ...data } = editData
            formik?.setValues(data)
        }
    }, [editData?.id])


    return (
        <Modal
            width={1200}
            // title={editData?.id ? "Edit Bounds" : "Create Bounds"}
            // title={editData ? "Edit Challenge" : "Add Challenges"}
            open={showAssesmentModal}
            onCancel={() => {
                setShowAssesmentModal(false)
                formik.resetForm()
                setEditData({})
                formik?.setValues({
                    activity_id: "",
                    lowerbound: "",
                    higherbound: "",
                })
            }}
            footer={<div></div>}
        >
            <div className="headingCont">
        <span className="heading">{editData?.id ? "Edit " : "Create"}</span>{" "}
        <span className="orange heading">Bounds</span>
      </div>
      <div className="parentCont">
            <form  className="form1" >
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={11}>
                        <FormLabel >Activity Name<span className="text-[red]">*</span></FormLabel>
                    
                        <TextField
                            fullWidth
                            size="small"
                            select
                            name="activity_id"
                            value={formik?.values?.activity_id}
                            onChange={formik.handleChange}
                            error={formik.touched.activity_id && formik.errors.activity_id}
                            helperText={
                                formik.touched.activity_id && formik.errors.activity_id
                            }

                            id="form-layouts-separator-select"
                            labelId="form-layouts-separator-select-label"
                            input={<OutlinedInput id="select-multiple-language" />}
                        >
                            <MenuItem value={""} disabled>
                                Select Activity
                            </MenuItem>
                            {programList?.map((value, index) => {
                                return (
                                    <MenuItem value={value?.id}>
                                        {value?.activity_name}
                                    </MenuItem>
                                );
                            })}
                        </TextField>
                    </Grid>
                    <Grid item xs={12} sm={11}>
                        <FormLabel >Higher Bound<span className="text-[red]">*</span></FormLabel>
                    
                        <TextField
                            fullWidth
                            placeholder="higher bound"
                            size="small"
                            type="number"
                            name="higherbound"
                            value={formik?.values?.higherbound}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched.higherbound && formik.errors.higherbound}
                            helperText={
                                formik.touched.higherbound && formik.errors.higherbound
                            }

                        />
                    </Grid>
                  
                    <Grid item xs={12} sm={11}>
                    <FormLabel >Lower Bound<span className="text-[red]">*</span></FormLabel>
                
                    <TextField
                        fullWidth
                        placeholder="Lower bound"
                        size="small"
                        type="number"
                        name="lowerbound"
                        value={formik?.values?.lowerbound}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.lowerbound && formik.errors.lowerbound}
                        helperText={
                            formik.touched.lowerbound && formik.errors.lowerbound
                        }

                    />
                </Grid>
                <Grid item xs={12} sm={6}>
              <Button
                className="btn"
                key="submit"
                type="primary"
                onClick={() => formik.handleSubmit()}
              >
                Submit
              </Button>
            </Grid>
                </Grid>
            </form>
            <div className="slick-container">
          <SlickCarousel />
        </div>
            </div>
        </Modal>
    )
}
export default CreateBounds
