import {
    FormLabel,
    Grid,
    MenuItem,
    OutlinedInput,
    TextField,
  } from "@mui/material";
  import { Button, Modal, TimePicker } from "antd";
  import React, { useEffect, useState } from "react";
  import {
    createPromotorsdata,
    getAllActivityData,
    updatePromotorsdata,
    uploadsaveFile,
  } from "../../API/api-endpoint";
  import { useFormik } from "formik";
  import Swal from "sweetalert2";
  import { IoCloseSharp } from "react-icons/io5";
  import SlickCarousel from "../../pages/SlickCarousel";
  
  const CreatePromotors = ({
    fetchReport,
    setShowAssesmentModal,
    showAssesmentModal,
    editData,
    setEditData,
  }) => {
    const [questionList, setQuestionList] = useState([]);
    const [programList, setProgramList] = useState([]);
    const [allLevelList, setAlllevelList] = useState([]);
  
    console.log("editData", editData);
    const formik = useFormik({
      initialValues: {
        url: "",
        title: "",
        description: "",
        image: "",
      },
      validate: (values) => {
        const errors = {};
        if (!values.url) {
          errors.url = "Url is required";
        }
        if (!values.title) {
          errors.title = "Title is required";
        }
        if (!values.description) {
          errors.description = "Description is required";
        }
        if (!values.image) {
          errors.image = "Image is required";
        }
  
        return errors;
      },
      // validationSchema: {},
      onSubmit: (values, { resetForm }) => {
        handleSubmitAssesmentForm(values, resetForm);
      },
    });
    console.log("formik", formik?.values, formik?.errors);
  
    useEffect(() => {
      getAllProgramsData();
    }, []);
    const getAllProgramsData = async () => {
      const response = await getAllActivityData();
      console.log("response", response);
      setProgramList(response?.rows);
    };
  
    const handleSubmitAssesmentForm = async (data, resetForm) => {
      let response = "";
      if (editData?.id) {
        response = await updatePromotorsdata(data);
      } else {
        response = await createPromotorsdata(data);
      }
      if (response?.status) {
        Swal.fire({
          title: "Success",
          text: response.message,
          icon: "success",
        });
        setEditData({});
        setShowAssesmentModal(false);
        fetchReport();
        formik.resetForm();
        formik?.setValues({
          url: "",
          title: "",
          description: "",
          image: "",
        });
      } else {
        Swal.fire({
          title: "Error",
          text: response.message,
          icon: "error",
        });
      }
      console.log("response", response);
    };
    useEffect(() => {
      if (editData?.id) {
        const { srID, ...data } = editData;
        formik?.setValues(data);
        formik?.setFieldValue("name", data?.name ? data?.name : "");
      }
    }, [editData?.id]);
  
    const handleFileUpload = async (event) => {
      const file = event.target.files[0];
      if (file) {
        const formData = new FormData();
        formData.append("file", file);
  
        const responst = await uploadsaveFile(formData);
        if (responst?.status) {
          formik.setFieldValue("image", responst?.file);
        }
      }
    };
    return (
      <Modal
        width={1200}
        open={showAssesmentModal}
        onCancel={() => {
          setShowAssesmentModal(false);
          formik.resetForm();
          setEditData({});
          formik?.setValues({
            url: "",
            title: "",
            description: "",
            image: "",
          });
        }}
        footer={
          <div></div>
          //   loading={isLoading}
        }
      >
        <div className="headingCont">
          <span className="heading">{editData?.id ? "Edit " : "Create"}</span>{" "}
          <span className="orange heading">Promoters</span>
        </div>
        {/* <h1>{editData ? editData.challengeId : values.challengeId}</h1> */}
        <div className="parentCont">
          <form className="form1" onSubmit={formik.handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={11}>
                <FormLabel>Title<span className="text-[red]">*</span></FormLabel>
  
                <TextField
                  fullWidth
                  placeholder="Title"
                  size="small"
                  type="text"
                  name="title"
                  value={formik?.values?.title}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.title && formik.errors.title}
                  helperText={formik.touched.title && formik.errors.title}
                />
              </Grid>
              <Grid item xs={12} sm={11}>
                <FormLabel>Description<span className="text-[red]">*</span></FormLabel>
  
                <TextField
                  fullWidth
                  placeholder="Description"
                  size="small"
                  type="text"
                  name="description"
                  value={formik?.values?.description}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.description && formik.errors.description}
                  helperText={
                    formik.touched.description && formik.errors.description
                  }
                />
              </Grid>
              <Grid item xs={12} sm={11} className="relative">
                <FormLabel>Upload Image<span className="text-[red]">*</span></FormLabel>
  
                <TextField
                  fullWidth
                  placeholder="Zone"
                  size="small"
                  type={formik?.values?.image ? "text" : "file"}
                  name="image"
                  disabled={formik?.values?.image}
                  value={formik?.values?.image}
                  onChange={handleFileUpload}
                  error={formik.touched.image && formik.errors.image}
                  helperText={formik.touched.image && formik.errors.image}
                  inputProps={{
                    accept: ".jpg, .png .jpeg", // Specify accepted file formats here
                  }}
                />
                {formik?.values?.image && (
                  <IoCloseSharp
                    onClick={() => {
                      formik.setFieldValue("image", "");
                    }}
                    color="darkgray"
                    className="cursor-pointer absolute top-0 right-[-10px]"
                    style={{ fontSize: "24px" }}
                  />
                )}
              </Grid>
              <Grid item xs={12} sm={11}>
                <FormLabel>Website URL<span className="text-[red]">*</span></FormLabel>
  
                <TextField
                  fullWidth
                  placeholder="Website URL"
                  size="small"
                  type="text"
                  name="url"
                  value={formik?.values?.url}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.url && formik.errors.url}
                  helperText={formik.touched.url && formik.errors.url}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Button
                  className="btn"
                  key="submit"
                  type="primary"
                  onClick={() => formik.handleSubmit()}
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          </form>
  
          <div className="slick-container">
            <SlickCarousel />
          </div>
        </div>
      </Modal>
    );
  };
  export default CreatePromotors;
  