import moment from "moment";

// for formik 
export const handleTimeChange = (event, fieldName, setfield) => {
  const { value } = event.target;
  console.log("value", value);

  // Format the time input: Add colons after typing 2nd and 5th character if needed
  let formattedTime = value.replace(/[^0-9]/g, "").slice(0, 6); // Remove non-numeric characters and limit to 6 characters
  if (formattedTime.length > 2) {
    formattedTime = `${formattedTime.slice(0, 2)}:${formattedTime.slice(2)}`;
  }
  if (formattedTime.length > 5) {
    formattedTime = `${formattedTime.slice(0, 5)}:${formattedTime.slice(5)}`;
  }
  return formattedTime;
  // setfield(fieldName,formattedTime);
};
export const handleMealTimeChange = (event) => {
  let { value } = event.target;
  console.log("value", value);

  // Remove AM/PM if present
  value = value.replace(/(AM|PM)/i, "").trim();

  // Remove non-numeric characters
  let formattedTime = value.replace(/[^0-9]/g, "").slice(0, 4); // Limit to 4 characters (HHMM)

  // Add colons appropriately
  if (formattedTime.length > 2) {
    formattedTime = `${formattedTime.slice(0, 2)}:${formattedTime.slice(2)}`;
  }

  // Determine if the time is AM or PM
  const hours = parseInt(formattedTime.slice(0, 2), 10);
  let period = "AM";

  if (hours >= 12) {
    period = "PM";
    if (hours > 12) {
      formattedTime = `${(hours - 12).toString().padStart(2, "0")}${formattedTime.slice(2)}`;
    }
  } else if (hours === 0) {
    formattedTime = `12${formattedTime.slice(2)}`;
  }

  // Append the period (AM/PM) if the length is 5 (HH:MM)
  if (formattedTime.length === 5) {
    formattedTime = `${formattedTime} ${period}`;
  }

  console.log("formattedTime", formattedTime);

  // Set the field value

  return formattedTime;
};
export const handleTimeChangeZones = (event) => {
  const { value } = event.target;
  console.log("value", value);

  // Format the time input: Add colons after typing 2nd and 5th character if needed
  let formattedTime = value.replace(/[^0-9]/g, "").slice(0, 6); // Remove non-numeric characters and limit to 6 characters
  if (formattedTime.length > 2) {
    formattedTime = `${formattedTime.slice(0, 2)}:${formattedTime.slice(2)}`;
  }
  if (formattedTime.length > 5) {
    formattedTime = `${formattedTime.slice(0, 5)}:${formattedTime.slice(5)}`;
  }
  return formattedTime;
  // setfield(fieldName,formattedTime);
};
export const handleInputTimeChange = (event, fieldName, setTime) => {
  const { value } = event.target;
  console.log("value", value);

  // Format the time input: Add colons after typing 2nd and 5th character if needed
  let formattedTime = value.replace(/[^0-9]/g, "").slice(0, 6); // Remove non-numeric characters and limit to 6 characters
  if (formattedTime.length > 2) {
    formattedTime = `${formattedTime.slice(0, 2)}:${formattedTime.slice(2)}`;
  }
  if (formattedTime.length > 5) {
    formattedTime = `${formattedTime.slice(0, 5)}:${formattedTime.slice(5)}`;
  }
  console.log("formattedTime", formattedTime);
  setTime((prevFormValue) => ({
    ...prevFormValue, [fieldName]: formattedTime
  }));
};
// for formik 
export const handleInputChangeMinutesSeconds = (event, fieldName, setfield) => {
  const { value } = event.target;
  console.log("value", value);

  // Format the time input: Add colons after typing 2nd and 5th character if needed
  let formattedTime = value.replace(/[^0-9]/g, "").slice(0, 4); // Remove non-numeric characters and limit to 6 characters
  if (formattedTime.length > 2) {
    formattedTime = `${formattedTime.slice(0, 2)}:${formattedTime.slice(2)}`;
  }

  return formattedTime;
  // setfield(fieldName,formattedTime);
};
export const handleInputTimeChangeMinutesSeconds = (event, fieldName, setTime) => {
  const { value } = event.target;
  console.log("value", value);

  // Format the time input: Add colons after typing 2nd and 5th character if needed
  let formattedTime = value.replace(/[^0-9]/g, "").slice(0, 6); // Remove non-numeric characters and limit to 6 characters
  if (formattedTime.length > 2) {
    formattedTime = `${formattedTime.slice(0, 2)}:${formattedTime.slice(2)}`;
  }
  if (formattedTime.length > 5) {
    formattedTime = `${formattedTime.slice(0, 5)}:${formattedTime.slice(5)}`;
  }
  setTime((prevFormValue) => ({
    ...prevFormValue, [fieldName]: formattedTime
  }));
};
// formik
export const handleTimeChangeSeconds = (event, fieldName, setfield) => {
  const { value } = event.target;
  console.log("value", value);

  // Format the time input: Add colons after typing 2nd and 5th character if needed
  let formattedTime = value.replace(/[^0-9]/g, "").slice(0, 2); // Remove non-numeric characters and limit to 6 characters
  if (formattedTime.length > 2) {
    formattedTime = `${formattedTime.slice(0, 2)}:${formattedTime.slice(2)}`;
  }

  return formattedTime;
  // setfield(fieldName,formattedTime);
};


export function calculatePace(speeds) {
  let data = parseFloat(speeds);
  console.log("data", data);
  let calculatedData = 0;
  let pace = "0";
  let hours = 0;
  let minutes = 0;
  let seconds = 0;

  if (!isNaN(data) && data !== 0.0) {
    calculatedData = 1000 / data;
    console.log("calculatedData", calculatedData, "calculatedData", data);
    hours = Math.floor(data / 3600);
    minutes = Math.floor((calculatedData) / 60);
    seconds = parseFloat("0." + minutes.toString().split(".")[1]) * 60 || 0;
    pace = `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  }
  console.log("pace", pace);
  return pace;
}




export const calculateSpeed = (timeCalculate, distance) => {

  const speedAray = timeCalculate.map((time, index) => {
    const speed = (parseFloat(time) * 3600) / (parseFloat(distance[index]) * 1000);
    return isNaN(speed) ? null : speed; // Round to the nearest integer
  });

  return speedAray;
};
export const calculateSpeedKmHr = (speedResultResult) => {

  const speedAray = speedResultResult.map((speed, index) => {
    const speedValue = (parseFloat(speed) * 3600) / 1000;
    return isNaN(speedValue) ? null : speedValue.toFixed(2); // Round to the nearest integer
  });

  return speedAray;
};
export const capitalizeFirstLetter = (str) => {
  return str?.charAt(0)?.toUpperCase() + str?.slice(1);
};


// utils.js

export const calculateMonthlyWeeklySummary = (events, year, month) => {
  const monthlyWeeklySummary = Array.from({ length: 5 }, () => ({
    distance: 0,
    duration: 0,
    maxEventsOnDay: 0,
    height: 0,
    plannedDistance: 0,
    plannedDuration: "00:00:00",
    cyclingDistance: 0,
    cyclingDuration: 0,
    cyclingPlanDistance: 0,
    cyclingPlanDuration: 0,
    swimmingDistance: 0,
    swimmingDuration: 0,
    swimmingPlanDistance: 0,
    swimmingPlanDuration: 0,
  }));

  events?.forEach((event) => {
    const { start } = event;
    let findType = event?.badge?.split(".")[0];
    const plannedDistance = parseFloat(event.workout_planned_distance == "NaN" ? 0 : event.workout_planned_distance) || 0;
    const plannedDuration = event.workout_planned_duration || "00:00:00";

    if (start) {
      const startDate = new Date(start);
      let currentMonth = startDate.getMonth() + 1;

      if (startDate.getFullYear() == year && currentMonth == month) {
        const weekNumber = Math.floor((startDate.getDate() - 1 + getDay(startDate)) / 7) + 1;

        if (!monthlyWeeklySummary[weekNumber - 1]) {
          monthlyWeeklySummary[weekNumber - 1] = {
            distance: 0,
            duration: 0,
            plannedDistance: 0,
            plannedDuration: "00:00:00",
            maxEventsOnDay: 0,
            height: 0,
            cyclingDistance: 0,
            cyclingDuration: 0,
            cyclingPlanDistance: 0,
            cyclingPlanDuration: 0,
            swimmingDistance: 0,
            swimmingDuration: 0,
            swimmingPlanDistance: 0,
            swimmingPlanDuration: 0,
          };
        }

        // Categorize events based on findType
        if (findType === 'cycling') {
          monthlyWeeklySummary[weekNumber - 1].cyclingDistance += parseFloat(event.actual_workout == "NaN" ? 0 : event.actual_workout);
          monthlyWeeklySummary[weekNumber - 1].cyclingDuration += parseFloat(event.actual_duration);
          monthlyWeeklySummary[weekNumber - 1].cyclingPlanDistance += parseFloat(plannedDistance);
          monthlyWeeklySummary[weekNumber - 1].cyclingPlanDuration += parseFloat(plannedDuration);
        } else if (findType === 'swimming') {
          monthlyWeeklySummary[weekNumber - 1].swimmingDistance += parseFloat(event.actual_workout == "NaN" ? 0 : event.actual_workout);
          monthlyWeeklySummary[weekNumber - 1].swimmingDuration += parseFloat(event.actual_duration);
          monthlyWeeklySummary[weekNumber - 1].swimmingPlanDistance += parseFloat(plannedDistance);
          monthlyWeeklySummary[weekNumber - 1].swimmingPlanDuration += parseFloat(plannedDuration);
        } else {
          // Default category (e.g., running)
          monthlyWeeklySummary[weekNumber - 1].distance += parseFloat(event.actual_workout == "NaN" ? 0 : event.actual_workout);
          monthlyWeeklySummary[weekNumber - 1].duration += parseFloat(event.actual_duration);
          monthlyWeeklySummary[weekNumber - 1].plannedDistance += plannedDistance;
          monthlyWeeklySummary[weekNumber - 1].plannedDuration += plannedDuration;
        }

        // Add planned values
        const sameDayEvents = events.filter(
          (e) =>
            new Date(e.start).toDateString() == startDate.toDateString() &&
            weekNumber ==
            Math.floor((new Date(e.start).getDate() - 1 + getDay(new Date(e.start))) / 7) + 1
        );

        if (sameDayEvents.length > monthlyWeeklySummary[weekNumber - 1].maxEventsOnDay) {
          monthlyWeeklySummary[weekNumber - 1].maxEventsOnDay = sameDayEvents.length;
        }
      }
    }
  });

  // Identify the day with the maximum number of events across all weeks
  let maxEventsDay = 0;
  for (let i = 0; i < 7; i++) {
    let totalEvents = 0;
    for (let j = 0; j < monthlyWeeklySummary.length; j++) {
      totalEvents += monthlyWeeklySummary[j]?.maxEventsOnDay || 0;
    }
    if (totalEvents > maxEventsDay) {
      maxEventsDay = totalEvents;
    }
  }

  // Set the height for the identified day across all weeks
  monthlyWeeklySummary.forEach((week) => {
    if (week.maxEventsOnDay == maxEventsDay) {
      week.height = 200; // Set the desired height
    }
  });

  // Convert durations to HH:mm:SS format
  monthlyWeeklySummary.forEach((week) => {
    week.duration = minutesToHHMMSS(week.duration);
    week.cyclingDuration = minutesToHHMMSS(week.cyclingDuration);
    week.swimmingDuration = minutesToHHMMSS(week.swimmingDuration);
    week.swimmingPlanDuration = minutesToHHMMSS(week.swimmingPlanDuration);
    week.cyclingPlanDuration = minutesToHHMMSS(week.cyclingPlanDuration);
  });

  return monthlyWeeklySummary;
};


const getDay = (date) => {
  let day = date.getDay();
  if (day == 0) {
    day = 6;
  } else {
    day -= 1;
  }
  return day;
};
const minutesToHHMMSS = (totalMinutes) => {
  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;
  const seconds = 0; // Assuming you don't have seconds in your total duration

  return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
};

export const validateMobileNumber = (mobileNumber) => {
  // Check if the mobile number is not empty
  if (mobileNumber?.trim() === '') {
    return { isValid: true, error: null }; // No validation error if mobile is not provided
  }

  // Regular expression for a 10-digit mobile number
  const mobileNumberRegex = /^[0-9]{10}$/;

  // Check if the mobile number matches the regex
  const isValidMobileNumber = mobileNumberRegex.test(mobileNumber);

  // Check if the length is 10
  const isCorrectLength = mobileNumber?.length === 10;

  // Return an object with validation results
  return {
    isValid: isValidMobileNumber && isCorrectLength,
    error: {
      invalidFormat: !isValidMobileNumber,
      incorrectLength: !isCorrectLength,
    },
  };
};
export const validateDOB = (dob) => {
  const currentDate = new Date();
  const dobDate = new Date(dob);
  const minDOBDate = new Date(currentDate.getFullYear() - 14, currentDate.getMonth(), currentDate.getDate()); // Calculate minimum DOB date (12 years ago)
  console.log('minDOBDate', minDOBDate, dobDate, dobDate >= minDOBDate);
  if (dobDate >= minDOBDate) {
    return 'Date of birth must be at least 14 years ago.';
  }

  return undefined; // Return undefined if validation passes
};
export const removeNullStrings = (obj) => {
  for (let key in obj) {
    if (obj[key] === "null") {
      obj[key] = null;
    } else if (typeof obj[key] === "object" && obj[key] !== null) {
      obj[key] = removeNullStrings(obj[key]);
    }
  }
  return obj;
};

export const removeNullValues = (obj) => {
  const newObj = {};
  for (let key in obj) {
    if (obj[key] != null && typeof obj[key] != 'object') {
      newObj[key] = obj[key];
    } else if (obj[key] != null && typeof obj[key] == 'object') {
      newObj[key] = removeNullValues(obj[key]);
    }
  }
  console.log("newObj", newObj);
  return newObj;
};
 
 export const calculateActualpriceAfterGST = (amount, discountPercentage) => {
   const discountAmount = (amount * discountPercentage) / 100;
   console.log("discountAmount",discountPercentage,amount,discountAmount);
  return parseInt(amount) + parseInt(discountAmount);
};


















