import React, { useCallback, useEffect, useState } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import listPlugin from "@fullcalendar/list";
import {
  INITIAL_EVENTS,
  LableOptions,
  filterOptions,
  filterDateIntoString,
} from "./event-utils";
import "./eventcalendar.css";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import {
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Modal,
  Typography,
} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import CloseIcon from "@mui/icons-material/Close";
import { DateCalendar, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Workouts from "../workout/Workouts";

const drawerWidth = 260;

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  justifyContent: "space-between",
}));

const DrawerBody = styled("div")(({ theme }) => ({
  padding: "0px 20px",
}));

const WorkoutModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  height: "70vh",
  bgcolor: "background.paper",
  border: "1px solid #000",
  boxShadow: 24,
  p: 4,
  overflowY: "scroll",
};

const EventsCalendar = () => {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState();
  const [events, setEvents] = useState(INITIAL_EVENTS);
  // console.log(events, "events");
  const [filteredEvents, setFilteredEvents] = useState(events);
  // console.log(filteredEvents, "filteredEvents");
  const [title, setTitle] = useState("");
  const [lable, setLable] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [checkedFilters, setCheckedFilters] = useState(
    filterOptions.map((option) => option.value)
  );
  const [smallCalendarDate, setSmallCalendarDate] = useState();
  // console.log(smallCalendarDate);
  const [workoutModalOpen, setWorkoutModalOpen] = React.useState(false);
  const handleWorkoutModalOpen = () => setWorkoutModalOpen(true);
  const handleWorkoutModalClose = () => setWorkoutModalOpen(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerInput = () => {
    setTitle("");
    setLable("");
    setStartDate("");
    setEndDate("");
    setSmallCalendarDate();
  };

  const handleDrawerClose = () => {
    setOpen(false);
    handleDrawerInput();
    setSelectedEvent("");
  };

  const handleDateSelect = (selectInfo) => {
    // console.log("selectInfo",selectInfo);
    if (selectInfo.startStr && selectInfo.endStr) {
      setStartDate(selectInfo.startStr);
      setEndDate(selectInfo.endStr);
    }
    if (smallCalendarDate) {
      setStartDate(filterDateIntoString(smallCalendarDate));
      setEndDate(filterDateIntoString(smallCalendarDate));
    }
    handleDrawerOpen();
  };

  const handleAddEvent = () => {
    if (selectedEvent?.event) {
      // Editing an existing event
      const eventId = Number(selectedEvent.event.id);
      const editedEventIndex = events.findIndex((item) => item.id === eventId);

      if (editedEventIndex !== -1) {
        const editedEvent = {
          ...events[editedEventIndex],
          title: title,
          start: startDate,
          end: endDate,
          classNames: lable,
        };

        const newEvents = [...events];
        newEvents[editedEventIndex] = editedEvent;
        setEvents(newEvents);
        setSelectedEvent("");
        handleDrawerClose();
      }
    } else {
      // Adding a new event
      if (title && startDate && endDate) {
        const newEvent = {
          id: Math.floor(Math.random() * 900000) + 100000,
          title: title,
          start: startDate,
          end: endDate,
          classNames: lable,
          description: "",
        };
        setEvents([...events, newEvent]);
        handleDrawerInput();
        handleDrawerClose();
      }
    }
  };

  const handleEventClick = (clickInfo) => {
    // console.log("clickInfo",clickInfo);
    const eventId = Number(clickInfo.event.id);
    const event = events.find((item) => item.id === eventId);
    setSelectedEvent(clickInfo);
    setTitle(event?.title || "");
    const getoption = LableOptions.find(
      (item) => item.LableValue === event?.classNames
    );
    if (getoption) {
      setLable(getoption.LableValue);
    } else {
      setLable("");
    }
    setStartDate(event?.start || "");
    setEndDate(event?.end || "");
    handleDrawerOpen();
  };

  const handleDeleteEvent = () => {
    const newEvents = events.filter(
      (item) => item.id !== Number(selectedEvent.event.id)
    );
    setEvents(newEvents);
    handleDrawerClose();
  };

  const handleLableChange = (event) => {
    setLable(event.target.value);
  };

  const handleCheckboxChange = (filterValue) => (event) => {
    const checked = event.target.checked;

    if (filterValue === "all") {
      setCheckedFilters(
        checked ? filterOptions.map((option) => option.value) : []
      );
    } else {
      if (checked) {
        setCheckedFilters((prevFilters) => [...prevFilters, filterValue]);
      } else {
        setCheckedFilters((prevFilters) =>
          prevFilters.filter((filter) => filter !== filterValue)
        );
      }
    }
  };

  const handleFilterEvents = useCallback(() => {
    if (checkedFilters.length === 0) {
      setFilteredEvents(events);
    } else {
      const filtered = events.filter((event) =>
        checkedFilters.includes(event.classNames)
      );
      setFilteredEvents(filtered);
    }
  }, [checkedFilters, events]);

  const handleEventChange = (changedEventinfo) => {
    // console.log(changedEventinfo);
    const id = Number(changedEventinfo.event._def.publicId);
    const newStartDate = filterDateIntoString(
      changedEventinfo.event._instance.range.start
    );
    const newEndDate = filterDateIntoString(
      changedEventinfo.event._instance.range.end
    );
    const newData = events.map((item) => {
      if (item.id === id) {
        item.start = newStartDate;
        item.end = newEndDate;
      }
      return item;
    });
    setEvents(newData);
  };

  useEffect(() => {
    handleFilterEvents();
  }, [handleFilterEvents]);

  useEffect(() => {
    setFilteredEvents(events);
  }, [events]);

  return (
    <div className="celandar-main">
      <div className={`demo-app ${open && "overlay"}`}>
        <div className="left-sidebar border">
          <div className="left-sidebar-add-button">
            <div className="add-button-inner" onClick={handleDateSelect}>
              <div style={{ fontSize: "30px" }}>+</div>
              <div>Add Workout</div>
            </div>
          </div>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateCalendar
              value={smallCalendarDate}
              onChange={(newValue) => {
                setSmallCalendarDate(newValue.$d);
              }}
            />
          </LocalizationProvider>
          <div className="filter-section">
            <div className="filter-title">
              <p>FILTER</p>
            </div>
            <div className="all-filters">
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      color="default"
                      checked={checkedFilters.length === filterOptions.length}
                      onChange={handleCheckboxChange("all")}
                    />
                  }
                  label="View All"
                />
              </FormGroup>
              <div className="all-filters">
                {filterOptions.map((option) => (
                  <FormGroup key={option.value}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          color={option.color}
                          checked={checkedFilters.includes(option.value)}
                          onChange={handleCheckboxChange(option.value)}
                        />
                      }
                      label={option.lable}
                    />
                  </FormGroup>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className="demo-app-main">
          <FullCalendar
            plugins={[
              dayGridPlugin,
              timeGridPlugin,
              interactionPlugin,
              listPlugin,
            ]}
            headerToolbar={{
              left: "prev,next,title",
              // center: "title",
              right: "dayGridMonth,timeGridWeek,timeGridDay,listWeek",
            }}
            initialView="dayGridMonth"
            editable={true}
            selectable={true}
            selectMirror={true}
            dayMaxEvents={true}
            weekends={true}
            // initialEvents={events}
            events={filteredEvents}
            select={handleDateSelect}
            // eventContent={renderEventContent} // custom render function
            eventClick={handleEventClick}
            eventChange={handleEventChange}
            // views={{
            //   timeGridWeek: {
            //     slotMinTime: "09:00:00",
            //     slotMaxTime: "22:00:00",
            //   },
            //   timeGridDay: {
            //     slotMinTime: "09:00:00",
            //     slotMaxTime: "22:00:00",
            //   },
            // }}
            // eventsSet={handleEvents} // called after events are initialized/added/changed/removed
            /* you can update a remote database when these fire:
        eventAdd={function(){}}
        eventChange={function(){}}
        eventRemove={function(){}}
        */
          />
        </div>
      </div>
      <Box sx={{ display: "flex" }}>
        <Drawer
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: drawerWidth,
            },
          }}
          variant="persistent"
          anchor="right"
          open={open}
        >
          <DrawerHeader>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <IconButton onClick={handleDrawerClose}>
                {theme.direction === "rtl" ? (
                  <ChevronLeftIcon />
                ) : (
                  <ChevronRightIcon />
                )}
              </IconButton>
              <Typography>
                {selectedEvent?.event ? "Updated Workout" : "Add Workout"}
              </Typography>
            </Box>
            <Box>
              {selectedEvent?.event && (
                <IconButton onClick={handleDeleteEvent}>
                  <DeleteOutlineIcon />
                </IconButton>
              )}
              <IconButton onClick={handleDrawerClose}>
                <CloseIcon />
              </IconButton>
            </Box>
          </DrawerHeader>
          <Divider />
          <DrawerBody>
            <div className="form-container">
              <div className="form-fields">
                <label>Title</label>
                <input
                  className="sidebar-input"
                  type="text"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                />
              </div>
              <div className="form-fields">
                <label>Lable</label>
                <FormControl fullWidth>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={lable}
                    label="Lable"
                    onChange={handleLableChange}
                  >
                    {LableOptions.map((item, index) => (
                      <MenuItem key={index} value={item.LableValue}>
                        {item.LableIcon}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
              <div className="form-fields">
                <label>Start Date</label>
                <input
                  className="sidebar-input"
                  type="text"
                  value={startDate}
                  onChange={(e) => setStartDate(e.target.value)}
                />
              </div>
              <div className="form-fields">
                <label>End Date</label>
                <input
                  className="sidebar-input"
                  type="text"
                  value={endDate}
                  onChange={(e) => setEndDate(e.target.value)}
                />
              </div>

              {/* <div className="form-fields">
                <label>Event URL</label>
                <input className="sidebar-input" type="text" />
              </div>
              <div className="form-fields">
                <label>Guests</label>
                <input className="sidebar-input" type="text" />
              </div>
              <div className="form-fields">
                <label>Location</label>
                <input className="sidebar-input" type="text" />
              </div> */}
              <div className="form-fields">
                <Button
                  sx={{ width: "100%" }}
                  variant="contained"
                  color="secondary"
                  onClick={handleWorkoutModalOpen}
                >
                  Add Workout Details
                </Button>
              </div>
              <Divider />
              <div
                className="form-fields"
                style={{ flexDirection: "row", alignItems: "center" }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleAddEvent}
                >
                  {selectedEvent?.event ? "Update" : "Submit"}
                </Button>
                <Button variant="outlined" onClick={handleDrawerClose}>
                  Cancel
                </Button>
              </div>
            </div>
          </DrawerBody>
        </Drawer>
      </Box>
      <div className="workout-modal">
        <Modal
          open={workoutModalOpen}
          onClose={handleWorkoutModalClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={WorkoutModalStyle}>
            <Workouts />
          </Box>
        </Modal>
      </div>
    </div>
  );
};

export default EventsCalendar;
