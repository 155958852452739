import {
    FormLabel,
    Grid,
    MenuItem,
    OutlinedInput,
    TextField,
  } from "@mui/material";
  import { Button, Modal, TimePicker } from "antd";
  import React, { useEffect, useState } from "react";
  import {
    createPaymentCreddata,
    getAllActivityData,
    updatePaymentCreddata,
    uploadsaveFile,
  } from "../../API/api-endpoint";
  import { useFormik } from "formik";
  import Swal from "sweetalert2";
  import { IoCloseSharp } from "react-icons/io5";
  import SlickCarousel from "../../pages/SlickCarousel";
  
  const PaymentCreds = ({
    fetchReport,
    setShowAssesmentModal,
    showAssesmentModal,
    editData,
    setEditData,
  }) => {
    const [questionList, setQuestionList] = useState([]);
    const [programList, setProgramList] = useState([]);
    const [allLevelList, setAlllevelList] = useState([]);
  
    console.log("editData", editData);
    const formik = useFormik({
      initialValues: {
        key: "",
        secret: "",
      },
      validate: (values) => {
        const errors = {};
        if (!values.key) {
          errors.key = "Key is required";
        }
        if (!values.secret) {
          errors.secret = "Secret is required";
        }
        return errors;
      },
      // validationSchema: {},
      onSubmit: (values, { resetForm }) => {
        handleSubmitAssesmentForm(values, resetForm);
      },
    });
    console.log("formik", formik?.values, formik?.errors);
  
    useEffect(() => {
      getAllProgramsData();
    }, []);
    const getAllProgramsData = async () => {
      const response = await getAllActivityData();
      console.log("response", response);
      setProgramList(response?.rows);
    };
  
    const handleSubmitAssesmentForm = async (data, resetForm) => {
      let response = "";
      if (editData?.id) {
        response = await updatePaymentCreddata(data);
      } else {
        response = await createPaymentCreddata(data);
      }
      if (response?.status) {
        Swal.fire({
          title: "Success",
          text: response.message,
          icon: "success",
        });
        setEditData({});
        setShowAssesmentModal(false);
        fetchReport();
        formik.resetForm();
        formik?.setValues({
          key: "",
          secret: "",
        });
      } else {
        Swal.fire({
          title: "Error",
          text: response.message,
          icon: "error",
        });
      }
      console.log("response", response);
    };
    useEffect(() => {
      if (editData?.id) {
        const { srID, createdAt, updatedAt, ...data } = editData;
        formik?.setValues(data);
      }
    }, [editData?.id]);
  
    const handleFileUpload = async (event) => {
      const file = event.target.files[0];
      if (file) {
        const formData = new FormData();
        formData.append("file", file);
  
        const responst = await uploadsaveFile(formData);
        if (responst?.status) {
          formik.setFieldValue("image", responst?.file);
        }
      }
    };
    return (
      <Modal
        width={1200}
        open={showAssesmentModal}
        onCancel={() => {
          setShowAssesmentModal(false);
          formik.resetForm();
          setEditData({});
          formik?.setValues({
            key: "",
            secret: "",
          });
        }}
        footer={
          <div></div>
          //   loading={isLoading}
        }
      >
        <div className="headingCont">
          <span className="heading">{editData?.id ? "Edit " : "Create"}</span>{" "}
          <span className="orange heading">Payment Credintials</span>
        </div>
        {/* <h1>{editData ? editData.challengeId : values.challengeId}</h1> */}
        <div className="parentCont">
          <form className="form1" onSubmit={formik.handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={11}>
                <FormLabel>Key<span className="text-[red]">*</span></FormLabel>
  
                <TextField
                  fullWidth
                  placeholder="Key"
                  size="small"
                  type="text"
                  name="key"
                  value={formik?.values?.key}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.key && formik.errors.key}
                  helperText={formik.touched.key && formik.errors.key}
                />
              </Grid>
              <Grid item xs={12} sm={11}>
                <FormLabel>Secret<span className="text-[red]">*</span></FormLabel>
  
                <TextField
                  fullWidth
                  placeholder="Secret"
                  size="small"
                  type="text"
                  name="secret"
                  value={formik?.values?.secret}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.secret && formik.errors.secret}
                  helperText={formik.touched.secret && formik.errors.secret}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Button
                  className="btn"
                  key="submit"
                  type="primary"
                  onClick={() => formik.handleSubmit()}
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          </form>
          <div className="slick-container">
            <SlickCarousel />
          </div>
        </div>
      </Modal>
    );
  };
  export default PaymentCreds;
  