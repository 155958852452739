import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";

const BorderLinearProgress = styled(LinearProgress)(
  ({ theme, percentage }) => ({
    height: 20,
    borderRadius: 5,
    position: "relative", // Positioning for the percentage text
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor:
        theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: percentage < 50 ? "red" : "green", // Change color based on percentage
    },
    "& .percentageText": {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      color: theme.palette.mode === "light" ? "#000" : "#fff",
    },
  })
);

const PogressBar = ({completed,total}) => {
 
  const calculatePercentage = () => {
    if (total === 0) {
      return 0;
    }
    return (completed / total) * 100;
  };

  const percentageCompleted = completed; // Rounding to 2 decimal places
console.log("percentageCompleted",percentageCompleted);
  return (
    <Box sx={{ flexGrow: 1 }}>
      <br />
      <BorderLinearProgress
        variant="determinate"
        value={completed}
        percentage={completed}
      >
        <span className="percentageText">{completed}%</span>
      </BorderLinearProgress>
    </Box>
  );
};

export default PogressBar;
