export const categories = ["running", "swimming", "cycling"];
const usersPerCategory = 20;

const generateUser = (id, isTop) => {
    const name = `User ${id}`;
    const age = Math.floor(Math.random() * 30) + 20;
    const email = `user${id}@example.com`;
    const gender = Math.random() < 0.5 ? "male" : "female";
    const category = categories[id % 3];

    return { name, age, email, gender, category, isTop };
};

export const LeaderBoardUsers = [];

for (let i = 0; i < categories.length; i++) {
    for (let j = 0; j < usersPerCategory; j++) {
        const isTop = j < 5; // Only the first 5 users in each category are "isTop"
        LeaderBoardUsers.push(generateUser(i * usersPerCategory + j, isTop));
    }
}

export const fakeChallenges = [
    {
      challengeId: Math.floor(Math.random() * 900000) + 100000,
      challengeName: "running",
      challengeDescription: "Stay fit and healthy with our daily workout challenge!",
      challengeDuration: "30 days",
      challengeStartDate: "2023-09-01",
      challengeEndDate: "2023-09-30",
      tranningBlock: "Cardio",
      challengeTarget: "100",
      challengeActivity: "Running",
      gamificationLevel: "Bronze",
      gamificationPoint: "500",
      isDisable:false
    },
    {
      challengeId: Math.floor(Math.random() * 900000) + 100000,
      challengeName: "swimming",
      challengeDescription: "Practice mindfulness meditation for a peaceful mind.",
      challengeDuration: "21 days",
      challengeStartDate: "2023-10-01",
      challengeEndDate: "2023-10-21",
      tranningBlock: "Meditation",
      challengeTarget: "50",
      challengeActivity: "Meditation",
      gamificationLevel: "Silver",
      gamificationPoint: "750",
      isDisable:true
    },
    {
      challengeId: Math.floor(Math.random() * 900000) + 100000,
      challengeName: "cycling",
      challengeDescription: "Stay hydrated by drinking enough water throughout the day.",
      challengeDuration: "7 days",
      challengeStartDate: "2023-11-01",
      challengeEndDate: "2023-11-07",
      tranningBlock: "Hydration",
      challengeTarget: "70",
      challengeActivity: "Drinking water",
      gamificationLevel: "Gold",
      gamificationPoint: "1000",
      isDisable:false
    },
  ];
  
  
