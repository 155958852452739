import { Chip, FormControl, FormLabel, Grid, MenuItem, OutlinedInput, Select, TextField } from '@mui/material';
import { Button, Modal } from 'antd'
import React, { useEffect, useState } from 'react'
import { CreatePrograms, CreateZonesClasification, createGoalNameYTA, createGoalWithoutVolumedata, getAllActivityData, getAllGoalNameForYTA, getAllPrograms, getAlllevels, getAllworkoutData, getFechAlGoals, updateGoalNameYTA, updateGoalWithoutVolumedata, updatePrograms, updateZonesClasification, weeklyFeedDataPattern, weeklyFeedDataProgram } from '../../API/api-endpoint';
import { useFormik } from 'formik';
import Swal from 'sweetalert2';
import SlickCarousel from "../../pages/SlickCarousel";
const scoreData = [1, 2, 3, 4, 5]
const CreateGoalWithoutVolume = ({ fetchReport, setShowAssesmentModal, showAssesmentModal, editData, setEditData }) => {
    const [programList, setProgramList] = useState([]);
    const [workoutList, setWorkoutName] = useState([]);

    const [goalList, setGoalList] = useState([]);

    console.log("editData", editData);
    const formik = useFormik({
        initialValues: {activity_id:4,goal:"",first:"",second:"",fourth:"",third:"",fifth:"",sixth:"",seventh:""},
         validate: (values) => {
            const errors = {};
            if (!values.goal) {
                errors.goal = "Goal name is required";
            }
            if (!values.activity_id) {
                errors.activity_id = "Activity name is required";
            }
            
            if (!values.first) {
                errors.first = "First is required";
              }
              if (!values.second) {
                errors.second = "Second is required";
              }
              if (!values.third) {
                errors.third = "Third is required";
              }
              if (!values.fourth) {
                errors.fourth = "Fourth is required";
              }
              if (!values.fifth) {
                errors.fifth = "Fifth is required";
              }
              if (!values.sixth) {
                errors.sixth = "Sixth is required";
              }
              if (!values.seventh) {
                errors.seventh = "Seventh is required";
              }
              // Repeat the pattern for other fields...
             
            return errors;
        },
        // validationSchema: {},
        onSubmit: (values, { resetForm }) => {
            handleSubmitAssesmentForm(values, resetForm)

        },
    });
    console.log("formik", formik?.values);
    const getAllProgramsData = async () => {
        const response = await getAllActivityData()
        console.log("response", response);
        setProgramList(response?.rows)
    }
    const getAllWorkoutName = async () => {
        const response = await getAllworkoutData()
        console.log("response", response?.rows);
        setWorkoutName(response)
    }
    // const getAllGoalsData = async () => {
    //     const response = await getAllGoalNameForYTA()
    //     console.log("response", response);
    //     setGoalList(response)
    // }
    const fetchDGoals = async (id) => {
      let response = await getFechAlGoals(id);
      setGoalList(response?.goals)
    };
    useEffect(() => {
        getAllProgramsData()
        getAllWorkoutName()
        // getAllGoalsData()
        fetchDGoals(4)
    }, [])

    const handleSubmitAssesmentForm = async (data, resetForm) => {
        let response = ""
        if (editData?.id) {
            response = await updateGoalWithoutVolumedata(data)
        } else {
            response = await createGoalWithoutVolumedata(data)
            console.log("response", response);
        }
        if (response?.status) {
            Swal.fire({
                title: "Success",
                text: response.message,
                icon: "success",
            });
            setShowAssesmentModal(false)
            fetchReport()
            resetForm()
            setEditData({})
            formik?.setValues({activity_id:4,first:"",second:"",fourth:"",third:"",fifth:"",sixth:"",seventh:""})
        } else {
            Swal.fire({
                title: "Error",
                text: response.message,
                icon: "error",
            });
        }
        console.log("response", response);
    }
    useEffect(() => {
        if (editData?.id) {
            const { srID, ...data } = editData
            console.log("data", data);
            formik?.setValues(data)
            formik?.setFieldValue("activity_id", data?.activity?.id)
        }
    }, [editData?.id])
    return (
        <Modal
      width={1200}
      open={showAssesmentModal}
      onCancel={() => {
        setShowAssesmentModal(false);
        setEditData({});
        formik.resetForm();
        formik?.setValues({
          activity_id:4,
          first: "",
          second: "",
          fourth: "",
          third: "",
          fifth: "",
          sixth: "",
          seventh: "",
        });
      }}
      footer={
        <div></div>
        //   loading={isLoading}
      }
    >
      <div className="headingCont">
        <span className="heading">{editData?.id ? "Edit " : "Create"}</span>{" "}
        <span className="orange heading">Fitness Goal</span>
        </div>

      <div className="parentCont">
        <form className="form1" onSubmit={formik.handleSubmit}>
          <Grid container >
            <Grid item className="mbtm" xs={12} sm={10.8}>
              <FormLabel>Activity Name<span className="text-[red]">*</span></FormLabel>

              <TextField
                fullWidth
                size="small"
                select
                SelectProps={{
                  MenuProps: {
                    PaperProps: {
                      style: {
                         scrollbarColor:"#E67E22 white",
                         scrollbarWidth:"thin"
                      },
                    },
                  },
                }}
                name="activity_id"
                value={
                  formik?.values?.activity_id ? formik?.values?.activity_id : "placeholder"
                }
                onChange={formik.handleChange}
                error={formik.touched.activity_id && formik.errors.activity_id}
                helperText={
                  formik.touched.activity_id && formik.errors.activity_id
                }
                disabled
                id="form-layouts-separator-select"
                labelId="form-layouts-separator-select-label"
                input={<OutlinedInput id="select-multiple-language" />}
              >
                <MenuItem value={"placeholder"} disabled>
                  Select Activity
                </MenuItem>
                {programList?.map((value, index) => {
                  return (
                    <MenuItem value={value?.id}>
                      {value?.activity_name}
                    </MenuItem>
                  );
                })}
              </TextField>
            </Grid>
            <Grid item className="mbtm" xs={12} sm={10.8}>
              <FormLabel>Goal Name<span className="text-[red]">*</span></FormLabel>

              <TextField
                fullWidth
                size="small"
                select
                SelectProps={{
                  MenuProps: {
                    PaperProps: {
                      style: {
                         scrollbarColor:"#E67E22 white",
                         scrollbarWidth:"thin"
                      },
                    },
                  },
                }}
                name="goal"
                value={formik?.values?.goal || "placeholder"}
                onChange={formik.handleChange}
                error={formik.touched.goal && formik.errors.goal}
                helperText={formik.touched.goal && formik.errors.goal}
                id="form-layouts-separator-select"
                labelId="form-layouts-separator-select-label"
                input={<OutlinedInput id="select-multiple-language" />}
              >
                <MenuItem value={"placeholder"} disabled>
                  Select Name
                </MenuItem>
                {goalList?.map((goalItem, index) => {
    return (
      <MenuItem key={index} value={goalItem?.goal}>
        {goalItem?.goal}
      </MenuItem>
    );
  })}
              </TextField>
            </Grid>

            <Grid className="marbot" container spacing={3}>
              <Grid item xs={12} sm={5.5}>
                <FormLabel>First<span className="text-[red]">*</span></FormLabel>

                <TextField
                  fullWidth
                  size="small"
                  select
                  name="first"
                  SelectProps={{
                    MenuProps: {
                      PaperProps: {
                        style: {
                           scrollbarColor:"#E67E22 white",
                           scrollbarWidth:"thin"
                        },
                      },
                    },
                  }}
                  value={formik?.values?.first ? formik?.values?.first : "placeholder"}
                  onChange={formik.handleChange}
                  error={formik.touched.first && formik.errors.first}
                  helperText={formik.touched.first && formik.errors.first}
                  id="form-layouts-separator-select"
                  labelId="form-layouts-separator-select-label"
                  input={<OutlinedInput id="select-multiple-language" />}
                >
                  <MenuItem value={"placeholder"} disabled>
                    Select Day
                  </MenuItem>
                  {workoutList?.map((value, index) => {
                    return (
                      <MenuItem value={value?.id}>{value?.workout}</MenuItem>
                    );
                  })}
                </TextField>
              </Grid>
              <Grid item xs={12} sm={5.5}>
                <FormLabel>Second<span className="text-[red]">*</span></FormLabel>

                <TextField
                  fullWidth
                  size="small"
                  select
                  name="second"
                  SelectProps={{
                    MenuProps: {
                      PaperProps: {
                        style: {
                           scrollbarColor:"#E67E22 white",
                           scrollbarWidth:"thin"
                        },
                      },
                    },
                  }}
                  value={formik?.values?.second ? formik?.values?.second : "placeholder"}
                  onChange={formik.handleChange}
                  error={formik.touched.second && formik.errors.second}
                  helperText={formik.touched.second && formik.errors.second}
                  id="form-layouts-separator-select"
                  labelId="form-layouts-separator-select-label"
                  input={<OutlinedInput id="select-multiple-language" />}
                >
                  <MenuItem value={"placeholder"} disabled>
                    Select Day
                  </MenuItem>
                  {workoutList?.map((value, index) => {
                    return (
                      <MenuItem value={value?.id}>{value?.workout}</MenuItem>
                    );
                  })}
                </TextField>
              </Grid>
            </Grid>

            <Grid className="marbot" container spacing={3}>
              <Grid item xs={12} sm={5.5}>
                <FormLabel>Third<span className="text-[red]">*</span></FormLabel>

                <TextField
                  fullWidth
                  size="small"
                  select
                  name="third"
                  SelectProps={{
                    MenuProps: {
                      PaperProps: {
                        style: {
                           scrollbarColor:"#E67E22 white",
                           scrollbarWidth:"thin"
                        },
                      },
                    },
                  }}
                  value={formik?.values?.third ? formik?.values?.third : "placeholder"}
                  onChange={formik.handleChange}
                  error={formik.touched.third && formik.errors.third}
                  helperText={formik.touched.third && formik.errors.third}
                  id="form-layouts-separator-select"
                  labelId="form-layouts-separator-select-label"
                  input={<OutlinedInput id="select-multiple-language" />}
                >
                  <MenuItem value={"placeholder"} disabled>
                    Select Day
                  </MenuItem>
                  {workoutList?.map((value, index) => {
                    return (
                      <MenuItem value={value?.id}>{value?.workout}</MenuItem>
                    );
                  })}
                </TextField>
              </Grid>
              <Grid item xs={12} sm={5.5}>
                <FormLabel>Fourth<span className="text-[red]">*</span></FormLabel>

                <TextField
                  fullWidth
                  size="small"
                  select
                  name="fourth"
                  SelectProps={{
                    MenuProps: {
                      PaperProps: {
                        style: {
                           scrollbarColor:"#E67E22 white",
                           scrollbarWidth:"thin"
                        },
                      },
                    },
                  }}
                  value={formik?.values?.fourth ? formik?.values?.fourth : "placeholder"}
                  onChange={formik.handleChange}
                  error={formik.touched.fourth && formik.errors.fourth}
                  helperText={formik.touched.fourth && formik.errors.fourth}
                  id="form-layouts-separator-select"
                  labelId="form-layouts-separator-select-label"
                  input={<OutlinedInput id="select-multiple-language" />}
                >
                  <MenuItem value={"placeholder"} disabled>
                    Select Day
                  </MenuItem>
                  {workoutList?.map((value, index) => {
                    return (
                      <MenuItem value={value?.id}>{value?.workout}</MenuItem>
                    );
                  })}
                </TextField>
              </Grid>
            </Grid>

            <Grid className="marbot" container spacing={3}>
              <Grid item xs={12} sm={5.5}>
                <FormLabel>Fifth<span className="text-[red]">*</span></FormLabel>

                <TextField
                  fullWidth
                  size="small"
                  select
                  name="fifth"
                  SelectProps={{
                    MenuProps: {
                      PaperProps: {
                        style: {
                           scrollbarColor:"#E67E22 white",
                           scrollbarWidth:"thin"
                        },
                      },
                    },
                  }}
                  value={formik?.values?.fifth ? formik?.values?.fifth : "placeholder"}
                  onChange={formik.handleChange}
                  error={formik.touched.fifth && formik.errors.fifth}
                  helperText={formik.touched.fifth && formik.errors.fifth}
                  id="form-layouts-separator-select"
                  labelId="form-layouts-separator-select-label"
                  input={<OutlinedInput id="select-multiple-language" />}
                >
                  <MenuItem value={"placeholder"} disabled>
                    Select Day
                  </MenuItem>
                  {workoutList?.map((value, index) => {
                    return (
                      <MenuItem value={value?.id}>{value?.workout}</MenuItem>
                    );
                  })}
                </TextField>
              </Grid>
              <Grid item xs={12} sm={5.5}>
                <FormLabel>Sixth<span className="text-[red]">*</span></FormLabel>

                <TextField
                  fullWidth
                  size="small"
                  select
                  name="sixth"
                  SelectProps={{
                    MenuProps: {
                      PaperProps: {
                        style: {
                           scrollbarColor:"#E67E22 white",
                           scrollbarWidth:"thin"
                        },
                      },
                    },
                  }}
                  value={formik?.values?.sixth ? formik?.values?.sixth : "placeholder"}
                  onChange={formik.handleChange}
                  error={formik.touched.sixth && formik.errors.sixth}
                  helperText={formik.touched.sixth && formik.errors.sixth}
                  id="form-layouts-separator-select"
                  labelId="form-layouts-separator-select-label"
                  input={<OutlinedInput id="select-multiple-language" />}
                >
                  <MenuItem value={"placeholder"} disabled>
                    Select Day
                  </MenuItem>
                  {workoutList?.map((value, index) => {
                    return (
                      <MenuItem value={value?.id}>{value?.workout}</MenuItem>
                    );
                  })}
                </TextField>
              </Grid>
            </Grid>
            
            <Grid className="marbot" container spacing={3}>
            <Grid item xs={12} sm={5.5}>
              <FormLabel>Seventh<span className="text-[red]">*</span></FormLabel>
              <TextField
                fullWidth
                size="small"
                select
                name="seventh"
                SelectProps={{
                  MenuProps: {
                    PaperProps: {
                      style: {
                         scrollbarColor:"#E67E22 white",
                         scrollbarWidth:"thin"
                      },
                    },
                  },
                }}
                value={formik?.values?.seventh ? formik?.values?.seventh : "placeholder"}
                onChange={formik.handleChange}
                error={formik.touched.seventh && formik.errors.seventh}
                helperText={formik.touched.seventh && formik.errors.seventh}
                id="form-layouts-separator-select"
                labelId="form-layouts-separator-select-label"
                input={<OutlinedInput id="select-multiple-language" />}
              >
                <MenuItem value={"placeholder"} disabled>
                  Select Day
                </MenuItem>
                {workoutList?.map((value, index) => {
                  return (
                    <MenuItem value={value?.id}>{value?.workout}</MenuItem>
                  );
                })}
              </TextField>
            </Grid>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Button
                className="btn"
                key="submit"
                type="primary"
                onClick={() => formik.handleSubmit()}
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </form>

        <div className="slick-container">
          <SlickCarousel />
        </div>
      </div>
    </Modal>
  );
};
export default CreateGoalWithoutVolume;
