import React, { useEffect, useState } from "react";
import { ContentState, EditorState, convertToRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
// import createImagePlugin from "draft-js-image-plugin";

import ImageOption from "./ImageOption";
import LinkOption from "./LinkOption";
import UnlinkOption from "./UnlinkOption";

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
// import "draft-js-image-plugin/lib/plugin.css";
import htmlToDraft from "html-to-draftjs";

// const imagePlugin = createImagePlugin();

const TextEditor = ({ initialContent = null,
  placeholder = "Start typing...",
  onContentChange,
  toolbarOptions = {},
  customClassNames = {},
  initial,
  setInitial }) => {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
 
  const onEditorStateChange = (newEditorState) => {
    setEditorState(newEditorState);
  
    try {
      // Convert the editor's content state to raw JS object
      const contentState = newEditorState.getCurrentContent();
      const rawContentState = convertToRaw(contentState);
  
      // Convert the raw content state to HTML
      const html = draftToHtml(rawContentState);
  
      // console.log("HTML content:", html); // Log HTML content for debugging
  
      if (onContentChange) {
        onContentChange(html);
      }
    } catch (error) {
      console.error("Error converting to HTML:", error);
    }
  };
  const sanitizeHtmlContent = (html) => {
    const div = document.createElement("div");
    div.innerHTML = html;
    const iframes = div.getElementsByTagName("iframe");
    while (iframes.length > 0) {
      iframes[0].parentNode.removeChild(iframes[0]);
    }
    return div.innerHTML;
  };
  useEffect(() => {
    if (initial) {
      if (typeof initialContent === "string") {
        const sanitizedContent = sanitizeHtmlContent(initialContent);
        const { contentBlocks, entityMap } = htmlToDraft(sanitizedContent);
        const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
        const editorState = EditorState.createWithContent(contentState);
        setEditorState(editorState);
        setInitial(false);
      }
    }
  }, [initial, initialContent, setInitial]);
  return (
    <div>
      <Editor
        editorState={editorState}
        onEditorStateChange={onEditorStateChange}
        toolbar={{
          options: [
            "inline",
            "blockType",
            "fontSize",
            "fontFamily",
            "list",
            "textAlign",
            "remove",
            "history",
          ],
        }}
        toolbarCustomButtons={[
          <ImageOption key="image-option" />,
          <LinkOption key="link-option" />,
          <UnlinkOption key="unlink-option" />,
        ]}
        // plugins={[imagePlugin]}
      />
    </div>
  );
};

export default TextEditor;
