import { Box, Toolbar, Typography } from "@mui/material";
import React from "react";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import "../../styles/navbar.css";
import { useNavigate } from "react-router-dom";
import InfoIcon from "@mui/icons-material/Info";

export default function NewGroupStepTwoNav({ setOpenPageOne}) {
  const navigate = useNavigate();

  return (
    <>
      <Toolbar className="appBar">
        <Box sx={{ display: "flex",cursor:"pointer" }}>
          <KeyboardBackspaceIcon
            onClick={() => setOpenPageOne("stepOne")}
            sx={{ margin: "auto" }}
          />
          <Box>
            <Typography
              className="chatTitle"
              style={{ paddingLeft: "1rem", fontSize: "16px" }}
            >
              Create New Group :{" "}
              <span style={{ color: "#707070" }}>Step 2/2</span>
            </Typography>
            <Typography
              className="select-buddies-text"
              style={{ marginTop: "-1rem" }}
            >
              Add Group Name & Image
            </Typography>
          </Box>
        </Box>
        <Box>
          <InfoIcon />
          {/* <img style={{ paddingRight: '0.5rem' }} height={20} src="/images/info-icon.png" alt="info" /> */}
        </Box>
      </Toolbar>
    </>
  );
}
