import React, { useState } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
// import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Skeleton from '@mui/material/Skeleton';
import axios from 'axios';
import { Avatar } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';

const ShowPost = () => {
    const [loading, setLoading] = useState(false);
    const [post, setPost] = useState(null);
    const [postId, setPostId] = useState('');

    const navigate = useNavigate()

    const token = 'b78ed8c9c33b65a4e85ae9c57634254d12a3c26bda45a562e40e6e38cdd9c7c82b3cb3ff009ecd45e730e7ab23f8669890045d53cc2babbf66f333f07fab912f98aa1e913ceb933ea5d153d7c1c836b78f722f781bc6483008713c11e44052820a2e1b5341681d74ac851fb6cf54194f1f4a3d17932877a9fb6403da22e7ea5394b3c73e703e871f3ff54c15c3afa4db973f09f83111491d50c729c2d66dc72506afa19b069158de44c6492f1304dd5ce69eb80568da0d3652980356d80ec768a8262b11f6404448a59a57389898b34ee5d1f0b403f04abdc65a4580114cb616914b05feb5ab956e98d01a92a33ef6d0'

    const fetchPost = async () => {
        const config = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }

        const payload = {
            postId: "1088"
        }

        try {
            setLoading(true);
            const { data } = await axios.post('/v5/post/get_post_by_id.php', payload, config)
            console.log(data.object)
            setPost(data.object);
        } catch (error) {
            console.error('Error fetching post:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleInputChange = (event) => {
        setPostId(event.target.value);
    };

    const handleButtonClick = () => {
        fetchPost();
    };

    return (
        <div style={{ maxWidth: '27rem', margin: '0 auto', paddingTop: '4rem' }}>
            <input type="text" value={postId} onChange={handleInputChange} placeholder="Enter Post ID" />
            <button onClick={handleButtonClick}>Fetch Post</button>

            {loading ? (
                <Skeleton sx={{ marginTop: "12rem" }} variant="rectangular" width="100%" height={300} animation="wave" />
            ) : (
                <Card sx={{ marginTop: "12rem" }}>
                    <Avatar alt="post profile pic" src={post?.imageUrl} sx={{ marginLeft: '0.8rem' }} />
                    <CardContent>
                        <h2>{post?.title}</h2>
                        <p>{post?.postDescription}</p>
                        <Button
                            onClick={() => {
                                Cookies.set('postId', postId)
                                navigate('/show-post/pick-chats')
                            }}
                            variant="contained" color="primary">Share Post</Button>
                    </CardContent>
                </Card>
            )}
        </div>
    );
};

export default ShowPost;
