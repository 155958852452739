import {  FormLabel, Grid, TextField } from '@mui/material';
import { Button, Modal  } from 'antd'
import React, { useEffect  } from 'react'
import { createAssesmentform, uploadsaveFile } from '../../API/api-endpoint';
import { useFormik } from 'formik';
import Swal from 'sweetalert2';
import SlickCarousel from '../SlickCarousel';
import { IoCloseSharp } from 'react-icons/io5';
const SelfAssesment = ({ idd,editFormData, setEditFormData, setShowAssesmentModal, showAssesmentModal,fetchAssesmentData }) => {
const urlRegex = /^(https?|chrome):\/\/[^\s$.?#].[^\s]*$/;
    const formik = useFormik({
        initialValues: {
          review:"" , review_rating: "", review: "", reviewrating_comments: "",
            images: [],date:new Date(),videourl:""
        }, 
        validate: (values) => {
          const errors = {};
          if (!values.videourl) {
            errors.videourl = "Videourl is required";
          }else if (!urlRegex.test(values.videourl)) {
            errors.videourl = "Invalid URL format";
          }
          return errors;
        },
        // validationSchema: {},
        onSubmit: (values, { resetForm }) => {
            handleSubmitAssesmentForm(values, resetForm)

        },
    });
   
   

    const handleSubmitAssesmentForm = async (data) => {
        const reponse = await createAssesmentform(data)
        if (reponse?.status) {
            Swal.fire({
                title: "Success",
                text: reponse.message,
                icon: "success",
              });
              setShowAssesmentModal(false)
              fetchAssesmentData(idd)
        }else{
            Swal.fire({
                title: "Error",
                text: reponse.message,
                icon: "error",
              });
        }
    }
      useEffect(() => {
        if (editFormData?.id) {
      const { activity,createdAt,weightage,updatedAt,review_rating,reviewrating_comments,userformreviewimages,totalscore, user,form_review_section_options_users,user_id,...getAllGoalsData } = editFormData;
let imagesshow = editFormData?.userformreviewimages[editFormData?.userformreviewimages?.length-1]
          let data ={...getAllGoalsData, options:editFormData?.options?.length>0?editFormData?.options:[],user_id_to_sent:editFormData?.user_id,images:imagesshow?.image?[imagesshow?.image]:[]}
          formik?.setValues(data)
        }
      }, [editFormData?.id]);
    
  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    if (file) {
      const formData = new FormData();
      formData.append("file", file);

      const responst = await uploadsaveFile(formData);
      if (responst?.status) {
        formik.setFieldValue("images",[ responst?.file]);
      }
    }
  };
    return (
        <Modal
        width={1200}
            
            // title={editData ? "Edit Challenge" : "Add Challenges"}
            open={showAssesmentModal}
            onCancel={() =>{ setShowAssesmentModal(false)
              setEditFormData({})
              formik?.setValues({
                score: "",  review_rating: "", review: "", reviewrating_comments: "",
                images: [], imagesdonts: [],date:new Date()
            })
            }}
            footer={
                <div></div>
                //   loading={isLoading}
              }
        >
        <div className="headingCont">
            <span className="heading">Self</span>{" "}
            <span className="orange heading">Assesement</span>
          </div>

      <div className="parentCont">
      
            <form className="form1">
                <Grid container spacing={2}>
                   
                    <Grid item xs={12} sm={3}>
                        <FormLabel >Review:</FormLabel>
                    </Grid>
                    <Grid item xs={12} sm={8} >
                        <TextField
                            fullWidth
                            placeholder="Review"
                            size="small"
                            type="text"
                            name="review"
                            value={formik?.values?.review}
                            onChange={formik.handleChange}
                            error={formik.touched.review && formik.errors.review}
                      helperText={
                        formik.touched.review && formik.errors.review
                      }
                            
                        />
                    </Grid>
                    
                    {/* <Grid item xs={12} sm={3}>
                        <FormLabel >Review Comments:</FormLabel>
                    </Grid>
                    <Grid item xs={12} sm={8} >
                        <TextField
                            fullWidth
                            placeholder="Review Comments"
                            size="small"
                            type="text"
                            name="reviewrating_comments"
                            value={formik?.values?.reviewrating_comments}
                            onChange={formik.handleChange}
                            error={formik.touched.reviewrating_comments && formik.errors.reviewrating_comments}
                            helperText={
                              formik.touched.reviewrating_comments && formik.errors.reviewrating_comments
                            }
                            
                        />
                    </Grid> */}
                    <Grid item xs={12} sm={3}>
                        <FormLabel >Images:</FormLabel>
                    </Grid>
                    <Grid item xs={12} sm={8} className="relative">
              <TextField
                fullWidth
                placeholder="Zone"
                size="small"
                type={formik?.values?.images?.length>0 ? "text" : "file"}
                name="images"
                disabled={formik?.values?.images?.length>0}
                value={formik?.values?.images?.length>0?formik?.values?.images[0]:null}
                onChange={handleFileUpload}
                error={formik.touched.images && formik.errors.images}
                helperText={formik.touched.images && formik.errors.images}
                inputProps={{
                  accept: ".jpg, .png .jpeg", // Specify accepted file formats here
                }}
              />
              {formik?.values?.images?.length>0 && (
                <IoCloseSharp
                  onClick={() => {
                    formik.setFieldValue("images", []);
                  }}
                  color="darkgray"
                  className="cursor-pointer absolute top-[5px] right-[-10px]"
                  style={{ fontSize: "24px" }}
                />
              )}
            </Grid>
                    <Grid item xs={12} sm={3}>
                        <FormLabel >Video URL:</FormLabel>
                    </Grid>
                    <Grid item xs={12} sm={8} >
                        <TextField
                            fullWidth
                            placeholder="rating"
                            size="small"
                            type="text"
                            name="videourl"
                            value={formik?.values?.videourl}
                            onChange={formik.handleChange}
                            error={formik.touched.videourl && formik.errors.videourl}
                      helperText={
                        formik.touched.videourl && formik.errors.videourl
                      }
                            
                        />
                    </Grid>
                   
                    <Grid item xs={12} sm={6}>
                    <Button
                      className="btn"
                      key="submit"
                      type="primary"
                      onClick={() => formik.handleSubmit()}
                    >
                      Submit
                    </Button>
                  </Grid>
                  
                </Grid>
            </form>
            
            <div className="slick-container">
          <SlickCarousel />
        </div>
      </div>
        </Modal>
    )
}

export default SelfAssesment
