import React from 'react'
import Header from '../components/Header'
import { Button } from 'antd'
import { Card, CardContent, FormLabel, Grid, MenuItem, OutlinedInput, TextField } from '@mui/material'
import { useFormik } from 'formik'
import Swal from 'sweetalert2'
import { registerCoach } from '../API/api-endpoint'
import SlickCarousel from './SlickCarousel'

const AdminRegstration = () => {

  const formik = useFormik({
    initialValues: {
      firstname: "",
      lastname: "",
      email: "",
      password: "",
      coachtype: "",

    }, validate: (values) => {
      const errors = {};
      if (!values.firstname) {
        errors.firstname = "First name is required";
      }
      if (!values.lastname) {
        errors.lastname = "Last name is required";
      }
      if (!values.email) {
        errors.email = "Email is required";
      } else if (!/^\S+@\S+\.\S+$/.test(values.email)) {
        errors.email = "Invalid email address";
      }

      if (!values.password) {
        errors.password = "Password is required";
      } else if (
        !/^(?=.*[a-zA-Z])(?=.*\d)(?=.*[\W_])\S{8,}$/.test(
          values.password
        )
      ) {
        errors.password =
        "Password must be at least 8 characters long and contain a character, number, and a special character without spaces";
      }
      
      if (!values.coachtype) {
        errors.coachtype = "Admin type is required";
      }
      return errors;
    },
    // validationSchema: {},
    onSubmit: (values, { resetForm }) => {
      handleSubmitAssesmentForm(values, resetForm)

    },
  });


  const handleSubmitAssesmentForm = async (data, resetForm) => {
    let response = ""
    let allDatadata = { ...data, role: 1 }
    response = await registerCoach(allDatadata)

    if (response?.status) {
      Swal.fire({
        title: "Success",
        text: response.message,
        icon: "success",
      });
      // fetchReport()
      resetForm()
      formik?.resetForm()
      formik?.setValues({ firstname:"",lastname:"",email:"",password:"",coachtype:"" })
    } else {
      Swal.fire({
        title: "Error",
        text: response.message,
        icon: "error",
      });
    }
    console.log("response", response);
  }
  return (
    <div>
      <Header />
      <div className="grid grid-cols-1 xl:grid-cols-5 items-start gap-x-4 " style={{ marginTop: "100px" }}>

      </div>
      <div className='p-4 m-[2% 6% 2% 6%]'>
       
        <div className='flex justify-center'>
          <Card sx={{ width: "1200px", margin: "2% 6% 2% 6%", boxShadow: "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px" }}>
          <CardContent>
          <div className="headingCont">
            <span className="heading">Admin </span>{" "}
            <span className="orange heading">Registration</span>
          </div>
          <div className="parentCont">
            <form className='form1'>
              <Grid container spacing={2}>

                <Grid item xs={12} sm={11}>
                  <FormLabel >First Name<span className="text-[red]">*</span></FormLabel>
                  <TextField
                    fullWidth
                    placeholder="First Name"
                    size="small"
                    type="text"
                    name="firstname"
                    value={formik?.values?.firstname}
                    onChange={formik.handleChange}
                    error={formik.touched.firstname && formik.errors.firstname}
                    helperText={
                      formik.touched.firstname && formik.errors.firstname
                    }
                    onInput={(e) => {
                      e.target.value = e.target.value.replace(/[^a-zA-Z\s]/g, '');
                      e.target.value = e.target.value.replace(/\b\w/g, (char) => char.toUpperCase());
                      if (e.target.value.length > 20) {
                        e.target.value = e.target.value.slice(0, 20); // Trim the input to 20 characters
                      }
                    }}

                  />
                </Grid>

                <Grid item xs={12} sm={11}>
                  <FormLabel >Last Name<span className="text-[red]">*</span></FormLabel>
                  <TextField
                    fullWidth
                    placeholder="Last Name"
                    size="small"
                    type="text"
                    name="lastname"
                    value={formik?.values?.lastname}
                    onChange={formik.handleChange}
                    error={formik.touched.lastname && formik.errors.lastname}
                    helperText={
                      formik.touched.lastname && formik.errors.lastname
                    }
                    onInput={(e) => {
                      e.target.value = e.target.value.replace(/[^a-zA-Z]/g, '');
                      e.target.value = e.target.value.replace(/\b\w/g, (char) => char.toUpperCase());
                      if (e.target.value.length > 20) {
                        e.target.value = e.target.value.slice(0, 20); // Trim the input to 20 characters
                      }
                    }}

                  />
                </Grid>

                <Grid item xs={12} sm={5.5}>
                  <FormLabel >Email<span className="text-[red]">*</span></FormLabel>
                  <TextField
                    fullWidth
                    placeholder="Email"
                    size="small"
                    type="text"
                    name="email"
                    value={formik?.values?.email}
                    onChange={formik.handleChange}
                    error={formik.touched.email && formik.errors.email}
                    helperText={
                      formik.touched.email && formik.errors.email
                    }

                  />
                </Grid>

                <Grid item xs={12} sm={5.5}>
                  <FormLabel >Password<span className="text-[red]">*</span></FormLabel>
                  <TextField
                    fullWidth
                    placeholder="Password"
                    size="small"
                    type="text"
                    name="password"
                    value={formik?.values?.password}
                    onChange={formik.handleChange}
                    error={formik.touched.password && formik.errors.password}
                    helperText={
                      formik.touched.password && formik.errors.password
                    }

                  />
                </Grid>


                <Grid item xs={12}  sm={11} >
                  <FormLabel >Coach Type<span className="text-[red]">*</span></FormLabel>
                  <TextField
                    fullWidth
                    size="small"
                    select
                    name="coachtype"
                    value={formik?.values?.coachtype}
                    onChange={formik.handleChange}
                    error={formik.touched.coachtype && formik.errors.coachtype}
                    helperText={
                      formik.touched.coachtype && formik.errors.coachtype
                    }

                    id="form-layouts-separator-select"
                    labelId="form-layouts-separator-select-label"
                    input={<OutlinedInput id="select-multiple-language" />}
                  >
                    <MenuItem value={""} disabled>
                      Select Coach Type
                    </MenuItem>
                    <MenuItem value={"parttime"}>
                      Part Time
                    </MenuItem>
                    <MenuItem value={"fulltime"}>
                      Full Time
                    </MenuItem>

                  </TextField>
                </Grid>

                <Grid item xs={12} md={3} sm={3} sx={{ textAlign: "left" }}>
                  <Button className="btn" key="submit" type="primary" onClick={() => formik.handleSubmit()}>Submit</Button>
                </Grid>
              </Grid>
            </form>
            <div className="slick-container">
              <SlickCarousel />
            </div>
          </div>
        </CardContent>
            </Card>
        </div>



      </div>
    </div>
  )
}

export default AdminRegstration
