import React, { useEffect, useState } from "react";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import MobileStepper from "@mui/material/MobileStepper";
import Button from "@mui/material/Button";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import DirectionsRunIcon from "@mui/icons-material/DirectionsRun";
import Select from 'react-select';

import "./Flow.css";
import Swal from "sweetalert2";
import {
  createUserSubscription,
  getAllCountry,
  getAllGoals,
  getSubscriptionPlanBYProgramID,
  getAllYoskaActivities,
  getAllyoskaProgramByActivityID,
  ValidatePromoCode,
  getAllState,
  getAllCities,
  getFechAlGoals,
  getDayoptionValues,
  URL,
  getPaymentCredData,
  updatePrfile,
  getAllyoskaProgramFeateryID,
  getdiscountcouponData,
  getSystemConfigrationData,
} from "../../API/api-endpoint";
import { styled } from "@mui/material/styles";
import { useNavigate,useLocation } from "react-router-dom";
import MuiCard from "@mui/material/Card";
import { Autocomplete, FormControlLabel, Grid, Radio, RadioGroup, TextField } from "@mui/material";
import { showError } from "../../components/Messages";
import SlickCarousel from "../SlickCarousel";
import { calculateActualpriceAfterGST, validateDOB } from "../../utils/Resubale";

// ** Styled Components
const Card = styled(MuiCard)(({ theme }) => ({
  [theme.breakpoints.up("sm")]: { width: "28rem" },
}));

const REnewSubscription = () => {
  const navigate = useNavigate();
  const [plan, setPlan] = useState("100");
  const [subscriptionName, setSubscriptioName] = useState("");
  const [promocodeList, setPromocodeList] = useState("");
  const [dobError, setDobError] = useState(false);
  console.log("dobError",dobError);
  const [amount, setAmount] = useState("100");
  const [duration, setduration] = useState("100");
  const [activity, setActivity] = useState("");
  const [selectActivity, setSelectActivity] = useState("");
  const [selectProgram, setselectProgram] = useState("");
  const [getGSTPer, setGSTper] = useState();
  const [selectProgramFeater, setselectProgramFeature] = useState();
  const [price, setPrice] = useState("");
  const [goalsList, setGoalsList] = useState([]);
  const [yoskaActivitieList, setYoskaActivitieList] = useState([]);
  const [yoskaProgramList, setYoskaProgramList] = useState([]);
  const [SubscriptionPlanList, setSubscriptionPlanList] = useState([]);
  console.log("SubscriptionPlanList",SubscriptionPlanList);
  const [CountryList, setCountryList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [fetchGoals, setFechGoals] = useState([]);
  const [paymentcreds, setPaymentCred] = useState()
  const [discountPrice, setDiscountPrice] = useState(0);
  const [discountAmountAfter, setAfterDicount] = useState(0);

  const [afteraddGSTAmount, setAfterAddGST] = useState(0);

console.log("discountAmountAfter",afteraddGSTAmount,discountAmountAfter);
  const [getDaysoptionList, setfetchDaysoptionList] = useState([]);
  const [activityBadge, setActivityBadg] = React.useState();

  const [formValue, setFormValue] = useState({
    name: "",
    address: "",
    email: "",
    phoneNumber: "",
    country: undefined,
    state: undefined,
    city: undefined,
    pincode: "",
    company_name: "",
    promocode: "",
  });
  const [formGoalValue, setFormGoalValue] = useState({
    process: "M",
    goal: "",
    daysinweek: "",
    daysoption: "",
    program_id: activity?.id,
  });
  useEffect(() => {
    if (activity?.id) {
      fetchDGoals();
      setFormGoalValue({ ...formGoalValue, program_id: activity?.id });
    }
  }, [activity.id]);
  const FullName = localStorage.getItem("fullname");
  const email = localStorage.getItem("email");
  const phoneNumber = localStorage.getItem("phonenumber");

  useEffect(() => {
    if (selectProgram) {

      getSubscriptionPlan();
    }
   
  }, [selectProgram]);
useEffect(()=>{
  if (subscriptionName) {
      getAllProgramsData(subscriptionName)
    }
},[subscriptionName])
  useEffect(() => {
    getdiscountcouponData()
    getGSTPersentage()
  }, []);


const getGSTPersentage = async()=>{
  const response  = await getSystemConfigrationData()
  let fileterTax = response?.filter(ele=>ele?.type=="tax")
  console.log("fileterTax",fileterTax[0]);
  setGSTper(parseInt(fileterTax[0]?.value))
}
  const location = useLocation();

  useEffect(() => {
    const handlePopState = (event) => {
      localStorage.clear()
      navigate("/")
    };

    // Push a new state onto the history stack
    window.history.pushState({ page: location.pathname }, '', location.pathname);

    window.addEventListener('popstate', handlePopState);

    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, [location]);

  const getAllProgramsData = async (subscriptionName) => {
    const response = await getdiscountcouponData()
    setDiscountPrice(0)
    setAfterDicount(amount)
    let gstAmount = calculateActualpriceAfterGST(amount,getGSTPer)
    setAfterAddGST(gstAmount)
    setFormValue({...formValue,promocode:""})
    const filterCoupan = response?.filter((data)=>data.subscriptionpackage?.name ==subscriptionName)
    if (filterCoupan?.length>0 ) {
      let modifiData = filterCoupan?.map((ele) => {
        return {
          label: ele?.coupon,
          value: ele?.coupon
        };
      });
      setPromocodeList(modifiData);
    }else{
      setPromocodeList([]);
    }
   
  };
  useEffect(() => {
    setFormValue({
      ...formValue,
      name: FullName,
      phoneNumber: formValue.phoneNumber ? phoneNumber : phoneNumber,
      email: email,
    });
    if (formGoalValue?.daysinweek) {
      fetchDaysOptuon();
    }
  }, [formGoalValue?.daysinweek]);
  const fetchDaysOptuon = async () => {
    const response = await getDayoptionValues(
      formGoalValue?.daysinweek,
      activity?.id
    );
    if (response) {
      setfetchDaysoptionList(response);
    }
  };
  const handleCountryChange = async (event, newValue) => {
    const countryId = newValue;
    setFormValue({
      ...formValue,
      country: countryId?.id, // Update the selected country in formValue
    });
    // Fetch states based on the selected country ID
    const response = await getAllState(countryId?.id);
    setStateList(response);
  };
  const handleStateChange = async (event, newValue) => {
    const stateID = newValue;
    setFormValue({
      ...formValue,
      state: stateID?.id, // Update the selected country in formValue
    });
    // Fetch states based on the selected country ID
    const response = await getAllCities(formValue.country, stateID?.id);
    setCityList(response);
  };
  //===========================get data from apis start================
  const fetchDGoals = async () => {
    let response = await getFechAlGoals(activity?.id);
    setFechGoals(response);
  };
  const getGoals = async () => {
    let response = await getAllGoals();
    setGoalsList(response);
  };

  const getYoskaActivities = async () => {
    let response = await getAllYoskaActivities();
    let showResponse = response?.filter((ele)=>ele?.is_under_coach_profile==1)
    console.log("showResponse",response,showResponse);
    setYoskaActivitieList(showResponse);
  };

  const getSubscriptionPlan = async () => {
    setSubscriptionPlanList([])
    let response = await getSubscriptionPlanBYProgramID(
      selectProgram["program_id"]
    ); //we have to send program_id here
    setSubscriptionPlanList(response);
  };
 
  const getAllProgramByActivityID = async (setActiveStep) => {
    setYoskaProgramList([])
    setselectProgram("");
    let response = await getAllyoskaProgramByActivityID(activity["id"]);
    if (response?.length > 0) {
      const result = await getAllyoskaProgramFeateryID(activity["id"])
      if (result.status) {
        setselectProgramFeature(result?.data)

      }
      setActiveStep((prevActiveStep) => prevActiveStep + 1)
      setselectProgram(response[0]);
      setYoskaProgramList(response);
    } else {
      showError(response?.message)
    }

  };

  const getCountry = async () => {
    let response = await getAllCountry();
    setCountryList(response);
  };

  useEffect(() => {
    getGoals();
    getYoskaActivities();
    getCountry();
  }, []);

  //===========================get data from apis end==================
  const fetchReport = async () => {
    const response = await getPaymentCredData()
    console.log("responsepayment",response);
    setPaymentCred(response[0])
  }
  useEffect(() => {
    fetchReport()
  }, [])
  const createUserSubscriptionplan = async (body) => {
    let userId = localStorage.getItem('userId')
    const newbody = JSON.parse(JSON.stringify(body));
    newbody.plan_id = plan;
    newbody.program = selectProgram?.program_id;
    newbody.price = discountAmountAfter==0? discountAmountAfter.toString():afteraddGSTAmount.toString();
    newbody.user_id  = userId

    let result = await createUserSubscription(newbody);

    if (result.status) {
      localStorage.removeItem("userSubsription")
      let token = localStorage.getItem('userTokentoken')
      localStorage.setItem('token',token)
      localStorage.removeItem("userDetails")
      localStorage.removeItem("userTokentoken")
      navigate("/strava");
    } else {
      Swal.fire({
        title: "Error",
        text: "SomeThing Went Wrong",
        icon: "error",
      });
    }
  };
  const createUserSubscriptionplanWithoutRazorpay = async () => {
    let data = {
      plan_id: plan,
      program: selectProgram?.program_id,
      price: discountAmountAfter==0? discountAmountAfter.toString():afteraddGSTAmount.toString(),
      razorpay_payment_id: "payment_without_razorpay"
    }
    console.log("without payment",data);

    let result = await createUserSubscription(data);

    if (result.status) {
      let token = localStorage.getItem('userTokentoken')
      localStorage.setItem('token',token)
      localStorage.removeItem("userDetails")
      localStorage.removeItem("userSubsription")
      localStorage.removeItem("userTokentoken")
      navigate("/strava");
    } else {
      Swal.fire({
        title: "Error",
        text: "SomeThing Went Wrong",
        icon: "error",
      });
    }
  };
 
  // ====================== razorPay ================================
  const options = {
    key: paymentcreds?.key,
    amount: afteraddGSTAmount * 100, //  = INR 1
    name: "Yoska",
    description: "some description",
    image: "https://cdn.razorpay.com/logos/7K3b6d18wHwKzL_medium.png",
    handler: function (response) {
      createUserSubscriptionplan(response);
    },
    prefill: {
      name: FullName,
      contact: phoneNumber, //"+919876543210", // phoneNumber,
      email: email,
    },
    notes: {
      address: `${formValue.company_name} ${formValue.address} 
                ${formValue.city} ${formValue.pincode} 
                ${formValue.state} ${formValue.country}`,
    },
    theme: {
      color: "orange",
      hide_topbar: false,
    },
  };

  const openPayModal = (options) => {
    var rzp1 = new window.Razorpay(options);
    rzp1.open();
  };

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://checkout.razorpay.com/v1/checkout.js";
    script.async = true;
    document.body.appendChild(script);
  }, []);
  // ====================== razorPay ================================
  const calculateActualPrice = (amount, discountPercentage) => {
    const discountAmount = (amount * discountPercentage) / 100;
    console.log("discountAmount",discountAmount,amount);
    return amount - discountAmount;
  };
// useEffect(()=>{

//   const actualPrice = calculateActualPrice(amount, discountPrice);
//   console.log("actua",actualPrice,amount,discountPrice);
// },[amount,discountPrice])
  const applyPromoCode = async () => {
    if (formValue.promocode.length == 0) {
      Swal.fire({
        title: "Error",

        text: "Please Enter the Promocode",
        icon: "error",
      });
    } else {
      let response = await ValidatePromoCode(formValue.promocode);
      if (response.status) {
        Swal.fire({
          title: "Success",
          text: "Coupon is valid",
          icon: "success",
        });
        setDiscountPrice(parseInt(response?.coupon["discount"]));
  const actualPrice = calculateActualPrice(amount, parseInt(response?.coupon["discount"]));
  setAfterDicount(actualPrice)
  let gstAmount = calculateActualpriceAfterGST(actualPrice,getGSTPer)
  setAfterAddGST(gstAmount)

      } else {
        Swal.fire({
          title: "Error",
          text: response,
          icon: "error",
        });
      }
    }
  };


  const renderActivity = () => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "20px",
          width: "100%",
          paddingBottom: "20px",
        }}
      >
        {yoskaActivitieList?.map((item, index) => {
          return (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                border:
                  activity["id"] === item["id"]
                    ? "solid 1px orange"
                    : "solid 1px lightgray",
                borderRadius: "10px",
                p: 1,
                cursor: "pointer",
              }}
              key={index}
              onClick={() =>{
                 setActivity(item)
                setselectProgram("")
                setPrice("")
                }}
            >
              {/*
      <img
        src={`${URL}/static/public/assets/${activity["badge"]}`}
        alt="..."
        className="w-10 h-10 rounded-full border-2"/>
      */}
              <img
                src={`${URL}/static/public/assets/${item["badge"]}`}
                alt="..."
                className="w-10 h-10 rounded-full border-2" />
              {/* <img src={item["yosk-activity-icon"]} className="icon" /> */}
              <p
                style={{
                  margin: "0",
                  paddingLeft: "10px",
                  color: activity["id"] === item["id"] ? "orange" : "",
                }}
              >
                {item["activity_name"]}
              </p>
            </Box>
          )
        })}

      </div>
    );
  };


  const renderRunningPricing = () => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "10px",
          width: "100%",
          paddingBottom: "20px",
        }}
      >
        {SubscriptionPlanList.map((item, index) => (
          <Box
            key={index}
            sx={{
              display: "flex",
              alignItems: "center",
              border: plan === item.id && "solid 1px orange",
              borderBottom: plan !== item.id && "solid 2px gray",
              flexDirection: "column",
              borderRadius: "10px",
              p: 1,
            }}
            onClick={() => {
              setPrice(item.price);
              setAmount(`${item.price}`);
              setduration(item?.duration);
              setPlan(item.id);
              setSubscriptioName(item.name)
            }}
          >
            <p
              style={{
                margin: "0",
                color: plan === item.id ? "orange" : "",
              }}
            >
              {item.name}
            </p>
            <p
              style={{
                margin: "0",
                color: plan === item.id ? "orange" : "",
              }}
            >
              ₹{item.price}/{item.billing_cycle}
            </p>
          </Box>
        ))}
      </div>
    );
  };
  
 
  const today = new Date();
  const twelveYearsAgo = new Date(today.getFullYear() - 14, today.getMonth(), today.getDate());
  
  const twelveYearsAgoString = twelveYearsAgo.toISOString().split("T")[0];
  useEffect(() => {
    if(formValue?.dob){
      const dobValidationResult = validateDOB(formValue?.dob);
      if(dobValidationResult === 'Date of birth must be at least 14 years ago.'){
        setDobError(true)
      }
      else{
        setDobError(false)
      }
    }
  }, [formValue?.dob,dobError]);
 
  const renderPayment = () => {
    return (
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          paddingBottom: "20px",
        }}
      >
        <div
          style={{
            width: "100%",
            // padding: "0px 20px",
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            justifyContent: "center",
          }}
        >
          <div style={{
            borderBottom: "solid 1px lightgray", padding: "10px", display: "flex",
            justifyContent: "space-between",
          }}>
            <div>Monthly Program : </div>
            <div>{selectProgram["program_name"]}</div>
          </div>
          <div
            style={{
              borderBottom: "solid 1px lightgray",
              padding: "10px",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div>Monthly Price : </div>
            <div>₹{(amount / duration).toFixed(2)}</div>
          </div>
          <div
            style={{
              borderBottom: "solid 1px lightgray",
              padding: "10px",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div>Discount: </div>
            <div>{discountPrice}%</div>
          </div>
          {parseInt(discountAmountAfter) !=0 &&

          <div
            style={{
              display: "flex",
              padding: "10px 10px 0px 10px",
              justifyContent: "space-between",
            }}
          >
            <div>Sub Total </div>
            
            <div>₹{discountAmountAfter}</div>
          </div>
          }
            {parseInt(discountAmountAfter) !=0 &&
           <div
            style={{
              display: "flex",
              padding: "0px 10px 0px 10px",
              justifyContent: "space-between",
            }}
          >
            <div >GST </div>
            
            <div>{getGSTPer}%</div>
          </div>
          }
          
          <div
            style={{
              display: "flex",
              padding: "10px",
              justifyContent: "space-between",
            }}
          >
            <div style={{fontWeight:"600"}}>Total Price </div>
            <div>₹{afteraddGSTAmount}</div>
          </div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <button
              style={{
                width: "50%",
                background: "black",
                padding: "10px 10px",
                borderRadius: "20px",
                color: "white",
              }}
              onClick={() => {
                if (discountAmountAfter === 0) {
                  createUserSubscriptionplanWithoutRazorpay()
                } else {
                  openPayModal(options);
                }
              }}
              
            >
              Click here to Pay
            </button>
          </div>
        </div>
      </div>
    );
  };

  const renderPrograms = () => {
    return (
      <>
        <p>Select Program For Getting Subscription Plans</p>
        <select
          className="running-step-input"
          name="Program"
          id="program"
          onChange={(e) => {
            setselectProgram(yoskaProgramList[e.target.value]);
          }}
        >
          {yoskaProgramList.map((program, index) => {
            return <option value={index}>{program.program_name}</option>;
          })}
        </select>
      </>
    );
  };
  {/*

*/}
  const steps = [
   
   
    {
      label: "Here are some activities to choose",
      description: renderActivity(),
    },
   
    {
      label: renderPrograms(),
      description: renderRunningPricing(),
    },
    {
      label: "Make a Payment",
      description: renderPayment(),
    },
  ];

  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const [userDetail, setuserDetail] = React.useState(0);
  const [userSubscription, setuserSubscription] = React.useState(0);



  const maxSteps = steps.length;
  let data = localStorage.getItem("userDetails")
  let data1 = localStorage.getItem("userSubsription")
  let usersubsriptionData = JSON.parse(data1)
  let userData = JSON.parse(data)
  useEffect(() => {

    setuserDetail(userData)
    setuserSubscription(usersubsriptionData)
    if (userData?.plan_id) {
      setPlan(userData?.plan_id)
      setPrice(userData?.price)
      setAmount(userData?.price)
    setAfterDicount(userData?.price)
      setselectProgram({ ...selectProgram, program_id: userData?.program_id, program_name: userData?.program_name, price: userData?.price })
      setActiveStep(8)
    }

  }, [userData?.plan_id])
  const handleNext = async () => {
    if (activeStep === 0) {
      if (activity.length === 0) {
        Swal.fire({
          title: "Error",
          text: "Please Select Activity",
          icon: "error",
        });
      } else {
        setActivityBadg(activity?.badge)
        getAllProgramByActivityID(setActiveStep);

      }
    } else if (activeStep === 1) {
      if (SubscriptionPlanList?.length > 0) {
        if (!price) {
          Swal.fire({
            title: "Error",
            text: "Please Select Any Plans",
            icon: "error",
          });
        } else {
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
      } else {
        Swal.fire({
          title: "Error",
          text: "Please Choose another programs first",
          icon: "error",
        });
      }

    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    if (userDetail?.plan_id) {
      localStorage.removeItem("userDetails")
      setActiveStep(0);
      setuserDetail({})
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }

  };

  
  const userID = localStorage.getItem("userId");
  
  return (
    <Box
      className="flow-section"
      style={
        (activeStep==6) ? 
        {
          width: "100vw",
          padding: "40px",
          height: "unset"
          // background: `url(${Background}) center no-repeat`, // Replace 'your-background-image.jpg' with the path to your background image
          // backgroundSize: "100% 100vh",
        }
        :
        {
        width: "100vw",
        padding: "40px",
        height: "100vh", // 100% of the viewport height
        // background: `url(${Background}) center no-repeat`, // Replace 'your-background-image.jpg' with the path to your background image
        // backgroundSize: "100% 100vh",
      }}
    >
      <Grid container>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={7} style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            // padding: "20px"
          }}>
            <div style={{width:"100%"}}>
              <Typography
                variant="h6"
                sx={{
                  padding: "0",
                  margin: "0",
                  // textAlign: "center",
                  // color: "orange",
                  color: activeStep == 3 ? "white" : "orange",
                  paddingBottom: "20px",
                  fontSize: activeStep == 3 ? "40px" : ""
                }}
              >
              <div className="headingCont">
              <Typography variant="h4" className="heading"><span  style={{color:"orange"}}> {steps[activeStep]?.label}</span></Typography>{" "}
            </div>
                
              </Typography>
              {/*
              maxWidth: activeStep == 3 ? 800 : 500,
            */}
              <Box sx={{ height: "100%",  width: "100%", fontSize:"20px" }}>
                {steps[activeStep].description}
              </Box>
              <MobileStepper
                style={activeStep == 3 ? { background: "rgb(176, 196, 222) !important" ,marginTop:"20px",padding:"0px 47px 0px 0px"} : { background: "white !important",marginTop:"20px" ,padding:"0px 47px 0px 0px"}}
                variant="text"
                steps={maxSteps}
                position="static"
                activeStep={activeStep}
                nextButton={
                  <Button
                  style={{background: "white !important",fontSize:"18px"}}
                    size="small"
                    className="pure-white-bg-btn"
                    onClick={handleNext}
                    disabled={activeStep === maxSteps - 1}
                  >
                    Next
                    {theme.direction === "rtl" ? (
                      <KeyboardArrowLeft />
                    ) : (
                      <KeyboardArrowRight />
                    )}
                  </Button>
                }
                backButton={
                  <Button
                    size="small"
                    style={{background: "white",fontSize:"18px"}}
                    className="pure-white-bg-btn"
                    onClick={handleBack}
                    disabled={activeStep === 0}
                  >
                    {theme.direction === "rtl" ? (
                      <KeyboardArrowRight />
                    ) : (
                      <KeyboardArrowLeft />
                    )}
                    Back
                  </Button>
                }
              />
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={5} >
            <SlickCarousel />
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default REnewSubscription
