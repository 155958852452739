import {
  Avatar,
  Box,
  Button,
  Checkbox,
  Paper,
  Tabs,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import ArrowOutwardSharpIcon from "@mui/icons-material/ArrowOutwardSharp";
import "../../styles/chatlanding.css";
import "./../../styles/newchat/newChat.css";
import NewGroupNav from "../../components/navbar/NewGroupNav";

import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import EastOutlinedIcon from "@mui/icons-material/EastOutlined";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllUsersAction,
  storeUsersToCreateGroup,
} from "../../redux/action/usersAction";
import { useNavigate } from "react-router-dom";
import NavGroupInfo from "../navbar/NavGroupInfo";
import { db } from "../../../../API/firebase.config";
import {
  get,
  push,
  ref,
  runTransaction,
  serverTimestamp,
  set,
  update,
} from "firebase/database";
import Swal from "sweetalert2";

export default function AddNewMember() {
  const paper = {
    padding: "0rem",
    minHeight: "100vh",
    maxWidth: "27rem",
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { allUsers } = useSelector((state) => state.users);
  const { currentUser } = useSelector((state) => state.auth);
  const { openedGroupInfo } = useSelector((state) => state.group);
  const [filteredUsers, seTfilteredUsers] = useState([]);

  const [selectedProfiles, setSelectedProfile] = useState([]);

  const addToSelected = (user) => {
    const copiedSelected = [...selectedProfiles];

    const userIndex = copiedSelected.findIndex((obj) => obj.uid === user.uid);
    if (userIndex !== -1) {
      copiedSelected.splice(userIndex, 1);
    } else {
      copiedSelected.push(user);
    }

    setSelectedProfile(copiedSelected);
  };

  const removeSelected = (uid) => {
    const arr = [...selectedProfiles];

    const index = arr.findIndex((obj) => obj.uid === uid);
    if (index !== -1) {
      arr.splice(index, 1);
    }
    setSelectedProfile(arr);
    return;
  };

  const handleAddNewMembers = async () => {
    const groupId = openedGroupInfo?.groupId;
    const newMemberIds = selectedProfiles.map((member) => member.uid);
    const groupRef = ref(db, `groups/${groupId}/groupMembers`);

    try {
      await runTransaction(groupRef, (groupMembers) => {
        if (Array.isArray(groupMembers)) {
          const updatedGroupMembers = [...groupMembers, ...newMemberIds];
          return updatedGroupMembers;
        }
        return groupMembers;
      });

      //update userChats it will add group to the userChats

      newMemberIds.map(async (item) => {
        const userChatsRef = ref(db, `userChats/${item}`);

        await update(userChatsRef, {
          [`${groupId}/chatType`]: "group",
          [`${groupId}/groupId`]: groupId,
          [`${groupId}/date`]: serverTimestamp(),
          [`${groupId}/isMsgReqAccepted`]: true,
        });
      });

      Swal.fire({
        title: "Success",
        text: "New Member Added",
        icon: "success",
      });
      navigate("/group-information");
      console.log("New members added to the group successfully.");
    } catch (error) {
      console.error("Error adding new members to the group:", error);
      throw error;
    }
  };

  useEffect(() => {
    dispatch(getAllUsersAction());
  }, []);

  return (
    <Box
      sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
    >
      <Paper sx={{ flexGrow: 1 }} elevation={10} style={paper}>
        <NavGroupInfo
          backButtonPath={"/group-information"}
          groupName={"Add New Member"}
        />

        <Box
          sx={{
            backgroundColor: "#FDFBE7",
            padding: "8px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              padding: "6px",
              overflowX: "auto",
            }}
          >
            {selectedProfiles.length === 0 && (
              <Box
                style={{
                  position: "relative",
                  marginRight: "1.5rem",
                  overflowX: "hidden",
                }}
              >
                <Avatar
                  sx={{ height: 58, width: 58 }}
                  alt="Remy Sharp"
                  src="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"
                />
                <Typography className="selectects-avatar-name">
                  Buddy
                </Typography>
              </Box>
            )}

            <Box
              scroll
              style={{
                display: "flex",
                // overflowX: 'scroll',
                scrollbarWidth: "thin",
                scrollbarColor: "transparent transparent",
                "-ms-overflow-style": "none" /* Internet Explorer and Edge */,
                "scrollbar-width": "thin",
                "&::-webkit-scrollbar": {
                  width: "6px" /* Adjust the width as needed */,
                },
                "&::-webkit-scrollbar-track": {
                  backgroundColor:
                    "transparent" /* Change to desired background color */,
                },
                "&::-webkit-scrollbar-thumb": {
                  backgroundColor:
                    "transparent" /* Change to desired thumb color */,
                },
              }}
            >
              {selectedProfiles
                ?.slice(0)
                .reverse()
                .map((user, index) => (
                  <Box
                    onClick={() => removeSelected(user.uid)}
                    key={index}
                    style={{
                      position: "relative",
                      marginRight: "1.5rem",
                      overflowX: "hidden",
                    }}
                  >
                    <Avatar
                      sx={{ height: 58, width: 58 }}
                      alt="Remy Sharp"
                      src={user.photoURL}
                    />
                    <Typography className="selectects-avatar-name">
                      {user.displayName.length > 8
                        ? `${user.displayName.slice(0, 8)}...`
                        : user.displayName}
                    </Typography>
                    <Box className="cross-button">
                      <img
                        height={7}
                        width={7}
                        src="/images/cross-icon.png"
                        alt="remove"
                      />
                    </Box>
                  </Box>
                ))}
            </Box>
          </Box>
          <ArrowOutwardSharpIcon sx={{ paddingRight: "0.4rem" }} />
        </Box>

        <Box>
          <Typography className="select-buddies-text">
            Select Buddies to add to your Group
          </Typography>

          {/* <Box style={{ position: 'relative', textAlign: "center" }}>
                    <input placeholder='Search Buddies' type="text" className='search-buddy-input' />
                    <Box className="search-icon-parent" style={{ textAlign: "center" }}>
                        <img src="/images/search-icon.png" alt="search" />
                    </Box>
                </Box> */}
        </Box>

        {filteredUsers.legnth > 0
          ? filteredUsers
          : allUsers?.map((item, index) => {
              return (
                !openedGroupInfo?.groupMembers?.includes(item.uid) && (
                  <Box
                    className="profile-row"
                    onClick={() => {
                      addToSelected(item);
                      document.getElementById(`check-profile-${index}`).click();
                    }}
                    key={index}
                    sx={{
                      padding: "8px",
                      paddingTop: "12px",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        padding: "6px",
                      }}
                    >
                      <Avatar alt="Remy Sharp" src={item.photoURL} />
                      <Box>
                        <Typography
                          className="userName"
                          fontWeight="fontWeightBold"
                          sx={{ paddingLeft: "1.2rem", fontSize: "14px" }}
                        >
                          {item.displayName}
                        </Typography>
                        <Typography className="followers-text">
                          {"7724 Followers | 4.65"}
                          <img src="/images/star-icon.png" alt="star" />
                        </Typography>
                      </Box>
                    </Box>
                    <Checkbox
                      id={`check-profile-${index}`}
                      sx={{
                        color: "#F4F4F4",
                        "&.Mui-checked": {
                          color: "#056B6B",
                        },
                      }}
                      checked={selectedProfiles.some(
                        (obj) => obj.uid === item.uid
                      )}
                      icon={<CheckCircleOutlineIcon />}
                      checkedIcon={<CheckCircleIcon />}
                    />
                  </Box>
                )
              );
            })}

        <Box className="sticky-next-btn">
          <Button
            disabled={!selectedProfiles.length > 0}
            onClick={handleAddNewMembers}
            style={{
              boxShadow: "-2px 4px 10px rgba(0, 0, 0, 0.25)",
              fontSize: 10,
              letterSpacing: "0.16px",
              display: "flex",
            }}
            className={
              selectedProfiles.length > 0
                ? "next-mui-btn-selected"
                : "next-mui-btn"
            }
          >
            Add
            <EastOutlinedIcon
              sx={
                selectedProfiles.length > 0
                  ? { fontSize: "14px", paddingLeft: "0.24rem", color: "black" }
                  : {
                      fontSize: "14px",
                      paddingLeft: "0.24rem",
                      color: "#FFFFFF",
                    }
              }
            />
          </Button>
        </Box>
      </Paper>
    </Box>
  );
}
