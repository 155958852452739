import React from 'react';

const Rating = ({ rating ,size}) => {
  const renderStars = () => {
    const stars = [];
    for (let i = 0; i < 5; i++) {
      if (i < rating) {
        stars.push(<span key={i} style={{color:"#E67E22"}}>&#9733;</span>); // Full star
      } else {
        stars.push(<span key={i}>&#9734;</span>); // Empty star
      }
    }
    return stars;
  };

  return <div style={{fontSize:`${size?size:"15px"}`}}>{renderStars()}</div>;
};

export default Rating;
