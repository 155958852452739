import React, { useEffect, useState } from "react";
import "../components/AssignCoach/Assign.css";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Grid from "@mui/material/Grid";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";
import { Box } from "@mui/system";
import { Button, FormLabel, MenuItem, OutlinedInput, TextField } from "@mui/material";
import { useNavigate } from "react-router";
import Header from "../components/Header";
import moment from "moment";
import { capitalizeFirstLetter } from "../utils/Resubale";
import { createPostLeaderBoard, getAllUserChallenges, getAllUserSubscription, URL } from "../API/api-endpoint";
import ReactSelect from "react-select";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#1e40af",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const LeaderBoardUser = () => {
  const navigate = useNavigate();

  const [getList, setGetList] = useState()
  const [selectChallenge, setSelectChallenge] = useState()
  const [fetchLeaderBoard, setFetchLeaderBoard] = useState()
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredLeaderBoard, setFilteredLeaderBoard] = useState([]);

  console.log("fetchLeaderBoard", fetchLeaderBoard);


  useEffect(() => {
    allAtheletes()

  }, [])
  console.log("getList", getList);
  const allAtheletes = async () => {

    const response = await getAllUserChallenges()
    setGetList(response)
    console.log("response", response);
  }
  const postLeaderBoard = async () => {
    let data = {
      challengeid: selectChallenge
    }
    const response = await createPostLeaderBoard(data)
    console.log("response", response);
    setFetchLeaderBoard(response?.sorteduserchallengeswithpoints)
  }
  useEffect(() => {
    if (selectChallenge) {
      postLeaderBoard()
    }
  }, [selectChallenge])

  const options = getList?.map((value) => ({
    label: value['challengeName'],
    value: value['id'],
  })) || [];

  const handleSearchChange = (event) => {
    const value = event.target.value.toLowerCase();
    setSearchTerm(value);

    
};

useEffect(() => {
  const filteredData = fetchLeaderBoard?.filter((row) => {
      const fullName = `${row?.user?.firstname} ${row?.user?.lastname}`.toLowerCase();
      return fullName.includes(searchTerm.toLowerCase());
  });

  setFilteredLeaderBoard(filteredData);
}, [fetchLeaderBoard, searchTerm]);



  return (
    <div className="zones-form-container absolute top-20 w-[100vw]">
      <Header />
      <div
        className="zones-form-main"
        style={{ fontSize: "2rem", textAlign: "center", marginBottom: "10px" }}
      >
        Leaderboard
      </div>
      <div style={{ fontSize: "18px", background: "#FFEADC", width: "100%", padding: "10px", borderTopRightRadius: "10px", borderTopLeftRadius: "10px" }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={10} sm={10} >
            <h3 style={{ padding: "10px 0px 0px 10px" }}><b>Select Challenge:</b></h3>
          </Grid>
          <Grid container spacing={0}>
          <Grid item xs={12} md={4} sm={4} sx={{ marginTop: "15px",marginLeft:"20px" }} >

            <ReactSelect
              styles={{
                control: (provided, state) => ({
                  ...provided,
                  backgroundColor: '#FFEADC',
                  borderColor: "gray",

                }),
                option: (provided, state) => ({
                  ...provided,
                  backgroundColor: state.isSelected ? '#E67E22' : provided.backgroundColor,
                }),
                singleValue: (provided) => ({
                  ...provided,
                  // color: '#E67E22',
                }),
                menu: (provided, state) => ({
                  ...provided,
                  zIndex: "10",
                  scrollbarColor: '#E67E22 white',
                  scrollbarWidth: 'thin',
                  '&::-webkit-scrollbar': {
                    width: '12px',
                  },
                  '&::-webkit-scrollbar-track': {
                    background: 'white',
                  },
                  '&::-webkit-scrollbar-thumb': {
                    backgroundColor: '#E67E22',
                    borderRadius: '20px',
                    border: '3px solid transparent',
                    backgroundClip: 'content-box',
                  },

                }),
              }}
              name="challengeName"
              classNamePrefix="custom-select"
              //   isMulti
              // value={formik?.values?.challengeName}
              options={options}
              value={options.find(option => option?.value === selectChallenge) || []}
              onChange={(selectedOption) => {
                setSelectChallenge(selectedOption ? selectedOption.value : '');
              }}

              placeholder="Select Challenge"
              isClearable
            />

            {/* <TextField
              fullWidth
              size="small"
              select
              name="challengeActivity"
              value={selectChallenge}
              SelectProps={{
                MenuProps: {
                  PaperProps: {
                    style: {
                       scrollbarColor:"#E67E22 white",
                       scrollbarWidth:"thin"
                    },
                  },
                },
              }}
              onChange={
                  (event) => {
                      setSelectChallenge(event.target.value)
                  }}
              id="form-layouts-separator-select"
              labelId="form-layouts-separator-select-label"
              input={<OutlinedInput id="select-multiple-language" />}
          >
              <MenuItem value={""} disabled>
                  Select Challenge
              </MenuItem>
              {getList?.map((value, index) => {
                  return (
                      <MenuItem value={value?.id}>
                          {capitalizeFirstLetter(value?.challengeName)}
                      </MenuItem>
                  );
              })}
          </TextField> */}
          </Grid>
          <Grid item xs={12} sm={7.7} sx={{ textAlign: "end",marginTop: "15px" }}>
                            <TextField type="text" size="small" value={searchTerm} onChange={handleSearchChange} placeholder="Search By User Name.." />
                        </Grid>
                        </Grid>

        </Grid>
        &nbsp;
      </div>
      <Grid xs={12} md={12}>
        <div className="table-section">
          <div className="zone-table-title">

          </div>
          <div className="zone-table">
            <TableContainer component={Paper} style={{ maxHeight: 550, scrollbarWidth: "none" }}>

              <Table stickyHeader sx={{ minWidth: 700 }} aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell align="left">Sr ID</StyledTableCell>
                    <StyledTableCell align="left">User Name</StyledTableCell>
                    <StyledTableCell align="left">Challenge Title</StyledTableCell>
                    <StyledTableCell align="left">Score </StyledTableCell>
                    <StyledTableCell align="left">Challenge Assignment Date </StyledTableCell>
                    <StyledTableCell align="left">Challenge Completion Date </StyledTableCell>
                    <StyledTableCell align="left">Completed Challenges Badge</StyledTableCell>



                  </TableRow>
                </TableHead>
                <TableBody>
                  {fetchLeaderBoard?.length > 0 ? (
                    <>
                      {filteredLeaderBoard?.map((row, index) => (
                        <StyledTableRow key={row?.subscription?.subscription_id}>
                          <StyledTableCell align="left">
                            {index + 1}
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            {row?.user?.firstname} {row?.user?.lastname}

                          </StyledTableCell>
                          <StyledTableCell align="left">
                            {row?.challenge?.challengeName}

                          </StyledTableCell>
                          <StyledTableCell align="left">
                            {row?.userchallangepoint?.points || "0"}

                          </StyledTableCell>
                          <StyledTableCell align="left">
                            {moment(row?.challenge?.challengeStartDate).format("DD-MM-YYYY")}
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            {moment(row?.challenge?.challengeEndDate).format("DD-MM-YYYY")}
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            {row?.completed &&
                              <img
                                src={row?.challenge?.badge ? `${URL}/static/public/userimages/${ JSON.parse(row?.challenge?.badge)}` : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTbQIfgvrUcDEY90ZrIVSMPYX4hvjFe963pHSxaVK3fuw&s"}
                                alt="..."
                                className="w-10 h-10 rounded-full border-2"
                              />
                            }

                          </StyledTableCell>


                        </StyledTableRow>
                      ))}
                    </>
                  ) : (
                    <div className="p-4">No data found</div>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </div>

        </div>
      </Grid>




    </div>
  );
};


export default LeaderBoardUser
