/*eslint-disable no-unused-expressions*/
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { startCase } from "lodash";
import { act } from "react-dom/test-utils";
import shortid from "shortid";
import {
  getAllTags,
  getAllTrainingBlockLibrary,
  getAllTrainingBlockByTraininingLibraryID,
  updateTrainingBlockLibrary,
  getTrainingPlanMasterMapingByTrainingPlanID,
  getAllTraningBlocks,
} from "../../API/api-endpoint";

const MultiTrainingBlocksSlice = createSlice({
  name: "multitrainingblock",
  initialState: {
    masterData: [],
    layout: [],
    componant: [],
    startingDay: 1,
    endingDay: 84,
    startingWeek: 1,
    endingWeek: 12,
    pageNumber: 1,
    weekNumber: 1,
    isWorkoutFormOpen: false,
    currentDivSelectedForForm: null,
    tags: [],
    trainingBlockLibrary: [],
    trainingBlocksbyLibraryId: [],
    isCreateTrainingBlockDialogisOpen: false,
    currentSelectedTrainingLibraryID: null,
    isEditTrainingBlockLibraryDialogisOpen: false,
    currentSelectedTrainingLibrary: null,
    selecteddayandweek: { day: 1, week: 1 },
    CurrentTrainingBlock: { id: 1, name: null },
    traniningBlockData: [],
    lastColumnDayInRow : []
  },
  
  reducers: {
    genrateGrid: (state, action) => {
  let newArray = [];
  const numColumns = 7;
  const numRows = 12;
  let gridcounter = state.startingDay;
  let weekCounter = state.startingWeek; // Initialize a week counter

  for (let row = 0; row < numRows; row++) {
    const newColArray = [];
    for (let col = 0; col < numColumns; col++) {
      const counter = gridcounter + col;
      newColArray.push({
        type: "COLUMN",
        id: `week-${weekCounter}-row-${weekCounter}-count-${counter}-col-${counter}`, // Modified to include weekCounter
        children: [],
        counter: counter,
      });
  
      if (col === numColumns - 1 && row <= numRows - 1) {
        // Add the week count in the last column after every 7 counters (except in the last row)
        newColArray.push({
          type: "COLUMN",
          id: `week-${weekCounter}-row-${weekCounter}-count-${counter + 1}-col-${counter + 1}`, // Modified to include weekCounter
          children: [],
          isWeekCount: true, // Add a property to identify it's a week count
        });
      }
    }
  
    newArray.push({
      type: "ROW",
      id: `week-${weekCounter}-row-${weekCounter}`, // Modified to include weekCounter
      children: newColArray,
    });
  
    gridcounter += numColumns;
    weekCounter++; // Increment week counter for the next row
  }

  state.layout = newArray;
  let masterData = [...state.masterData];
  newArray.forEach((element) => {
    masterData.push(element);
  });
  state.masterData = masterData;
  return state;
},
    
    
    
    
    
    
    
    UpdateMasterDataWithUpdatedEvent: (state, action) => {
      const array = JSON.parse(JSON.stringify(state.masterData));
      const startIndex = state.startingWeek - 13;
      const endIndex = state.endingWeek;
      const newElements = action.payload;

      // Remove elements from startIndex to endIndex
      const removedElements = array.splice(
        startIndex,
        endIndex - startIndex + 1
      );

      // Insert newElements at startIndex
      array.splice(startIndex, 0, ...newElements);
      state.masterData = array;
    },
    onNext: (state, action) => {
      state.startingDay += 84;
      state.startingWeek += 12;
      state.endingDay += 84;
      state.endingWeek += 12;
      state.pageNumber++;
      // (state.masterData.length < state.endingWeek) + 12
      //   ? genrateGrid()
      //   : null;
    },
    onPrev: (state, action) => {
      let newArray = JSON.parse(JSON.stringify(state.masterData));
      // if (state.startingDay != 1 && state.pageNumber != 1) {
        state.pageNumber--;
        state.startingDay -= 84;
        state.startingWeek -= 12;
        state.endingDay -= 84;
        state.endingWeek -= 12;
        state.weekNumber -= 24;
        // let newData = newArray.slice(state.startingWeek - 13, state.endingWeek);
        // state.layout = newData;
      // }
    },
    updateLayout: (state, action) => {
      state.layout = action.payload;
    },
    updateComponant: (state, action) => {
      state.componant = action.payload;
    },
    deleteAllEvent: (state, action) => {
      let newArray = JSON.parse(JSON.stringify(state.masterData));

      const selectedColumnIds = [action.payload];
      const updatedArray = newArray.map((row) => {
        if (row.type === "ROW") {
          const updatedChildren = row.children.map((column) => {
            if (
              column.type === "COLUMN" &&
              selectedColumnIds.includes(column.id)
            ) {
              return {
                ...column,
                children: [], // Set children to an empty array for selected COLUMN elements
              };
            }
            return column;
          });

          return {
            ...row,
            children: updatedChildren,
          };
        }
        return row;
      });
      state.layout = updatedArray;
    },
    copyAllEvent: (state, action) => {
      const sourceColumnId = action.payload;
      let MasterData = JSON.parse(JSON.stringify(state.masterData));
      MasterData.map((row) => {
        if (row.type === "ROW") {
          const updatedChildren = row.children.map((column) => {
            if (column.type === "COLUMN" && column.id === sourceColumnId) {
              localStorage.setItem(
                "tempArray",
                JSON.stringify(column?.children)
              );
            }
            return column;
          });

          return { ...row, children: updatedChildren };
        }
        return row;
      });
    },
    pasteAllEvent: (state, action) => {
      let MasterData = JSON.parse(JSON.stringify(state.masterData));
      let componants = JSON.parse(JSON.stringify(state.componant));
      const destinationColumnId = action.payload;
      const sourceColEventList = JSON.parse(localStorage.getItem("tempArray"));
      // Generate new IDs for the copied events
      const copiedEventsWithNewIds = sourceColEventList.map((event) => ({
        ...event,
        id: shortid.generate(), // Generate a new shortid for each event
      }));

      //setcomponants to add newids element

      copiedEventsWithNewIds.forEach((event) => {
        componants[event.id] = event;
      });

      state.componant = componants;

      const updatedArray = MasterData.map((row) => {
        if (row.type === "ROW") {
          const updatedChildren = row.children.map((column) => {
            if (column.type === "COLUMN" && column.id === destinationColumnId) {
              const destinationChildren = [
                ...column.children,
                ...copiedEventsWithNewIds,
              ];
              return { ...column, children: destinationChildren };
            }
            return column;
          });

          return { ...row, children: updatedChildren };
        }
        return row;
      });
      state.masterData = updatedArray;
      let newData = updatedArray.slice(
        state.startingWeek - 13,
        state.endingWeek
      );
      state.layout = newData;
      localStorage.removeItem("tempArray");
    },
    toggleComponentVisibility: (state, action) => {
      const selectedColumnIds = [action.payload];
      const componentIds = [];

      const updatedArray = state.masterData.map((row) => {
        if (row.type === "ROW") {
          const updatedChildren = row.children.map((column) => {
            if (
              column.type === "COLUMN" &&
              selectedColumnIds.includes(column.id)
            ) {
              componentIds.push(...column.children.map((item) => item.id));
            }
            return column;
          });

          return {
            ...row,
            children: updatedChildren,
          };
        }
        return row;
      });

      componentIds.forEach((componentId) => {
        const component = state.componant[componentId];
        if (component) {
          state.componant[componentId] = {
            ...component,
            isHidden: !component.isHidden,
          };
        }
      });
    },
    handleWorkoutFormState: (state, action) => {
      state.isWorkoutFormOpen = action.payload.bool;
      state.currentDivSelectedForForm = action.payload.id;
    },
    handleCreateTraningBlockDialogState: (state, action) => {
      state.isCreateTrainingBlockDialogisOpen = action.payload.bool;
      state.currentSelectedTrainingLibraryID = action.payload.libraryID;
    },
    handleEditTrainingBlockDialogState: (state, action) => {
      state.isEditTrainingBlockLibraryDialogisOpen = action.payload.bool;
      state.currentSelectedTrainingLibrary = action.payload.library;
    },
    handleselecteddayandWeek: (state, action) => {
      state.selecteddayandweek.day = action.payload.day;
      state.selecteddayandweek.week = action.payload.week;
    },
    handlecurrentTrainingBlockData: (state, action) => {
      state.CurrentTrainingBlock.id = action.payload.id;
      state.CurrentTrainingBlock.name = action.payload.name;
    },
    updateColumnsinTrainingData: (state, action) => {
      let layoutData = JSON.parse(JSON.stringify(state?.layout));
      let traningblockdata = JSON.parse(
        JSON.stringify(state.traniningBlockData)
      );
      //this both foreach loop are set all children as empty array
      layoutData?.forEach((row) => {
        row.children.forEach((column) => {
          column.children = []; // Assign an empty array to 'children'
        });
      });

      // let newComponent = JSON.parse(JSON.stringify(state.componant));
      let newComponent = [];
      const array = JSON.parse(JSON.stringify(state.masterData));
      traningblockdata.forEach((data) => {
        const rowID = `week-${data.week_id}-row-${data.week_id}`;
        const ColID = `week-${data.week_id}-row-${data.week_id}-count-${
          data.day_id
        }-col-${data.day_id }`;

        // Find the row in layout
        const row = layoutData.find((item) => item.id === rowID);

        if (row) {
          // Find the column in the row
          let column = row.children.find((item) => item.id === ColID);
          if (column) {
            // Create a copy of the column
            const updatedColumn = { ...column };
            // Initialize the updatedColumn's children as an empty array if it's not already
            if (Array.isArray(updatedColumn.children)) {
              updatedColumn.children = [];
            }

            // Add the workouts from data to the updatedColumn's children
            const workouts = data.workout_id;
            if (Array.isArray(workouts)) {
              workouts.forEach((workout) => {
                let newChildern = {
                  ...workout,
                  id: workout["workout_id"],
                  type: "component",
                  training_plan_master_mapping_id:workout.training_plan_master_mapping_id,
                  trainingblock_id:data.training_block_id.training_block_id,

                  // isHidden: false,
                  content: workout["workout_name"],
                  // Add other properties from workout here
                };
                let newcomponantObject = {
                  ...workout,
                  id: workout["workout_id"],
                  type: workout["workout_name"],
                  training_plan_master_mapping_id:workout.training_plan_master_mapping_id,
                  trainingblock_id:data.training_block_id.training_block_id,

                  // isHidden: false,
                  content: workout["workout_name"],
                };
                updatedColumn.children = [
                  ...updatedColumn.children,
                  newChildern,
                ];
                newComponent = {
                  ...newComponent,
                  [newcomponantObject.id]: newcomponantObject,
                };
              });
            }

            // Replace the original column with the updatedColumn in the row's children
            const columnIndex = row.children.findIndex(
              (item) => item.id === ColID
            );
            if (columnIndex !== -1) {
              row.children[columnIndex] = updatedColumn;
            }
          }
        }
      });
      const startIndex = state.startingWeek - 13;
      const endIndex = state.endingWeek;
      const newElements = layoutData;

      // Remove elements from startIndex to endIndex
      const removedElements = array.splice(
        startIndex,
        endIndex - startIndex + 1
      );

      // Insert newElements at startIndex
      array.splice(startIndex, 0, ...newElements);
      state.masterData = array;
      state.componant = newComponent;
      state.layout = layoutData;
    },
    updateLastColumnDayInRow: (state, action) => {
      let layoutData = JSON.parse(JSON.stringify(state.layout));
      const newDataArray = layoutData.map((row) => {
          const rowName = row.id;
          const durationMap = {};
          const distanceMap = {};
          
          row.children.forEach((column) => {
              column.children.forEach((child) => {
                  const { workout_tags, workout_planned_duration, workout_planned_distance } = child;
                  if (workout_tags !== "") {
                    const tags = workout_tags.split(',');
                    // const tags = workout_tags.split(',');
                  tags.forEach((tag) => {
                      const trimmedTag = tag.trim();
                      if (!isNaN(parseFloat(workout_planned_duration))) {
                          durationMap[trimmedTag] = (durationMap[trimmedTag] || 0) + parseFloat(workout_planned_duration);
                      }
                      if (!isNaN(parseFloat(workout_planned_distance))) {
                          distanceMap[trimmedTag] = (distanceMap[trimmedTag] || 0) + parseFloat(workout_planned_distance);
                      }
                  });
                }
              });
          });
  
          const rowData = Object.keys(durationMap).map((workout_tags) => ({
              workout_tags,
              total_duration: durationMap[workout_tags],
              total_distance: distanceMap[workout_tags],
          }));
  
          const tagObject = {};
          rowData.forEach(({ workout_tags, total_duration, total_distance }) => {
              tagObject[workout_tags] = {
                  distance: total_distance,
                  duration: total_duration,
              };
          });
  
          const modifiedArray = [];
  
          for (const tag in tagObject) {
              const tagData = tagObject[tag];
              const modifiedData = {
                  [`${tag} Distance`]: tagData.distance || 0, // Default to 0 if undefined
                  [`${tag} Duration`]: tagData.duration || 0, // Default to 0 if undefined
              };
              modifiedArray.push(modifiedData);
          }
  
          const splitArray = [];
  
          modifiedArray.forEach((tagData) => {
              for (const key in tagData) {
                  const splitObject = { [key]: tagData[key] };
                  splitArray.push(splitObject);
              }
          });
  
          return { rowName, splitArray };
      });
  
      state.lastColumnDayInRow = newDataArray;
  
      return state; // Return the updated state
  }
  
  },
  extraReducers: (builder) => {
    // Get All Tags Items
    builder.addCase(getTagsFromDb.fulfilled, (state, action) => {
      state.tags = action.payload;
    });

    //getAllTraningBlocksLibrary
    builder.addCase(getAllTrainingBlocksLibrary.fulfilled, (state, action) => {
      state.trainingBlockLibrary = action.payload;
    });

    //getAllTraningBlocksbyLibraryId
    builder.addCase(
      getAllTraningBlocksbyLibraryId.fulfilled,
      
      (state, action) => {
        state.trainingBlocksbyLibraryId=[]
        state.trainingBlocksbyLibraryId = action.payload;
      }
    );

    //getTrainingPlanMasterMappingByTrainingId
    builder.addCase(
      getTrainingPlanMasterMappingByTrainingId.fulfilled,
      (state, action) => {
        state.traniningBlockData = action.payload;
      }
    );
  },
});

export const getTagsFromDb = createAsyncThunk(
  "multitrainingblock/getTagsFromDb",
  async () => {
    const data = await getAllTags();
    return data;
  }
);


// to fetch traning block liabrary 
export const getAllTrainingBlocksLibrary = createAsyncThunk(
  "multitrainingblock/getAllTrainingBlocksLibrary",
  async () => {
    const data1 = await getAllTraningBlocks()
    const data = await getAllTrainingBlockLibrary();
    return data;
  }
);
// to fetch list of  training block from library  id
export const getAllTraningBlocksbyLibraryId = createAsyncThunk(
  "multitrainingblock/getAllTraningBlocksbyLibraryId",
  async (lib_id) => {
    const data = await getAllTrainingBlockByTraininingLibraryID(lib_id);
    return data.trainingBlocks;
  }
);
// to fetch particlaur training block by traning bloack id  
export const getTrainingPlanMasterMappingByTrainingId = createAsyncThunk(
  "multitrainingblock/getTrainingPlanMasterMappingByTrainingId",
  async (training_plan_id) => {
    const data = await getTrainingPlanMasterMapingByTrainingPlanID(
      training_plan_id
    );
    return data.getTrainingPlanMasterMapping;
  }
);

export default MultiTrainingBlocksSlice.reducer;
export const {
  genrateGrid,
  UpdateMasterDataWithUpdatedEvent,
  onNext,
  onPrev,
  updateLayout,
  updateComponant,
  deleteAllEvent,
  copyAllEvent,
  pasteAllEvent,
  toggleComponentVisibility,
  handleWorkoutFormState,
  handleCreateTraningBlockDialogState,
  handleEditTrainingBlockDialogState,
  handleselecteddayandWeek,
  handlecurrentTrainingBlockData,
  updateColumnsinTrainingData,
  updateLastColumnDayInRow
} = MultiTrainingBlocksSlice.actions;
