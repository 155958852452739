import { Chip, FormControl, FormLabel, Grid, MenuItem, OutlinedInput, Select, TextField } from '@mui/material';
import { Button, Modal } from 'antd'
import React, { useEffect, useState } from 'react'
import { CreatePrograms, CreateZonesClasification ,createGoalNameYTA,createGoalsdata,getAllActivityData,getAllGoalNameForYTA,getAllPrograms, getAlllevels, updateGoalNameYTA, updateGoalsdata, updatePrograms, updateZonesClasification, weeklyFeedDataPattern, weeklyFeedDataProgram } from '../../API/api-endpoint';
import { useFormik } from 'formik';
import Swal from 'sweetalert2';
import SlickCarousel from "../../pages/SlickCarousel";
const scoreData = [1, 2, 3, 4, 5]
const CreateGoals = ({ fetchReport, setShowAssesmentModal, showAssesmentModal,editData ,setEditData}) => {
    const formik = useFormik({
        initialValues: {
             goalname: "", 
        }, validate: (values) => {
            const errors = {};
            if (!values.goalname) {
                errors.goalname = "Goal name is required";
            }
            return errors;
        },
        // validationSchema: {},
        onSubmit: (values, { resetForm }) => {
            handleSubmitAssesmentForm(values, resetForm)

        },
    });
  
    const handleSubmitAssesmentForm = async (data,resetForm) => {
        let response =""
        if (editData?.id) {
            response = await updateGoalNameYTA(data)
           }else{
            response = await createGoalNameYTA(data)
            console.log("response",response);
   
           }
       
        if (response?.status) {
            Swal.fire({
                title: "Success",
                text: response.message,
                icon: "success",
            });
            setShowAssesmentModal(false)
            setEditData({})
            fetchReport()
            resetForm()
            formik?.setValues({goalname:""})
        } else {
            Swal.fire({
                title: "Error",
                text: response.message,
                icon: "error",
            });
        }
        console.log("response", response);
    }
    useEffect(()=>{
        if (editData?.id) {
            const {srID,...data}=editData
            console.log("data",data);
            formik?.setValues(data)
        }
    },[editData?.id])
    return (
        <Modal
      width={1200}
      open={showAssesmentModal}
      onCancel={() => {
        setShowAssesmentModal(false);
        setEditData({});
        formik.resetForm();
        // formik?.setValues({goalname:""})
      }}
      footer={
        <div></div>
        //   loading={isLoading}
      }
    >
      <div className="headingCont">
        <span className="heading">{editData?.id ? "Edit " : "Create"}</span>{" "}
        <span className="orange heading">Goals</span>
        <p className="grey">Set up a new fitness challenge for Yoska Users</p>
      </div>

      <div className="parentCont">
        <form className="form1" onSubmit={formik.handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={11}>
              <FormLabel>Goal Name<span className="text-[red]">*</span></FormLabel>

              <TextField
                fullWidth
                placeholder="Goal Name"
                size="small"
                type="text"
                name="goalname"
                value={formik?.values?.goalname}
                onChange={formik.handleChange}
                error={formik.touched.goalname && formik.errors.goalname}
                helperText={formik.touched.goalname && formik.errors.goalname}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <Button
                className="btn"
                key="submit"
                type="primary"
                onClick={() => formik.handleSubmit()}
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </form>

        <div className="slick-container">
          <SlickCarousel />
        </div>
      </div>
    </Modal>
  );
};

export default CreateGoals;
