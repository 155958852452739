import axios from "axios";
import {
  CLEAR_USERS_TO_CREATE_GROUP_SUCCESS,
  GET_ALL_USERS_FAIL,
  GET_ALL_USERS_INIT,
  GET_ALL_USERS_SUCCESS,
  SET_SEARCH_USER_DATA,
  STORE_OPENED_USER_INFO_FAIL,
  STORE_OPENED_USER_INFO_SUCCESS,
  STORE_USERS_TO_CREATE_GROUP_SUCCESS,
} from "../constant/usersConstants";
import { getDatabase, onValue, ref } from "firebase/database";
import { filterUsersProgram, getAssignedCoach, newGetCoach, updatedusers } from "../../../../API/api-endpoint";

export const getAllUsersAction = (roleID) => async (dispatch) => {
  try {
    dispatch({ type: GET_ALL_USERS_INIT });
    
console.log("newcvbnDispatched");
    const db = getDatabase();
    const starCountRef = ref(db, "/users");
    console.log("starCountRef",starCountRef);
    await onValue(starCountRef, async(snapshot) => {
      const data = snapshot.val();
      console.log("allusers",Object?.values(data));
      if ( roleID && roleID ==5) {
        let users = Object?.values(data);
        const result= await updatedusers()
        if (result && result?.length > 0) {
          const google_id = result[0]?.google_id;
        
          const matchingUser = users?.find(user => user?.uid === google_id);
        
          if (matchingUser) {
            const matchingUserArray = matchingUser ? [matchingUser] : [];
            // console.log("Matching User:", matchingUser);
            dispatch({ type: GET_ALL_USERS_SUCCESS, payload: matchingUserArray });
          } else {
            // console.log("No matching user found.");
          }
        } 
        // result &&
        // dispatch({ type: GET_ALL_USERS_SUCCESS, payload: Object.values(result[1]) });
        // console.log("cvbn",Object.values(result[1]));
      }
    //   else if (roleID && roleID == 3) {
    //     const result = await filterUsersProgram(Object.values(data));
    //     if (result?.status) {
    //       console.log("newathletescvbn", result?.users);
    //         const googleIds = result?.users.map(user => user.google_id);
    //         console.log("Google IDs", googleIds);
    //         const newathletes = Object.values(data)?.filter(user => googleIds.includes(user.uid));
    //         dispatch({ type: GET_ALL_USERS_SUCCESS, payload: newathletes });
    //         console.log("newathletescvbn", newathletes);
    //     }
    // }
    else if (roleID && roleID == 3) {
      console.log("newcvbnDispatchedinside3");
      const prgramId = localStorage.getItem("programID")
      const result = await getAssignedCoach(prgramId);
      if (result?.allassinged?.length > 0) {
        console.log("newathletescvbn", result?.allassinged);
        const googleIds = result?.allassinged
        .map(user => user?.athlete?.google_id)
        .filter(Boolean);
      
          console.log("newathletescvbnGoogle IDs", googleIds);
          const newathletes = Object.values(data)?.filter(user => googleIds.includes(user.uid));
          
          
          dispatch({ type: GET_ALL_USERS_SUCCESS, payload: newathletes });
          console.log("newathletescvbn", newathletes);
          
      }
  }
    
      
      else{
        data &&
        dispatch({ type: GET_ALL_USERS_SUCCESS, payload: Object.values(data) });
      }
     
    });
  } catch (error) {
    dispatch({ type: GET_ALL_USERS_FAIL, payload: error });
  }
};

export const storeOpenedUserInfoAction = (userInfo) => (dispatch) => {
  try {
    console.log(userInfo);

    dispatch({ type: STORE_OPENED_USER_INFO_SUCCESS, payload: userInfo });
  } catch (error) {
    dispatch({ type: STORE_OPENED_USER_INFO_FAIL, payload: error });
  }
};

export const storeUsersToCreateGroup = (usersData) => (dispatch) => {
  dispatch({ type: STORE_USERS_TO_CREATE_GROUP_SUCCESS, payload: usersData });
};

export const clearUsersToCreateGroup = () => (dispatch) => {
  dispatch({ type: CLEAR_USERS_TO_CREATE_GROUP_SUCCESS, payload: [] });
};

export const setSearchUserData = (allUsers, serchQuery) => (dispatch) => {
  console.log("searchNigix00",allUsers,serchQuery);
  if (serchQuery.length === 0) {
    dispatch({ type: SET_SEARCH_USER_DATA, payload: allUsers });
    console.log("searchNigix 0",allUsers);
  } else {
    const serchedUSerData = allUsers?.filter((item) =>
      item?.displayName?.toLowerCase().includes(serchQuery?.toLowerCase())
    );
    dispatch({ type: SET_SEARCH_USER_DATA, payload: serchedUSerData });
    console.log("searchNigix 1",serchedUSerData);
  }
};
