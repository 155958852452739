import { FormLabel, Grid, MenuItem, OutlinedInput, TextField } from '@mui/material';
import { Button, Modal, TimePicker } from 'antd'
import React, { useEffect, useState } from 'react'
import { createBound, createOptiondata, getAllActivityData, getAllPrograms, getAllQuestionData, getAlllevels, updateOptiondata, updateBounds, createWorkoutDistribution, updateWorkoutDistribution } from '../../API/api-endpoint';
import { useFormik } from 'formik';
import Swal from 'sweetalert2';
import { handleTimeChange } from '../../utils/Resubale';
const CreateDistribution = ({ fetchReport, setShowAssesmentModal, showAssesmentModal, editData, setEditData }) => {
    const [questionList, setQuestionList] = useState([]);
    const [programList, setProgramList] = useState([]);
    const [allLevelList, setAlllevelList] = useState([]);

    console.log("editData", editData);
    const formik = useFormik({
        initialValues: {
          activity: "",
                numofdays: "",
                optionNumber: "",
                monday: "",
                thursday: "",
                friday: "",
                saturday: "",
                sunday: "",
                tuesday: "",
                wednesday: "",

        }, validate: (values) => {
            const errors = {};
            if (!values.activity) {
                errors.activity = "Activity is required";
            }
            if (values.numofdays === "") {
                errors.numofdays = "Num of day is required";
            }
            if (!values.monday) {
                errors.monday = "Monday is required";
            }
            if (!values.thursday) {
                errors.thursday = "Thursday is required";
            } if (!values.friday) {
                errors.friday = "Friday is required";
            } if (!values.saturday) {
                errors.saturday = "Saturday is required";
            } if (!values.sunday) {
                errors.sunday = "Sunday is required";
            } if (!values.tuesday) {
                errors.tuesday = "Tuesday is required";
            } if (!values.wednesday) {
                errors.wednesday = "Wednesday is required";
            }

            return errors;
        },
        // validationSchema: {},
        onSubmit: (values, { resetForm }) => {
            handleSubmitAssesmentForm(values, resetForm)

        },
    });
    console.log("formik", formik?.values);
    const QuestionData = async () => {
        const response = await getAllQuestionData()
        console.log("response", response);
        setQuestionList(response)
    }
    useEffect(() => {
        QuestionData()
        getAllProgramsData()
        getAllLevelsData()
    }, [])
    const getAllProgramsData = async () => {
        const response = await getAllActivityData()

        setProgramList(response?.rows)
    }
    const getAllLevelsData = async () => {
        const response = await getAlllevels()
        console.log("response", response);
        setAlllevelList(response?.rows)
    }

    const handleSubmitAssesmentForm = async (data, resetForm) => {
        let response = ""
        if (editData?.id) {

            response = await updateWorkoutDistribution(data)

        } else {
            response = await createWorkoutDistribution(data)

        }
        if (response?.status) {
            Swal.fire({
                title: "Success",
                text: response.message,
                icon: "success",
            });
            setEditData({})
            setShowAssesmentModal(false)
            fetchReport()
            formik.resetForm()
            formik?.setValues({
                activity: "",
                numofdays: "",
                optionNumber: "",
                monday: "",
                thursday: "",
                friday: "",
                saturday: "",
                sunday: "",
                tuesday: "",
                wednesday: "",

            })
        } else {
            Swal.fire({
                title: "Error",
                text: response.message,
                icon: "error",
            });
        }
        console.log("response", response);
    }
    useEffect(() => {
        if (editData?.id) {
            const { srID, ...data } = editData
            formik?.setValues(data)
        }
    }, [editData?.id])


    return (
        <Modal
            width={600}
            title={editData?.id ? "Edit workout distribution" : "Create workout distribution"}
            // title={editData ? "Edit Challenge" : "Add Challenges"}
            open={showAssesmentModal}
            onCancel={() => {
                setShowAssesmentModal(false)
                formik.resetForm()
                setEditData({})
                formik?.setValues({
                    activity: "",
                    numofdays: "",
                    optionNumber: "",
                    monday: "",
                    thursday: "",
                    friday: "",
                    saturday: "",
                    sunday: "",
                    tuesday: "",
                    wednesday: "",
    
                })
            }}
            footer={
                <div >
                    <Button key="submit" type="primary" onClick={() => formik.handleSubmit()}>Submit</Button>
                </div>
                //   loading={isLoading}
            }
        >

            <form >
                <Grid container spacing={2}>
                    <Grid item xs={12} md={3} sm={3}>
                        <FormLabel >Activity Name<span className="text-[red]">*</span></FormLabel>
                    </Grid>
                    <Grid item xs={12} md={8} sm={8} >
                        <TextField
                            fullWidth
                            size="small"
                            select
                            name="activity"
                            value={formik?.values?.activity}
                            onChange={formik.handleChange}
                            error={formik.touched.activity && formik.errors.activity}
                            helperText={
                                formik.touched.activity && formik.errors.activity
                            }

                            id="form-layouts-separator-select"
                            labelId="form-layouts-separator-select-label"
                            input={<OutlinedInput id="select-multiple-language" />}
                        >
                            <MenuItem value={""} disabled>
                                Select Activity
                            </MenuItem>
                            {programList?.map((value, index) => {
                                return (
                                    <MenuItem value={value?.id}>
                                        {value?.activity_name}
                                    </MenuItem>
                                );
                            })}
                        </TextField>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <FormLabel >Option Number<span className="text-[red]">*</span></FormLabel>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                        <TextField
                            fullWidth
                            placeholder="Option Number"
                            size="small"
                            type="number"
                            name="optionNumber"
                            value={formik?.values?.optionNumber}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched.optionNumber && formik.errors.optionNumber}
                            helperText={
                                formik.touched.optionNumber && formik.errors.optionNumber
                            }

                        />
                    </Grid>

                    <Grid item xs={12} sm={3}>
                        <FormLabel >Num of days<span className="text-[red]">*</span></FormLabel>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                        <TextField
                            fullWidth
                            placeholder="Lower bound"
                            size="small"
                            type="number"
                            name="numofdays"
                            value={formik?.values?.numofdays}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched.numofdays && formik.errors.numofdays}
                            helperText={
                                formik.touched.numofdays && formik.errors.numofdays
                            }

                        />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <FormLabel >Monday<span className="text-[red]">*</span></FormLabel>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                        <TextField
                            fullWidth
                            placeholder="Monday"
                            size="small"
                            type="number"
                            name="monday"
                            value={formik?.values?.monday}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched.monday && formik.errors.monday}
                            helperText={
                                formik.touched.monday && formik.errors.monday
                            }

                        />
                    </Grid>

                    <Grid item xs={12} sm={3}>
                        <FormLabel >Tuesday<span className="text-[red]">*</span></FormLabel>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                        <TextField
                            fullWidth
                            placeholder="Tuesday"
                            size="small"
                            type="number"
                            name="tuesday"
                            value={formik?.values?.tuesday}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched.tuesday && formik.errors.tuesday}
                            helperText={
                                formik.touched.tuesday && formik.errors.tuesday
                            }

                        />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <FormLabel >Wednesday<span className="text-[red]">*</span></FormLabel>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                        <TextField
                            fullWidth
                            placeholder="Wednesday"
                            size="small"
                            type="number"
                            name="wednesday"
                            value={formik?.values?.wednesday}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched.wednesday && formik.errors.wednesday}
                            helperText={
                                formik.touched.wednesday && formik.errors.wednesday
                            }

                        />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <FormLabel >Thursday<span className="text-[red]">*</span></FormLabel>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                        <TextField
                            fullWidth
                            placeholder="Thursday"
                            size="small"
                            type="number"
                            name="thursday"
                            value={formik?.values?.thursday}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched.thursday && formik.errors.thursday}
                            helperText={
                                formik.touched.thursday && formik.errors.thursday
                            }

                        />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <FormLabel >Friday<span className="text-[red]">*</span></FormLabel>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                        <TextField
                            fullWidth
                            placeholder="Friday"
                            size="small"
                            type="number"
                            name="friday"
                            value={formik?.values?.friday}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched.friday && formik.errors.friday}
                            helperText={
                                formik.touched.friday && formik.errors.friday
                            }

                        />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <FormLabel >Saturday<span className="text-[red]">*</span></FormLabel>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                        <TextField
                            fullWidth
                            placeholder="Saturday"
                            size="small"
                            type="number"
                            name="saturday"
                            value={formik?.values?.saturday}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched.saturday && formik.errors.saturday}
                            helperText={
                                formik.touched.saturday && formik.errors.saturday
                            }

                        />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <FormLabel >Sunday<span className="text-[red]">*</span></FormLabel>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                        <TextField
                            fullWidth
                            placeholder="Sunday"
                            size="small"
                            type="number"
                            name="sunday"
                            value={formik?.values?.sunday}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched.sunday && formik.errors.sunday}
                            helperText={
                                formik.touched.sunday && formik.errors.sunday
                            }

                        />
                    </Grid>
                </Grid>
            </form>
        </Modal>
    )
}
export default CreateDistribution
