import React, { useState, useEffect } from "react";
import SearchIcon from "@mui/icons-material/Search";
import { styled, alpha } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import Card from "@mui/material/Card";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Button from "@mui/material/Button";
import { Collapse } from "@mui/material";
import { IconAntennaBars1, IconEdit } from "@tabler/icons";
import { Dropdown, Space } from "antd";
import {
  copyPasteWorkoutLibrari,
  copyPasteWorkoutLibrary,
  deleteWorkoutLibrary,
  getAllWorkoutLibrary,
  getAllWorkoutLibrary1,
  getAllWorkoutsByLiabrary,
  getAllWorkoutsFromLibraryByLibraryId,
} from "../../../../API/api-endpoint";
import DeleteModal from "../../../DeleteModal";
import Swal from "sweetalert2";
import "../ManageWorkouts/AddWorkout.css"
import CustomModal from "../../../../components/CopyModalForm";
import { URL } from "../../../../API/api-endpoint"
import CircularProgress from '@mui/material/CircularProgress';
import { capitalizeFirstLetter } from "../../../../utils/Resubale";
import { useMemo } from "react";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  border: "1px solid black",
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    // width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      // width: "20ch",
    },
  },
}));



const Searchbar = (props) => {
  const {
    setIsOpenWorkOut,
    setdraggedEvent,
    setIsOpenHandleWorkOut,
    setSelectedLibraryItem,
    setWorkoutLibraryDrawerState,
    workoutLibraryDrawerState,
    selectedLibraryItem,
    setIsLibraryChange,
    workoutLibrary,
    draggableEl,
    handleDragStart,
    editFormData,
    setEditFormData,
    setOpenEditDirect,
    workouts,
    setWorkouts,
    getLibraryList,
    isOpenDirect
  } = props;
  // take a value from the user and save it to a state
  const [searchTerm, setSearchTerm] = useState("");
  console.log("editFormData", editFormData, isOpenDirect);
  const [workoutSearch, setworkoutSearch] = useState("");
  const [debouncedSearch, setDebouncedSearch] = useState(workoutSearch);

  const [deleteModal, setDeleteModal] = useState(false);

  const [isOpenCollapse, setIsOpenCollapse] = useState(null);
  const [LibraryId, setLibraryId] = useState();
  const [copyCutLibraryId, setCutCopyLibraryID] = useState(undefined);
  const [copyWorkouts, setCopyWorkouts] = useState({ valid: false, id: "", title: "" });


  // at every occurance of the change event, sets the current value of the form to the state
  const handleChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleCutCopyLibraryID = async () => {
    setCutCopyLibraryID(selectedLibraryItem?.id);

    setCopyWorkouts({ valid: true, id: selectedLibraryItem.id })

  };

  const handleManageWorkouts = () => {
    setIsLibraryChange(true);
    setIsOpenHandleWorkOut(true);
    setIsOpenWorkOut("showTable")
  };

  const handleDrawerChange = () => {
    setWorkoutLibraryDrawerState(true);
  };
  const handleOpenCopyWorkouts = async () => {
    setCutCopyLibraryID(selectedLibraryItem?.id);
    if (copyWorkouts?.title) {
      let result = await copyPasteWorkoutLibrari(copyWorkouts);
      if (result.status) {
        Swal.fire({
          title: "Success",
          text: result.message,
          icon: "success",
        });
        setCopyWorkouts({ valid: false, title: "", id: "" })
        setSelectedLibraryItem({});
        getLibraryList()
      }
    } else {
      Swal.fire({
        title: "Alert",
        text: "Please add title first",
        icon: "info",
      });
    }
  }
  const items = [
    {
      label: (
        <div onClick={() => handleCutCopyLibraryID("copy")}>
          Copy Workout Library
        </div>
      ),
      key: "0",
    },
    {
      label: <div onClick={handleDrawerChange}>Edit Workout Library</div>,
      key: "4",
    },
    {
      label: <div onClick={() => handleOpenDelete()}>Delete Workout Library</div>,
      key: "5",
    },
    {
      label: (
        <div onClick={() => handleManageWorkouts()}>Manage workout(s)</div>
      ),
      key: "6",
    },
  ];


  const deleteLibrary = async () => {
    let result = await deleteWorkoutLibrary(
      selectedLibraryItem["id"]
    );
    if (result?.status) {
      Swal.fire({
        title: "Success",
        text: result.message,
        icon: "success",
      });
    }
    setDeleteModal(false)
    setSelectedLibraryItem({});
    getLibraryList()
  };
  const handleOpenDelete = async () => {
    setDeleteModal(true)
  };



  const [badge, setBadge] = useState("");
  const [IsLoader, setIsLoader] = useState(true);
  const fetchAllWorkoutsFromLibrary = async (id) => {
    setIsLoader(true)
    let result = await getAllWorkoutsByLiabrary(id);
    setIsLoader(false)
    setWorkouts(result[0]?.workout_library_workouts);
    setBadge(result[0]?.tagnames[0]?.badge)

  };
  const handleOpen = (clickedIndex, type, library_id) => {
    setWorkouts([])
    if (type == "down") {
      setLibraryId(library_id);
      fetchAllWorkoutsFromLibrary(library_id)
    }
    if (isOpenCollapse === clickedIndex) {
      setIsOpenCollapse(null);
    } else {
      setIsOpenCollapse(clickedIndex);
    }
  };

  const filterData = workoutLibrary?.filter((workout) =>
    workout?.workout_library_name?.toLowerCase().includes(searchTerm?.toLowerCase())
  );
  function debounce(func, delay) {
    let timeout;
    return (...args) => {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        func.apply(this, args);
      }, delay);
    };
  }
  useEffect(() => {
    const handler = debounce(() => {
      setDebouncedSearch(workoutSearch);
    }, 200); // Adjust the delay as needed

    handler();
    return () => {
      clearTimeout(handler);
    };
  }, [workoutSearch]);
  const filteredList = useMemo(() => {
    return workouts?.filter((row) => {
      return (
        row?.workout_master?.workout_planned_title?.toLowerCase().includes(debouncedSearch.toLowerCase())
      );
    });
  }, [workouts, debouncedSearch]);
  return (
    <div className="mt-3" style={{ height: "80vh" }}>
      {" "}
      <Search style={{ backgroundColor: "white", borderColor: "white" }}>
        <SearchIconWrapper>
          <SearchIcon style={{ color: "#E67E22" }} />
        </SearchIconWrapper>
        <StyledInputBase
          placeholder="Search…"
          inputProps={{ "aria-label": "search" }}
          value={searchTerm}
          onChange={handleChange}
        />
      </Search> &nbsp;
      {deleteModal &&
        <DeleteModal deleteModal={deleteModal} workoutName={"Workout Library"} setDeleteModal={setDeleteModal} deleteWorkoutFromLibrary={deleteLibrary} />}
      <CustomModal
        title="Change title to copy workout Library"
        visible={copyWorkouts.valid}
        onOk={handleOpenCopyWorkouts}
        onCancel={() => setCopyWorkouts({ valid: false, id: '', title: '' })}
        initialTitle={copyWorkouts.title}
        onChangeTitle={(title) =>
          setCopyWorkouts({ ...copyWorkouts, title })
        }
      />
      <div id="events">
        {filterData?.map((item, index) => (
          <div className="p-1 ml-1 mt-1"  >
            <div style={{ display: "flex", justifyContent: "space-between", fontSize: "14px" }}>
              <div className="library-scrollbar">
                <h3
                  onClick={() => {
                    setSelectedLibraryItem({ ...item, isEditForm: true });
                  }}
                  style={{ fontSize: "14px" }}
                >
                  {capitalizeFirstLetter(item?.workout_library_name)}
                </h3>

              </div>
              <span>
                {isOpenCollapse === index ? (
                  <KeyboardArrowUpIcon
                    style={{ color: "#E67E22" }}
                    onClick={() => handleOpen(index, "up")

                    }
                  />
                ) : (
                  <KeyboardArrowDownIcon
                    style={{ color: "#E67E22" }}
                    onClick={() => {
                      handleOpen(index, "down", item?.id);
                      setSelectedLibraryItem({ ...item, isEditForm: true });
                    }}
                  />

                )}
              </span>
              <span className="p-1">
                <Dropdown
                  menu={{
                    items,
                  }}
                  trigger={["click"]}
                  onOpenChange={() => {
                    setSelectedLibraryItem({ ...item, isEditForm: true });
                  }}
                >
                  <div>
                    <Space>
                      <IconAntennaBars1

                        style={{ transform: "rotate(90deg)", color: "#E67E22" }}
                      />
                    </Space>
                  </div>
                </Dropdown>
              </span>
            </div>
            <hr />
            {IsLoader && isOpenCollapse === index ? (<div><CircularProgress /></div>) : (
              <div>

                {isOpenCollapse === index &&
                  <Search style={{ backgroundColor: "white", borderColor: "white", borderRadius: "50px", margin: "0px 10px 10px 10px" }}>
                    <SearchIconWrapper>
                      <SearchIcon style={{ color: "#E67E22" }} />
                    </SearchIconWrapper>
                    <StyledInputBase
                      placeholder="Search…"
                      inputProps={{ "aria-label": "search" }}
                      value={workoutSearch}
                      onChange={(e) => setworkoutSearch(e.target.value)}
                    />
                  </Search>
                }
                {filteredList !== undefined &&
                  filteredList?.map((workout, workoutindex) => {
                    return (
                      <Collapse in={isOpenCollapse === index} key={workoutindex}>
                        <div
                          style={{ backgroundColor: "white", borderColor: "white", }}
                          className="library-scrollbar eventworkout fc-event fc-h-event mb-1 fc-daygrid-event fc-daygrid-block-event p-2  cursor-pointer"
                          id={`eventworkout-${workoutindex}`} // Update this line to use a unique identifier for each workout
                          title={workout?.workout_master?.workout_planned_title}
                          data-id={workout?.id}
                          workoutData={JSON.stringify(workout)}
                          draggable={true}
                          onDragStart={(e) => handleDragStart(e, workout)}

                        >
                          <div style={{ display: "flex", justifyContent: "end" }}>
                            <IconEdit
                              size={15}
                              color="white"
                              onClick={() => {
                                
                                  setIsOpenHandleWorkOut(false);
                                  setIsOpenWorkOut("showForm");
                                  setOpenEditDirect(true)
                                  setEditFormData({ workout_id: workout?.id, isEditForm: true });

                              }}
                            />
                          </div>

                          <div
                            className="bg-[#e67e224d]  cursor-all-scroll"
                            // id={`eventworkout-${workoutindex}`} // Update this line to use a unique identifier for each workout
                            // title={workout?.workout_master?.workout_planned_title}
                            // data-id={workout?.id}
                            // workoutData={JSON.stringify(workout)}
                            // draggable={true}
                            style={{ backgroundColor: "white", borderColor: "white", marginRight: "6px", fontSize: "13px" }}
                          // onDragStart={(e) => handleDragStart(e, workout)}
                          // onClick={() => console.log("handleDragStart",workout)}
                          >
                            <span style={{ color: "black" }} className="fc-event-main">{workout?.workout_master?.workout_planned_title}</span>
                          </div>
                        </div>
                      </Collapse>
                    );
                  })}
              </div>
            )}


          </div>
        ))}{" "}
      </div>
    </div>
  );
};

export default Searchbar;
