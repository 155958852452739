let eventGuid = 0
// let todayStr = new Date().toISOString().replace(/T.*$/, '') // YYYY-MM-DD of today

export const INITIAL_EVENTS = [
  {
    id: createEventId(),
    title:
      'Stretch cords. Another 2 weeks out of the pool. Ankle is just slow to heal',
    start: '2023-02-06'
  },
  {
    id: createEventId(),
    title: 'Club run.',
    start: '2023-02-07'
  },
  {
    id: createEventId(),
    title: 'Stretch cords.',
    start: '2023-02-08'
  },
  {
    id: createEventId(),
    title: 'Home up Whites Road. 300M walk 700M run X 8.',
    start: '2023-02-09'
  }
]

export function createEventId() {
  return String(eventGuid++)
}

export const getDateObj = (day, month, year) => {
  return new Date(year, month, day)
}
