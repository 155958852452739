import React, { useState } from "react";
import { filterNameAndColor } from "../../../../utils/constant";
import { Checkbox } from "@mui/material";
import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";

const Item = styled(Paper)(({ theme }) => ({
  color: theme.palette.text.primary,
  background : "rgb(241 245 249 / 1)"
}));

const Filter = (props) => {
  const { data, setFilteredData } = props;
  const [filters, setFilters] = useState(filterNameAndColor);

  const handleChange = (e) => {
    if (e.target.name === "ViewAll") {
      filters.forEach((filter) => {
        filter.isSelected = e.target.checked;
      });
      setFilters([...filters]);
    } else {
      filters.forEach((filter) => {
        if (filter.name === e.target.name) {
          filter.isSelected = e.target.checked;
        }
      });
    }

    let tempList = [];
    let isOneFilterChecked = false;
    filters.forEach((filter) => {
      if (filter.isSelected) {
        isOneFilterChecked = true;
        let list = data.filter((item) => item.calender === filter.name);
        tempList.push(...list);
      }
    });

    if (isOneFilterChecked === false) {
      tempList = data;
    }

    setFilteredData([...tempList]);
  };

  return (
    <div className="ml-4">
      <h3
        style={{
          display: "flex",
          justifyContent: "left",
          margin: "4% 0px 2% 0px",
          fontSize: "1.2rem",
          padding: "1%",
          color: "gray",
        }}
      >
        FILTER
      </h3>
      <div>
        <Stack direction="column" >
          <Item elevation={0} className="bg-slate-100">
            <Checkbox
              size="medium"
              sx={{
                color: "#7B68EE",
                "&.Mui-checked": {
                  color: "#7B68EE",
                },
              }}
              defaultChecked
              onChange={handleChange}
              name="ViewAll"
              value={"ViewAll"}
              className="bg-slate-100"
            />
            View all
          </Item>
          {filters.map((filter, index) => {
            return (
              <>
                <Item elevation={0} key={index} >
                  <Checkbox
                    key={index}
                    size="medium"
                    name={filter.name}
                    sx={{
                      color: filter.color,
                      "&.Mui-checked": {
                        color: filter.color,
                      },
                    }}
                    onChange={handleChange}
                    value={filter.name}
                    defaultChecked={filter.isSelected}
                  />
                  {filter.name}
                </Item>
              </>
            );
          })}{" "}
        </Stack>
      </div>
    </div>
  );
};

export default Filter;
