import React, { useEffect, useMemo, useState } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";
import { Button, CircularProgress, Grid,  Pagination, TextField } from "@mui/material";
import "../../components/AssignCoach/Assign.css";
import { ExclamationCircleFilled } from '@ant-design/icons';
import Header from "../../components/Header";
import {  deleteWorkoutDistribution,  getWorkoutDistribution } from "../../API/api-endpoint";
import { IconEdit, IconTrash } from "@tabler/icons";
import { Modal } from "antd";
import MenuItem from "@mui/material/MenuItem";
import CreateDistribution from "../../components/FeedData/CreateDistribution";

// let PageSize = 15;
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#1e40af",
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
        border: 0,
    },
}));
const WorkoutDistribution = () => {
    const { confirm } = Modal;
    const [reportData, setReportData] = useState()
    const [editData, setEditData] = useState()
    const [isLoading, setIsLoading] = useState(true)
    const [searchTerm, setSearchTerm] = useState('');

    const [showAssesmentModal, setShowAssesmentModal] = useState(false);


    const fetchReport = async () => {
        const response = await getWorkoutDistribution()
        setIsLoading(false)

        console.log("response", response);
        setReportData(response)
    }
    useEffect(() => {
        fetchReport()
    }, [])
    const [currentPage, setCurrentPage] = useState(1);
    const [selectedActivityFilter, setSelectedActivityFilter] = useState('All');

    const handleActivityFilterChange = (event) => {
        setSelectedActivityFilter(event.target.value);
        setCurrentPage(1); // Reset the current page when the filter changes
    };
    const filteredList = useMemo(() => {
        return reportData?.filter((row) => {
            if (selectedActivityFilter === 'All') {
                return row?.activities?.activity_name?.toLowerCase().includes(searchTerm.toLowerCase());
            } else {
                return (
                    row?.activities?.activity_name?.toLowerCase().includes(searchTerm.toLowerCase()) &&
                    row?.activities?.activity_name?.toLowerCase().trim() === selectedActivityFilter.toLowerCase()
                );
            }

        });
    }, [reportData, selectedActivityFilter, searchTerm]);
    let PageSize = searchTerm || selectedActivityFilter !== 'All' ? filteredList?.length : 15;

    const checkLastPage = useMemo(() => {
        let frstPgae = (currentPage - 1) * PageSize;
        let lastPage = frstPgae + PageSize;
        return filteredList?.slice(frstPgae, lastPage)?.map((row, index) => ({
            ...row,
            // Adjusting index on the first page and adding count from the second page onward
            srID: index + 1 + (currentPage > 1 ? frstPgae : 0),
        }));
    }, [currentPage, reportData, filteredList]);
    const handlePageChange = (event, page) => {
        setCurrentPage(page);
    };

    const DeleteZones = async (id) => {
        const response = await deleteWorkoutDistribution(id)
        setCurrentPage(1)
        fetchReport()
        console.log("res", response);
    }
    const showDeleteConfirm = (id) => {
        confirm({
            title: 'Are you sure delete this workout distibution?',
            icon: <ExclamationCircleFilled />,
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk() {
                DeleteZones(id)
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    };
    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
        setCurrentPage(1); // Reset the current page when the search term changes
    };
    return (
        <div>
            <Header />
            <div className="grid grid-cols-1 xl:grid-cols-5 items-start gap-x-4"></div>
            <div style={{ marginTop: "100px", padding: "20px" }}>
                <TableContainer component={Paper}>
                    <div style={{ fontSize: "18px", background: "#FFEADC", width: "100%", padding: "10px" }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={4} sx={{ marginTop: "30px" }}>
                                <Button variant="contained" onClick={() => setShowAssesmentModal(true)}>Create Workout Distribution</Button>
                            </Grid>
                            <Grid item xs={12} sm={6} sx={{ textAlign: "end", marginTop: "30px" }}>
                                <TextField
                                    select
                                    size="small"
                                    label="Filter by Activity"
                                    value={selectedActivityFilter}
                                    onChange={handleActivityFilterChange}
                                    style={{ width: '150px' }}
                                >
                                    <MenuItem value="All">All</MenuItem>
                                    <MenuItem value="Running">Running</MenuItem>
                                    <MenuItem value="Cycling">Cycling</MenuItem>
                                    <MenuItem value="Fitness">Fitness</MenuItem>
                                    <MenuItem value="Triathlon">Triathlon</MenuItem>
                                    <MenuItem value="Swimming">Swimming</MenuItem>
                                    <MenuItem value="Duathlon">Duathlon</MenuItem>
                                    <MenuItem value="Nutrition">Nutrition</MenuItem>
                                    <MenuItem value="Note/Tip">Note/Tip</MenuItem>
                                </TextField>
                            </Grid>
                            <Grid item xs={12} sm={2} sx={{ textAlign: "end", marginTop: "30px" }}>
                                <TextField type="text" size="small" value={searchTerm} onChange={handleSearchChange} placeholder="Search By Activity Name.." />
                            </Grid>
                        </Grid>
                    </div>
                    <TableContainer style={{ maxHeight: 550, scrollbarWidth: "none" }}>
                        <Table stickyHeader sx={{ minWidth: 700 }} aria-label="customized table">
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell align="left">Sr ID</StyledTableCell>
                                    <StyledTableCell align="left">Activity Name</StyledTableCell>
                                    <StyledTableCell align="left">NumberOf Days</StyledTableCell>
                                    <StyledTableCell align="left">Option Number</StyledTableCell>
                                    <StyledTableCell align="left">Monday</StyledTableCell>
                                    <StyledTableCell align="left">Tuesday</StyledTableCell>
                                    <StyledTableCell align="left">Wednesday</StyledTableCell>
                                    <StyledTableCell align="left">Thursday</StyledTableCell>
                                    <StyledTableCell align="left">Friday</StyledTableCell>
                                    <StyledTableCell align="left">Saturday</StyledTableCell>
                                    <StyledTableCell align="left">Sunday</StyledTableCell>
                                    <StyledTableCell align="left">Action</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {isLoading ? (
                                    <CircularProgress className="m-6" />) : (
                                    <>
                                        {checkLastPage?.length > 0 ? (
                                            <>
                                                {checkLastPage?.map((row, index) => (
                                                    <StyledTableRow key={index}>
                                                        <StyledTableCell align="left">
                                                            {row?.srID}
                                                        </StyledTableCell>
                                                        <StyledTableCell align="left">
                                                            {row?.activities?.activity_name}

                                                        </StyledTableCell>
                                                        <StyledTableCell align="left">
                                                            {row?.numofdays} Days

                                                        </StyledTableCell>
                                                        <StyledTableCell align="left">
                                                            {row?.optionNumber}
                                                        </StyledTableCell>
                                                        <StyledTableCell align="left">
                                                            {row?.monday}
                                                        </StyledTableCell>  <StyledTableCell align="left">
                                                            {row?.tuesday}
                                                        </StyledTableCell>  <StyledTableCell align="left">
                                                            {row?.wednesday}
                                                        </StyledTableCell>  <StyledTableCell align="left">
                                                            {row?.thursday}
                                                        </StyledTableCell>  <StyledTableCell align="left">
                                                            {row?.friday}
                                                        </StyledTableCell>  <StyledTableCell align="left">
                                                            {row?.saturday}
                                                        </StyledTableCell>  <StyledTableCell align="left">
                                                            {row?.sunday}
                                                        </StyledTableCell>
                                                        <StyledTableCell align="left">
                                                            <div className="flex ">
                                                                <span className="px-2 cursor-pointer">
                                                                    <IconEdit
                                                                        color="blue"
                                                                        onClick={() => {
                                                                            setShowAssesmentModal(true);
                                                                            setEditData(row);
                                                                        }}
                                                                    />
                                                                </span>
                                                                <span className="px-2 cursor-pointer">
                                                                    <IconTrash
                                                                        color="red"
                                                                        onClick={() => showDeleteConfirm(row?.id)}
                                                                    />
                                                                </span>
                                                            </div>

                                                        </StyledTableCell>
                                                    </StyledTableRow>
                                                ))}
                                            </>
                                        ) : (
                                            <div className="p-4">No data found</div>
                                        )}
                                    </>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </TableContainer>
                &nbsp;
                <div className="flex justify-end">
                    <Pagination
                        count={Math.ceil(filteredList?.length / PageSize)} // Calculate total number of pages
                        color="primary"
                        page={currentPage}
                        onChange={handlePageChange}
                    />
                </div>
            </div>
            {
                <CreateDistribution setEditData={setEditData} editData={editData} fetchReport={fetchReport} showAssesmentModal={showAssesmentModal} setShowAssesmentModal={setShowAssesmentModal} />}
        </div>
    )
}
export default WorkoutDistribution
