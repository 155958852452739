import {
  FormLabel,
  Grid,
  MenuItem,
  OutlinedInput,
  TextField,
} from "@mui/material";
import { Button, Modal, TimePicker } from "antd";
import React, { useEffect, useState } from "react";
import {
  createImageURLdata,
  getAllActivityData,
  getAllQuestionData,
  getAlllevels,
  updateImageURLdata,
  uploadsaveFile,
} from "../../API/api-endpoint";
import { useFormik } from "formik";
import Swal from "sweetalert2";
import { IoCloseSharp } from "react-icons/io5";
import SlickCarousel from "../../pages/SlickCarousel";

const CraeteImageREview = ({
  fetchReport,
  setShowAssesmentModal,
  showAssesmentModal,
  editData,
  setEditData,
}) => {
  const [questionList, setQuestionList] = useState([]);
  const [programList, setProgramList] = useState([]);
  const [allLevelList, setAlllevelList] = useState([]);

  console.log("editData", editData);
  const formik = useFormik({
    initialValues: {
      activity_id: "",
      name: "",
      is_do: true,
      image_url: "",
    },
    validate: (values) => {
      const errors = {};
      if (!values.activity_id) {
        errors.activity_id = "Activity is required";
      }
      if (!values.name) {
        errors.name = "Name is required";
      }
      if (!values.image_url) {
        errors.image_url = "Image is required";
      }

      return errors;
    },
    // validationSchema: {},
    onSubmit: (values, { resetForm }) => {
      handleSubmitAssesmentForm(values, resetForm);
    },
  });
  console.log("formik", formik?.values, formik?.errors);

  useEffect(() => {
    getAllProgramsData();
  }, []);
  const getAllProgramsData = async () => {
    const response = await getAllActivityData();
    console.log("response", response);
    setProgramList(response?.rows);
  };

  const handleSubmitAssesmentForm = async (data, resetForm) => {
    let response = "";
    if (editData?.id) {
      response = await updateImageURLdata(data);
    } else {
      response = await createImageURLdata(data);
    }
    if (response?.status) {
      Swal.fire({
        title: "Success",
        text: response.message,
        icon: "success",
      });
      setEditData({});
      setShowAssesmentModal(false);
      fetchReport();
      formik.resetForm();
      formik?.setValues({
        activity_id: "",
        name: "",
        is_do: true,
        image_url: "",
      });
    } else {
      Swal.fire({
        title: "Error",
        text: response.message,
        icon: "error",
      });
    }
    console.log("response", response);
  };
  useEffect(() => {
    if (editData?.id) {
      const { srID, ...data } = editData;
      formik?.setValues(data);
      formik?.setFieldValue("name", data?.name ? data?.name : "");
    }
  }, [editData?.id]);

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    if (file) {
      const formData = new FormData();
      formData.append("file", file);

      const responst = await uploadsaveFile(formData);
      if (responst?.status) {
        formik.setFieldValue("image_url", responst?.file);
      }
    }
  };
  return (
    <Modal
      width={1200}
      open={showAssesmentModal}
      onCancel={() => {
        setShowAssesmentModal(false);
        formik.resetForm();
        setEditData({});
        formik?.setValues({
          activity_id: "",
          name: "",
          is_do: true,
          image_url: "",
        });
      }}
      footer={
        <div></div>
        //   loading={isLoading}
      }
    >
      <div className="headingCont">
      <span className="heading">{editData?.id ? "Edit " : "Create"}</span>{" "}
        <span className="orange heading">Review Image</span>
        <p className="grey">Set up a new fitness challenge for Yoska Users</p>
      </div>

      <div className="parentCont">
        <form className="form1" onSubmit={formik.handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={11}>
              <FormLabel>Activity Name<span className="text-[red]">*</span></FormLabel>

              <TextField
                fullWidth
                size="small"
                select
                name="activity_id"
                value={formik?.values?.activity_id}
                onChange={formik.handleChange}
                error={formik.touched.activity_id && formik.errors.activity_id}
                helperText={
                  formik.touched.activity_id && formik.errors.activity_id
                }
                id="form-layouts-separator-select"
                labelId="form-layouts-separator-select-label"
                input={<OutlinedInput id="select-multiple-language" />}
              >
                <MenuItem value={""} disabled>
                  Select Activity
                </MenuItem>
                {programList?.map((value, index) => {
                  return (
                    <MenuItem value={value?.id}>
                      {value?.activity_name}
                    </MenuItem>
                  );
                })}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={11}>
              <FormLabel>Name<span className="text-[red]">*</span></FormLabel>

              <TextField
                fullWidth
                placeholder="Name"
                size="small"
                type="text"
                name="name"
                value={formik?.values?.name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.name && formik.errors.name}
                helperText={formik.touched.name && formik.errors.name}
              />
            </Grid>
            <Grid item xs={12} sm={11} className="relative">
              <FormLabel>Upload Image<span className="text-[red]">*</span></FormLabel>

              <TextField
                fullWidth
                placeholder="Zone"
                size="small"
                type={formik?.values?.image_url ? "text" : "file"}
                name="image_url"
                disabled={formik?.values?.image_url}
                value={formik?.values?.image_url}
                onChange={handleFileUpload}
                error={formik.touched.image_url && formik.errors.image_url}
                helperText={formik.touched.image_url && formik.errors.image_url}
                inputProps={{
                  accept: ".jpg, .png .jpeg", // Specify accepted file formats here
                }}
              />
              {formik?.values?.image_url && (
                <IoCloseSharp
                  onClick={() => {
                    formik.setFieldValue("image_url", "");
                  }}
                  color="darkgray"
                  className="cursor-pointer absolute top-[5px] right-[-10px]"
                  style={{ fontSize: "24px" }}
                />
              )}
            </Grid>
            <Grid item xs={12} sm={11}>
              <FormLabel>Is Do</FormLabel>

              <TextField
                fullWidth
                size="small"
                select
                name="is_do"
                value={formik?.values?.is_do}
                onChange={formik.handleChange}
                error={formik.touched.is_do && formik.errors.is_do}
                helperText={formik.touched.is_do && formik.errors.is_do}
                id="form-layouts-separator-select"
                labelId="form-layouts-separator-select-label"
                input={<OutlinedInput id="select-multiple-language" />}
              >
                <MenuItem value={true}>Yes</MenuItem>
                <MenuItem value={"false"}>No</MenuItem>
              </TextField>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Button
                className="btn"
                key="submit"
                type="primary"
                onClick={() => formik.handleSubmit()}
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </form>

        <div className="slick-container">
          <SlickCarousel />
        </div>
      </div>
    </Modal>
  );
};
export default CraeteImageREview;
