import { Avatar, Box, Paper, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import Navbar from '../components/Navbar';
import axios from 'axios';
import { useState } from 'react';

export default function TempChatPage() {
    const paper = {
        padding: "0rem",
        minHeight: "100vh",
        maxWidth: '27rem'
    };

    const [list, setList] = useState([])


    const token = 'b78ed8c9c33b65a4e85ae9c57634254d12a3c26bda45a562e40e6e38cdd9c7c82b3cb3ff009ecd45e730e7ab23f8669890045d53cc2babbf66f333f07fab912f98aa1e913ceb933ea5d153d7c1c836b78f722f781bc6483008713c11e44052820a2e1b5341681d74ac851fb6cf54194f1f4a3d17932877a9fb6403da22e7ea5394b3c73e703e871f3ff54c15c3afa4db973f09f83111491d50c729c2d66dc72506afa19b069158de44c6492f1304dd5ce69eb80568da0d3652980356d80ec768a8262b11f6404448a59a57389898b34ee5d1f0b403f04abdc65a4580114cb616914b05feb5ab956e98d01a92a33ef6d0'

    const getAllUserChatsList = async () => {
        const config = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }

        const payload = {
            totalItems: 20,
            pageNumber: 0,
            type: "0"
        }

        try {
            const { data } = await axios.post('/v5/threads/get_all_threads.php', payload, config)
            console.log(data.object.list)
            setList(data.object.list)

        } catch (error) {
            console.error('Error fetching post:', error);
        }
    }

    console.log(list)

    useEffect(() => {
        getAllUserChatsList()
    }, [])

    return <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Paper sx={{ flexGrow: 1 }} elevation={10} style={paper}>
            <Navbar />
            {/* position: 'fixed', width: "100%", maxWidth: '26rem', zIndex: 10 */}
            <Box sx={{ backgroundColor: "#FDFBE7", padding: "8px", display: "flex", justifyContent: 'space-between', alignItems: 'center', marginBottom: '5px' }}>
                <Box sx={{ display: "flex", alignItems: 'center', padding: '6px' }}>
                    <Avatar sx={{ backgroundColor: '#FDE723' }}>
                        <img src="/chats-icon.png" alt="" />
                    </Avatar>
                    <Typography className='start-new-chat' sx={{ paddingLeft: '1.2rem' }}>Start a New Chat</Typography>
                </Box>
                <img src="/cross-arrow.png" alt="cross-arrow" />
            </Box>

            {
                list?.map((item, index) => {
                    return <Box
                        sx={
                            !item.isMsgReqAccepted
                                ? { cursor: "pointer", padding: "8px", paddingTop: "8px", display: "flex", justifyContent: 'space-between', alignItems: 'center' }
                                : { backgroundColor: "#FDFBE7", cursor: "pointer", padding: "8px", paddingTop: "10px", display: "flex", justifyContent: 'space-between', alignItems: 'center' }
                        }>
                        <Box sx={{ display: "flex", alignItems: 'center', padding: '6px' }}>
                            <Avatar alt="Remy Sharp" src={item?.imageURL} />
                            <Box>
                                <Typography className='userName' fontWeight="fontWeightBold" sx={{ paddingLeft: '1.2rem' }}>{item?.userFullName}</Typography>
                                <Typography sx={{ paddingLeft: '1.2rem', fontSize: '14px', paddingTop: "4px" }}>
                                    {item.lastMessage}
                                </Typography>

                            </Box>
                        </Box>
                        <Box sx={{ textAlign: 'right' }} >
                            <Box className="timeStamp" style={{ marginTop: '-19px', color: 'var(--grey-2, #707070)' }}>
                            </Box>
                            <Box sx={{ textAlign: 'right', display: "flex", alignItems: 'center', justifyContent: 'end', paddingTop: "4px" }}>
                            </Box>
                        </Box>
                    </Box>
                })
            }

        </Paper >
    </Box >
}