import { FormLabel, Grid, TextField } from '@mui/material';
import { Button, Modal, TimePicker } from 'antd'
import React, { useEffect, useState } from 'react'
import { createActivityTrackdata,  updateActivityTrackdata, uploadsaveFile } from '../../API/api-endpoint';
import { useFormik } from 'formik';
import Swal from 'sweetalert2';
import { IoCloseSharp } from "react-icons/io5";
import SlickCarousel from "../../pages/SlickCarousel";

const CreateActivityTrack = ({ fetchReport, setShowAssesmentModal, showAssesmentModal, editData, setEditData }) => {
    const formik = useFormik({
        initialValues: {
            activity_track: "",
            badge: "",
        }, validate: (values) => {
            const errors = {};
            if (!values.activity_track) {
                errors.activity_track = "Activity Track is required";
            }
           
            if (!values.badge) {
                errors.badge = "Badge is required";
            }
        
            return errors;
        },
        // validationSchema: {},
        onSubmit: (values, { resetForm }) => {
            handleSubmitAssesmentForm(values, resetForm)

        },
    });
    const handleSubmitAssesmentForm = async (data, resetForm) => {
        let response = ""
        if (editData?.id) {
            response = await updateActivityTrackdata(data)
        } else {
            response = await createActivityTrackdata(data)

        }
        if (response?.status) {
            Swal.fire({
                title: "Success",
                text: response.message,
                icon: "success",
            });
            setEditData({})
            setShowAssesmentModal(false)
            fetchReport()
            formik.resetForm()
            formik?.setValues({
                activity_track: "",
                badge: "",
            })
        } else {
            Swal.fire({
                title: "Error",
                text: response.message,
                icon: "error",
            });
        }
        console.log("response", response);
    }
    useEffect(() => {
        if (editData?.id) {
            const { srID, ...data } = editData
            formik?.setValues(data)
        }
    }, [editData?.id])

    const handleFileUpload = async (event) => {
        const file = event.target.files[0];
        if (file) {

            const formData = new FormData();
            formData.append('file', file);

            const responst = await uploadsaveFile(formData)
            if (responst?.status) {

                formik.setFieldValue("badge", responst?.file)
            }
        }
    }
    return (
        <Modal
      width={1200}
      open={showAssesmentModal}
      onCancel={() => {
        setShowAssesmentModal(false);
        formik.resetForm();
        setEditData({});
        formik?.setValues({
          activity_track: "",
          badge: "",
        });
      }}
      footer={
        <div></div>
        //   loading={isLoading}
      }
    >
      <div className="headingCont">
        <span className="heading">{editData?.id ? "Edit " : "Create"}</span>{" "}
        <span className="orange heading">Activity Track</span>
      </div>

      <div className="parentCont">
        <form className="form1" onSubmit={formik.handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={11}>
              <FormLabel>Activity Group Track<span className="text-[red]">*</span></FormLabel>

              <TextField
                fullWidth
                placeholder="Activity Group Track"
                size="small"
                type="text"
                name="activity_track"
                value={formik?.values?.activity_track}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.activity_track && formik.errors.activity_track
                }
                helperText={
                  formik.touched.activity_track && formik.errors.activity_track
                }
              />
            </Grid>
            <Grid item xs={12} sm={11} className="relative">
              <FormLabel>Upload Badge<span className="text-[red]">*</span></FormLabel>

              <TextField
                fullWidth
                placeholder="Zone"
                size="small"
                type={formik?.values?.badge ? "text" : "file"}
                name="badge"
                disabled={formik?.values?.badge}
                value={formik?.values?.badge}
                onChange={handleFileUpload}
                error={formik.touched.badge && formik.errors.badge}
                helperText={formik.touched.badge && formik.errors.badge}
                inputProps={{
                  accept: ".jpg, .png .jpeg", // Specify accepted file formats here
                }}
              />
              {formik?.values?.badge && (
                <IoCloseSharp
                  onClick={() => {
                    formik.setFieldValue("badge", "");
                  }}
                  color="darkgray"
                  className="cursor-pointer absolute top-0 right-[-10px]"
                  style={{ fontSize: "24px" }}
                />
              )}
            </Grid>
            <Grid item xs={12} sm={6}>
              <Button
                className="btn"
                key="submit"
                type="primary"
                onClick={() => formik.handleSubmit()}
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </form>
        <div className="slick-container">
          <SlickCarousel />
        </div>
      </div>
    </Modal>
  );
};
export default CreateActivityTrack;
