import React, { useEffect, useState } from "react";
import { Box, Button, Grid, IconButton, MenuItem, Select } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import "./walkjog.css";
import PropTypes from "prop-types";
import { useAutocomplete } from "@mui/base/useAutocomplete";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import SellIcon from "@mui/icons-material/Sell";
import AddIcon from "@mui/icons-material/Add";
import { styled } from "@mui/material/styles";
import { autocompleteClasses } from "@mui/material/Autocomplete";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { EditorState } from "draft-js";

const TagInput = styled("div")(
  ({ theme }) => `
  color: ${
    theme.palette.mode === "dark" ? "rgba(255,255,255,0.65)" : "rgba(0,0,0,.85)"
  };
  font-size: 14px;
`
);

const Label = styled("label")`
  padding: 0 0 4px;
  line-height: 1.5;
  display: block;
`;

const InputWrapper = styled("div")(
  ({ theme }) => `
  width: 100%;
  border: 1px solid ${theme.palette.mode === "dark" ? "#434343" : "#d9d9d9"};
  background: "lightgray";
  border-radius: 4px;
  padding: 5px;
  display: flex;
  flex-wrap: wrap;

  & input {
    background-color: "lightgray";
    color: ${
      theme.palette.mode === "dark"
        ? "rgba(255,255,255,0.65)"
        : "rgba(0,0,0,.85)"
    };
    height: 30px;
    box-sizing: border-box;
    padding: 4px 6px;
    width: 0;
    min-width: 30px;
    flex-grow: 1;
    border: 0;
    margin: 0;
    outline: 0;
  }
`
);

function Tag(props) {
  const { label, onDelete, ...other } = props;
  return (
    <div {...other}>
      <span>{label}</span>
      <CloseIcon onClick={onDelete} />
    </div>
  );
}

Tag.propTypes = {
  label: PropTypes.string.isRequired,
  onDelete: PropTypes.func.isRequired,
};

const StyledTag = styled(Tag)(
  ({ theme }) => `
  display: flex;
  align-items: center;
  height: 24px;
  margin: 2px;
  line-height: 22px;
  background-color: ${
    theme.palette.mode === "dark" ? "rgba(255,255,255,0.08)" : "#fafafa"
  };
  border: 1px solid ${theme.palette.mode === "dark" ? "#303030" : "#e8e8e8"};
  border-radius: 2px;
  box-sizing: content-box;
  padding: 0 4px 0 10px;
  outline: 0;
  overflow: hidden;

  &:focus {
    border-color: ${theme.palette.mode === "dark" ? "#177ddc" : "#40a9ff"};
    background-color: ${theme.palette.mode === "dark" ? "#003b57" : "#e6f7ff"};
  }

  & span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  & svg {
    font-size: 12px;
    cursor: pointer;
    padding: 4px;
  }
`
);

const Listbox = styled("ul")(
  ({ theme }) => `
  width: 300px;
  margin: 2px 0 0;
  padding: 0;
  position: absolute;
  list-style: none;
  background-color: ${theme.palette.mode === "dark" ? "#141414" : "#fff"};
  overflow: auto;
  max-height: 250px;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  z-index: 1;

  & li {
    padding: 5px 12px;
    display: flex;

    & span {
      flex-grow: 1;
    }

    & svg {
      color: transparent;
    }
  }

  & li[aria-selected='true'] {
    background-color: ${theme.palette.mode === "dark" ? "#2b2b2b" : "#fafafa"};
    font-weight: 600;

    & svg {
      color: #1890ff;
    }
  }

  & li.${autocompleteClasses.focused} {
    background-color: ${theme.palette.mode === "dark" ? "#003b57" : "#e6f7ff"};
    cursor: pointer;

    & svg {
      color: currentColor;
    }
  }
`
);

const wlakJogTags = [
  { title: "Running" },
  { title: "Basic Fitness" },
  { title: "Walking" },
  { title: "Beginner" },
  { title: "Swimming" },
  { title: "ETC" },
  { title: "Playing" },
];

const WalkJog = () => {
  const {
    getRootProps,
    getInputLabelProps,
    getInputProps,
    getTagProps,
    getListboxProps,
    getOptionProps,
    groupedOptions,
    value,
    focused,
    setAnchorEl,
  } = useAutocomplete({
    id: "customized-hook-demo",
    defaultValue: [wlakJogTags[1], wlakJogTags[4]],
    multiple: true,
    options: wlakJogTags,
    getOptionLabel: (option) => option.title,
  });

  const [selectedTags, setSelectedTags] = useState([]);
  console.log(selectedTags);
  useEffect(() => {
    setSelectedTags(value);
  }, [value]);

  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  const handleEditorStateChange = (newEditorState) => {
    setEditorState(newEditorState);
  };

  return (
    <div className="walkjog-form-container">
      <div className="walkjog-form-main">
        <div className="walkjog-title-section">
          <div className="title">walk jog - nutrition atheletes</div>
          <div className="button-section">
            <Button variant="contained" color="success">
              Save
            </Button>
            <IconButton aria-label="delete">
              <DeleteIcon />
            </IconButton>
          </div>
        </div>
        <div className="walkjog-form-section">
          <div className="walkjog-form-group">
            <label> Workout Name</label>
            <input type="text" placeholder="" />
          </div>
        </div>
        <div className="walkjog-second-row">
          <Grid container sx={{ display: "flex", gap: "30px" }}>
            <Grid md={5} sm={5} lg={5} xs={12}>
              <div className="walkjog-acticity-inputs">
                <div className="walkjog-form-group">
                  <label>Activity</label>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    className="select"
                    name="measureunit1"
                    value={""}
                    label="select"
                  >
                    <MenuItem value={"Running"}>Running</MenuItem>
                    <MenuItem value={"Swimming"}>Swimming</MenuItem>
                    <MenuItem value={"Walking"}>Walking</MenuItem>
                  </Select>
                </div>
                <div className="walkjog-form-group">
                  <label>Sub Activity</label>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    className="select"
                    name="measureunit1"
                    value={""}
                    label="select"
                  >
                    <MenuItem value={"Running"}>Running</MenuItem>
                    <MenuItem value={"Swimming"}>Swimming</MenuItem>
                    <MenuItem value={"Walking"}>Walking</MenuItem>
                  </Select>
                </div>
              </div>
            </Grid>
            <Grid md={6} sm={6} lg={6} xs={12}>
              <div className="walkjog-zone-section">
                <div className="title">
                  Zone have been defined for this activity. You can attach zone
                  for a selected matric
                </div>
                <div className="walkjog-zone-group">
                  <div className="metric">
                    <div className="walkjog-form-group">
                      <label>Metric</label>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        className="select "
                        name="measureunit1"
                        value={""}
                        label="select"
                        variant="standard"
                      >
                        <MenuItem value={"east"}>East</MenuItem>
                        <MenuItem value={"west"}>West</MenuItem>
                        <MenuItem value={"north"}>North</MenuItem>
                        <MenuItem value={"south"}>South</MenuItem>
                      </Select>
                    </div>
                  </div>
                  <div className="zone">
                    <div className="walkjog-form-group">
                      <label>Zone</label>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        className="select"
                        name="measureunit1"
                        value={""}
                        label="select"
                        variant="standard"
                      >
                        <MenuItem value={"zone1"}>zone 1</MenuItem>
                        <MenuItem value={"zone2"}>zone 2</MenuItem>
                        <MenuItem value={"zone3"}>zone 3</MenuItem>
                      </Select>
                    </div>
                  </div>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
        <div className="walkjog-third-row">
          <Grid
            container
            sx={{
              display: "flex",
              gap: { md: "25px", sm: "20px", xs: "20px" },
            }}
          >
            <Grid md={2} sm={2} lg={2} xs={12}>
              <div className="walkjog-form-group">
                <label>Duration</label>
                <input type="text" placeholder="00 : 30 : 00" />
              </div>
            </Grid>
            <Grid md={3} sm={3} lg={3} xs={12}>
              <div className="walkjog-form-group">
                <label>Distance</label>
                <input type="text" placeholder="0" />
              </div>
            </Grid>
            <Grid md={3} sm={3} lg={3} xs={12}>
              {" "}
              <div className="walkjog-form-group">
                <label>Unit of measure</label>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  className="select"
                  name="measureunit1"
                  value={""}
                  label="select"
                  variant="standard"
                >
                  <MenuItem value={"km"}>KM</MenuItem>
                  <MenuItem value={"meter"}>Meter</MenuItem>
                  <MenuItem value={"yard"}>yard</MenuItem>
                </Select>
              </div>
            </Grid>
            <Grid md={3} sm={3} lg={3} xs={12}>
              <div className="walkjog-form-group">
                <label>Pace</label>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  className="select"
                  name="measureunit1"
                  value={""}
                  label="select"
                  variant="standard"
                >
                  <MenuItem value={"60kmph"}>60 kmph</MenuItem>
                  <MenuItem value={"40kmph"}>40 kmph</MenuItem>
                  <MenuItem value={"20kmph"}>20 kmph</MenuItem>
                </Select>
              </div>
            </Grid>
          </Grid>
        </div>
        <div className="walkjog-tag-input">
          <div className="tag-input-section">
            <TagInput>
              <div {...getRootProps()}>
                <Label {...getInputLabelProps()}>Tages</Label>
                <Box sx={{ display: "flex" }}>
                  <IconButton>
                    <SellIcon />
                  </IconButton>
                  <InputWrapper
                    ref={setAnchorEl}
                    className={focused ? "focused" : ""}
                  >
                    {value.map((option, index) => (
                      <StyledTag
                        label={option.title}
                        {...getTagProps({ index })}
                      />
                    ))}
                    <input {...getInputProps()} />
                  </InputWrapper>
                </Box>
              </div>
              {groupedOptions.length > 0 ? (
                <Listbox {...getListboxProps()}>
                  {groupedOptions.map((option, index) => (
                    <li {...getOptionProps({ option, index })}>
                      <span>{option.title}</span>
                      <CheckIcon fontSize="small" />
                    </li>
                  ))}
                </Listbox>
              ) : null}
            </TagInput>
          </div>
        </div>
        <div className="walkjog-blocks-section">
          <div className="walkjog-blocks-main">
            <div className="title">Blocks</div>
            <div className="blocks-inputs">
              <IconButton>
                <AddIcon />
              </IconButton>
            </div>
          </div>
        </div>
        <div className="walkjog-discription-section">
          <div className="title">Discription</div>
          <Editor
            editorState={editorState}
            toolbarClassName="toolbarClassName"
            wrapperClassName="wrapperClassName"
            editorClassName="editorClassName"
            onEditorStateChange={handleEditorStateChange}
            // toolbar={toolBarOptions}
          />
        </div>
        <div className="walkjog-footer-section">
          <Button variant="contained" color="success">
            Save
          </Button>
        </div>
      </div>
    </div>
  );
};

export default WalkJog;
