import React from 'react'
import { Input, Select } from '@mantine/core'
import Header from '../components/Header'

const CoachReportPage = () => {
  return (
    <>
      <Header />
      {/* Topbar */}
      <div className='p-6'>
        <div className='flex items-start gap-x-2'>
          <h1 className='font-medium text-xl'>Coach Report</h1>
        </div>
        <br />
        <div className='flex flex-col md:flex-row gap-4 md:gap-8 items-start md:items-end mb-6'>
          <div>
            <Input.Wrapper id='input-demo' label='Search'>
              <Input id='input-demo' />
            </Input.Wrapper>
          </div>
          <div>
            <Select
              label='Select a coach'
              placeholder='Pick one'
              data={[
                { value: 'Usha Hegde', label: 'Usha Hegde' },
                { value: 'Ignit Anand', label: 'Ignit Anand' },
                { value: 'Neil Dsilva', label: 'Neil Dsilva' },
                { value: 'Ranajit Roy', label: 'Ranajit Roy' }
              ]}
            />
          </div>
        </div>
        {/* Table */}
        <div className='w-full mb-6'>
          <div className='my-6'>
            <div className='flex flex-col'>
              <div className='overflow-x-auto shadow-md'>
                <div className='inline-block min-w-full align-middle'>
                  <div className='overflow-hidden '>
                    <table className='min-w-full divide-y divide-gray-200 table-fixed dark:divide-gray-700'>
                      <thead className='bg-gray-100 '>
                        <tr>
                          <th
                            scope='col'
                            className='py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase dark:text-gray-400'
                          >
                            Coach Name
                          </th>
                          <th
                            scope='col'
                            className='py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase dark:text-gray-400'
                          >
                            No of Athletes
                          </th>
                          <th
                            scope='col'
                            className='py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase dark:text-gray-400'
                          >
                            Swim Feedback
                          </th>
                          <th
                            scope='col'
                            className='py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase dark:text-gray-400'
                          >
                            Run Feedback
                          </th>
                          <th
                            scope='col'
                            className='py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase dark:text-gray-400'
                          >
                            Race Assessment
                          </th>
                          <th
                            scope='col'
                            className='py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase dark:text-gray-400'
                          >
                            Weekly Feedback
                          </th>
                        </tr>
                      </thead>
                      <tbody className='bg-white divide-y divide-gray-200 '></tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default CoachReportPage
