import {
  FormLabel,
  Grid,
  MenuItem,
  OutlinedInput,
  TextField,
} from "@mui/material";
import { Button, Modal, TimePicker } from "antd";
import React, { useEffect, useState } from "react";
import {
  creatediscountcoupondata,
  getAllActivityData,
  getsubspackageData,
  updatediscountcoupondata,
  uploadsaveFile,
} from "../../API/api-endpoint";
import { useFormik } from "formik";
import Swal from "sweetalert2";
import { IoCloseSharp } from "react-icons/io5";
import moment from "moment";
import SlickCarousel from "../../pages/SlickCarousel";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";



const CreateDiscount = ({
  fetchReport,
  setShowAssesmentModal,
  showAssesmentModal,
  editData,
  setEditData,
}) => {
  const [programList, setProgramList] = useState([]);
  const [SElectedProgramPrice, setSElectedProgramPrice] = useState();


  console.log("SElectedProgramPrice", SElectedProgramPrice);
  const formik = useFormik({
    initialValues: {
      coupon: "",
      type: "",
      subscription_package_id: "",
      valid_to: "",
      discount: "",
      status: true,
      current_usage: "",
      max_usage: "",
    },
    validate: (values) => {
      const errors = {};
      if (!values.current_usage) {
        errors.current_usage = "Current usage is required";
      }
      if (!values.max_usage) {
        errors.max_usage = "Max usage is required";
      }
      if (!values.valid_to) {
        errors.valid_to = "Date is required";
      }
      if (!values.discount) {
        errors.discount = "Discount is required";
      } else if (values.discount >100){
        errors.discount = `Discount percentage should be less than or equal to 100%`
      }
      if (!values.subscription_package_id) {
        errors.subscription_package_id = "Subscription Name is required";
      }
      if (!values.coupon) {
        errors.coupon = "Coupon is required";
      }
      if (!values.type) {
        errors.type = "Type is required";
      }

      return errors;
    },
    // validationSchema: {},
    onSubmit: (values, { resetForm }) => {
      handleSubmitAssesmentForm(values, resetForm);
    },
  });
  console.log("formik", formik?.values, formik?.errors);

  useEffect(() => {
    getAllProgramsData();
  }, []);
  const getAllProgramsData = async () => {
    const response = await getsubspackageData();
    console.log("response", response);
    setProgramList(response);
  };

  const handleSubmitAssesmentForm = async (data, resetForm) => {
    let response = "";
    if (editData?.id) {
      response = await updatediscountcoupondata(data);
    } else {
      response = await creatediscountcoupondata(data);
    }
    if (response?.status) {
      Swal.fire({
        title: "Success",
        text: response.message,
        icon: "success",
      });
      setEditData({});
      setShowAssesmentModal(false);
      fetchReport();
      formik.resetForm();
      formik?.setValues({
        coupon: "",
        type: "",
        subscription_package_id: "",
        valid_to: "",
        discount: "",
        status: true,
        current_usage: "",
        max_usage: "",
      });
    } else {
      Swal.fire({
        title: "Error",
        text: response.message,
        icon: "error",
      });
    }
    console.log("response", response);
  };
  useEffect(() => {
    if (editData?.id) {
      const { srID, ...data } = editData;
      formik?.setValues(data);
      formik?.setFieldValue("name", data?.name ? data?.name : "");
    }
  }, [editData?.id]);


 
useEffect(()=>{
  if (formik?.values?.subscription_package_id) {
   let filterdata= programList?.filter((ele)=>ele?.id ==formik?.values?.subscription_package_id)
   setSElectedProgramPrice(filterdata[0]?.price)
  }
},[formik?.values?.subscription_package_id])
  return (
    <Modal
      width={1200}
      open={showAssesmentModal}
      onCancel={() => {
        setShowAssesmentModal(false);
        formik.resetForm();
        setEditData({});
        formik?.setValues({
          coupon: "",
          type: "",
          subscription_package_id: "",
          valid_to: "",
          discount: "",
          status: true,
          current_usage: "",
          max_usage: "",
        });
      }}
      footer={
        <div></div>
        //   loading={isLoading}
      }
    >
      <div className="headingCont">
        <span className="heading">{editData?.id ? "Edit " : "Create"}</span>{" "}
        <span className="orange heading">Discount Coupon</span>
      </div>

      <div className="parentCont">
        <form className="form1" onSubmit={formik.handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={11}>
              <FormLabel>Subscription Package<span className="text-[red]">*</span></FormLabel>

              <TextField
                fullWidth
                size="small"
                select
                name="subscription_package_id"
                SelectProps={{
                  MenuProps: {
                    PaperProps: {
                      style: {
                         scrollbarColor:"#E67E22 white",
                         scrollbarWidth:"thin"
                      },
                    },
                  },
                }}
                value={
                  formik?.values?.subscription_package_id
                    ? formik?.values?.subscription_package_id
                    : "placeholder"
                }
                onChange={formik.handleChange}
                error={
                  formik.touched.subscription_package_id &&
                  formik.errors.subscription_package_id
                }
                helperText={
                  formik.touched.subscription_package_id &&
                  formik.errors.subscription_package_id
                }
                id="form-layouts-separator-select"
                labelId="form-layouts-separator-select-label"
                input={<OutlinedInput id="select-multiple-language" />}
              >
                <MenuItem value={"placeholder"} disabled>
                  Select Subscription
                </MenuItem>
                {programList?.map((value, index) => {
                  return <MenuItem value={value?.id}>{value?.name} ({value?.price})</MenuItem>;
                })}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={11}>
              <FormLabel>Coupon Name<span className="text-[red]">*</span></FormLabel>
              <TextField
                fullWidth
                placeholder="Coupon"
                size="small"
                type="text"
                name="coupon"
                value={formik?.values?.coupon}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.coupon && formik.errors.coupon}
                helperText={formik.touched.coupon && formik.errors.coupon}
              />
            </Grid>

            <Grid item xs={12} sm={11}>
              <FormLabel>Type<span className="text-[red]">*</span></FormLabel>

              <TextField
                fullWidth
                placeholder="Type"
                size="small"
                type="text"
                name="type"
                value={formik?.values?.type}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.type && formik.errors.type}
                helperText={formik.touched.type && formik.errors.type}
              />
            </Grid>
            <Grid item xs={12} sm={11}>
              <FormLabel>Discount in %<span className="text-[red]">*</span></FormLabel>

              <TextField
                fullWidth
                placeholder="Discount"
                size="small"
                type="number"
                name="discount"
                value={formik?.values?.discount}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.discount && formik.errors.discount}
                helperText={formik.touched.discount && formik.errors.discount}
              />
            </Grid>
            <Grid item xs={12} sm={11}>
              <FormLabel>Current Usage<span className="text-[red]">*</span></FormLabel>

              <TextField
                fullWidth
                placeholder="Current Usage"
                size="small"
                type="number"
                name="current_usage"
                value={formik?.values?.current_usage}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.current_usage && formik.errors.current_usage
                }
                helperText={
                  formik.touched.current_usage && formik.errors.current_usage
                }
              />
            </Grid>
            <Grid item xs={12} sm={11}>
              <FormLabel>Max Usage<span className="text-[red]">*</span></FormLabel>

              <TextField
                fullWidth
                placeholder="Max Usage"
                size="small"
                type="number"
                name="max_usage"
                value={formik?.values?.max_usage}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.max_usage && formik.errors.max_usage}
                helperText={formik.touched.max_usage && formik.errors.max_usage}
              />
            </Grid>
            <Grid item xs={12} sm={11}  className="dateWrap">
              <FormLabel>Valid To<span className="text-[red]">*</span></FormLabel>

              <DatePicker
  selected={formik.values.valid_to ? new Date(formik.values.valid_to) : null}
  onChange={(date) => {
    formik.setFieldValue(
      "valid_to",
      moment(date).format("YYYY-MM-DD")
    );
  }}
  dateFormat="dd-MM-yyyy"
  placeholderText="DD-MM-YYYY"
  minDate={new Date()}
  customInput={
    <TextField
      style={{ width: "100%" }}
      fullWidth
      size="small"
      type="text"
      name="valid_to"
      error={formik.touched.valid_to && formik.errors.valid_to}
      helperText={formik.touched.valid_to && formik.errors.valid_to}
      InputLabelProps={{
        shrink: true,
      }}
    />
  }
/>

            </Grid>
            <Grid item xs={12} sm={11}>
              <FormLabel>Status</FormLabel>

              <TextField
                fullWidth
                size="small"
                select
                name="status"
                value={formik?.values?.status}
                onChange={formik.handleChange}
                error={formik.touched.status && formik.errors.status}
                helperText={formik.touched.status && formik.errors.status}
                id="form-layouts-separator-select"
                labelId="form-layouts-separator-select-label"
                input={<OutlinedInput id="select-multiple-language" />}
              >
                <MenuItem value={true}>Yes</MenuItem>
                <MenuItem value={"false"}>No</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Button
                className="btn"
                key="submit"
                type="primary"
                onClick={() => formik.handleSubmit()}
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </form>

        <div className="slick-container">
          <SlickCarousel />
        </div>
      </div>
    </Modal>
  );
};
export default CreateDiscount;
