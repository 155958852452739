import {
    FormLabel,
    Grid,
    MenuItem,
    OutlinedInput,
    TextField,
  } from "@mui/material";
  import { Button, Modal, TimePicker } from "antd";
  import React, { useEffect, useState } from "react";
  import {
    createMultipleRaceCalculation,
    createOptiondata,
    getAllQuestionData,
    updateMultipleRaceCalculation,
    updateOptiondata,
  } from "../../API/api-endpoint";
  import { useFormik } from "formik";
  import Swal from "sweetalert2";
  import { handleTimeChangeSeconds } from "../../utils/Resubale";
  import SlickCarousel from "../../pages/SlickCarousel";
  const CreateMultipleRace = ({
    fetchReport,
    setShowAssesmentModal,
    showAssesmentModal,
    editData,
    setEditData,
  }) => {
    const [questionList, setQuestionList] = useState([]);
    console.log("editData", questionList);
    const formik = useFormik({
      initialValues: {
        goal_name: "",
        running_time: "",
        running_quota: "",
        cycling_quota: "",
        cycling_time: "",
        swimming_quota: "",
        swimming_time: "",
      },
      validate: (values) => {
        const errors = {};
  
        if (!values.goal_name) {
          errors.goal_name = "Goal Name is required";
        }
        if (!values.running_time) {
          errors.running_time = "Running Time is required";
        }
        if (!values.running_quota) {
          errors.running_quota = "Running Distance is required";
        }
        if (!values.cycling_time) {
          errors.cycling_time = "Cycling Time is required";
        }
        if (!values.cycling_quota) {
          errors.cycling_quota = "Cycling Distance is required";
        }
        if (!values.swimming_time) {
          errors.swimming_time = "Swimming Time is required";
        }
        if (!values.swimming_quota) {
          errors.swimming_quota = "Swimming Distance is required";
        }
        return errors;
      },
      // validationSchema: {},
      onSubmit: (values, { resetForm }) => {
        handleSubmitAssesmentForm(values, resetForm);
      },
    });
    console.log("formik", formik?.values);
    const QuestionData = async () => {
      const response = await getAllQuestionData();
      console.log("response", response);
      setQuestionList(response);
    };
    useEffect(() => {
      QuestionData();
    }, []);
  
    const handleSubmitAssesmentForm = async (data, resetForm) => {
      let response = "";
      if (editData?.id) {
        response = await updateMultipleRaceCalculation(data);
      } else {
        response = await createMultipleRaceCalculation(data);
      }
      if (response?.status) {
        Swal.fire({
          title: "Success",
          text: response.message,
          icon: "success",
        });
        setEditData({});
        setShowAssesmentModal(false);
        fetchReport();
        formik.resetForm();
        formik?.setValues({
          goal_name: "",
          running_time: "",
          running_quota: "",
          cycling_quota: "",
          cycling_time: "",
          swimming_quota: "",
          swimming_time: "",
        });
      } else {
        Swal.fire({
          title: "Error",
          text: response.message,
          icon: "error",
        });
      }
      console.log("response", response);
    };
    useEffect(() => {
      if (editData?.id) {
        const { srID, ...data } = editData;
        console.log("data", data);
  
        formik?.setValues(data);
        formik?.setFieldValue("comments", data?.comments ? data?.comments : "");
      } else {
        setEditData({});
      }
    }, [editData?.id]);
  
    return (
      <Modal
        width={1200}
        open={showAssesmentModal}
        onCancel={() => {
          setShowAssesmentModal(false);
          formik.resetForm();
          setEditData({});
          formik?.setValues({
            goal_name: "",
            running_time: "",
            running_quota: "",
            cycling_quota: "",
            cycling_time: "",
            swimming_quota: "",
            swimming_time: "",
          });
        }}
        footer={
          <div></div>
          //   loading={isLoading}
        }
      >
        <div className="headingCont">
          <span className="heading">{editData?.id ? "Edit " : "Create"}</span>{" "}
          <span className="orange heading">Multiple Race Calculation</span>
        </div>
        <div className="parentCont">
          <form className="form1" onSubmit={formik.handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={11}>
                <FormLabel>Goal Name<span className="text-[red]">*</span></FormLabel>
  
                <TextField
                  fullWidth
                  placeholder="goal name"
                  size="small"
                  type="text"
                  name="goal_name"
                  value={formik?.values?.goal_name}
                  onChange={formik.handleChange}
                  error={formik.touched.goal_name && formik.errors.goal_name}
                  helperText={formik.touched.goal_name && formik.errors.goal_name}
                />
              </Grid>
              <Grid item xs={12} sm={11}>
                <FormLabel>Running Distance<span className="text-[red]">*</span></FormLabel>
  
                <TextField
                  fullWidth
                  placeholder="Running Distance"
                  size="small"
                  type="number"
                  name="running_quota"
                  value={formik?.values?.running_quota}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.running_quota && formik.errors.running_quota
                  }
                  helperText={
                    formik.touched.running_quota && formik.errors.running_quota
                  }
                />
              </Grid>
              <Grid item xs={12} sm={11}>
                <FormLabel>Running Time<span className="text-[red]">*</span></FormLabel>
  
                <TextField
                  fullWidth
                  placeholder="(mm)"
                  size="small"
                  type="text"
                  name="running_time"
                  value={formik?.values?.running_time}
                  onChange={(e) => {
                    const formattedTime = handleTimeChangeSeconds(e);
                    formik.setFieldValue("running_time", formattedTime);
                  }}
                  error={
                    formik.touched.running_time && formik.errors.running_time
                  }
                  helperText={
                    formik.touched.running_time && formik.errors.running_time
                  }
                />
              </Grid>
  
              <Grid item xs={12} sm={11}>
                <FormLabel>Cycling Distance<span className="text-[red]">*</span></FormLabel>
  
                <TextField
                  fullWidth
                  placeholder="Cycling Distance"
                  size="small"
                  type="number"
                  name="cycling_quota"
                  value={formik?.values?.cycling_quota}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.cycling_quota && formik.errors.cycling_quota
                  }
                  helperText={
                    formik.touched.cycling_quota && formik.errors.cycling_quota
                  }
                />
              </Grid>
              <Grid item xs={12} sm={11}>
                <FormLabel>Cycling Time<span className="text-[red]">*</span></FormLabel>
  
                <TextField
                  fullWidth
                  placeholder="(mm)"
                  size="small"
                  type="number"
                  name="cycling_time"
                  value={formik?.values?.cycling_time}
                  onChange={(e) => {
                    const formattedTime = handleTimeChangeSeconds(e);
                    formik.setFieldValue("cycling_time", formattedTime);
                  }}
                  error={
                    formik.touched.cycling_time && formik.errors.cycling_time
                  }
                  helperText={
                    formik.touched.cycling_time && formik.errors.cycling_time
                  }
                />
              </Grid>
  
              <Grid item xs={12} sm={11}>
                <FormLabel>Swimming Distance<span className="text-[red]">*</span></FormLabel>
  
                <TextField
                  fullWidth
                  placeholder="Swimming Distance"
                  size="small"
                  type="number"
                  name="swimming_quota"
                  value={formik?.values?.swimming_quota}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.swimming_quota && formik.errors.swimming_quota
                  }
                  helperText={
                    formik.touched.swimming_quota && formik.errors.swimming_quota
                  }
                />
              </Grid>
              <Grid item xs={12} sm={11}>
                <FormLabel>Swimming Time<span className="text-[red]">*</span></FormLabel>
  
                <TextField
                  fullWidth
                  placeholder="(mm)"
                  size="small"
                  type="number"
                  name="swimming_time"
                  value={formik?.values?.swimming_time}
                  onChange={(e) => {
                    const formattedTime = handleTimeChangeSeconds(e);
                    formik.setFieldValue("swimming_time", formattedTime);
                  }}
                  error={
                    formik.touched.swimming_time && formik.errors.swimming_time
                  }
                  helperText={
                    formik.touched.swimming_time && formik.errors.swimming_time
                  }
                />
              </Grid>
  
              <Grid item xs={12} sm={6}>
                <Button
                  className="btn"
                  key="submit"
                  type="primary"
                  onClick={() => formik.handleSubmit()}
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          </form>
  
          <div className="slick-container">
            <SlickCarousel />
          </div>
        </div>
      </Modal>
    );
  };
  export default CreateMultipleRace;
  