import React, {  useEffect, useState } from "react";
import {  Modal, Button } from "antd";
import {  FormLabel, Grid } from "@mui/material";
import Swal from "sweetalert2";
import { useFormik } from "formik";
import { assignChallengesToGroup, getAllGroups } from "../../API/api-endpoint";
import SlickCarousel from "../SlickCarousel";
import Select from 'react-select';


const AssignChallengeGroup = ({  isAssignUserModalOpen, isLoading, allChalengesData, modalName, setIsAssignUserModalOpen }) => {
  const [getList, setGetList] = useState()

  const allAtheletes = async () => {
    const response = await getAllGroups()
    setGetList(response?.groups);
  }
  useEffect(() => {
    allAtheletes()

  }, [])


  const formik = useFormik({
    initialValues: { challenges: [] },
    onSubmit: (values, { resetForm }) => {
      handleOnSubmitForm(values, resetForm)
    },
  });
  const handleOnSubmitForm = async () => {
    if (formik.values?.group_id && formik?.values?.challenges?.length > 0) {
      const response = await assignChallengesToGroup(formik.values)

      if (response.status) {
        // setIsLoading(false)
        setIsAssignUserModalOpen(false)
        Swal.fire({
          title: "Success",
          text: response.message,
          icon: "success",
        })
      }

    } else {
      Swal.fire({
        title: "Error",
        text: "Please fill all details",
        icon: "error",
      });
    }
  }
  const handleUserChange = async (event, newValue, name) => {
    formik.setFieldValue(name, newValue)
  };
  const handleGroupChange = async (event, newValue, name) => {
    formik.setFieldValue(name, newValue[0])
  };

  return (
    <Modal
      width={1200}
      open={isAssignUserModalOpen}
      onCancel={() => setIsAssignUserModalOpen(false)}
      footer={
        <div></div>}
    >
      <div className="headingCont">
        <span className="heading">{modalName == "simulation" ? "User " : "Assign "}</span>{" "}
        <span className="orange heading">Challenges to group</span>
      </div>
      <div className="parentCont">
        <form className="form1">
          <Grid container spacing={2}>
          <Grid item xs={12} sm={11}>
          <FormLabel >Select Group<span className="text-[red]">*</span></FormLabel>
        
          {allChalengesData?.allchallenges?.length > 0 &&
             <Select
             styles={{
              menu: (provided, state) => ({
                ...provided,
                scrollbarColor: '#E67E22 transparent',
                scrollbarWidth: 'thin',
                '&::-webkit-scrollbar': {
                  width: '12px',
                },
                '&::-webkit-scrollbar-track': {
                  background: 'transparent',
                },
                '&::-webkit-scrollbar-thumb': {
                  backgroundColor: '#E67E22',
                  borderRadius: '20px',
                  border: '3px solid transparent',
                  backgroundClip: 'content-box',
                },
              }),
            }}
             isMulti
             options={getList?.map(group => ({ label: group?.group_name, value: group?.id }))}
             onChange={(selectedOptions) => handleGroupChange(null, selectedOptions?.map(option => option?.value), 'group_id')}
           />
          }
        </Grid>

            <Grid item xs={12} sm={11}>
              <FormLabel >Select Challenges<span className="text-[red]">*</span></FormLabel>
            
              {allChalengesData?.allchallenges?.length > 0 &&
                 <Select
                 styles={{
                  menu: (provided, state) => ({
                    ...provided,
                    scrollbarColor: '#E67E22 transparent',
                    scrollbarWidth: 'thin',
                    '&::-webkit-scrollbar': {
                      width: '12px',
                    },
                    '&::-webkit-scrollbar-track': {
                      background: 'transparent',
                    },
                    '&::-webkit-scrollbar-thumb': {
                      backgroundColor: '#E67E22',
                      borderRadius: '20px',
                      border: '3px solid transparent',
                      backgroundClip: 'content-box',
                    },
                  }),
                }}
                 isMulti
                 options={allChalengesData?.allchallenges.map(challenge => ({ label: challenge?.challengeName, value: challenge?.id }))}
                 onChange={(selectedOptions) => handleUserChange(null, selectedOptions?.map(option => option?.value), 'challenges')}
               />
              }
            </Grid>
            <Grid item xs={12} sm={5.5} className="spcl">
            </Grid>
            <Grid item xs={12} sm={5.5} className="spcl">
              <Button
                className="btn"
                key="submit"
                type="primary"
                loading={isLoading}
                onClick={() => formik.handleSubmit()}
              >
                Submit
              </Button>
            </Grid>



          </Grid>
        </form>
        <div className="slick-container">
          <SlickCarousel />
        </div>
      </div>
    </Modal>
  )
}
export default AssignChallengeGroup
