import React from 'react'
import Sidebar from './Sidebar'
import { Box } from '@mui/system'
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import { useState } from 'react';
import { useFormik } from 'formik';
import { Button, CardContent, FormControl, FormControlLabel, FormLabel, Grid, InputLabel, MenuItem, OutlinedInput, Radio, RadioGroup, Select, TextField, ToggleButton, ToggleButtonGroup } from '@mui/material';
import moment from 'moment';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ReactQuill from "react-quill";
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import { DisconnectStrava, getAllCities, getAllCountry, getAllState, getDayoptionValues, getPrograms, getStravaURL, getUserBadges, updatePersonlise } from '../../API/api-endpoint';
import { useEffect } from 'react';
import Swal from 'sweetalert2';
import { removeNullValues } from '../../utils/Resubale';
import { showError } from '../Messages';
import { Tooltip } from 'antd';

let daysInWeek = ["2", "3", "4", "5"]

const Profile = ({ fetchGoals, setfetchDaysoptionList, getDaysoptionList, setFormGoalValue, formGoalValue, assignedCocahId, formik, setIsEditProfile, handleOnSubmitForm, isEditProfile, usersDetails, getUsersProfile, setUserDetails }) => {
  // getUsersProfile

  const [CountryList, setCountryList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [isOpenGoal, setIsOpenGoal] = useState(false)
  const [isOpenChangeGoal, setIsOpenChangeGoal] = useState(false)
  const [allBadges, setAllBadges] = useState();

  const [cityList, setCityList] = useState([]);
  const calculateAge = () => {
    const birthDate = moment(formik?.values?.dateofbirth, 'YYYY-MM-DD');
    const currentDate = moment();
    const age = currentDate.diff(birthDate, 'years');
    return age;
  };
  console.log("formik", fetchGoals, formik?.values, formik?.errors);
  const [stravaURL, setstravaURL] = useState()
  const userId = localStorage.getItem('userId')

  const handleCountryChange = async (event) => {
    const countryId = event.target.value;
    formik.setFieldValue("country", countryId)

    // Fetch states based on the selected country ID
    fetchState(countryId)
  };
  const fetchState = async (countryId) => {
    const response = await getAllState(countryId);
    setStateList(response);
  }
  const handleStateChange = async (event) => {
    const stateID = event.target.value;
    formik.setFieldValue("state", stateID)

    fetchCity(formik, stateID)
    // Fetch states based on the selected country ID

  };

  const fetchCity = async (formik, stateID) => {
    const response = await getAllCities(formik?.values?.country, stateID);
    setCityList(response);
  }



  const getCountry = async () => {
    let response = await getAllCountry();
    setCountryList(response);
  };

  const getURLByLibraryId = async () => {
    // console.log("result",userId);

    if (assignedCocahId !== null) {
      let result = await getStravaURL(assignedCocahId);
      setstravaURL(result)
      // setWorkouts(result);

    }

  };
  useEffect(() => {
    if (assignedCocahId) {
      getBadgeOfUser(assignedCocahId)
      getURLByLibraryId();
    }
    if (formGoalValue?.daysinweek) {
      fetchDaysOptuon(formGoalValue?.daysinweek);
    }
    getCountry();
    if (formik?.values?.country) {
      fetchState(formik?.values?.country)

    }
    if (formik?.values?.state) {
      fetchCity(formik, formik.values.state)

    }
  }, [formGoalValue?.daysinweek, assignedCocahId, formik?.values?.country, formik?.values?.state]);
  const fetchDaysOptuon = async (week, activity) => {
    const response = await getDayoptionValues(
      week ? week : formGoalValue?.daysinweek,
      activity ? activity : formGoalValue?.activities
    );
    console.log("response", response);
    if (response.length > 0) {
      setfetchDaysoptionList(response);
    } else {
      setFormGoalValue({ ...formGoalValue, daysoption: "" })
    }
  };
  const handleDisconnect = async () => {
    const disconnectResponse = await DisconnectStrava(assignedCocahId)
    if (disconnectResponse?.status) {
      Swal.fire({
        title: "Sucess",
        text: disconnectResponse.message,
        icon: "success",
      });
      let coachProgramID = localStorage.getItem("programID");

      const actProgram = await getPrograms(assignedCocahId);
      let filter = actProgram?.filter((data) => data.active == 1)
      let coachID = filter[0]?.program_id ? filter[0]?.program_id : coachProgramID;
      console.log("actProgram", coachID, coachProgramID);
      if (coachID) {
        const response = await getUsersProfile(coachID, assignedCocahId)
        let data = { ...response?.existingUserContacts, ...response?.personalization_profile, ...response?.user }
        let destructer = removeNullValues(data)
        formik.setValues(destructer)
        setUserDetails(response)
      }
    }
  }
  const handleConnect = () => {
    setTimeout(async () => {
      let coachProgramID = localStorage.getItem("programID");

      const actProgram = await getPrograms(assignedCocahId);
      let filter = actProgram?.filter((data) => data.active == 1)
      let coachID = filter[0]?.program_id ? filter[0]?.program_id : coachProgramID;
      console.log("actProgram", coachID, coachProgramID);
      if (coachID) {
        const response = await getUsersProfile(coachID, assignedCocahId)
        let data = { ...response?.existingUserContacts, ...response?.personalization_profile, ...response?.user }
        formik.setValues(data)
        setUserDetails(response)
      }
    }, 3000);
  }
  const updateGoals = async () => {
    if (formGoalValue?.goal && formGoalValue?.daysinweek && formGoalValue?.daysoption) {
      let response = await updatePersonlise(formGoalValue, assignedCocahId); //its valdiate terms and conditions
      if (response?.status) {
        Swal.fire({
          title: "Success",
          text: response?.message,
          icon: "success",
        });
        setIsOpenGoal(false)
        setIsOpenChangeGoal(false)
        formik.setFieldValue("daysoption", formGoalValue.daysoption)
        formik.setFieldValue("daysinweek", formGoalValue.daysinweek)
        setFormGoalValue({
          ...formGoalValue, daysoption: "",
        })

      }
      // setIsOpenGoal
    } else {
      showError("Please fill all details")
    }
  }
  const handleChangeGoal = (event, newAlignment) => {
    setFormGoalValue({
      ...formGoalValue,
      daysinweek: event.target.value,
      goal: formik?.values?.goal,

    })

  };
  const control = {
    value: formGoalValue?.daysinweek,
    onChange: handleChangeGoal,
    exclusive: true,
  };
  const today = new Date();
  const twelveYearsAgo = new Date(today.getFullYear() - 14, today.getMonth(), today.getDate());

  const twelveYearsAgoString = twelveYearsAgo.toISOString().split("T")[0];
  const getBadgeOfUser = async (id) => {
    // console.log("result",userId);
    let result = await getUserBadges(id);
    setAllBadges(result)
    console.log("result", result);



  };
  return (
    <div className='flex bg-[#f1f1f1] text-[18px]'>

      <div className='w-[30%]'>
        <Sidebar handleOnSubmitForm={handleOnSubmitForm} usersDetails={usersDetails} formik={formik} setIsEditProfile={setIsEditProfile} assignedCocahId={assignedCocahId} isEditProfile={isEditProfile} />
      </div>
      <div className='w-[60%] justify-center items-center ' style={{
        display: "flex",
        flexDirection: "column",
        gap: "20px",
        paddingBottom: "20px",

      }}>
        <Box className=" w-[90%] h-[36%] bg-[#fffdfd] rounded-xl shadow-lg shadow-#fffdfd-500 p-4 mt-4">
          <b>{formik?.values?.firstname} {formik?.values?.lastname}</b>
          <p>{formik?.values?.gender}</p>
          <p><strong>{`${calculateAge()} Years`}</strong> Date Of Birth <strong>{moment(formik?.values?.dateofbirth).format("DD-MM-YYYY")}</strong></p>


        </Box>
        {!isEditProfile &&

          <Box className=" w-[90%] h-[25%] bg-[#fffdfd] rounded-xl shadow-lg shadow-#fffdfd-500 p-4">
            <b>Address</b>
            <p>{formik?.values?.address1 ? formik?.values?.address1 : "NA"}</p>
          </Box>
        }

        {!isEditProfile && formik?.values?.daysoption ?

          <Box className=" w-[90%]  bg-[#fffdfd] rounded-xl shadow-lg shadow-#fffdfd-500 p-4">
            <b>Schedule Details</b><br />
            <p><b>Program Name </b>  {formik?.values?.activity}</p>
            {formik?.values?.goal &&
              <p><b>Goal</b>  {formik?.values?.goal}</p>
            }
            <p><b>Days in week</b>  {formGoalValue?.daysinweek}</p>

            {isOpenGoal &&
              <>
                <ToggleButtonGroup size="small" value={formGoalValue?.daysinweek} {...control} aria-label="Small sizes">
                  {fetchGoals?.availibledaysoption?.map((ele) => {
                    return (
                      <ToggleButton
                        style={{
                          backgroundColor: ele.rundays == formGoalValue.daysinweek ? '#E67E22' : 'inherit',
                          color: ele.rundays == formGoalValue.daysinweek ? 'white' : 'inherit',
                          width: "50px"
                        }}
                        value={ele?.rundays} key={ele?.rundays}>
                        {ele?.rundays}
                      </ToggleButton>
                    )
                  })}
                </ToggleButtonGroup>
              </>
            }
            {isOpenGoal ? (
              <div >
                {getDaysoptionList?.length > 0 ?
                  <div className={getDaysoptionList?.length > 0 && "flex w-[100%] "} style={{ scrollbarColor: "#E67E22 lightGray ", scrollbarWidth: "thin", overflowX: "scroll" }}>
                    {getDaysoptionList?.map((ele) => {
                      return (
                        <div
                          style={
                            {
                              minWidth: "200px",
                              border: "1px solid gray",
                              margin: "10px",
                              borderRadius: "7px",
                              padding: "10px 0px 10px 10px",
                              boxShadow:
                                "rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px",
                            }
                          }
                        >
                          <div>
                            <div className="flex justify-between items-center">
                              <div>Monday </div>
                              {isOpenGoal &&
                                <RadioGroup
                                  aria-labelledby="demo-radio-buttons-group-label"
                                  defaultValue="female"
                                  name="radio-buttons-group"
                                  value={formGoalValue.daysoption}

                                  onChange={(e) =>

                                    setFormGoalValue({
                                      ...formGoalValue,
                                      daysoption: e.target.value,
                                    })
                                  }
                                >
                                  <FormControlLabel
                                    value={ele?.options}
                                    control={<Radio />}
                                  />
                                </RadioGroup>
                              }

                            </div>
                            <div>{ele?.mondayworkout}</div>
                          </div>
                          &nbsp;
                          <div className=" mr-4">
                            <div className='mr-2'>Tuesday </div>
                            <div>{ele?.tuesdayworkout}</div>
                          </div>
                          &nbsp;
                          <div className=" mr-4">
                            <div className='mr-2'>Wednesday </div>
                            <div>{ele?.wednesdayworkout}</div>
                          </div>
                          &nbsp;
                          <div className=" mr-4">
                            <div className='mr-2'>Thursday </div>
                            <div>{ele?.thursdayworkout}</div>
                          </div>
                          &nbsp;
                          <div className=" mr-4">
                            <div className='mr-2'>Friday </div>
                            <div>{ele?.fridayworkout}</div>
                          </div>
                          &nbsp;
                          <div className=" mr-4">
                            <div className='mr-2'>Saturday </div>
                            <div>{ele?.saturdayworkout}</div>
                          </div>
                          &nbsp;
                          <div className=" mr-4">
                            <div className='mr-2'>Sunday </div>
                            <div>{ele?.sundayworkout}</div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  : <div className='p-4 text-lg font-semibold'>No Options Found</div>}
              </div>
            ) : (
              <div className={getDaysoptionList?.length > 0 && "flex w-[100%] overflow-scroll"}>
                {getDaysoptionList?.filter((ele) => {
                  return ele.options == formik?.values?.daysoption;
                }).map((ele) => {
                  return (
                    <div
                      style={
                        {
                          width: "100%",
                          border: "1px solid gray",
                          margin: "10px",
                          borderRadius: "7px",
                          padding: "10px 0px 10px 10px",
                          boxShadow:
                            "rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px",
                        }
                      }
                    >
                      <div className="flex items-center">
                        <div className="flex justify-between items-center mr-2">
                          <div>Monday -</div>
                          {isOpenGoal &&
                            <RadioGroup
                              aria-labelledby="demo-radio-buttons-group-label"
                              defaultValue="female"
                              name="radio-buttons-group"
                              value={formGoalValue.daysoption}

                              onChange={(e) =>

                                setFormGoalValue({
                                  ...formGoalValue,
                                  daysoption: e.target.value,

                                })
                              }
                            >
                              <FormControlLabel
                                value={ele?.options}
                                control={<Radio />}
                              />
                            </RadioGroup>
                          }

                        </div>
                        <div>{ele?.mondayworkout}</div>
                      </div>
                      &nbsp;
                      <div className=" mr-4 flex items-center">
                        <div className='mr-2'>Tuesday -</div>
                        <div>{ele?.tuesdayworkout}</div>
                      </div>
                      &nbsp;
                      <div className=" mr-4 flex items-center">
                        <div className='mr-2'>Wednesday -</div>
                        <div>{ele?.wednesdayworkout}</div>
                      </div>
                      &nbsp;
                      <div className=" mr-4 flex items-center">
                        <div className='mr-2'>Thursday -</div>
                        <div>{ele?.thursdayworkout}</div>
                      </div>
                      &nbsp;
                      <div className=" mr-4 flex items-center">
                        <div className='mr-2'>Friday -</div>
                        <div>{ele?.fridayworkout}</div>
                      </div>
                      &nbsp;
                      <div className=" mr-4 flex items-center">
                        <div className='mr-2'>Saturday -</div>
                        <div>{ele?.saturdayworkout}</div>
                      </div>
                      &nbsp;
                      <div className=" mr-4 flex items-center">
                        <div className='mr-2'>Sunday -</div>
                        <div>{ele?.sundayworkout}</div>
                      </div>
                    </div>
                  );
                })}
              </div>
            )}



            <div className='mt-2' style={{ display: "flex", justifyContent: "end" }}>
              {isOpenGoal ?
                <Button style={{ color: "white" }} variant='contained' disabled={!formGoalValue?.daysoption} onClick={updateGoals}>Submit</Button>
                :
                <Button style={{ color: "white" }} variant='contained' onClick={() => setIsOpenGoal(true)}>Change Schedule</Button>
              }

            </div>
          </Box> :
          <Box className=" w-[90%] min-h-[40%]  bg-[transparent]  p-4"></Box>
        }

        {isEditProfile &&

          <Box className=" w-[90%]  bg-[#fffdfd] rounded-xl shadow-lg shadow-#fffdfd-500 p-4">
            <b>Personal Information</b>
            <CardContent>
              <Grid container>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={4}>
                    <label variant="h6">First Name</label>
                    <TextField
                      fullWidth
                      id="filled-basic" variant="filled"
                      placeholder="First Name"
                      error={formik.errors?.firstname && formik.errors?.firstname}
                      helperText={
                        formik.errors.firstname && formik.errors.firstname
                      }
                      type="text"
                      name="firstname"
                      value={formik?.values?.firstname}
                      onChange={formik.handleChange}
                      onInput={(e) => {
                        e.target.value = e.target.value.replace(/[^a-zA-Z]/g, ''); // Restrict input to alphabetic characters
                        if (e.target.value.length > 20) {
                          e.target.value = e.target.value.slice(0, 20); // Trim the input to 20 characters
                        }
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <label variant="h6">Last Name</label>
                    <TextField
                      fullWidth
                      id="filled-basic" variant="filled"
                      placeholder="Workout Title"
                      error={formik.errors?.lastname && formik.errors?.lastname}
                      helperText={
                        formik.errors.lastname && formik.errors.lastname
                      }
                      type="text"
                      name="lastname"
                      value={formik?.values?.lastname}
                      onChange={formik.handleChange}
                      onInput={(e) => {
                        e.target.value = e.target.value.replace(/[^a-zA-Z]/g, ''); // Restrict input to alphabetic characters
                        if (e.target.value.length > 20) {
                          e.target.value = e.target.value.slice(0, 20); // Trim the input to 20 characters
                        }
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <label variant="h6">DOB</label>
                    <TextField
                      fullWidth
                      id="filled-basic" variant="filled"
                      placeholder="Workout Title"
                      type="date"
                      error={formik.errors?.dateofbirth && formik.errors?.dateofbirth}
                      helperText={
                        formik.errors.dateofbirth && formik.errors.dateofbirth
                      }
                      name="dateofbirth"
                      inputProps={{
                        max: twelveYearsAgoString, placeholder: "DD-MM-YYYY*" // Set max attribute to today's date
                      }}
                      value={formik?.values?.dateofbirth}
                      onChange={formik.handleChange}
                    />
                  </Grid>

                  <Grid item xs={12} sm={4}>
                    <label variant="h6">Email</label>
                    <TextField
                      fullWidth
                      id="filled-basic" variant="filled"
                      placeholder="Workout Title"
                      type="text"
                      disabled
                      name="email"
                      value={formik?.values?.email}
                      onChange={formik.handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} >
                    <label variant="h6">Qualification</label>
                    <TextField
                      fullWidth
                      id="filled-basic" variant="filled"
                      placeholder="Workout Title"
                      type="text"
                      name="qualification"
                      value={formik?.values?.qualification}
                      onChange={formik.handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} >
                    <label variant="h6">Gender</label>
                    <Select
                      fullWidth
                      id="filled-basic" variant="filled"
                      // style={{color:"white"}}
                      placeholder="123"
                      name="gender"
                      value={formik.values.gender ? formik.values.gender : ""}
                      onChange={formik.handleChange}
                      // id="form-layouts-separator-select"
                      labelId="form-layouts-separator-select-label"
                      input={
                        <OutlinedInput id="select-multiple-language" />
                      }
                    >
                      <MenuItem value={"male"}>Male</MenuItem>
                      <MenuItem value={"female"}>Female</MenuItem>

                    </Select>
                  </Grid>

                  <Grid item xs={12} sm={4} >
                    <label variant="h6">Occupation</label>
                    <TextField
                      fullWidth
                      id="filled-basic" variant="filled"
                      placeholder="Occupation"
                      type="text"
                      name="occupation"
                      value={formik?.values?.occupation}
                      onChange={formik.handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} >
                    <label variant="h6">Organization</label>
                    <TextField
                      fullWidth
                      id="filled-basic" variant="filled"
                      placeholder="Organization"
                      type="text"
                      name="organization"
                      value={formik?.values?.organization}
                      onChange={formik.handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} >
                  </Grid>
                  <Grid item xs={12} sm={12} >
                    <b>Address Information</b>
                  </Grid>


                  <Grid item xs={12} sm={4} >
                    <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                      <InputLabel id="demo-simple-select-standard-label">Country</InputLabel>
                      <Select
                        labelId="demo-simple-select-standard-label"
                        id="demo-simple-select-standard"
                        value={formik?.values?.country}
                        onChange={handleCountryChange}
                        label="Age"
                      >
                        {CountryList?.map((option) => (
                          <MenuItem key={option.id} value={option?.id}>
                            {option?.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={4} >
                    <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                      <InputLabel id="demo-simple-select-standard-label">State</InputLabel>
                      <Select
                        labelId="demo-simple-select-standard-label"
                        id="demo-simple-select-standard"
                        value={formik?.values?.state}
                        onChange={handleStateChange}
                        label="Age"
                      >
                        {stateList?.map((option) => (
                          <MenuItem key={option.id} value={option?.id}>
                            {option?.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={4} >
                    <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                      <InputLabel id="demo-simple-select-standard-label">City</InputLabel>
                      <Select
                        labelId="demo-simple-select-standard-label"
                        id="demo-simple-select-standard"
                        value={formik?.values?.city}
                        onChange={(e) => formik.setFieldValue("city", e.target.value)}
                      >
                        {cityList?.map((option) => (
                          <MenuItem key={option.id} value={option?.id}>
                            {option?.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={4} >
                    <label variant="h6">Address</label>
                    <TextField
                      fullWidth
                      id="filled-basic" variant="filled"
                      placeholder="Address"
                      type="text"
                      name="address1"
                      value={formik?.values?.address1}
                      onChange={formik.handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} >
                    <label variant="h6">Address2</label>
                    <TextField
                      fullWidth
                      id="filled-basic" variant="filled"
                      placeholder="address2"
                      type="text"
                      name="address2"
                      value={formik?.values?.address2}
                      onChange={formik.handleChange}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
          </Box>
        }
        {/*
             {isEditProfile &&
                    <Box className=" w-[90%] h-[40vh]  bg-[#fffdfd] rounded-xl shadow-lg shadow-#fffdfd-500 p-4">
                        <b className='mb-2'>Achievements</b>
                        <ReactQuill style={{ height: "50%" }}
                            value={formik?.values?.workout_description}
                            name="workout_description"
                            onChange={(value) => formik.setFieldValue("workout_description", value)}
                            modules={{
                                toolbar: [
                                    [{ header: "1" }, { header: "2" }, { font: [] }],
                                    [{ size: [] }],
                                    ["bold", "italic", "underline", "strike", "blockquote"],
                                    [
                                        { list: "ordered" },
                                        { list: "bullet" },
                                        { indent: "-1" },
                                        { indent: "+1" },
                                    ],
                                    ["link", "image", "video"],
                                    ["clean"],
                                ],
                            }}
                        />


                    </Box>
                }

            */}

      </div>
      <div className=' w-[40%] p-4'>
        <Box className=" w-[100%] min-h-[40%] bg-[#fffdfd] rounded-xl shadow-lg shadow-#fffdfd-500 p-4">
          <div style={{ display: "flex" }}>
            <div style={{ width: "80%" }}>
              <b>Contact</b>
              <p><PhoneIcon className='text-[blue] mb-2 mt-2  ' style={{ color: "#E67E22" }} />&nbsp; <strong>

                {isEditProfile ? (
                  <TextField
                    id="filled-basic" variant="filled"
                    placeholder="Contact"
                    type="text"
                    name="mobile"
                    value={formik?.values?.mobile}
                    error={formik.errors.mobile?.incorrectLength || formik.errors.mobile?.invalidFormat}
                    helperText={
                      formik.errors.mobile?.incorrectLength ? 'Mobile Number Should Be Of 10 Digits' :
                        formik.errors.mobile?.invalidFormat ? 'Invalid format' :
                          undefined
                    }
                    onChange={(event) => {
                      const { value } = event.target;
                      const isValid = /^[0-9]*$/.test(value) && value.length <= 10;
                      if (isValid) {
                        formik.handleChange(event);
                      }
                    }}
                  />
                ) : (
                  <>{formik?.values?.mobile ? formik?.values?.mobile : "NA"}</>
                )}
              </strong></p> &nbsp;


              <p><PhoneIcon className='text-[blue] mb-2 mt-2' style={{ color: "#E67E22" }} />&nbsp;<strong>

                {isEditProfile ? (
                  <TextField
                    id="filled-basic" variant="filled"
                    placeholder="Emergency Contact"
                    type="text"
                    name="econtact"
                    value={formik?.values?.econtact}
                    error={formik.errors.econtact?.incorrectLength || formik.errors.econtact?.invalidFormat}
                    helperText={
                      formik.errors.econtact?.incorrectLength ? 'Emergency Contact Should Be Of 10 Digits' :
                        formik.errors.econtact?.invalidFormat ? 'Invalid format' :
                          undefined
                    }
                    onChange={(event) => {
                      const { value } = event.target;
                      const isValid = /^[0-9]*$/.test(value) && value.length <= 10;
                      if (isValid) {
                        formik.handleChange(event);
                      }
                    }}
                  />
                ) : (
                  <>  E-Contact {formik?.values?.econtact ? formik?.values?.econtact : "NA"}</>
                )}
              </strong></p>
              &nbsp;
              {isEditProfile ? (
                <p><EmailIcon className='text-[blue]' style={{ color: "#E67E22" }} /> &nbsp;<strong>
                  <TextField
                    id="filled-basic" variant="filled"
                    placeholder="Contact"
                    type="text"
                    name="email"
                    disabled
                    value={formik?.values?.email}
                    onChange={formik.handleChange}
                  />
                </strong></p>

              ) : (

                <p style={{width:"90%", wordBreak:"break-all"}}><EmailIcon className='text-[blue]' style={{ color: "#E67E22" }} /> &nbsp;<strong>{formik?.values?.email}</strong></p>
              )}
              &nbsp;
              {isEditProfile ? (
                <p className='flex rounded-md'>

                  <img
                    src="https://play-lh.googleusercontent.com/j-ZV144PlVuTVsLuBzIKyEw9CbFnmWw9ku2NJ1ef0gZJh-iiIN1nrNPmAtvgAteyDqU"
                    alt="..."
                    className="w-6 h-6  border-2 cursor-pointer"
                  />
                  &nbsp;<strong>

                    <TextField
                      id="filled-basic" variant="filled"
                      placeholder="Strava Name"
                      type="text"
                      name="strava"
                      value={formik?.values?.strava}
                      onChange={formik.handleChange}
                    />
                  </strong></p>
              ) : (
                <p className='flex rounded-md'>

                  <img
                    src="https://play-lh.googleusercontent.com/j-ZV144PlVuTVsLuBzIKyEw9CbFnmWw9ku2NJ1ef0gZJh-iiIN1nrNPmAtvgAteyDqU"
                    alt="..."
                    className="w-6 h-6  border-2 cursor-pointer"
                  />
                  &nbsp;<strong>
                    Strava Name: {formik?.values?.strava_username ? formik?.values?.strava_username : "NA"}
                  </strong></p>

              )}
              &nbsp;
              <p><InstagramIcon className='text-[#E4405F] mb-2 mt-2  ' />&nbsp; <strong>

                {isEditProfile ? (
                  <TextField
                    id="filled-basic" variant="filled"
                    placeholder="Insta"
                    type="text"
                    name="insta"
                    value={formik?.values?.insta}
                    onChange={formik.handleChange}
                  />
                ) : (
                  <>{usersDetails?.usercontact?.insta ? usersDetails?.usercontact?.insta : "NA"}</>
                )}
              </strong></p>
              &nbsp;
              <p><FacebookIcon className='text-[blue] mb-2 mt-2  ' />&nbsp; <strong>

                {isEditProfile ? (
                  <TextField
                    id="filled-basic" variant="filled"
                    placeholder="Facebook"
                    type="text"
                    name="facebook"
                    value={formik?.values?.facebook}
                    onChange={formik.handleChange}
                  />
                ) : (
                  <>{usersDetails?.usercontact?.facebook ? usersDetails?.usercontact?.facebook : "NA"}</>
                )}
              </strong></p>
            </div>
            <div style={{ border: "2px solid gray", width: "55%", height: "250px" }}>
              <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "30px", background: "gray" }}>

                <p style={{ fontSize: "14px", fontWeight: 600 }}> Badges won</p>
              </div>
              {allBadges?.length > 0 ? (
                <div style={{ padding: "10px", display: "flex" }}>
                  {allBadges?.map((bedges) => {
                    return (
                      <Tooltip placement="bottom" title={bedges?.challenge}>

                        <img
                          src={bedges?.badge ? `${URL}/static/public/userimages/${bedges?.badge}` : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTbQIfgvrUcDEY90ZrIVSMPYX4hvjFe963pHSxaVK3fuw&s"}
                          alt="..."
                          className="w-8 h-8  border-2 rounded-full"
                        />
                      </Tooltip>
                    )
                  })}

                </div>
              ) : (
                <div style={{ padding: "10px 4px 0px 10px" }}>
                  Your earned badges would come here
                </div>
              )}

            </div>
          </div>
        </Box>
        {!isEditProfile &&

          <Box className=" w-[100%] h-[10%] bg-[#fffdfd] rounded-xl shadow-lg shadow-#fffdfd-500 p-4 mt-4">
            <b>Emergency Contact</b>
            <p>{formik?.values?.contact ? formik?.values?.contact : "NA"}</p>

          </Box>
        }
        <Box className=" w-[100%] h-[30%] bg-[#fffdfd] rounded-xl shadow-lg shadow-#fffdfd-500 p-4 mt-4">
          <div> <b>Connect <InfoOutlinedIcon /> </b></div>
          <p>Strava&nbsp;</p>
          <p><strong>Status:</strong> &nbsp;<strong>{formik?.values?.strava_id ? "Connected" : "Disconnected"}</strong></p>

          <Button variant='contained'>
            {formik?.values?.strava_id ? <a onClick={() => handleDisconnect()}>Disconnect</a> :

              <a href={stravaURL?.url} target='_blank' onClick={() => handleConnect()}>Connect with strava</a>
            }
          </Button>
        </Box>
      </div>
    </div>
  )
}

export default Profile
