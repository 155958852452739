import React, { useEffect, useRef, useState } from 'react';
import Header from '../../components/Header';
import {  LinearProgress } from '@mui/material';
import { fetchSavedAssesmnetGetDataCoach, NotifiAtheletes, URL } from '../../API/api-endpoint';
import Rating from '../../components/Rating';
import { Button } from 'antd';
import SelfAssesment from './SelfAssesment';
import Options from './Options';
import { useLocation, useNavigate } from 'react-router-dom';
import html2pdf from 'html2pdf.js';
import { showError, showSuccess } from '../../components/Messages';
import { capitalizeFirstLetter } from '../../utils/Resubale';
const SavedReview = () => {
    const navigate = useNavigate()
    const location = useLocation();
    const [savedData, setSavedData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [showAssesmentModal, setShowAssesmentModal] = useState(false);
    const [AssementOption, setAssementOption] = useState(false);
    const [idd, setId] = useState();
    const [isShowing, setisShowing] = useState(false);

    const pdfRef = useRef();

    const [editFormData, setEditFormData] = useState({});

    const currentData = savedData || {};
    const { user, review, activity, totalscore, userformreviewimages,  id, options, athelete, videourl,reviewed_by } = currentData;

    const fetchAssesmentData = async (id) => {
        setIsLoading(true);
        const response = await fetchSavedAssesmnetGetDataCoach();
        let filterdata = response?.newModified?.filter((ele) => ele?.id == id)

        setIsLoading(false);
        setSavedData(filterdata[0]);
    };


    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const idParam = params.get('id');

        // Fetch data using the id
        setId(idParam)
        if (idParam) {
            fetchAssesmentData(idParam);
        }
    }, [location]);



    const getCoachCommentColor = (rating) => {
        let color;
        if (rating >= 1 && rating < 2) {
            color = 'red';
        } else if (rating >= 2 && rating < 4) {
            color = 'orange';
        } else if (rating >= 4 && rating <= 5) {
            color = 'green';
        } else {
            color = 'black'; // default color if rating is out of range
        }
        return color;
    };
    const downloadPDF = () => {
        setisShowing(true);
    
        // Get all images within the PDF content
        const images = pdfRef.current.querySelectorAll("img");
        const imageLoadPromises = [];
    
        // Ensure all images are loaded before generating the PDF
        images.forEach((img) => {
            if (!img.complete) {
                const loadPromise = new Promise((resolve, reject) => {
                    img.onload = resolve; // resolve when the image loads
                    img.onerror = reject; // reject if there is an error
                });
                imageLoadPromises.push(loadPromise);
            }
        });
    
        // Wait for all images to load
        Promise.all(imageLoadPromises).then(() => {
            const element = pdfRef.current;
            const options = {
                margin: 0,
                filename: 'review.pdf',
                image: { type: 'jpeg', quality: 0.98 },
                html2canvas: {
                    scale: 2,
                    useCORS: true,  // This allows cross-origin images to be used if CORS headers are correctly set
                },
                jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' }
            };
    
            html2pdf().from(element).set(options).save();
            setisShowing(false);
        }).catch((error) => {
            console.error("Error loading images: ", error);
            setisShowing(false);
        });
    };
    
    

    const NotifyFormREview = async (id) => {
        const response = await NotifiAtheletes(id)
        if (response?.status) {
            const message = capitalizeFirstLetter(response.message);
            showSuccess(message);
        }else{
            showError(response?.message)
        }

    }
    return (
        <div>
            <Header />
            <div className="grid grid-cols-1 xl:grid-cols-5 items-start gap-x-4" style={{ marginTop: "100px" }}></div>
            <div className='p-4 m-[2% 6% 2% 6%]'>
                <div className='flex justify-between w-[90%]'>
                    <h2 className='text-3xl' style={{ marginLeft: "6%", color: "#E67E22" }}>
                        <strong>Form Review Requests</strong>
                    </h2>
                    <h2 className='text-3xl' style={{ marginLeft: "6%", color: "#E67E22" }}>
                        <Button type="primary" onClick={() => navigate(`/formreview`)}>Back</Button>
                        &nbsp;&nbsp;
                        <Button type="primary" onClick={downloadPDF}>Download PDF</Button>
                    </h2>
                </div>
                {isLoading ? (
                    <LinearProgress />
                ) : (
                    <div ref={pdfRef} >
                        <div style={{ width: "80%", margin: "30px auto", padding: "55px", boxShadow: "rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset" }}>
                            <div><b style={{ fontSize: "20px", padding: "10px 0px 0px 0px" }}>{activity?.activity_name} Feedback</b></div>
                            <div style={{ width: "40%", margin: "0 auto" }}>
                            </div>

                            <div style={{ margin: "1rem 0px 0px 0px" }}>
                                <div className="flex">
                                    <div style={{ "width": "35%", height: "40px", "border": "1px solid gray", fontWeight: "700", padding: "10px" }}>
                                        Name
                                    </div >
                                    <div style={{ "width": "65%", height: "40px", "border": "1px solid gray", "borderLeft": "none", fontWeight: "700", padding: "10px", }}>
                                        {athelete?.firstname} {athelete?.lastname}
                                    </div>
                                </div>
                                <div className="flex">
                                    <div style={{ "width": "35%", height: "40px", "border": "1px solid gray", fontWeight: "700", padding: "10px" }}>
                                        Contact Details
                                    </div >
                                    <div style={{ "width": "65%", height: "40px", "border": "1px solid gray", "borderLeft": "none", fontWeight: "700", padding: "10px" }}>
                                        {athelete?.email}
                                    </div>
                                    {/* <div style={{ "width": "100%", padding: "2px", "border": "1px solid gray", "borderLeft": "none", fontWeight: "700",padding:"0px 0px 0px 10px" }}>
                {user?.email}
              </div> */}
                                </div>

                            </div>

                            <div style={{ margin: "1rem 0px 0px 0px" }}>
                                <div className="flex">
                                    <div style={{ "width": "35%", "border": "1px solid gray", fontWeight: "700", padding: "10px" }}>
                                        Review
                                    </div >
                                    <div style={{ "width": "65%", "border": "1px solid gray", "borderLeft": "none", fontWeight: "700", padding: "10px" }}>
                                        {review}
                                    </div>
                                </div>

                                <div className="flex">
                                    <div style={{ "width": "35%", height: "50px", "border": "1px solid gray", fontWeight: "700", padding: "10px" }}>
                                        Overall rating
                                    </div >
                                    <div style={{ "width": "65%", height: "50px", "border": "1px solid gray", "borderLeft": "none", fontWeight: "700", padding: "10px" }}>
                                        <Rating rating={parseInt(totalscore / options?.length <= 0.9 ? 1 : totalscore / options?.length)} size={"20px"} />
                                    </div>

                                </div>


                            </div>
                            {options?.length > 0 &&

                                <div style={{ margin: "1rem 0px 0px 0px" }}>
                                    <div className="flex">
                                        <div style={{ "width": "35%", height: "40px", "border": "1px solid gray", fontWeight: "700", padding: "10px" }}>
                                            Stroke Review
                                        </div >
                                        <div style={{ "width": "65%", height: "40px", "border": "1px solid gray", "borderLeft": "none", fontWeight: "700", padding: "10px" }}>
                                            Stroke Review
                                            Rating                                </div>
                                        <div style={{ "width": "65%", height: "40px", "border": "1px solid gray", "borderLeft": "none", fontWeight: "700", padding: "10px" }}>
                                            Stroke Review Comments   </div>

                                    </div>
                                    <div className='assement-scroll'>
                                        {options?.map((section) => {
                                            const color = getCoachCommentColor(section?.weightage);
                                            return (
                                                <div className="flex" >
                                                    <div style={{ "width": "35%", "border": "1px solid gray", fontWeight: "700", padding: "10px" }}>
                                                        {section?.coachSection}
                                                    </div >
                                                    <div style={{ "width": "65%", "border": "1px solid gray", "borderLeft": "none", fontWeight: "700", padding: "10px" }}>
                                                        <Rating rating={section?.weightage} size={"20px"} />
                                                    </div>
                                                    <div style={{ "width": "65%", "border": "1px solid gray", "borderLeft": "none", fontWeight: "700", padding: "10px", color: `${color}` }}>
                                                        {section?.coachcomment}

                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>

                                </div>
                            }
                            <div style={isShowing?{display:"none"}:{ display: "flex", justifyContent: "center", width: "100%", padding: "20px 0px 0px 0px" }}>
                                <Button type="primary" onClick={() => {
                                    setShowAssesmentModal(true);
                                    setAssementOption(false);

                                    setEditFormData(currentData);
                                }}>Edit</Button> &nbsp;&nbsp;&nbsp;
                                <Button type="primary" onClick={() => {
                                    setAssementOption(true);
                                    setShowAssesmentModal(false);

                                    setEditFormData(currentData);
                                }}>FeedBack</Button>
                                &nbsp;&nbsp;&nbsp;
                                {reviewed_by &&
                                <Button type="primary" onClick={() =>NotifyFormREview(id)}>Notify Athletes</Button>
                                }

                            </div>
                            <div style={{ margin: "1rem 0px 0px 0px" }}>
                                <div className="flex">
                                    <div style={{ "width": "35%", padding: "2px", "border": "1px solid gray", fontWeight: "700", padding: "10px" }}>
                                        Video URL
                                    </div >
                                    <div style={{ "width": "65%", padding: "2px", "border": "1px solid gray", "borderLeft": "none", fontWeight: "700", padding: "10px" }}>
                                        <a href={videourl} target="_blank" style={{ textDecoration: "underline", color: "blue",wordBreak:"break-all" }}>{videourl}</a>
                                    </div>
                                </div>
                                <div className="flex" >
                                    <div style={{ "width": "35%", padding: "2px", "border": "1px solid gray", fontWeight: "700", padding: "10px" }}>
                                        Images
                                    </div >
                                    <div style={{ "width": "65%", padding: "2px", "border": "1px solid gray", "borderLeft": "none", fontWeight: "700", padding: "10px",display:"grid", gridTemplateColumns:" 1fr",gap:"10px" }}>
                                        {userformreviewimages?.map((img) => {
                                            return (
                                                <div style={{pageBreakInside:"avoid"}}>
                                                    <img src={`${URL}/static/public/userimages/${img?.image}`} alt={`Image of ${img?.image}`} style={{height:"100%",width:"100%"}} />

                                                   
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>


                            </div>

                        </div>
                    </div>

                )}
              
            </div>
            
            {showAssesmentModal &&
                <SelfAssesment
                    idd={idd}
                    fetchAssesmentData={fetchAssesmentData}
                    editFormData={editFormData}
                    setEditFormData={setEditFormData}
                    showAssesmentModal={showAssesmentModal}
                    setShowAssesmentModal={setShowAssesmentModal}
                />

            }
            {AssementOption &&
                <Options
                    idd={idd}
                    fetchAssesmentData={fetchAssesmentData}
                    editFormData={editFormData}
                    setEditFormData={setEditFormData}
                    showAssesmentModal={AssementOption}
                    setShowAssesmentModal={setAssementOption}
                />}
        </div>
    );
}

export default SavedReview;
