import { Button, FormControlLabel, Radio, RadioGroup, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { handleInputChangeMinutesSeconds,  handleTimeChangeZones } from '../../utils/Resubale'

const RunCalculate = ({error,setError,errorOne,setErrorOne, fetchGoals, distanceUnit, formValue, setFormValue, calculateValue, calculateValues }) => {
  const [valueName, setValuename] = useState()
  

  const validateTime = (time) => {
    if (!time) return true;
    const regex = /^([0-1]\d|2[0-3]):([0-5]\d):([0-5]\d)$/;
    return regex.test(time);
  };

  const validateMinutesSeconds = (time) => {
    if (!time) return true; // Allow empty input
    const regex = /^([0-5]\d):([0-5]\d)$/; // mm:ss format
    return regex.test(time);
  };
  useEffect(() => {
    if (formValue?.type === "cycling") {
      setValuename("speedcycle")
      setFormValue({ type: "cycling", sporttype: "single", inputTime: "", goal: "" })
    } else if (formValue?.type === "running") {
      setValuename("pacerun")
      setFormValue({ type: "running", sporttype: "single", inputTime: "", goal: "" })
    }
    else if (formValue?.type === "swimming") {
      setValuename("paceswim")
      setFormValue({ type: "swimming", sporttype: "single", inputTime: "", goal: "" })


    }
  }, [formValue?.type])

  const handleChange = (value, name) => {
    setFormValue({ ...formValue, [name]: value, inputTime:"" })
  }
  return (
    <div>
      <div
        style={{
          marginTop:"20px",
          borderRadius: "10px",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          gap: "10px",
        }}
        className="flow-running-form"
      >
      <div className="headingCont">
      <span className="heading">{formValue.type=="running"?"Run":formValue.type=="cycling"?"Cycle":formValue.type=="swimming"?"Swim":"Triathlon"}</span>{" "}
      <span className="orange heading">Calculator</span>
    </div>

        <label style={{ width: "10vw" }}>Goals:</label>
        <select
          className="running-step-input"
          name="Country"
          id="country"
          value={formValue.goal}
          onChange={(e) =>
            setFormValue({ ...formValue, goal: e.target.value })
          }
        ><option value="" disabled>
            Select Goals
          </option>
          {fetchGoals?.map((ele, index) => (
            <option key={index} value={ele?.goal_name}>
              {ele?.goal_name}
            </option>
          ))}
        </select>

        <label style={{ width: "10vw" }}>Input Time:</label>
        <TextField
          fullWidth
          type='text'
          value={formValue.inputTime}
          onChange={(e) => {
            const formattedTime = handleTimeChangeZones(e);
              setFormValue({ ...formValue, inputTime:formattedTime,[valueName]: ""})
              if (!validateTime(formattedTime)) {
                setError('Time must be in hh:mm:ss format');
              } else {
                setError('');
              }
        }}
        onBlur={(e) => {
          if (formValue.inputTime && !validateTime(formValue.inputTime)) {
            setError('Time must be in hh:mm:ss format');
          }
        }}
        error={Boolean(error)}
      helperText={error}
          placeholder="Enter Time Trial (hh:mm:ss)"
        />
        <label style={{ width: "10vw" }}>Pace:</label>
        <TextField
          fullWidth
          value={formValue.pacerun?formValue.pacerun:formValue.paceswim?formValue.paceswim:formValue.speedcycle?formValue.speedcycle:""}
          type='text'
          onChange={(e) => {
            if (formValue?.type === "cycling") {
              handleChange(e.target.value, valueName)
            } else if(formValue?.type === "swimming"){
              const formattedTime = handleInputChangeMinutesSeconds(e);
              setFormValue({ ...formValue, [valueName]: formattedTime, inputTime:"" })
              if (!validateMinutesSeconds(formattedTime)) {
                setErrorOne('Time must be in mm:ss format');
              } else {
                setErrorOne('');
              }
            } else {
              const formattedTime = handleInputChangeMinutesSeconds(e);
              setFormValue({ ...formValue, [valueName]: formattedTime, inputTime:"" })
              if (!validateMinutesSeconds(formattedTime)) {
                setErrorOne('Time must be in mm:ss format');
              } else {
                setErrorOne('');
              }
            }
          }
          }
          onBlur={(e) => {
            if (formValue?.type !== "cycling" && formValue[valueName] && !validateMinutesSeconds(formValue[valueName])) {
              setErrorOne('Time must be in mm:ss format');
            }
          }}
          placeholder={formValue?.type === "cycling" ? "Enter pace" : "Enter pace (mm:ss)"}
          error={formValue?.type !== "cycling" && Boolean(errorOne)}
          helperText={formValue?.type !== "cycling" && errorOne}
        />
      </div>
      &nbsp;
      <RadioGroup
        aria-labelledby="demo-radio-buttons-group-label"
        defaultValue="female"
        name="radio-buttons-group"
        value={formValue.unit}
        onChange={(e) =>
          setFormValue({ ...formValue, unit: e.target.value })
        }
      >
        {distanceUnit?.map((unit) => {
          return (
            <FormControlLabel value={unit?.value} control={<Radio />} label={unit?.name} />
          )
        })}
      </RadioGroup>
      
      <Button className="form-btn"  variant='contained' onClick={calculateValue}>Calculate</Button>

      &nbsp;
      {calculateValues?.length > 0 &&
        <div className='mt-4 text-xl'><strong>Results</strong>

          <div className='flex'>
            <div style={{ border: "1px solid #d1caca", borderBottom: "none", borderRight: "none" }} className='p-[6px] box-border rounded-tl-sm w-[50%]  min-h-[5vh]'>
              <b>Total distance</b>
            </div>
            {calculateValues[0]?.paceSpeed == "NaN" || calculateValues[0]?.paceSpeed == "NaN:NaN" ? (null) : (
              <div style={{ border: "1px solid #d1caca", borderBottom: "none" }} className='p-[6px] box-border rounded-tr-sm w-[50%]  min-h-[5vh]'>
                <b> {formValue?.type === "cycling" ? "Speed per Hour" : "Pace per Minute"}</b>
              </div>
            )

            }

            {calculateValues[0]?.time &&

              <div style={{ border: "1px solid #d1caca", borderBottom: "none" }} className='p-[6px] box-border rounded-tr-sm w-[50%]  min-h-[5vh]'>
                <b> {formValue?.type === "cycling" ? "Time" : "Time"}</b>
              </div>
            }
          </div>
          {calculateValues?.map((ele) => {
            return (
              <div className='flex'>
                <div style={{ border: "1px solid #d1caca", borderRight: "none" }} className='p-[6px] box-border rounded-bl-sm w-[50%]  min-h-[5vh]'>
                  {`${ele?.totlaDistance}  ${ele?.totalDistancenit}`}
                </div>
                {ele?.paceSpeed == "NaN" || ele?.paceSpeed == "NaN:NaN" ? (null) : (
                  <div style={{ border: "1px solid #d1caca" }} className='p-[6px] box-border rounded-br-sm w-[50%]  min-h-[5vh]'>
                    {`${ele?.paceSpeed}  ${ele?.unit}`}
                  </div>
                )
                }
                {ele?.time &&
                  <div style={{ border: "1px solid #d1caca" }} className='p-[6px] box-border rounded-br-sm w-[50%]  min-h-[5vh]'>
                    {`${ele?.time}`}
                  </div>
                }
              </div>
            )
          })}
        </div>
      }

    </div>
  )
}

export default RunCalculate
