import React, { Component } from 'react'
import Header from '../components/Header'
import { Menu, Switch } from '@mantine/core'
import {
  IconFilter,
  IconLiveView,
  IconMessage,
  IconTrash,
  IconPlus,
  IconSearch,
  IconX,
  IconUser,
  IconCalendarEvent,
  IconLeaf,
  IconWallet,
  IconCalendarPlus,
  IconScale,
  Icon3dCubeSphere,
  IconInfoCircle,
  IconLayersUnion,
  IconYoga,
  IconRss,
  IconUserCircle,
  IconReport,
  IconTarget,
  IconArrowUpRightCircle,
  IconAccessible,
  IconHelp,
  IconVaccine,
  IconActivity,
  IconTrack,
  IconAccessPoint,
  IconFileCertificate
} from '@tabler/icons'
import FullCalendar, { formatDate } from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import { INITIAL_EVENTS, createEventId } from '../utils/EventUtils'

export default class TriathlonProgramPage extends Component {
  constructor(props) {
    super(props)

    this.state = {
      createModal: false
    }
  }

  handleModalReveal = () => {
    this.setState({
      createModal: true
    })
  }

  athletes = [
    { id: 1, name: 'Ajit Roy' },
    { id: 2, name: 'Ajit Sivaram' },
    { id: 3, name: 'Arvindera S Sawhney' },
    { id: 4, name: 'Mamu Hage' },
    { id: 5, name: 'Nagarajan Narasimhan' },
    { id: 6, name: 'Nidhin Valsan' },
    { id: 7, name: 'nitish kulkarni' },
    { id: 8, name: 'Nitish kulkarni' },
    { id: 9, name: 'Pradeep Katrodia' },
    { id: 10, name: 'Rajat sethi' },
    { id: 11, name: 'Satish Padwalkar' },
    { id: 12, name: 'Seju Kuruvilla' },
    { id: 13, name: 'Sh. Atul Karwal' },
    { id: 14, name: 'Sh. Mohsen Shahedi' },
    { id: 15, name: 'Sh. NS Bundela' },
    { id: 16, name: 'Shobhit Saxena' }
  ]

  actions = [
    {
      id: 1,
      title: 'Quick view',
      icon: <IconLiveView size={18} />
    },
    {
      id: 2,
      title: 'Chat',
      icon: <IconMessage size={18} />
    },
    {
      id: 3,
      title: 'Details',
      icon: <IconTrash size={18} />
    }
  ]

  userActions = [
    {
      id: 1,
      title: 'Plan',
      icon: <IconCalendarEvent size={22} />
    },
    {
      id: 2,
      title: 'Season plan',
      icon: <IconLeaf size={22} />
    },
    {
      id: 3,
      title: 'Goals',
      icon: <IconTarget size={22} />
    },
    {
      id: 4,
      title: 'Injuries',
      icon: <IconVaccine size={22} />
    },
    {
      id: 5,
      title: 'Fitness history',
      icon: <IconActivity size={22} />
    },
    {
      id: 6,
      title: 'Payment',
      icon: <IconWallet size={22} />
    },
    {
      id: 7,
      title: 'Performance summary',
      icon: <IconAccessible size={22} />
    },
    {
      id: 8,
      title: 'Personal schedule',
      icon: <IconCalendarPlus size={22} />
    },
    {
      id: 9,
      title: 'Zones',
      icon: <Icon3dCubeSphere size={22} />
    },
    {
      id: 10,
      title: 'Instructions',
      icon: <IconInfoCircle size={22} />
    },
    {
      id: 11,
      title: 'Weight tracker',
      icon: <IconScale size={22} />
    },
    {
      id: 12,
      title: 'Reces',
      icon: <IconYoga size={22} />
    },
    {
      id: 13,
      title: 'Engagement history',
      icon: <IconLayersUnion size={22} />
    },
    {
      id: 14,
      title: 'Trainee management',
      icon: <IconTrack size={22} />
    },
    {
      id: 15,
      title: 'Run feedback',
      icon: <IconRss size={22} />
    },
    {
      id: 16,
      title: 'Accessories',
      icon: <IconAccessPoint size={22} />
    },
    {
      id: 17,
      title: 'Progress report',
      icon: <IconReport size={22} />
    },
    {
      id: 18,
      title: 'Challenges',
      icon: <IconArrowUpRightCircle size={22} />
    },
    {
      id: 19,
      title: 'Leader board',
      icon: <IconFileCertificate size={22} />
    },
    {
      id: 20,
      title: 'Help',
      icon: <IconHelp size={22} />
    },
    {
      id: 21,
      title: 'Training profile',
      icon: <IconUser size={22} />
    },
    {
      id: 22,
      title: 'Corporate leader board',
      icon: <IconUserCircle size={22} />
    }
  ]

  render() {
    return (
      <>
        <Header />
        <div className='grid grid-cols-1 xl:grid-cols-5 items-start gap-x-4'>
          <div className='hidden xl:block w-full p-4 bg-slate-100 sticky top-20'>
            <div className='flex items-center justify-between'>
              <div className='flex items-center space-x-8'>
                <div>
                  <IconPlus
                    size={24}
                    color='dodgerblue'
                    onClick={() => this.handleModalReveal()}
                    className='cursor-pointer'
                  />
                </div>
                <div>
                  <IconSearch size={24} color='dodgerblue' />
                </div>
                <div>
                  <IconFilter size={24} color='dodgerblue' />
                </div>
              </div>
              <div>
                <IconX size={24} color='dodgerblue' />
              </div>
            </div>
            <br />
            <div className='mb-4'>
              <Switch label='Multiple Athletes' />
            </div>
            <div className='mb-6'>
              <Switch label='Lite Program Athletes' />
            </div>
            <div className='flex flex-col gap-y-2 text-sm mb-4'>
              <div className='text-slate-700'>
                Showing Athletes for Neil Dsilva
              </div>
              <div className='text-slate-700'>
                <span className='font-medium'>Total Athletes</span> : 6
              </div>
            </div>
            <hr />
            <div className='flex flex-col gap-y-4 items-start my-6'>
              {this.athletes.map((item) => (
                <div
                  className='flex justify-between items-center gap-x-4 w-full'
                  key={item.id}
                >
                  <div className='flex items-center gap-x-2'>
                    <span>
                      <IconUser size={20} color='#334155' />
                    </span>
                    <span className='text-slate-700'>{item.name}</span>
                  </div>
                  <div>
                    <Menu shadow='md' width={150} position='bottom-end'>
                      <Menu.Target className='cursor-pointer'>
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          className='icon icon-tabler icon-tabler-dots-vertical'
                          width='20'
                          height='20'
                          viewBox='0 0 24 24'
                          strokeWidth='1.5'
                          stroke='#2c3e50'
                          fill='none'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        >
                          <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                          <circle cx='12' cy='12' r='1' />
                          <circle cx='12' cy='19' r='1' />
                          <circle cx='12' cy='5' r='1' />
                        </svg>
                      </Menu.Target>

                      <Menu.Dropdown>
                        {this.actions.map((item) => (
                          <Menu.Item
                            color={'gray'}
                            icon={item.icon}
                            key={item.id}
                          >
                            {item.title}
                          </Menu.Item>
                        ))}
                      </Menu.Dropdown>
                    </Menu>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className='flex items-center justify-center p-4 w-full col-span-4'>
            {/* Create modal */}
            <div
              className='md:absolute md:top-32 md:right-2/2 px-4 py-6 bg-slate-50 drop-shadow-2xl rounded-md border-2 border-slate-200/75 w-full md:w-8/12 lg:w-6/12 xl:w-4/12 z-50'
              style={{ display: this.state.createModal ? 'block' : 'none' }}
            >
              <div className='flex items-center justify-between'>
                <span className='text-xl font-medium m-0 pb-2'>
                  New Athlete
                </span>
                <span className='text-slate-600'>
                  <IconX
                    size={22}
                    className='mb-2 cursor-pointer'
                    onClick={() =>
                      this.setState({
                        createModal: false
                      })
                    }
                  />
                </span>
              </div>
              <hr />
              <br />
              <div className='mb-5 grid grid-cols-1 md:grid-cols-2 items-start gap-4'>
                <div>
                  <p className='mb-1 text-slate-600 px-1 font-medium'>
                    Group <span className='text-red-500'>*</span>{' '}
                  </p>
                  <select
                    name='status'
                    id='status'
                    placeholder='Group'
                    className='w-full border-b-2 py-2 rounded-md focus:outline-none text-slate-600'
                  >
                    <option value='#'>--Select--</option>
                    <option value='General Fitness'>General Fitness</option>
                  </select>
                </div>
                <div>
                  <p className='mb-1 text-slate-600 px-1 font-medium'>
                    Sub-group <span className='text-red-500'>*</span>{' '}
                  </p>
                  <select
                    name='status'
                    id='status'
                    placeholder='Group'
                    className='w-full border-b-2 py-2 rounded-md focus:outline-none text-slate-600'
                  >
                    <option value='#'>--Select--</option>
                    <option value='Fitness habit formation'>
                      Fitness habit formation
                    </option>
                    <option value='General fitness and walking'>
                      General fitness and walking
                    </option>
                    <option value='Weight management - 1'>
                      Weight management - 1
                    </option>
                    <option value='Weight management - 2'>
                      Weight management - 2
                    </option>
                  </select>
                </div>
              </div>
              <div className='mb-5'>
                <p className='mb-1 text-slate-600 px-1 font-medium'>
                  Status <span className='text-red-500'>*</span>{' '}
                </p>
                <select
                  name='status'
                  id='status'
                  placeholder='Group'
                  className='w-full border-b-2 py-2 rounded-md focus:outline-none text-slate-600'
                >
                  <option value='#'>--Select--</option>
                  <option value='Subscribed'>Subscribed</option>
                </select>
              </div>
              <div className='mb-5 grid grid-cols-1 md:grid-cols-2 items-start gap-4'>
                <div>
                  <p className='mb-1 text-slate-600 px-1 font-medium'>
                    Firstname <span className='text-red-500'>*</span>{' '}
                  </p>
                  <input
                    type='text'
                    placeholder='Enter your firstname'
                    className='w-full border-b-2 py-2 rounded-md focus:outline-none text-slate-600 px-1'
                  />
                </div>
                <div>
                  <p className='mb-1 text-slate-600 px-1 font-medium'>
                    Lastname <span className='text-red-500'>*</span>{' '}
                  </p>
                  <input
                    type='text'
                    placeholder='Enter your lastname'
                    className='w-full border-b-2 py-2 rounded-md focus:outline-none text-slate-600 px-1'
                  />
                </div>
              </div>
              <div className='mb-5'>
                <p className='mb-1 text-slate-600 px-1 font-medium'>
                  Gender <span className='text-red-500'>*</span>{' '}
                </p>
                <select
                  name='gender'
                  id='gender'
                  placeholder='Group'
                  className='w-full border-b-2 py-2 rounded-md focus:outline-none text-slate-600'
                >
                  <option value='#'>--Select--</option>
                  <option value='Male'>Male</option>
                  <option value='Female'>Female</option>
                </select>
              </div>
              <div className='mb-5 grid grid-cols-1 md:grid-cols-2 items-start gap-4'>
                <div>
                  <p className='mb-1 text-slate-600 px-1 font-medium'>
                    Email <span className='text-red-500'>*</span>{' '}
                  </p>
                  <input
                    type='text'
                    placeholder='Enter your email address'
                    className='w-full border-b-2 py-2 rounded-md focus:outline-none text-slate-600 px-1'
                  />
                </div>
                <div>
                  <p className='mb-1 text-slate-600 px-1 font-medium'>
                    Mobile <span className='text-red-500'>*</span>{' '}
                  </p>
                  <input
                    type='text'
                    placeholder='Enter your mobile number'
                    className='w-full border-b-2 py-2 rounded-md focus:outline-none text-slate-600 px-1'
                  />
                </div>
              </div>
              <div className='mb-5'>
                <div>
                  <p className='mb-1 text-slate-600 px-1 font-medium'>
                    City <span className='text-red-500'>*</span>{' '}
                  </p>
                  <input
                    type='text'
                    placeholder='Enter your city'
                    className='w-full border-b-2 py-2 rounded-md focus:outline-none text-slate-600 px-1'
                  />
                </div>
              </div>
              <div className='mb-5 flex items-center'>
                <input type='checkbox' name='welcome' id='welcome' />
                <span className='ml-2 text-sm'>Send welcome email</span>
              </div>
              <div className='mb-2'>
                <button className='p-2.5 bg-orange-500 text-slate-50 w-full rounded-sm'>
                  Create
                </button>
              </div>
            </div>
            <div className='flex flex-col w-full'>
              {/* Top header */}
              <div>
                <div className='flex  items-center justify-between gap-6 my-6'>
                  <div className='flex items-center gap-x-4'>
                    <span>
                      <img
                        src='https://randomuser.me/api/portraits/men/14.jpg'
                        alt='...'
                        className='w-10 rounded-full'
                      />
                    </span>
                    <span className='font-medium text-md lg:text-xl'>
                      Ajit Roy
                    </span>
                  </div>
                  <div className='flex gap-x-4 items-center'>
                    <div>
                      <Menu shadow='md' width={250} position='bottom-end'>
                        <Menu.Target className='cursor-pointer'>
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            className='icon icon-tabler icon-tabler-dots-vertical'
                            width='24'
                            height='24'
                            viewBox='0 0 24 24'
                            strokeWidth='1.5'
                            stroke='#2c3e50'
                            fill='none'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          >
                            <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                            <circle cx='12' cy='12' r='1' />
                            <circle cx='12' cy='19' r='1' />
                            <circle cx='12' cy='5' r='1' />
                          </svg>
                        </Menu.Target>

                        <Menu.Dropdown>
                          {this.userActions.map((item) => (
                            <Menu.Item
                              color={'gray'}
                              icon={item.icon}
                              key={item.id}
                            >
                              {item.title}
                            </Menu.Item>
                          ))}
                        </Menu.Dropdown>
                      </Menu>
                    </div>
                  </div>
                </div>
                <div>
                  <p className='text-sm text-red-500 bg-red-50 text-center p-1'>
                    Strava last updated on : 11 Oct-2022 08:30:30
                  </p>
                  <br />
                  <p className='text-sm text-center'>
                    Here is a quote to start your day. Wish you a wonderful
                    training day.
                  </p>
                  <p className='text-center mt-2'>
                    <em>
                      "During my 18 years I came to bat almost 10,000 times. I
                      struck out about 1,700 times and walked maybe 1,800 times.
                      You figure a ballplayer will average about 500 at bats a
                      season. That means I played seven years without ever
                      hitting the ball."( Mickey Mantle )
                    </em>
                  </p>
                </div>
              </div>
              <br />
              {/* Middle header  */}
              <div className='grid grid-cols-1 md:grid-cols-5 items-start gap-4'>
                <div className='md:col-span-3 p-8 bg-slate-100 rounded border-2'>
                  <div className='flex items-start gap-4 md:gap-8'>
                    <div className='flex flex-col items-center'>
                      <span>
                        <IconTarget size={48} color='#222' />
                      </span>
                      <span>Goal</span>
                    </div>
                    <div>
                      <p className='text-base mb-2'>
                        Kimberly is not working on any active goal currently.
                      </p>
                      <p className='text-sm text-slate-500 mb-1'>
                        Start to Create Goals and Comments
                      </p>
                      {/* <p className='text-xs'>33 days to achieve your goa</p> */}
                    </div>
                  </div>
                </div>
                <div className='bg-slate-200 md:col-span-2 h-full border-2 rounded'>
                  <div className='p-2 bg-white text-center'>Upcoming Race</div>
                  <div className='flex justify-center items-center h-32 md:h-full'>
                    <p>Click here to select race</p>
                  </div>
                </div>
              </div>
              <br />
              <div
                className='demo-app'
                style={{ display: this.state.createModal ? 'none' : 'block' }}
              >
                {/* {this.renderSidebar()} */}
                <div className='demo-app-main'>
                  <FullCalendar
                    plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                    themeSystem='bootstrap5'
                    headerToolbar={{
                      left: 'prev,next today',
                      center: 'dayGridMonth,timeGridWeek,timeGridDay',
                      right: 'title'
                    }}
                    initialView='dayGridMonth'
                    editable={true}
                    selectable={true}
                    selectMirror={true}
                    dayMaxEvents={true}
                    weekends={this.state.weekendsVisible}
                    initialEvents={INITIAL_EVENTS} // alternatively, use the `events` setting to fetch from a feed
                    select={this.handleDateSelect}
                    eventContent={renderEventContent} // custom render function
                    eventClick={this.handleEventClick}
                    eventsSet={this.handleEvents} // called after events are initialized/added/changed/removed
                    /* you can update a remote database when these fire:
            eventAdd={function(){}}
            eventChange={function(){}}
            eventRemove={function(){}}
            */
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }

  renderSidebar() {
    return (
      <div className='demo-app-sidebar'>
        <div className='demo-app-sidebar-section'>
          <h2>Instructions</h2>
          <ul>
            <li>Select dates and you will be prompted to create a new event</li>
            <li>Drag, drop, and resize events</li>
            <li>Click an event to delete it</li>
          </ul>
        </div>
        <div className='demo-app-sidebar-section'>
          <label>
            <input
              type='checkbox'
              checked={this.state.weekendsVisible}
              onChange={this.handleWeekendsToggle}
            ></input>
            toggle weekends
          </label>
        </div>
        <div className='demo-app-sidebar-section'>
          <h2>All Events ({this.state.currentEvents.length})</h2>
          <ul>{this.state.currentEvents.map(renderSidebarEvent)}</ul>
        </div>
      </div>
    )
  }

  handleWeekendsToggle = () => {
    this.setState({
      weekendsVisible: !this.state.weekendsVisible
    })
  }

  handleDateSelect = (selectInfo) => {
    let title = prompt('Please enter a new title for your event')
    let calendarApi = selectInfo.view.calendar

    calendarApi.unselect() // clear date selection

    if (title) {
      calendarApi.addEvent({
        id: createEventId(),
        title,
        start: selectInfo.startStr,
        end: selectInfo.endStr,
        allDay: selectInfo.allDay
      })
    }
  }

  handleEventClick = (clickInfo) => {
    if (
      window.confirm(
        `Are you sure you want to delete the event '${clickInfo.event.title}'`
      )
    ) {
      clickInfo.event.remove()
    }
  }

  handleEvents = (events) => {
    this.setState({
      currentEvents: events
    })
  }
}

function renderEventContent(eventInfo) {
  return (
    <>
      {/* <b>{eventInfo.timeText}</b> */}
      <i>{eventInfo.event.title}</i>
    </>
  )
}

function renderSidebarEvent(event) {
  return (
    <li key={event.id}>
      <b>
        {formatDate(event.start, {
          year: 'numeric',
          month: 'short',
          day: 'numeric'
        })}
      </b>
      <i>{event.title}</i>
    </li>
  )
}
