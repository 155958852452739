import { Avatar, Box, Typography } from "@mui/material";
import React, { memo, useEffect, useState } from "react";
import InsertLinkOutlinedIcon from "@mui/icons-material/InsertLinkOutlined";
import { MdOutlineGroup } from "react-icons/md";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'; 
import NotificationsActiveOutlinedIcon from "@mui/icons-material/NotificationsActiveOutlined";
import { useDispatch } from "react-redux";
import { storeOpenedUserInfoAction } from "../redux/action/usersAction";
import {
  serverTimestamp,
  onValue,
  query,
  limitToLast,
  orderByChild,
  equalTo,
  ref,
  update,
  get,
  remove
} from "firebase/database";
import { getGroupInfoAction } from "../redux/action/groupAction";
import { db } from "../../../API/firebase.config";
import { useSelector } from "react-redux";
import { URL } from "../../../API/api-endpoint";
import { useMessageContext } from "../../../context/MessageContext";
import Swal from 'sweetalert2';
import { getCurrentUserChatsAction } from "../redux/action/userChats";

const UserChats = ({ item, currentUser, isUnRead,setOpenPage,openPage,setIsUnRead }) => {
  const dispatch = useDispatch();
  const [lastMessage, setLastMessage] = useState("");
  const [lastMessageTime, setLastMessageTime] = useState("");
  const [unSeenMessageCount, setUnSeenMessageCount] = useState(0);
  const [userInfo, setUserInfo] = useState({});
  const [groupInfo, setGroupInfo] = useState({});
  const [totalUnseenMessageCount, setTotalUnseenMessageCount] = useState(0);
  const { updateTotalUnseenMessageCount } = useMessageContext();
  const[totalMessageCount,setTotalMessageCount]= useState(0)
  const { openedUser } = useSelector(state => state.users)
  const { openedGroupInfo } = useSelector((state) => state.group);
  const roleID = localStorage.getItem("roleID");


  const handleSelect = async (user) => {
    dispatch(storeOpenedUserInfoAction(user));

    const combinedId =
      currentUser.uid > user.uid
        ? currentUser.uid + user.uid
        : user.uid + currentUser.uid;

    try {
      const chatRef = ref(db, "chats/" + combinedId);
      const snapshot = await get(chatRef);

      if (!snapshot.exists()) {
        const currentUserChatsRef = ref(db, `userChats/${currentUser.uid}`);
        const userChatsRef = ref(db, `userChats/${user.uid}`);

        await update(currentUserChatsRef, {
          [`${combinedId}/userInfo`]: {
            uid: user.uid,
            displayName: user.displayName,
            photoURL: user.photoURL,
          },
          [`${combinedId}/date`]: serverTimestamp(),
          [`${combinedId}/isMsgReqAccepted`]: true,
          [`${combinedId}/isMsgReqDeclined`]: false,
          [`${combinedId}/lastMessage`]: "",
          [`${combinedId}/chatType`]: "personal",
        });

        await update(userChatsRef, {
          [`${combinedId}/userInfo`]: {
            uid: currentUser.uid,
            displayName: currentUser.displayName,
            photoURL: currentUser.profileImageUrl,
          },
          [`${combinedId}/date`]: serverTimestamp(),
          [`${combinedId}/isMsgReqAccepted`]: false,
          [`${combinedId}/isMsgReqDeclined`]: false,
          [`${combinedId}/lastMessage`]: "",
          [`${combinedId}/chatType`]: "personal",
        });

        // navigate("/individual-chat");
        setOpenPage("individual")
        setTimeout(() => {
          setIsUnRead(false);
        }, 3000);
        
      }
      // navigate("/individual-chat");
      setOpenPage("individual")
      setTimeout(() => {
        setIsUnRead(false);
      }, 3000);
      
      // Mark messages as seen
      // const messagesRef = ref(db, `chats/${combinedId}/messages`);
      // const messagesSnapshot = await get(messagesRef);

      // if (messagesSnapshot.exists()) {
      //   const messages = messagesSnapshot.val();

      //   const updates = {};
      //   for (const messageId in messages) {
      //     updates[`${messageId}/isSeen`] = true;
      //   }

      //   await update(ref(db, `chats/${combinedId}/messages`), updates);
      // }
    } catch (err) {
      console.log(err);
    }
  };

  const handleGotoGroupChat = async (groupId) => {
    dispatch(getGroupInfoAction(groupId));

    const messagesRef = ref(db, `chats/${groupId}/messages`);
    const messagesSnapshot = await get(messagesRef);

    

    if (messagesSnapshot.exists()) {
      const messages = messagesSnapshot.val();

      const updates = {};
      for (const messageId in messages) {
        updates[`${messageId}/isSeen`] = true;
      }

      await update(ref(db, `chats/${groupId}/messages`), updates);
    }
    
    // navigate("/group-chat/creator");
    setOpenPage("group-chat")
    setTimeout(() => {
      setIsUnRead(false);
    }, 3000);
    
    
  };

  const getLastMessage = (userUid) => {
    const combinedId =
      currentUser.uid > userUid
        ? currentUser.uid + userUid
        : userUid + currentUser.uid;
  
    let lastMessage = "";
    let lastMessageTime = 0;
    const messagesRef = ref(db, `chats/${combinedId}/messages`);
    const lastMessageQuery = query(
      messagesRef,
      orderByChild("timeStamp"),
      limitToLast(50) // Increase the limit to ensure we can find a message that is not deleted
    );
  
    const callback = (snapshot) => {
      if (snapshot.exists()) {
        const messages = snapshot.val();
        if (messages && typeof messages === 'object') {
          // Convert the messages object to an array
          const messagesArray = Object.keys(messages).map(key => messages[key]);
          // Sort the messages based on timeStamp
          messagesArray.sort((a, b) => b.timeStamp - a.timeStamp);
  
          // Find the last valid message
          const lastValidMessage = messagesArray.find(message => !message.isDeleted);
  
          if (lastValidMessage) {
            const type = lastValidMessage.type;
            lastMessage = type === "text" ? lastValidMessage.message : type;
            lastMessageTime = lastValidMessage.timeStamp;
  
            setLastMessage(lastMessage);
            console.log("lastMessage", lastMessage);
  
            const formattedTime = new Date(lastMessageTime).toLocaleString("en-US", {
              hour: "numeric",
              minute: "numeric",
              hour12: true,
            });
            setLastMessageTime(formattedTime);
          } else {
            console.log("No valid messages found.");
          }
        } else {
          console.log("Invalid message structure.");
        }
      } else {
        console.log("No messages found.");
      }
    };
  
    onValue(lastMessageQuery, callback);
  
    return;
  };
  

  const getUnseenMessageCount = (userUid) => {
    const combinedId =
      currentUser.uid > userUid
        ? currentUser.uid + userUid
        : userUid + currentUser.uid;

    const messagesRef = ref(db, `chats/${combinedId}/messages`);

    const unseenMessagesQuery = query(
      messagesRef,
      orderByChild("isSeen"),
      equalTo(false)
    );

    let unseenMsgesCount = 0;

    const callback = (snapshot) => {
      unseenMsgesCount = 0; // Reset count before updating
      snapshot.forEach((childSnapshot) => {
        const message = childSnapshot.val();
        if (message.senderId !== currentUser.uid && !message.isSeen) {
          unseenMsgesCount++; // Increment count
        }
      });

      // playAudio();

      setUnSeenMessageCount(unseenMsgesCount); // Set count after processing
    };

    onValue(unseenMessagesQuery, callback);
    return;
  };

  const getTotalMessageCount = (userUid) => {
    const combinedId =
      currentUser.uid > userUid
        ? currentUser.uid + userUid
        : userUid + currentUser.uid;
  
    const messagesRef = ref(db, `chats/${combinedId}/messages`);
  
    let totalMsgesCount = 0;
  
    const callback = (snapshot) => {
      totalMsgesCount = 0; // Reset count before updating
      snapshot.forEach((childSnapshot) => {
        totalMsgesCount++; // Increment count
      });
  
      setTotalMessageCount(totalMsgesCount); // Set count after processing
    };
  
    onValue(messagesRef, callback);
    return;
  };
  

  // const playAudio = () => {
  //   const audio = new Audio("/audio/new-msg.mp3");
  //   if (audio.paused) {
  //     audio.play();
  //   }
  // };

  const getLastMessageForGroupChat = (groupId) => {
    let lastMessage = "";
    let lastMessageTime = 0;
    const messagesRef = ref(db, `chats/${groupId}/messages`);
    const lastMessageQuery = query(
      messagesRef,
      orderByChild("timeStamp"),
      limitToLast(1)
    );

    const callback = async (snapshot) => {
      if (snapshot.exists()) {
        const lastMessageKey = Object.keys(snapshot.val())[0];
        const type = snapshot.val()[lastMessageKey].type;
        lastMessage =
          type === "text" ? snapshot.val()[lastMessageKey].message : type;
        lastMessageTime = snapshot.val()[lastMessageKey].timeStamp;
        const isCurrentUserExist =
          currentUser.uid in snapshot.val()[lastMessageKey].seenBy;
        setLastMessage(
          isCurrentUserExist ? lastMessage : "You no longer participant"
        );

        const formattedTime = await new Date(
          parseInt(lastMessageTime)
        ).toLocaleString("en-US", {
          hour: "numeric",
          minute: "numeric",
          hour12: true,
        });
        setLastMessageTime(formattedTime);
      } else {
        console.log("No messages found.");
      }
    };

    onValue(lastMessageQuery, callback);

    setLastMessage(lastMessage);
    setLastMessageTime(lastMessageTime);
    return;
  };

  const getUnseenMessageCountForGroupChat = (groupId) => {
    const messagesRef = ref(db, `chats/${groupId}/messages`);

    const unseenMessagesQuery = query(
      messagesRef,
      orderByChild(`seenBy/${currentUser.uid}`),
      equalTo(false)
    );

    let unseenMessageCount = 0;

    const callback = (snapshot) => {
      unseenMessageCount = 0; // Reset count before updating
      snapshot.forEach((childSnapshot) => {
        const message = childSnapshot.val();
        if (message.seenBy && !message.seenBy[currentUser.uid]) {
          unseenMessageCount++; // Increment count
        }
      });

      setUnSeenMessageCount(unseenMessageCount); // Set count after processing
    };

    onValue(unseenMessagesQuery, callback);
  };

  async function getUserDataById(userId) {
    try {
      const userRef = ref(db, `users/${userId}`);
      const snapshot = await get(userRef);

      if (snapshot.exists()) {
        setUserInfo(snapshot.val());
        return;
      } else {
        throw new Error("User data not found.");
      }
    } catch (error) {
      console.error("Error getting user data:", error);
      throw error;
    }
  }

  async function getGroupDataById(groupId) {
    try {
      const userRef = ref(db, `groups/${groupId}`);
      const snapshot = await get(userRef);

      if (snapshot.exists()) {
        setGroupInfo(snapshot.val());
        return;
      } else {
        throw new Error("User data not found.");
      }
    } catch (error) {
      console.error("Error getting user data:", error);
      throw error;
    }
  }

  useEffect(() => {
    const unseenCounts = {}; // Store individual unseen counts

    const handleUnseenMessages = (snapshot, combinedId) => {
      let unseenMsgCount = 0;

      snapshot.forEach((childSnapshot) => {
        const message = childSnapshot.val();
        // console.log("ppp", message);
        if (message.senderId !== currentUser.uid && !message.isSeen) {
          unseenMsgCount++; // Increment count
        }
      });

      unseenCounts[combinedId] = unseenMsgCount;

      // Calculate the total unseen message count by summing up counts for all users
      const totalUnseenCount = Object.values(unseenCounts).reduce(
        (acc, count) => acc + count,
        0
      );

      setTotalUnseenMessageCount(totalUnseenCount);
      updateTotalUnseenMessageCount(totalUnseenCount);

      // Log individual counts and total count to the console
      // console.log("bbbbbUnseen Counts:", unseenCounts);
      // console.log("bbbbbsTotal Unseen Messages:", totalUnseenCount);
    };

    const handleIndividualChats = (snapshot) => {
      snapshot.forEach((childSnapshot) => {
        const combinedId = childSnapshot.key;
        const messagesRef = ref(db, `chats/${combinedId}/messages`);
        const unseenMessagesQuery = query(
          messagesRef,
          orderByChild("isSeen"),
          equalTo(false)
        );

        onValue(unseenMessagesQuery, (messagesSnapshot) => {
          handleUnseenMessages(messagesSnapshot, `individual_${combinedId}`);
        });
      });
    };

    const handleGroupChats = (snapshot) => {
      snapshot.forEach((childSnapshot) => {
        const combinedId = childSnapshot.key; // Assuming group id is used as combinedId
        const messagesRef = ref(db, `chats/${combinedId}/messages`);
        const unseenMessagesQuery = query(
          messagesRef,
          orderByChild(`seenBy/${currentUser.uid}`),
          equalTo(false)
        );

        onValue(unseenMessagesQuery, (messagesSnapshot) => {
          handleUnseenMessages(messagesSnapshot, `group_${combinedId}`);
        });
      });
    };

    // Fetch all user chats
    const userChatsRef = ref(db, `userChats/${currentUser.uid}`);
    const userChatsQuery = query(userChatsRef);

    onValue(userChatsQuery, (snapshot) => {
      handleIndividualChats(snapshot);
      handleGroupChats(snapshot);
    });

    item?.userInfo?.uid && getUserDataById(item?.userInfo?.uid);
    item?.userInfo?.uid && getLastMessage(item?.userInfo?.uid);
    item?.userInfo?.uid && getUnseenMessageCount(item?.userInfo?.uid);

    item?.groupId && getGroupDataById(item?.groupId);
    item?.groupId && getLastMessageForGroupChat(item?.groupId);
    item?.groupId && getUnseenMessageCountForGroupChat(item?.groupId);

    // Your existing code...
  }, [item, currentUser.uid]);

  useEffect(() => {
    // Log the total unseen message count to the console
    console.log("Total Unseen Messages:", totalUnseenMessageCount);
  }, [totalUnseenMessageCount]);

  useEffect(() => {
  getTotalMessageCount(item?.userInfo?.uid);
}, [item?.userInfo?.uid]);

const handleDeleteGroupChat = async (groupId) => {
  const result = await Swal.fire({
    title: 'Are you sure?',
    text: "You won't be able to revert this!",
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Yes, delete it!'
  });

  if (result.isConfirmed) {
    try {
      const chatRef = ref(db, `chats/${groupId}`);
      await remove(chatRef);
      console.log("Group chat deleted successfully.");
      // Optionally, remove the group information as well
      const groupRef = ref(db, `groups/${groupId}`);
      await remove(groupRef);

      dispatch(getCurrentUserChatsAction(currentUser?.uid));
      setOpenPage("")
      console.log("Group information deleted successfully.");

      Swal.fire(
        'Deleted!',
        'The group chat has been deleted.',
        'success'
      );
    } catch (error) {
      console.error("Error deleting group chat:", error);
      Swal.fire(
        'Error!',
        'There was an error deleting the group chat.',
        'error'
      );
    }
  }
};

const handleDeletePersonalChat = async (userInfo) => {
  const combinedId = currentUser.uid > userInfo.uid 
    ? currentUser.uid + userInfo.uid 
    : userInfo.uid + currentUser.uid;

  const result = await Swal.fire({
    title: 'Are you sure?',
    text: "You won't be able to revert this!",
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Yes, delete it!'
  });
  

  if (result.isConfirmed) {
    try {
      // Remove the chat messages
      const chatRef = ref(db, `chats/${combinedId}`);
      await remove(chatRef);
      console.log("Personal chat messages deleted successfully.");

      // Optionally, remove the chat reference for the user
      const userChatRef = ref(db, `users/${currentUser.uid}/chats/${combinedId}`);
      await remove(userChatRef);
      console.log("Chat reference for the user deleted successfully.");

      // Optionally, remove the chat reference for the other user
      const otherUserChatRef = ref(db, `users/${userInfo.uid}/chats/${combinedId}`);
      await remove(otherUserChatRef);
      console.log("Chat reference for the other user deleted successfully.");

      // Refresh current user chats
      dispatch(getCurrentUserChatsAction(currentUser?.uid));
      setOpenPage("");

      Swal.fire(
        'Deleted!',
        'The personal chat has been deleted.',
        'success'
      );
    } catch (error) {
      console.error("Error deleting personal chat:", error);
      Swal.fire(
        'Error!',
        'There was an error deleting the personal chat.',
        'error'
      );
    }
  }
};



  return (
    <Box
    sx={{marginLeft:"10px"}}>
      {isUnRead
        ? unSeenMessageCount !== 0 &&
          (item.chatType === "personal" ? (
            <Box
              onClick={() => handleSelect(userInfo)}
              sx={
                item.isMsgReqAccepted
                  ? {
                      cursor: "pointer",
                      padding: "8px",
                      paddingTop: "8px",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }
                  : {
                      backgroundColor: "#FFEADC",
                      cursor: "pointer",
                      padding: "8px",
                      paddingTop: "10px",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginTop:"5px",
                      borderRadius: "10px"
                    }
              }
            >
              <Box
                sx={{ display: "flex", alignItems: "center", padding: "6px" }}
              >
                
                <Avatar alt={userInfo.displayName}
                     src={`${URL}/static/public/userimages/${userInfo?.photoURL}`} />
                <Box>
                  <Typography
                    className="userName"
                    fontWeight="fontWeightBold"
                    sx={{ paddingLeft: "1.2rem" }}
                  >
                    {userInfo?.displayName}
                  </Typography>
                  <Typography
                    sx={{
                      paddingLeft: "1.2rem",
                      fontSize: "14px",
                      paddingTop: "4px",
                    }}
                  >
                    {lastMessage?.length > 40
                      ? `${lastMessage?.slice(0, 40)}...`
                      : lastMessage}
                  </Typography>
                  {!item?.isMsgReqAccepted && (
                    <Typography
                      className="acceptReqPrompt"
                      sx={{ paddingLeft: "1.2rem" }}
                    >
                      Message Request : Tap to view & then you can accept /
                      decline
                    </Typography>
                  )}
                </Box>
              </Box>
              <Box sx={{ textAlign: "right" }}>
                <Box
                  className="timeStamp"
                  style={
                    unSeenMessageCount !== 0 ||
                    lastMessage === "photo" ||
                    lastMessage === "document"
                      ? { marginTop: "2.5px" }
                      : { marginTop: "-19px", color: "var(--grey-2, #707070)" }
                  }
                >
                  {lastMessageTime !== 0 && lastMessageTime}
                </Box>
                <Box
                  sx={{
                    textAlign: "right",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                    paddingTop: "4px",
                  }}
                >
                  {(lastMessage === "photo" || lastMessage === "document") && (
                    <InsertLinkOutlinedIcon className="chat-icon" />
                  )}
                  {item.showBellIcon && (
                    <NotificationsActiveOutlinedIcon className="chat-icon" />
                  )}
                  {unSeenMessageCount !== 0 && (
                    <Box
                      className="badgeIcon chat-icon"
                      sx={{
                        fontSize: "11px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {unSeenMessageCount}
                    </Box>
                  )}
                  {
                    roleID == 1 &&
                  
                  <DeleteOutlineIcon
                     onClick={(e) => {
                       e.stopPropagation(); // Prevents triggering the onClick of the parent Box
                       handleDeletePersonalChat(userInfo);
                     }}
                     sx={{ cursor: "pointer", color: "red", marginLeft: 1,fontSize:"15px" }}
                   />
                   }
                </Box>
              </Box>
            </Box>
          ) : (
            <Box
              onClick={() => handleGotoGroupChat(item?.groupId)}
              sx={
                item.isMsgReqAccepted
                  ? {
                      cursor: "pointer",
                      padding: "8px",
                      paddingTop: "15px",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }
                  : {
                      backgroundColor: "#FDFBE7",
                      cursor: "pointer",
                      padding: "8px",
                      paddingTop: "10px",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }
              }
            >
              <Box
                sx={{ display: "flex", alignItems: "center", padding: "6px" }}
              >
                <Box position="relative" display="inline-block">
                  <Avatar alt="Remy Sharp" src={groupInfo?.groupProfileURL} />
                  <Box
                    position="absolute"
                    bottom={0}
                    right={0}
                    bgcolor="transparent" // Optional: To make the background of the icon white
                    borderRadius="50%" 
                    p={0.5} // Optional: Padding around the icon
                  >
                    <MdOutlineGroup fontSize="16" style={{backgroundColor:"orange",borderRadius:"9999px"}} /> {/* Adjust icon size and color as needed */}
                  </Box>
                </Box>
                <Box>
                  <Typography
                    className="userName"
                    fontWeight="fontWeightBold"
                    sx={{ paddingLeft: "1.2rem" }}
                  >
                    {groupInfo?.groupName}
                  </Typography>
                  <Typography sx={{ paddingLeft: "1.2rem", fontSize: "14px" }}>
                    {lastMessage?.length > 40
                      ? `${lastMessage?.slice(0, 40)}...`
                      : lastMessage}
                  </Typography>
                </Box>
              </Box>
              <Box sx={{ textAlign: "right" }}>
                <Box
                  className="timeStamp"
                  style={
                    unSeenMessageCount !== 0
                      ? { marginTop: "2.5px" }
                      : { marginTop: "-18px", color: "var(--grey-2, #707070)" }
                  }
                >
                  {lastMessageTime !== 0 && lastMessageTime}
                </Box>
                <Box
                  sx={{
                    textAlign: "right",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                  }}
                >
                  {item.showMediaIcon && (
                    <InsertLinkOutlinedIcon className="chat-icon" />
                  )}
                  {item.showBellIcon && (
                    <NotificationsActiveOutlinedIcon className="chat-icon" />
                  )}
                  {unSeenMessageCount > 0 && (
                    <Box
                      className="badgeIcon chat-icon"
                      sx={{
                        fontSize: "11px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {unSeenMessageCount}
                    </Box>
                  )}
                  {
                    roleID == 1 &&
                  <DeleteOutlineIcon
                     onClick={(e) => {
                       e.stopPropagation(); // Prevents triggering the onClick of the parent Box
                       handleDeleteGroupChat(item?.groupId);
                     }}
                    sx={{ cursor: "pointer", color: "red", marginLeft: 1,fontSize:"15px" }}
                  />
                  }
                </Box>
              </Box>
            </Box>
          ))
        : unSeenMessageCount === 0  &&
          ((item.chatType === "personal") ? (
            (totalMessageCount !== 0) ? (
            <Box
              onClick={() => handleSelect(userInfo)}
              sx={
                (openedUser?.uid === item?.userInfo?.uid && openPage === "individual")
                  ? {
                      backgroundColor: "#E67E22",
                      borderRadius:"10px",
                      color: "white",
                      cursor: "pointer",
                      padding: "8px",
                      paddingTop: "10px",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }
                  : item.isMsgReqAccepted
                  ? {
                      cursor: "pointer",
                      padding: "8px",
                      paddingTop: "8px",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }
                  : {
                      backgroundColor: "#FFEADC",
                      cursor: "pointer",
                      padding: "8px",
                      paddingTop: "10px",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      borderRadius: "10px",
                      margin: "5px 0"
                    }
              }
              >
              <Box
                sx={{ display: "flex", alignItems: "center", padding: "6px" }}
              >
                <Avatar alt={userInfo.displayName}
                     src={`${URL}/static/public/userimages/${userInfo?.photoURL}`} />
                <Box>
                  <Typography
                    className="userName"
                    fontWeight="fontWeightBold"
                    sx={
                      (openedUser?.uid === item?.userInfo?.uid && openPage === "individual") ?
                      {
                        paddingLeft: "1.2rem",color: "white !important"
                      }
                      :
                      {paddingLeft: "1.2rem",color:"gray !important"} }
                  >
                    {userInfo?.displayName}
                  </Typography>
                  <Typography
                    sx={
                      (openedUser?.uid === item?.userInfo?.uid && openPage === "individual") ?
                      {
                        paddingLeft: "1.2rem",
                      fontSize: "14px",
                      paddingTop: "4px",
                      color:"white !important"
                      }
                      :
                      {
                      paddingLeft: "1.2rem",
                      fontSize: "14px",
                      paddingTop: "4px",
                      color:"gray !important"
                    }}
                  >
                    {lastMessage?.length > 40
                      ? `${lastMessage?.slice(0, 40)}...`
                      : lastMessage}
                  </Typography>
                  {!item?.isMsgReqAccepted && (
                    <Typography
                      className="acceptReqPrompt"
                      sx={
                        (openedUser?.uid === item?.userInfo?.uid && openPage === "individual") ?
                        { paddingLeft: "1.2rem",color: "white !important" }
                        :
                        { paddingLeft: "1.2rem" }}
                    >
                      Message Request : Tap to view & then you can accept /
                      decline
                    </Typography>
                  )}
                </Box>
              </Box>
              <Box sx={{ textAlign: "right" }}>
                <Box
                  className="timeStamp"
                  style={
                    openedUser?.uid === item?.userInfo?.uid && openPage === "individual"
                      ? { color: "white" ,marginTop: "-19px",}
                      : unSeenMessageCount !== 0 ||
                        lastMessage === "photo" ||
                        lastMessage === "document"
                      ? { marginTop: "2.5px" , color: "var(--grey-2, #707070)"}
                      : { marginTop: "-19px", color: "var(--grey-2, #707070)" }
                  }
                  
                >
                  {lastMessageTime !== 0 && lastMessageTime}
                </Box>
                <Box
                  sx={{
                    textAlign: "right",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                    paddingTop: "4px",
                  }}
                >
                  {(lastMessage === "photo" || lastMessage === "document") && (
                    <InsertLinkOutlinedIcon className="chat-icon" />
                  )}
                  {item.showBellIcon && (
                    <NotificationsActiveOutlinedIcon className="chat-icon" />
                  )}
                  {unSeenMessageCount !== 0 && (
                    <Box
                      className="badgeIcon chat-icon"
                      sx={{
                        fontSize: "11px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {unSeenMessageCount}
                    </Box>
                  )}
                  {
                    roleID == 1 &&
                  <DeleteOutlineIcon
                    onClick={(e) => {
                      e.stopPropagation(); // Prevents triggering the onClick of the parent Box
                      handleDeletePersonalChat(userInfo);
                    }}
                    sx={{ cursor: "pointer", color: "red", marginLeft: 1,fontSize:"15px" }}
                  />
                  }
                </Box>
              </Box>
            </Box>
            )
            : null
          ) : (
            <Box
              onClick={() => handleGotoGroupChat(item?.groupId)}
              sx={
                (openedGroupInfo?.groupId === item?.groupId && openPage === "group-chat")
                ? {
                    backgroundColor: "#E67E22",
                    borderRadius:"10px",
                    cursor: "pointer",
                      padding: "8px",
                      paddingTop: "10px",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }
                    :
                item.isMsgReqAccepted
                  ? {
                      cursor: "pointer",
                      padding: "8px",
                      paddingTop: "15px",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }
                  : {
                      backgroundColor: "#FDFBE7",
                      cursor: "pointer",
                      padding: "8px",
                      paddingTop: "10px",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }
              }
            >
              <Box
                sx={{ display: "flex", alignItems: "center", padding: "6px" }}
              >
                <Box position="relative" display="inline-block">
                  <Avatar alt="Remy Sharp" src={groupInfo?.groupProfileURL} />
                  <Box
                    position="absolute"
                    bottom={0}
                    right={0}
                    bgcolor="transparent" // Optional: To make the background of the icon white
                    borderRadius="50%" 
                    p={0.5} // Optional: Padding around the icon
                  >
                    <MdOutlineGroup fontSize="16" style={{backgroundColor:"orange",borderRadius:"9999px"}} /> {/* Adjust icon size and color as needed */}
                  </Box>
                </Box>
                <Box>
                  <Typography
                    className="userName"
                    fontWeight="fontWeightBold"
                    sx={
                      (openedGroupInfo?.groupId === item?.groupId && openPage === "group-chat")
                      ?
                      { paddingLeft: "1.2rem",color:"white !important" }
                    :
                    {paddingLeft: "1.2rem",color:"gray !important"}
                  }
                  >
                    {groupInfo?.groupName}
                  </Typography>
                  <Typography 
                  sx={
                    (openedGroupInfo?.groupId === item?.groupId && openPage === "group-chat")
                      ?
                      { paddingLeft: "1.2rem", fontSize: "14px",color:"white !important" }
                      :
                    { paddingLeft: "1.2rem", fontSize: "14px",color:"gray !important" }}>
                    {lastMessage?.length > 40
                      ? `${lastMessage?.slice(0, 40)}...`
                      : lastMessage}
                  </Typography>
                </Box>
              </Box>
              <Box sx={{ textAlign: "right" }}>
                <Box
                  className="timeStamp"
                  style={
                    (openedGroupInfo?.groupId === item?.groupId && openPage === "group-chat")
                      ?
                       { marginTop: "-18px", color: "white" }
                      :
                    unSeenMessageCount !== 0
                      ? { marginTop: "2.5px" }
                      : { marginTop: "-18px", color: "var(--grey-2, #707070)" }
                  }
                >
                  {lastMessageTime !== 0 && lastMessageTime}
                </Box>
                <Box
                  sx={{
                    textAlign: "right",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                  }}
                >
                  {item.showMediaIcon && (
                    <InsertLinkOutlinedIcon className="chat-icon" />
                  )}
                  {item.showBellIcon && (
                    <NotificationsActiveOutlinedIcon className="chat-icon" />
                  )}
                  {unSeenMessageCount > 0 && (
                    <Box
                      className="badgeIcon chat-icon"
                      sx={{
                        fontSize: "11px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {unSeenMessageCount}
                    </Box>
                  )}
                  {
                    roleID == 1 &&
                  <DeleteOutlineIcon
                    onClick={(e) => {
                      e.stopPropagation(); // Prevents triggering the onClick of the parent Box
                      handleDeleteGroupChat(item?.groupId);
                    }}
                    sx={{ cursor: "pointer", color: "red", marginLeft: 1,fontSize:"15px" }}
                  />
                  }
                </Box>
              </Box>
            </Box>
          ))}
    </Box>
  );
};

export default memo(UserChats);
