import { configureStore } from "@reduxjs/toolkit";
import MultiTrainingBlocksSlice from "./slices/MultiTrainingBlocksSlice";
import { authReducer } from "../pages/Chat/redux/reducer/authReducer";
import { usersReducer } from "../pages/Chat/redux/reducer/usersReducer";
import { chatsReducer } from "../pages/Chat/redux/reducer/chatsReduces";
import { getCurrentUserChatsReducer } from "../pages/Chat/redux/reducer/userChats";
import { groupReducer } from "../pages/Chat/redux/reducer/groupReducer";

const rootReducer = {
  MultiTrainingBlocksSlice: MultiTrainingBlocksSlice,
  auth: authReducer,
  users: usersReducer,
  chats: chatsReducer,
  userChats: getCurrentUserChatsReducer,
  group: groupReducer,
};

const userLocalStorageData = localStorage.getItem("currentUser")
  ? JSON.parse(localStorage.getItem("currentUser"))
  : null;
console.log(userLocalStorageData);

const preloadedState = {
  auth: {
    currentUser: userLocalStorageData,
  },
};

const store = configureStore({
  reducer: rootReducer,
  preloadedState,
});

export default store;
