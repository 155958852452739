import React from 'react';

const StarRating = ({ numStars }) => {
  // Generate an array with the specified number of stars
  const stars = Array.from({ length: numStars }, (_, index) => (
    <span key={index} style={{color:"orange", fontSize:"20px",marginLeft:"2px"}}>&#9733;</span> // Unicode for a star character
  ));

  return (
    <span>
      {stars}
    </span>
  );
};
export default StarRating
