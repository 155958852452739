import {
  Box,
  Fade,
  FormControlLabel,
  IconButton,
  Toolbar,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import "../styles/navbar.css";
import Switch from "@mui/material/Switch";
import SearchIcon from "@mui/icons-material/Search";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useNavigate } from "react-router-dom";

export default function Navbar({
  setIsUnRead,
  isUnRead,
  setSearchKeyword,
  searchKeyword,
  setIsSearchActive,
  isSearchActive,
}) {
  console.log("searchKeyword",isUnRead);
  const navigate = useNavigate();

  // const handleLogOut = () => {
  //   localStorage.clear();
  //   navigate("/login");
  // };

  const handleSetIsSearch = async () => {
    if (isSearchActive) {
      // Clear the input value only if isSearchActive is true
      setSearchKeyword("");
    }
    await setIsSearchActive(!isSearchActive ? true : false);
    const inputElement = document.getElementById("search-msg-input");
    if (inputElement) {
      inputElement.focus();
    }
  };
  return (
    <>
      <Toolbar className="appBar">
        <Box sx={{ display: "flex" }}>
          <KeyboardBackspaceIcon className="cursor-pointer" onClick={() => navigate("/workout")} /> {/*onClick={handleLogOut} */}
          <Typography  className="chatTitle">Chat</Typography>
        </Box>
        {!isSearchActive ? (
          <Box className="readUnreadButton">
            <span
              className="readUnreadButton"
              style={!isUnRead ? { color: "#E67E22" } : {}}
            >
              {" "}
              All Messages
            </span>
            <Switch
              style={{ color: "#E67E22" }}
              value={isUnRead}
              checked={isUnRead}
              onChange={(e) => setIsUnRead(e.target.checked)}
              inputProps={{ "aria-label": "controlled" }}
            />
            <span
              className="readUnreadButton"
              style={isUnRead ? { color: "#E67E22" } : {}}
            >
              {" "}
              Unread
            </span>
          </Box>
        ) : (
          <Box style={{ position: "relative", textAlign: "center" }}>
            <input
              id="search-msg-input"
              value={searchKeyword}
              onChange={(e) => setSearchKeyword(e.target.value)}
              placeholder="Search Buddies"
              type="text"
              className="search-message-input"
            />
          </Box>
        )}
        <Box style={{ width: "1.5rem",cursor:"pointer" }}>
          <img
            onClick={handleSetIsSearch}
            src={
              !isSearchActive
                ? "/images/searcher-icon.svg"
                : "/images/close-icon.png"
            }
            alt="search"
            style={{}}
          />
        </Box>
      </Toolbar>
    </>
  );
}
