import React from 'react'
import { Navigate } from 'react-router-dom';

export const ProtectedRoutes = ({children}) => {
  const token = localStorage.getItem('token')
  const userSubsription = localStorage.getItem('userSubsription')
  console.log("userSubsription",token,userSubsription);


    
    if (token === null) {
        return <Navigate to="/" replace />
    }
 
    return children
}
