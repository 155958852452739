import React from "react";
import Header from "../components/Header";
import WorkoutSidebar from "../components/WorkoutSidebar";
import {
  IconEdit,
  IconLiveView,
  IconMessage,
  IconTrash,
  IconX,
} from "@tabler/icons";
import { useState } from "react";
import TagInput from "../components/Taginput";
import "./workout.css";
import { Table, Tag } from "antd";

const actions = [
  {
    id: 1,
    title: "Quick view",
    icon: <IconLiveView size={18} />,
  },
  {
    id: 2,
    title: "Chat",
    icon: <IconMessage size={18} />,
  },
  {
    id: 3,
    title: "Details",
    icon: <IconTrash size={18} />,
  },
];

const Workout = () => {
  const [createModal, setCreateModal] = useState(false);
  const [tags, setTags] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [selectedLibraryItem, setSelectedLibraryItem] = useState(null);
  const [isOpenHandleWoerkOut, setIsOpenHandleWoerkOut] = useState(false);
  const [isOpenWoerkOut, setIsOpenWoerkOut] = useState("showTable");
  console.log(selectedLibraryItem?.workouts, "selectedLibraryItem");
  const handleModalReveal = () => {
    setCreateModal(true);
  };

  const [selectedActivity, setSelectedActivity] = useState("");

  const handleActivityChange = (event) => {
    setSelectedActivity(event.target.value);
  };

  const columns = [
    {
      title: "Id",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Duration",
      dataIndex: "duration",
      key: "duration",
    },
    {
      title: "Activity Description",
      dataIndex: ["activity", "description"],
      key: "activityDescription",
    },
    {
      title: "Has Zone",
      dataIndex: "activity",
      key: "hasZone",
      render: (activity) => (
        <Tag color={activity.hasZone ? "green" : "red"}>
          {activity.hasZone ? "Yes" : "No"}
        </Tag>
      ),
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      render: (_, record) => (
        <div className="flex ">
          <span className="px-2">
            <IconEdit color="blue" />
          </span>
          <span className="px-2">
            <IconTrash color="red" />
          </span>
        </div>
      ),
    },
  ];

  return (
    <div>
      <Header />
      <div className="grid grid-cols-1 xl:grid-cols-5 items-start gap-x-4">
        <div className="p-4 bg-slate-100 fixed top-24 w-full lg:w-4/12 xl:w-2/12 left-0 overflow-y-scroll h-full">
          <WorkoutSidebar
            actions={actions}
            handleModalReveal={handleModalReveal}
            setSelectedLibraryItem={setSelectedLibraryItem}
            setIsOpenHandleWoerkOut={setIsOpenHandleWoerkOut}
          />
        </div>
        <div className="flex items-center justify-center p-4 w-full md:w-10/12 lg:col-span-4 absolute right-0 overflow-y-scroll">
          {/* Create modal */}
          <div
            className={`${
              createModal ? "md:fixed" : "md:absolute"
            } md:top-32 md:right-2/2 px-4 py-6 bg-slate-50 drop-shadow-2xl rounded-md border-2 border-slate-200/75 w-full md:w-8/12 lg:w-6/12 xl:w-4/12 z-50`}
            style={{ display: createModal ? "block" : "none" }}
          >
            <div className="flex items-center justify-between">
              <span className="text-xl font-medium m-0 pb-2">
                Create Library
              </span>
              <span className="text-slate-600 ">
                <IconX
                  size={22}
                  className="mb-2 cursor-pointer"
                  onClick={() => setCreateModal(false)}
                />
              </span>
            </div>
            <hr />
            <br />

            <div className="mb-5">
              <div>
                <p className="mb-1 text-slate-600 px-1 font-medium">
                  Description <span className="text-red-500">*</span>{" "}
                </p>
                <input
                  type="text"
                  placeholder="Enter your Description"
                  className="w-full border-b-2 py-2 rounded-md focus:outline-none text-slate-600 px-1"
                />
              </div>
            </div>
            <div className="mb-5">
              <div>
                <p className="mb-1 text-slate-600 px-1 font-medium">
                  Tags <span className="text-red-500">*</span>{" "}
                </p>
                <TagInput
                  tags={tags}
                  setTags={setTags}
                  inputValue={inputValue}
                  setInputValue={setInputValue}
                />
              </div>
            </div>
            <div className="mb-2">
              <button className="p-2.5 bg-orange-500 text-slate-50 w-full rounded-sm">
                Create
              </button>
            </div>
          </div>
        </div>
      </div>
      {isOpenHandleWoerkOut && (
        <div
          style={{
            marginTop: "100px",
            marginLeft: "270px",
            width: "calc(100% - 270px)",
          }}
        >
          <div className="workout-screen-section">
            <div className="text-center py-4">
              <h1 className="font-bold text-xl text-slate-600">
                {selectedLibraryItem?.description}
              </h1>
            </div>
            <div className="workout-button-section">
              <button
                class="bg-blue-500 hover:bg-blue-700 text-white font-semibold py-2 px-4 rounded"
                onClick={() => {
                  setIsOpenWoerkOut("showForm");
                }}
              >
                Add Workout
              </button>
              <button class="bg-green-500 hover:bg-green-700 text-white font-semibold py-2 px-4 rounded">
                Copy Workout
              </button>
              <button class="bg-red-500 hover:bg-red-700 text-white font-semibold py-2 px-4 rounded">
                Cut Workout
              </button>
              <button class="bg-cyan-500 hover:bg-cyan-700 text-white font-semibold py-2 px-4 rounded">
                Paste Workout
              </button>
            </div>
            {isOpenWoerkOut === "showTable" && (
              <div className="workout-table-section py-4">
                <Table
                  columns={columns}
                  dataSource={selectedLibraryItem?.workouts}
                  pagination={true}
                />
              </div>
            )}
            {isOpenWoerkOut === "showForm" && (
              <div className="workout-form-section py-5 px-10">
                <div className="workout-form-main">
                  <div>
                    <p className="mb-1 text-slate-600 px-1 font-medium">
                      Workout Name <span className="text-red-500">*</span>{" "}
                    </p>
                    <input
                      type="text"
                      placeholder="Enter your Workout Name"
                      className="w-full border-b-2 py-2 rounded-md focus:outline-none text-slate-600 px-1"
                    />
                  </div>
                  <div className="mt-5">
                    <p className="mb-1 text-slate-600 px-1 font-medium">
                      Activity <span className="text-red-500">*</span>{" "}
                    </p>
                    <select
                      name="activity"
                      id="activity"
                      placeholder="Group"
                      className="w-full border-b-2 py-2 rounded-md focus:outline-none text-slate-600"
                      onChange={handleActivityChange}
                    >
                      <option value="#">--Select--</option>
                      <option value="Running">Running</option>
                      <option value="Swimming">Swimming</option>
                      <option value="Cycling">Cycling</option>
                    </select>
                  </div>
                  {selectedActivity === "Running" && (
                    <div className="p-4 bg-slate-400">
                      <div>
                        <p>
                          zone can be defind for activity. you can attch zone
                          for second metric.
                        </p>
                      </div>
                      <div className="mt-5  grid grid-cols-1 md:grid-cols-2 items-start gap-4 ">
                        <div>
                          <p className="mb-1 text-slate-600 px-1 font-medium">
                            Matric <span className="text-red-500">*</span>{" "}
                          </p>
                          <select
                            name="status"
                            id="status"
                            placeholder="Matric"
                            className="w-full border-b-2 py-2 rounded-md focus:outline-none bg-slate-400 text-slate-600"
                          >
                            <option value="#">--Select--</option>
                            <option value="General Fitness">
                              General Fitness
                            </option>
                          </select>
                        </div>
                        <div>
                          <p className="mb-1 text-slate-600 px-1 font-medium">
                            Zone <span className="text-red-500">*</span>{" "}
                          </p>
                          <select
                            name="status"
                            id="status"
                            placeholder="Group"
                            className="w-full border-b-2 py-2 rounded-md focus:outline-none bg-slate-400 text-slate-600"
                          >
                            <option value="#">--Select--</option>
                            <option value="Fitness habit formation">
                              Fitness habit formation
                            </option>
                            <option value="General fitness and walking">
                              General fitness and walking
                            </option>
                            <option value="Weight management - 1">
                              Weight management - 1
                            </option>
                            <option value="Weight management - 2">
                              Weight management - 2
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="mt-5">
                    <p className="mb-1 text-slate-600 px-1 font-medium">
                      Sub Activity <span className="text-red-500">*</span>{" "}
                    </p>
                    <select
                      name="subactivity"
                      id="subactivity"
                      placeholder="Group"
                      className="w-full border-b-2 py-2 rounded-md focus:outline-none text-slate-600"
                    >
                      <option value="#">--Select--</option>
                      <option value="Male">Running</option>
                      <option value="Female">Swimming</option>
                      <option value="Female">Cycling</option>
                    </select>
                  </div>
                  <div className="mt-5">
                    <p className="mb-1 text-slate-600 px-1 font-medium">
                      Duration <span className="text-red-500">*</span>{" "}
                    </p>
                    <input
                      type="text"
                      placeholder="Enter your Duration"
                      className="w-full border-b-2 py-2 rounded-md focus:outline-none text-slate-600 px-1"
                    />
                  </div>
                  <div className="mt-5">
                    <p className="mb-1 text-slate-600 px-1 font-medium">
                      Distance <span className="text-red-500">*</span>{" "}
                    </p>
                    <input
                      type="text"
                      placeholder="Enter your Distance"
                      className="w-full border-b-2 py-2 rounded-md focus:outline-none text-slate-600 px-1"
                    />
                  </div>
                  <div className="mt-5">
                    <p className="mb-1 text-slate-600 px-1 font-medium">
                      Unit of Measura<span className="text-red-500">*</span>{" "}
                    </p>
                    <select
                      name="unitofmeasura"
                      id="unitofmeasura"
                      placeholder="Group"
                      className="w-full border-b-2 py-2 rounded-md focus:outline-none text-slate-600"
                    >
                      <option value="#">--Select--</option>
                      <option value="km">Km</option>
                      <option value="meter">Meter</option>
                      <option value="yards">Yards</option>
                    </select>
                  </div>
                  <div className="mt-5">
                    <p className="mb-1 text-slate-600 px-1 font-medium">
                      Pace <span className="text-red-500">*</span>{" "}
                    </p>
                    <input
                      type="text"
                      placeholder="Enter your Pace"
                      className="w-full border-b-2 py-2 rounded-md focus:outline-none text-slate-600 px-1"
                    />
                  </div>
                  <div className="mt-5">
                    <div>
                      <p className="mb-1 text-slate-600 px-1 font-medium">
                        Tags <span className="text-red-500">*</span>{" "}
                      </p>
                      <TagInput
                        tags={tags}
                        setTags={setTags}
                        inputValue={inputValue}
                        setInputValue={setInputValue}
                      />
                    </div>
                  </div>
                  <div className="mt-5">
                    <p className="mb-1 text-slate-600 px-1 font-medium">
                      Sequence Number <span className="text-red-500">*</span>{" "}
                    </p>
                    <input
                      type="number"
                      placeholder="Enter your Sequence Number"
                      className="w-full border-b-2 py-2 rounded-md focus:outline-none text-slate-600 px-1"
                    />
                  </div>
                  <div className="mt-5 flex justify-around">
                    <div>
                      <p className="mb-1 text-slate-600 px-1 font-medium">
                        Level <span className="text-red-500">*</span>{" "}
                      </p>
                      <div className="flex p-1">
                        <input
                          type="checkbox"
                          placeholder="Enter your Sequence Number"
                        />
                        <lable className="ml-2">Level 1</lable>
                      </div>
                      <div className="flex p-1">
                        <input
                          type="checkbox"
                          placeholder="Enter your Sequence Number"
                        />
                        <lable className="ml-2">Level 2</lable>
                      </div>
                      <div className="flex p-1">
                        <input
                          type="checkbox"
                          placeholder="Enter your Sequence Number"
                        />
                        <lable className="ml-2">Level 3</lable>
                      </div>
                      <div className="flex p-1">
                        <input
                          type="checkbox"
                          placeholder="Enter your Sequence Number"
                        />
                        <lable className="ml-2">Level 4</lable>
                      </div>
                      <div className="flex p-1">
                        <input
                          type="checkbox"
                          placeholder="Enter your Sequence Number"
                        />
                        <lable className="ml-2">Level 5</lable>
                      </div>
                    </div>
                    <div>
                      <p className="mb-1 text-slate-600 px-1 font-medium">
                        Phases <span className="text-red-500">*</span>{" "}
                      </p>
                      <div className="flex p-1">
                        <input
                          type="checkbox"
                          placeholder="Enter your Sequence Number"
                        />
                        <lable className="ml-2">Base</lable>
                      </div>
                      <div className="flex p-1">
                        <input
                          type="checkbox"
                          placeholder="Enter your Sequence Number"
                        />
                        <lable className="ml-2">Build</lable>
                      </div>
                      <div className="flex p-1">
                        <input
                          type="checkbox"
                          placeholder="Enter your Sequence Number"
                        />
                        <lable className="ml-2">Peak</lable>
                      </div>
                    </div>
                    <div>
                      <p className="mb-1 text-slate-600 px-1 font-medium">
                        Category <span className="text-red-500">*</span>{" "}
                      </p>
                      <div className="flex p-1">
                        <input
                          type="checkbox"
                          placeholder="Enter your Sequence Number"
                        />
                        <lable className="ml-2">10 K</lable>
                      </div>
                      <div className="flex p-1">
                        <input
                          type="checkbox"
                          placeholder="Enter your Sequence Number"
                        />
                        <lable className="ml-2">Half Marathone</lable>
                      </div>
                      <div className="flex p-1">
                        <input
                          type="checkbox"
                          placeholder="Enter your Sequence Number"
                        />
                        <lable className="ml-2">Full Marathone</lable>
                      </div>
                      <div className="flex p-1">
                        <input
                          type="checkbox"
                          placeholder="Enter your Sequence Number"
                        />
                        <lable className="ml-2">50 - 100 Km</lable>
                      </div>
                      <div className="flex p-1">
                        <input
                          type="checkbox"
                          placeholder="Enter your Sequence Number"
                        />
                        <lable className="ml-2">101 - 300 Km</lable>
                      </div>
                    </div>
                  </div>
                  <div className="mt-5 bg-slate-200 p-5">
                    <p className="text-slate-500 font-bold">Guidlines</p>
                    <div className="px-5">
                      <ul className="list-disc">
                        <li className="text-slate-500">
                          Format of the routine is exercises and done one after
                          other in the order mentioned.
                        </li>
                        <li className="text-slate-500">
                          if you find that some of the exercises are best
                          avoided due to pre-existing condition or current
                          injury, please avoid them and repeat one of the others
                          in the list.
                        </li>
                        <li className="text-slate-500">
                          Each exercises has to be done for 30 secs.
                        </li>
                        <li className="text-slate-500">
                          Rest break after each exercises is 45 secs.
                        </li>
                        <li className="text-slate-500">
                          Rest break after one full circuit set is 120 secs.
                        </li>
                        <li className="text-slate-500">
                          Number of time you reapet the circuit = 5 times.
                        </li>
                      </ul>
                      <br />
                      <p className="text-slate-500">
                        <span className="font-bold">Exercise/Circuit List</span>
                        - Below are the exercises for today. Please Follow them.
                      </p>
                      <br />
                      <p className="text-slate-500">
                        <span className="font-bold">Exercise/Circuit List</span>
                        <br />
                        <span className="text-blue-400">link</span>
                      </p>
                      <br />
                      <p className="text-slate-500">
                        <span className="font-bold">Home - Lower Bode :-</span>
                        <span className="text-blue-400 pl-2">link</span>
                      </p>
                      <br />
                      <p className="text-slate-500">
                        <span className="font-bold">
                          Home - Core : side plank -
                        </span>
                        <span className="text-blue-400 pl-2">link</span>
                      </p>
                    </div>
                  </div>

                  <div className="mt-5 mb-2 flex">
                    <button
                      className="p-2.5 m-2 bg-orange-500 text-slate-50 w-full rounded-sm"
                      onClick={() => setIsOpenWoerkOut("showTable")}
                    >
                      Cancle
                    </button>
                    <button
                      className="p-2.5 m-2 bg-blue-500 text-slate-50 w-full rounded-sm"
                      // onClick={() => }
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Workout;
