import React, { useContext, useEffect, useState } from "react";
import { Form, Input, DatePicker, Modal, Table, Tag, Button } from "antd";
import { Checkbox, FormControlLabel, FormLabel, Grid, MenuItem, OutlinedInput, Radio, RadioGroup, Select, TextField } from "@mui/material";
import Swal from "sweetalert2";
import { useFormik } from "formik";
import { URL } from "../../../../API/api-endpoint";


const CalenderModal = ({selectedMergeId, workoutName, mergeWorkouts, openMergeModal,setOpenMergeModal, mergeModalData,  isLoading, allChalengesData, modalName, setIsAssignUserModalOpen, deleteWorkoutFromLibrary }) => {
    
    // let filteredData = mergeModalData?.filter((ele) => {
    //     return ele.badgeType.some((badge) => badge.activity_name.toLowerCase() === findTyep.toLowerCase());
    // });
    // console.log("mergeModalData",mergeModalData,selectedMergeId,findTyep,filteredData);
    return (
        <Modal
            width={600}
            // title={editData ? "Edit Challenge" : "Add Challenges"}
            open={openMergeModal}
            centered
            onCancel={() => setOpenMergeModal(false)}
          
        >

            <div className="flex min-h-[15vh] p-4">
                <Grid container>
                    <Grid container spacing={2}>

                        {
                            mergeModalData
                                ?.filter(
                                    (ele) =>
                                        ele?.borderColor === "red" || ele?.borderColor === "gray"
                                )
                                ?.map((ele) => (
                                    <Grid item xs={12} sm={4}>
                                        <div
                                            style={{
                                                border:`1px solid ${ele?.borderColor}`,
                                                height: "100%",
                                                // background: ,
                                                margin: "0 10px",
                                                padding: "7px",
                                                cursor:"pointer",
                                                color:"black",
                                                borderRadius:"4px"
                                            
                                            }}
                                            onClick={()=>mergeWorkouts(ele?.id)}
                                        >
                                            <img
                                                src={`${URL}/static/public/assets/${ele?.badge}`}
                                                alt="..."
                                                className="w-6 h-6 border-2 rounded-full cursor-pointer mt-2 mb-2"
                                            />
                                            <div>
                                                <strong>Activity</strong>: {ele?.workout}
                                            </div>
                                            <div>
                                                <strong>Distance</strong> :{ele?.workout_planned_distance} {ele?.distanceunit}
                                            </div>
                                        </div>
                                    </Grid>

                                ))
                        }
                    </Grid></Grid>

            </div>

        </Modal>
    )
}

export default CalenderModal

