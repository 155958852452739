import React from 'react'
import { Input } from '@mantine/core'
import { DatePicker } from '@mantine/dates'
import Header from '../components/Header'

const RaceDetailsPage = () => {
  return (
    <>
      <Header />
      {/* Topbar */}
      <div className='p-6'>
        <div className='flex items-end gap-x-2'>
          <h1 className='font-medium text-xl'>Athlete Race Details</h1>
        </div>
        <br />
        <div className='flex flex-col md:flex-row gap-4 md:gap-8 items-start md:items-end mb-6'>
          <div>
            <Input.Wrapper id='input-demo' label='Search'>
              <Input id='input-demo' />
            </Input.Wrapper>
          </div>
          <div>
            <DatePicker placeholder='Pick date' label='From' />
          </div>
          <div>
            <DatePicker placeholder='Pick date' label='To' />
          </div>
          <div>
            <button className='py-1.5 px-5 bg-blue-500 text-slate-50 rounded text-base'>
              Show races
            </button>
          </div>
        </div>
        {/* Table */}
        <div className='w-full mb-6'>
          <div className='my-6'>
            <div className='flex flex-col'>
              <div className='overflow-x-auto shadow-md'>
                <div className='inline-block min-w-full align-middle'>
                  <div className='overflow-hidden '>
                    <table className='min-w-full divide-y divide-gray-200 table-fixed dark:divide-gray-700'>
                      <thead className='bg-gray-100 '>
                        <tr>
                          <th
                            scope='col'
                            className='py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase dark:text-gray-400'
                          >
                            Name
                          </th>
                          <th
                            scope='col'
                            className='py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase dark:text-gray-400'
                          >
                            Race
                          </th>
                          <th
                            scope='col'
                            className='py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase dark:text-gray-400'
                          >
                            Date
                          </th>
                        </tr>
                      </thead>
                      <tbody className='bg-white divide-y divide-gray-200 '></tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default RaceDetailsPage
